'use strict';

import axios from 'axios';
import { USER_API_URL } from '../../../constants/Environment';

const loginUser = ({ commit }, userToLogin) => {
  const { email, password } = userToLogin;
  let userData = `${email}:${password}`;
  let encryptedUser = btoa(userData);
  axios
    .post(`${USER_API_URL}/login`, null, {
      headers: { auth: `Basic ${encryptedUser}` }
    })
    .then(response => {
      if (response.status === 200) {
        saveTokenOnlocalStorage(response);
        saveUserInfoOnlocalStorage({ commit }, response);
      }
    })
    .catch(error => {
      if (error.response.status === 401)
        commit('setUserInfo', { fullName: '', email: '', roleId: null });
    });
};

const saveTokenOnlocalStorage = response => {
  const { headers } = response;

  window.localStorage.setItem(
    'token',
    headers.authorization.slice('Bearer '.length, headers.authorization.length)
  );
};

const saveUserInfoOnlocalStorage = ({ commit }, response) => {
  const { headers } = response;

  let tokenBodyEncoded = headers.authorization.split('.')[1];
  let userData = JSON.parse(atob(tokenBodyEncoded));

  window.localStorage.setItem(
    'userInfo',
    `{"username":"${userData.email}", "roleId":"${userData.roleId}", "fullName":"${userData.fullName}"}`
  );
  commit('setUserInfo', userData);
};

const loggedUser = ({ commit }) => {
  let userData = JSON.parse(window.localStorage.getItem('userInfo'));
  if (userData)
    if (userData.fullName === undefined || userData.fullName === null) {
      axios
        .get(`${USER_API_URL}/user/by-email/${userData.username}`)
        .then(response => {
          commit('setUserInfo', {
            fullName: response.data.fullName,
            email: response.data.email,
            roleId: response.data.roleId
          });
          window.localStorage.setItem(
            'userInfo',
            `{"username":"${response.data.email}", "roleId":"${response.data.roleId}", "fullName":"${response.data.fullName}"}`
          );
        })
        .catch(error => {
          console.error('There was an error while sync the user' + error);
        });
    } else {
      commit('setUserInfo', {
        fullName: userData.fullName,
        email: userData.username,
        roleId: userData.roleId
      });
    }
};

const logoutUser = ({ commit }) => {
  commit('setUserInfo', { fullName: '', email: '', roleId: '' });
  window.localStorage.removeItem('token');
  window.localStorage.removeItem('userInfo');
};

const setDefaultUser = ({ commit }) => {
  commit('setUserInfo', { fullName: '', email: '', roleId: '' });
};

const retrieveAllUsersWithPassword = async ({ commit }) => {
  await axios.get(`${USER_API_URL}/user/with-password`).then(response => {
    commit('setUsersWithPassword', response.data);
  });
};

const updateUser = async ({ commit }, userToUpdate) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${USER_API_URL}/user/${userToUpdate.id}`, userToUpdate)
      .then(response => {
        commit('updateUser', userToUpdate);
        resolve(response);
      })
      .catch(error => {
        reject(error);
        console.error('There was an error while updating the user' + error);
      });
  });
};

const setNewUser = ({ commit }, newUser) => {
  commit('addUser', newUser);
};

const quickValidation = async ({ commit }, { code, pin }) => {
  await axios
    .get(`${USER_API_URL}/user/quick-validation?code=` + code + `&pin=` + pin)
    .then(response => {
      commit('setQuickValidationResponse', response.data);
    })
    .catch(error => {
      console.error('user quick validation failure' + error);
    });
};

export default {
  retrieveAllUsersWithPassword,
  updateUser,
  setNewUser,
  loginUser,
  loggedUser,
  logoutUser,
  setDefaultUser,
  quickValidation
};
