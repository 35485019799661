'use strict';

const setAllOrderStatuses = (state, orderStatuses) => {
  state.orderStatuses = orderStatuses;
};

const setInitialOrderStatus = (state, orderStatus) => {
  state.initialOrderStatus = orderStatus;
};

const setFinalOrderStatus = (state, orderStatus) => {
  state.finalOrderStatus = orderStatus;
};

const addOrderStatus = (state, orderStatusToAdd) => {
  state.orderStatuses.push(orderStatusToAdd);
};

const updateOrderStatus = (state, orderStatus) => {
  const foundOrderStatusIndex = state.orderStatuses.findIndex(
    os => os.id === orderStatus.id
  );
  if (foundOrderStatusIndex >= 0) {
    state.orderStatuses.splice(foundOrderStatusIndex, 1, orderStatus);
  }
};

const removeOrderStatus = (state, orderStatusToDeleteId) => {
  state.orderStatuses = state.orderStatuses.filter(
    orderStatus => orderStatus.id !== orderStatusToDeleteId
  );
};

export default {
  setAllOrderStatuses,
  setInitialOrderStatus,
  setFinalOrderStatus,
  addOrderStatus,
  updateOrderStatus,
  removeOrderStatus
};
