<i18n src="./resources/locales.json"></i18n>

<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" scrollable max-width="300px">
      <v-card>
        <v-card-title>{{ $t('selectTheParts') }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 280px">
          <v-list>
            <v-list-item v-for="part in allParts" :key="part.id">
              <v-list-item-action>
                <v-checkbox
                  :value="part.id"
                  v-model="checkedParts"
                ></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ part.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="primary" @click="save"> {{ $t('save') }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'PartsAssigmentListModal',

  data() {
    return {
      dialog: false,
      objectToAssignPart: {},
      checkedParts: [],
      initialParts: []
    };
  },

  computed: {
    ...mapGetters('parts', ['allParts'])
  },

  created() {
    this.retrieveAllParts();
  },

  methods: {
    ...mapActions('parts', ['retrieveAllParts']),

    open(objectToAssignPartToUpsert) {
      this.objectToAssignPart = Object.assign({}, objectToAssignPartToUpsert);
      if (this.objectToAssignPart.parts !== null)
        this.initialParts = this.objectToAssignPart.parts.map(part => {
          return part.id;
        });
      this.checkedParts = this.initialParts;
      this.dialog = true;
    },

    close() {
      this.objectToAssignPart = {};
      this.checkedParts = [];
      this.initialParts = [];
      this.dialog = false;
    },

    save() {
      if (
        JSON.stringify(this.initialParts) !== JSON.stringify(this.checkedParts)
      ) {
        let partsToSave = this.allParts.filter(part =>
          this.checkedParts.some(checkedPart => checkedPart === part.id)
        );
        this.objectToAssignPart.parts = partsToSave;
        this.$emit('confirm-action', this.objectToAssignPart);
      }
      this.close();
    }
  }
};
</script>
