<i18n src="./resources/locales.json"></i18n>

<template>
  <v-dialog persistent v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title class="headline">
        {{ $t('attention') }}
      </v-card-title>
      <v-card-text>
        {{ message }}
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="maintainOrder">{{
          $t('maintainOrder')
        }}</v-btn>
        <v-btn color="primary" @click="moveOrder">{{ $t('moveOrder') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'MoveOrderStatusMessageModal',

  data() {
    return {
      message: '',
      dialog: false
    };
  },

  methods: {
    open(modalInfo) {
      this.message = modalInfo.message;
      this.dialog = true;
    },

    moveOrder() {
      this.close();
      this.$emit('confirm-move-order');
    },

    maintainOrder() {
      this.close();
      this.$emit('maintain-order');
    },

    close() {
      this.dialog = false;
      this.message = '';
    }
  }
};
</script>
