'use strict';

import { SECOND_ORDER_STATE_POSITION } from '@/constants/ConfigurationConstants';

const allOrderStatuses = state => {
  return state.orderStatuses
    .sort(function(a, b) {
      return a.position - b.position;
    })
    .slice();
};

const getInitialOrderStatus = state => {
  return Object.assign({}, state.initialOrderStatus);
};

const getFinalOrderStatus = state => {
  return Object.assign({}, state.finalOrderStatus);
};

const getOrderStatusById = state => orderStatusId => {
  return Object.assign(
    {},
    state.orderStatuses.find(os => os.id === orderStatusId)
  );
};

const getAllInProgressOrderStatuses = state => {
  const filteredOrderStatuses = state.orderStatuses.filter(
    os => os.isInitial === false && os.isFinal === false
  );
  return filteredOrderStatuses
    .sort(function(a, b) {
      return a.position - b.position;
    })
    .slice();
};

const getSecondOrderStatePosition = state => {
  return state.orderStatuses.find(
    status => status.position === SECOND_ORDER_STATE_POSITION
  );
};

export default {
  allOrderStatuses,
  getInitialOrderStatus,
  getAllInProgressOrderStatuses,
  getFinalOrderStatus,
  getOrderStatusById,
  getSecondOrderStatePosition
};
