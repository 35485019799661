<i18n src="./resources/locales.json"></i18n>

<template>
  <v-dialog v-model="dialog" max-width="450px" persistent scrollable>
    <v-card v-if="order">
      <v-card-title>
        {{ $t('orderDetail') }}
        <v-spacer></v-spacer>
      </v-card-title>

      <v-card-subtitle>
        <span class="font-weight-bold">
          {{ numberFormatter(order.orderNumber) }}
        </span>
        - <OrderStatusChip :orderStatus="order.orderStatus" />
        <OrderCancelChip v-if="isOrderCancelled()" />
      </v-card-subtitle>

      <v-divider class="mx-4 mb-2"></v-divider>

      <v-card-text>
        <Alert
          v-if="isRenderInfoMessageForSpareParts()"
          color="info"
          icon="mdi-information"
          :message="$t('sparePartsInfoMessage')"
        />
        <Alert
          v-if="isRenderWarningMessageForSpareParts()"
          color="warning"
          icon="mdi-alert-circle"
          :message="$t('sparePartsWarningMessage')"
        />
        <Alert
          v-if="isRenderErrorMessageForSpareParts()"
          color="error"
          icon="mdi-alert"
          :message="$t('sparePartsAndEstimatedDeliverOrderErrorMessage')"
        />
        <Alert
          v-if="isFinishedWorkDateLowerThanRecieveSpareParts()"
          color="error"
          icon="mdi-alert"
          :message="$t('sparePartsAndWorkDeliverOrderErrorMessage')"
        />
        <Alert
          v-if="isOrderEstimatedDeliverDateLowerThanDeliverWork()"
          color="error"
          icon="mdi-alert"
          :message="$t('deliveryOrderAndWorkDeliverErrorMessage')"
        />

        <v-card-text class="mb-n3 mt-n8" v-if="order !== null">
          <span class="font-weight-bold primary--text"
            ><br />{{ $t('client') }}</span
          >
          <p class="mb-0">
            <span class="font-weight-bold">{{ $t('name') }}</span>
            {{ order.client.name }} {{ order.client.lastName }}
          </p>
          <p class="mb-0">
            <span class="font-weight-bold">{{ $t('phone') }}</span>
            {{ order.client.phone }}
          </p>
          <p class="mb-0">
            <span class="font-weight-bold">{{ $t('id') }}</span>
            {{ order.client.ci }}
          </p>

          <div v-if="order.receivedBy !== null">
            <span class="font-weight-bold primary--text"
              ><br />{{ $t('receivedBy') }}</span
            >
            <p class="mb-0">
              <span class="font-weight-bold">{{ order.receivedBy }}</span>
            </p>
          </div>

          <div v-if="order.canceledBy !== null">
            <span class="font-weight-bold primary--text"
              ><br />{{ $t('canceledBy') }}</span
            >
            <p class="mb-0">
              <span class="font-weight-bold">{{ order.canceledBy }}</span>
            </p>
          </div>

          <v-textarea
            v-model="newNote"
            :label="$t('note')"
            dense
            rows="2"
            auto-grow
            class="mt-6 red-label consistent-label"
            @input="onFieldChange"
          ></v-textarea>

          <div v-if="isNotQuickService()" class="mt-n3 mb-n5">
            <span class="font-weight-bold primary--text"
              ><br />{{ $t('engine') }}</span
            >
            <p>{{ renderEgine(order) }}</p>
          </div>
        </v-card-text>

        <v-list>
          <v-list-group
            v-for="item in sections"
            :key="item.title"
            v-model="item.active"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item v-if="item.name === 'Parts'">
              <v-list-item-content
                class="ml-n12"
                v-if="order !== null && order.detail.item"
              >
                <ul v-for="(part, i) in order.detail.item.parts" :key="i">
                  <li>{{ renderPart(part) }}</li>
                </ul>
                <p
                  v-if="
                    order.detail.item.parts !== undefined &&
                      order.detail.item.parts.length === 0
                  "
                >
                  {{ $t('thereAreNoParts') }}
                </p>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="item.name === 'Services'">
              <v-list-item-content
                class="ml-n16"
                v-if="order !== null && order.detail"
              >
                <ul
                  class="items-list"
                  v-for="(service, i) in order.detail.services"
                  :key="i"
                >
                  <li class="items mt-1" :style="getBulletColor(service, true)">
                    {{ renderService(service) }}
                  </li>
                  <div v-if="service && service.subServices.length === 0">
                    <p class="mt-2">
                      <span class="font-weight-bold ml-11"
                        >{{ $t('price') }}:</span
                      >
                      {{ service.price | currency('BOB') }}
                    </p>
                    <p
                      v-if="!hasAWorkerAssigned(service, service.id, true)"
                      class="mt-n2"
                    >
                      <span class="font-weight-bold ml-11">{{
                        $t('assignment')
                      }}</span>
                      {{ renderAssignmentDateTime(service, service.id, true) }}
                    </p>
                    <p
                      v-if="!hasAWorkerAssigned(service, service.id, true)"
                      class="mt-n2"
                    >
                      <span class="font-weight-bold ml-11">{{
                        $t('deliver')
                      }}</span>
                      {{ renderDeliverDateTime(service, service.id, true) }}
                    </p>
                    <p
                      v-if="
                        !hasAWorkerAssigned(service, service.id, true) &&
                          renderFinishedDateTime(service, service.id, true) !==
                            null
                      "
                      class="mt-n2"
                    >
                      <span class="font-weight-bold ml-11">{{
                        $t('finished')
                      }}</span>
                      {{ renderFinishedDateTime(service, service.id, true) }}
                    </p>
                    <p
                      v-if="
                        !hasAWorkerAssigned(service, service.id, true) &&
                          hasNote(service, service.id, true) !== null
                      "
                      class="mt-n2"
                    >
                      <span class="font-weight-bold ml-11">{{
                        $t('note') + ': '
                      }}</span>
                      <a
                        @click="openNote(hasNote(service, service.id, true))"
                        >{{ $t('seeNote') }}</a
                      >
                    </p>
                  </div>
                  <div
                    v-if="
                      service &&
                        service.subServices !== null &&
                        service.subServices.length !== 0
                    "
                  >
                    <ul
                      class="items-list"
                      v-for="(subService, i) in service.subServices"
                      :key="i"
                    >
                      <li
                        class="items mt-1"
                        :style="getBulletColor(subService, false)"
                      >
                        {{ renderSubServices(service, subService) }}
                      </li>
                      <p class="mt-2">
                        <span class="font-weight-bold ml-5"
                          >{{ $t('price') }}:</span
                        >
                        {{ subService.price | currency('BOB') }}
                      </p>
                      <p
                        v-if="
                          !hasAWorkerAssigned(service, subService.id, false)
                        "
                        class="mt-n2"
                      >
                        <span class="font-weight-bold ml-5">{{
                          $t('assignment')
                        }}</span>
                        {{
                          renderAssignmentDateTime(
                            service,
                            subService.id,
                            false
                          )
                        }}
                      </p>
                      <p
                        v-if="
                          !hasAWorkerAssigned(service, subService.id, false)
                        "
                        class="mt-n2"
                      >
                        <span class="font-weight-bold ml-5">{{
                          $t('deliver')
                        }}</span>
                        {{
                          renderDeliverDateTime(service, subService.id, false)
                        }}
                      </p>
                      <p
                        v-if="
                          !hasAWorkerAssigned(service, subService.id, false) &&
                            renderFinishedDateTime(
                              service,
                              subService.id,
                              false
                            ) !== null
                        "
                        class="mt-n2"
                      >
                        <span class="font-weight-bold ml-5">{{
                          $t('finished')
                        }}</span>
                        {{
                          renderFinishedDateTime(service, subService.id, false)
                        }}
                      </p>
                      <p
                        v-if="
                          !hasAWorkerAssigned(service, subService.id, false) &&
                            hasNote(service, subService.id, false) !== null
                        "
                        class="mt-n2"
                      >
                        <span class="font-weight-bold ml-5">{{
                          $t('note') + ': '
                        }}</span>
                        <a
                          @click="
                            openNote(hasNote(service, subService.id, false))
                          "
                          >{{ $t('seeNote') }}</a
                        >
                      </p>
                    </ul>
                  </div>
                </ul>
                <p
                  v-if="
                    order.detail.services && order.detail.services.length === 0
                  "
                >
                  {{ $t('thereAreNoServices') }}
                </p>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="item.name === 'Spare-Parts'">
              <v-list-item-content
                class="ml-n16"
                v-if="order !== null && order.detail && order.detail.item"
              >
                <ul
                  class="mt-n4"
                  v-for="(sparePart, i) in order.detail.item.spareParts"
                  :key="i"
                >
                  <li class="items mt-1">
                    <p class="mt-2">
                      <span class="mt-2">
                        <span class="font-weight-bold"
                          >{{ sparePart.quantity }}x</span
                        >
                        {{ renderSparePart(sparePart)
                        }}<span
                          class="font-weight-bold"
                          v-if="sparePart.price > 0"
                          >{{ sparePart.price | currency('BOB') }}</span
                        ><br /><span class="font-weight-bold primary--text">{{
                          renderReceivedSparePart(sparePart)
                        }}</span>
                        <span class="font-weight-bold ">{{
                          renderReceivedDateTimeOfSparePart(sparePart)
                        }}</span>
                        <br v-if="sparePart.receivedBy" />
                        <span
                          class="font-weight-bold primary--text"
                          v-if="sparePart.receivedBy"
                        >
                          {{ $t('receivedBy') }}
                        </span>
                        <span class="font-weight-bold">
                          {{ renderReceivedByUserOfSparePart(sparePart) }}
                        </span>
                      </span>
                    </p>
                  </li>
                </ul>
                <p v-if="order.detail.item.spareParts.length === 0">
                  {{ $t('thereAreNoSpareParts') }}
                </p>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
        <div v-if="!isOrderCancelled()">
          <v-card-text class="mt-n8">
            <div class="mb-7">
              <span class="font-weight-bold primary--text"
                ><br />{{ $t('receptionDate') }}</span
              >
            </div>
            <v-row>
              <v-col cols="4" sm="5">
                <DatePicker
                  :labelText="$t('date')"
                  :givenDate.sync="receptionDate"
                  :maxDate="undefined"
                  :minDate="undefined"
                  :icon="`mdi-calendar`"
                  :dateTextFieldClass="`mt-n6 mb-1`"
                  :disabledDate="true"
                />
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="4" sm="5">
                <HourPicker
                  :labelText="$t('hour')"
                  :givenHour.sync="receptionHour"
                  :icon="`mdi-clock-time-four-outline`"
                  :hourTextFieldClass="`mt-n6 mb-1 ml-n6`"
                  :disabledTime="true"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="mt-n14">
            <div class="mb-7">
              <span class="font-weight-bold primary--text"
                ><br />{{ $t('estimatedDeliverDate') }}</span
              >
            </div>
            <v-row>
              <v-col cols="4" sm="5">
                <DatePicker
                  :labelText="$t('date')"
                  :givenDate.sync="estimatedDeliverDate"
                  :maxDate="undefined"
                  :minDate="minDate"
                  :icon="`mdi-calendar`"
                  :dateTextFieldClass="`mt-n6 mb-1`"
                  :disabledDate="true"
                />
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="4" sm="5">
                <HourPicker
                  :labelText="$t('hour')"
                  :givenHour.sync="estimatedDeliverHour"
                  :icon="`mdi-clock-time-four-outline`"
                  :hourTextFieldClass="`mt-n6 mb-1 ml-n6`"
                  :disabledTime="true"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-if="order.deliverDate !== null" class="mt-n14">
            <div class="mb-7">
              <span class="font-weight-bold primary--text"
                ><br />{{ $t('deliverDate') }}</span
              >
            </div>
            <v-row>
              <v-col cols="4" sm="5">
                <DatePicker
                  :labelText="$t('date')"
                  :givenDate.sync="deliverDate"
                  :maxDate="undefined"
                  :minDate="undefined"
                  :icon="`mdi-calendar`"
                  :dateTextFieldClass="`mt-n6 mb-1`"
                  :disabledDate="true"
                />
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="4" sm="5">
                <HourPicker
                  :labelText="$t('hour')"
                  :givenHour.sync="deliverHour"
                  :icon="`mdi-clock-time-four-outline`"
                  :hourTextFieldClass="`mt-n6 mb-1 ml-n6`"
                  :disabledTime="true"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </div>
        <v-card-text
          v-if="isOrderCancelled() && order.cancellationDate !== null"
          class="mt-n8"
        >
          <div class="mb-7">
            <span class="font-weight-bold primary--text"
              ><br />{{ $t('cancellationDate') }}</span
            >
          </div>
          <v-row>
            <v-col cols="4" sm="5">
              <DatePicker
                :labelText="$t('date')"
                :givenDate.sync="cancellationDate"
                :maxDate="undefined"
                :minDate="undefined"
                :icon="`mdi-calendar`"
                :dateTextFieldClass="`mt-n6 mb-1`"
                :disabledDate="true"
              />
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="4" sm="5">
              <HourPicker
                :labelText="$t('hour')"
                :givenHour.sync="cancellationHour"
                :icon="`mdi-clock-time-four-outline`"
                :hourTextFieldClass="`mt-n6 mb-1 ml-n6`"
                :disabledTime="true"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text
          v-if="isOrderCancelled() && order.cancellationDate === null"
          class="mt-n8"
        >
          <div class="mb-7">
            <span class="font-weight-bold primary--text"
              ><br />{{ $t('unspecifiedCancellationDate') }}</span
            >
          </div>
        </v-card-text>

        <v-card-text class="mt-n8">
          <span v-if="order !== null && order.detail">
            <div class="mt-n8">
              <span class="font-weight-bold primary--text"
                ><br />{{ $t('totalToPay') }}</span
              >

              <p class="mb-0">
                <span class="mb-0 font-weight-bold">
                  {{ $t('services') }}
                </span>
                <br />
                <span class="deliver-option-width">
                  {{ $t('totalServices') }}: </span
                ><span class="deliver-option-width">
                  {{
                    calculateTotalServices(order.detail.services)
                      | currency('BOB')
                  }}
                </span>
                <span class="deliver-option-width">
                  {{ $t('discount') }}:
                </span>
                <v-text-field
                  v-model.number="newServicesDiscount"
                  type="number"
                  :rules="[
                    value =>
                      isEmpty(value) || $t('discountMustBeMoreOrEqualToZero'),
                    value => isPositive(value) || $t('discountCantBeNegative'),
                    value =>
                      isLessThanTotal(
                        value,
                        calculateTotalServices(order.detail.services)
                      ) || $t('serviceDiscountCantBeMoreThatTotalServices')
                  ]"
                  required
                  suffix="Bolivianos"
                  dense
                  class="deliver-option-width"
                ></v-text-field>
                <span class="deliver-option-width"> {{ $t('balance') }} </span
                ><span class="deliver-option-width">
                  {{
                    getRemainingServices(
                      order.detail.services,
                      newServicesDiscount
                    ) | currency('BOB')
                  }}
                </span>
              </p>

              <br />
              <v-divider class="mx-6"></v-divider>

              <p class="mb-0">
                <span class="mb-0 font-weight-bold">
                  {{ $t('totalSpareParts') }}
                </span>
                <br />
                <span class="deliver-option-width">
                  {{ $t('totalSpareParts') }}:</span
                ><span class="deliver-option-width">
                  {{
                    calculateTotalSpareParts(order.detail.item.spareParts)
                      | currency('BOB')
                  }}
                </span>
                <span class="deliver-option-width">
                  {{ $t('discount') }}:
                </span>
                <v-text-field
                  v-model.number="newSparePartsDiscount"
                  type="number"
                  :rules="[
                    value =>
                      isEmpty(value) || $t('discountMustBeMoreOrEqualToZero'),
                    value => isPositive(value) || $t('discountCantBeNegative'),
                    value =>
                      isLessThanTotal(
                        value,
                        calculateTotalSpareParts(order.detail.item.spareParts)
                      ) || $t('sparePartsDiscountCantBeMoreThatTotalSpareParts')
                  ]"
                  required
                  suffix="Bolivianos"
                  dense
                  class="deliver-option-width"
                ></v-text-field>
                <span class="deliver-option-width"> {{ $t('balance') }} </span
                ><span class="deliver-option-width">
                  {{
                    getRemainingSpareParts(
                      order.detail.item.spareParts,
                      newSparePartsDiscount
                    ) | currency('BOB')
                  }}
                </span>
              </p>

              <br />
              <v-divider class="mx-6"></v-divider>

              <p class="mb-0">
                <span class="mb-0 font-weight-bold">
                  {{ $t('totalServicesAndSpareParts') }}
                </span>
                <br />
                <span class="deliver-option-width"> {{ $t('total') }}:</span
                ><span class="deliver-option-width">
                  {{
                    getTotalServicesAndSpareParts(
                      order.detail.services,
                      order.detail.item.spareParts,
                      newServicesDiscount,
                      newSparePartsDiscount
                    ) | currency('BOB')
                  }}
                </span>
              </p>

              <br />
              <v-divider class="mx-6"></v-divider>

              <AdvancePayments
                :order="order"
                :servicesDiscount="newServicesDiscount"
                :sparePartsDiscount="newSparePartsDiscount"
              />

              <v-divider class="mx-6"></v-divider>

              <p class="mb-0">
                <span class="mb-0 font-weight-bold">
                  {{ $t('finalRemaining') }}
                </span>
                <br />
                <span class="deliver-option-width">
                  {{ $t('finalRemaining') }}:</span
                ><span class="deliver-option-width">
                  {{
                    calculateRestOfPayment(
                      order.detail.services,
                      order.detail.item.spareParts,
                      order.totalPayments,
                      newServicesDiscount,
                      newSparePartsDiscount
                    ) | currency('BOB')
                  }}
                </span>
              </p>
            </div>
          </span>
        </v-card-text>
      </v-card-text>

      <v-divider class="mx-4 mt-n4"></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close"> {{ $t('close') }} </v-btn>
        <v-btn color="primary" @click="save" v-if="isShowSaveButton()">
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import AdvancePayments from '@/components/reusable-components/advance-payments/AdvancePayments.vue';
import OrderStatusChip from '@/components/reusable-components/order-status-chip/OrderStatusChip.vue';
import OrderCancelChip from '@/components/reusable-components/order-cancel-chip/OrderCancelChip.vue';
import DatePicker from '@/components/reusable-components/date-picker/DatePicker.vue';
import HourPicker from '@/components/reusable-components/hour-picker/HourPicker.vue';
import Alert from '@/components/reusable-components/alert/Alert.vue';
import roles from '../../../mixins/roles.js';
import { BUSINESS_ID_RECTIFICATION } from '@/constants/ConfigurationConstants';

export default {
  name: 'OrderDetailModalInfo',

  components: {
    AdvancePayments,
    Alert,
    OrderStatusChip,
    OrderCancelChip,
    DatePicker,
    HourPicker
  },

  data: () => ({
    dialog: false,
    isValid: false,
    order: null,
    estimatedDeliverDatePickerMenu: false,
    estimatedDeliverHourPickerMenu: false,
    cancellationDate: null,
    cancellationHour: null,
    receptionDate: null,
    receptionHour: null,
    estimatedDeliverDate: null,
    estimatedDeliverHour: null,
    deliverDate: null,
    deliverHour: null,
    newServicesDiscount: null,
    newSparePartsDiscount: null,
    newNote: '',
    initialSpareParts: '',
    minDate: moment().format('YYYY-MM-DD'),
    assignedServices: [],
    businessId: BUSINESS_ID_RECTIFICATION,
    orderId: null,
    isFromListOrCalendar: null
  }),

  created() {
    this.$root.$on('open-order-detail-modal-info', this.open);
  },

  beforeDestroy() {
    this.$root.$off('open-order-detail-modal-info');
  },

  computed: {
    sections() {
      return [
        { name: 'Parts', title: this.$t('parts') },
        { name: 'Services', title: this.$t('services') },
        { name: 'Spare-Parts', title: this.$t('spareParts') }
      ];
    },

    ...mapGetters('workers', ['allWorkers'])
  },

  mixins: [roles],

  methods: {
    ...mapActions('orders', ['updateOrder', 'setOrderByIdAsEmpty']),
    ...mapActions('calendar', ['updateOrderFromCalendar']),

    open(orderToShow, isFromListOrCalendar) {
      this.order = Object.assign({}, orderToShow);
      this.orderId = orderToShow.id;
      this.isFromListOrCalendar = isFromListOrCalendar;
      this.renderOrder();
      this.dialog = true;
    },

    renderOrder() {
      this.parseAssignedServices();
      this.receptionDate = moment(this.order.creationDate).format('YYYY-MM-DD');
      this.receptionHour = moment(this.order.creationDate).format('HH:mm');
      if (this.order.estimatedDeliverDate !== null) {
        this.estimatedDeliverDate = moment(
          this.order.estimatedDeliverDate
        ).format('YYYY-MM-DD');
        this.estimatedDeliverHour = moment(
          this.order.estimatedDeliverDate
        ).format('HH:mm');
      }
      if (this.order.deliverDate !== null) {
        this.deliverDate = moment(this.order.deliverDate).format('YYYY-MM-DD');
        this.deliverHour = moment(this.order.deliverDate).format('HH:mm');
      }
      if (this.order.cancellationDate !== null) {
        this.cancellationDate = moment(this.order.cancellationDate).format(
          'YYYY-MM-DD'
        );
        this.cancellationHour = moment(this.order.cancellationDate).format(
          'HH:mm'
        );
      }
      this.newNote = this.order.note;
      this.newServicesDiscount = this.order.servicesDiscount;
      this.newSparePartsDiscount = this.getSparePartsDiscount();
      if (this.order.detail && this.order.detail.item) {
        this.initialSpareParts = JSON.stringify(
          this.order.detail.item.spareParts
        );
      }
    },

    close() {
      this.dialog = false;
      this.order = null;
      this.estimatedDeliverDate = null;
      this.estimatedDeliverHour = null;
      this.receptionDate = null;
      this.receptionHour = null;
      this.orderId = null;
      this.isFromListOrCalendar = null;
      this.setOrderByIdAsEmpty();
    },

    numberFormatter(orderNumber) {
      return orderNumber.toString().length < 5
        ? this.numberFormatter('0' + orderNumber)
        : orderNumber;
    },

    renderEgine(order) {
      let result = '';
      const { detail } = order;
      const { item } = detail;

      if (item) {
        const { specification } = item;
        const { engine, brand, numberOfCylinders } = specification;

        if (engine !== '') result += ` ${engine},`;
        if (brand !== '') result += ` ${brand},`;
        if (numberOfCylinders !== '') result += ` ${numberOfCylinders}`;
      }

      return result;
    },

    renderPart(part) {
      if (part && part.subParts.length !== 0)
        return `${part.name} (${this._renderSubParts(part)}) `;
      else return ` ${part.name} `;
    },

    _renderSubParts(part) {
      let subParts = part.subParts.filter(subPart => subPart.name !== '');
      return subParts.map(subPart => subPart.name).join(', ');
    },

    _searchWorker(isService, serviceId, foundAssignServices) {
      let foundAssign;
      let foundWorker = null;
      if (isService) {
        foundAssign = foundAssignServices.find(
          assign => assign.serviceId === serviceId
        );
      } else {
        foundAssign = foundAssignServices.find(
          assign => assign.subServiceId === serviceId
        );
      }
      if (foundAssign !== undefined) {
        foundWorker = this.allWorkers.find(
          worker => worker.id === foundAssign.workerId
        );
        if (foundWorker !== undefined) {
          foundWorker = foundWorker.name;
        }
      }
      if (foundWorker === null) {
        foundWorker = this.$t('n/a');
      }
      return foundWorker;
    },

    renderService(service) {
      let foundAssignServices = [];
      foundAssignServices = this.assignedServices.filter(
        assign => assign.serviceId === service.id
      );
      if (service.subServices.length === 0) {
        return ` ${service.name} (${this._searchWorker(
          true,
          service.id,
          foundAssignServices
        )})`;
      } else {
        return ` ${service.name} `;
      }
    },

    renderSubServices(service, subService) {
      let foundAssignServices = [];
      foundAssignServices = this.assignedServices.filter(
        assign => assign.serviceId === service.id
      );
      return ` ${subService.name} (${this._searchWorker(
        false,
        subService.id,
        foundAssignServices
      )})`;
    },

    renderAssignmentDateTime(service, serviceId, isService) {
      let foundAssignServices = [];
      let foundAssign;
      let assignedDateTime = null;
      const assignmentDateTimeMask = 'aa/mm/dd - hh:mm';
      foundAssignServices = this.assignedServices.filter(
        assign => assign.serviceId === service.id
      );
      if (isService) {
        foundAssign = foundAssignServices.find(
          assign => assign.serviceId === serviceId
        );
      } else {
        foundAssign = foundAssignServices.find(
          assign => assign.subServiceId === serviceId
        );
      }
      if (foundAssign !== undefined) {
        assignedDateTime = foundAssign.assignedDateTime;
        if (assignedDateTime !== undefined && assignedDateTime !== null) {
          return `${moment(assignedDateTime).format('DD-MM-YYYY')} - ${moment(
            assignedDateTime
          ).format('HH:mm')}`;
        } else {
          return assignmentDateTimeMask;
        }
      }
    },

    renderDeliverDateTime(service, serviceId, isService) {
      let foundAssignServices = [];
      let foundAssign;
      let deliverDateTime = null;
      const deliverDateTimeMask = 'aa/mm/dd - hh:mm';
      foundAssignServices = this.assignedServices.filter(
        assign => assign.serviceId === service.id
      );
      if (isService) {
        foundAssign = foundAssignServices.find(
          assign => assign.serviceId === serviceId
        );
      } else {
        foundAssign = foundAssignServices.find(
          assign => assign.subServiceId === serviceId
        );
      }
      if (foundAssign !== undefined) {
        deliverDateTime = foundAssign.deliverDateTime;
        if (deliverDateTime !== undefined && deliverDateTime !== null) {
          return `${moment(deliverDateTime).format('DD-MM-YYYY')} - ${moment(
            deliverDateTime
          ).format('HH:mm')}`;
        } else {
          return deliverDateTimeMask;
        }
      }
    },

    renderFinishedDateTime(service, serviceId, isService) {
      let foundAssignServices = [];
      let foundAssign;
      let finishedDateTime = null;
      foundAssignServices = this.assignedServices.filter(
        assign => assign.serviceId === service.id
      );
      if (isService) {
        foundAssign = foundAssignServices.find(
          assign => assign.serviceId === serviceId
        );
      } else {
        foundAssign = foundAssignServices.find(
          assign => assign.subServiceId === serviceId
        );
      }
      if (foundAssign !== undefined) {
        finishedDateTime = foundAssign.finishedDateTime;
        if (finishedDateTime !== undefined && finishedDateTime !== null) {
          return `${moment(finishedDateTime).format('DD-MM-YYYY')} - ${moment(
            finishedDateTime
          ).format('HH:mm')}`;
        } else {
          return null;
        }
      }
    },

    hasNote(service, serviceId, isService) {
      let foundAssignServices = [];
      let foundAssign;
      let note = '';
      foundAssignServices = this.assignedServices.filter(
        assign => assign.serviceId === service.id
      );
      if (isService) {
        foundAssign = foundAssignServices.find(
          assign => assign.serviceId === serviceId
        );
      } else {
        foundAssign = foundAssignServices.find(
          assign => assign.subServiceId === serviceId
        );
      }
      if (foundAssign !== undefined) {
        note = foundAssign.note;
        if (note !== undefined && note !== '') {
          return note;
        } else {
          return null;
        }
      }
    },

    openNote(note) {
      this.$root.$emit(
        'open-message-modal',
        Object.assign({}, { title: this.$t('note'), message: note })
      );
    },

    hasAWorkerAssigned(service, serviceId, isService) {
      let foundAssignServices = [];
      let assignedWorker;
      foundAssignServices = this.assignedServices.filter(
        assign => assign.serviceId === service.id
      );
      assignedWorker = this._searchWorker(
        isService,
        serviceId,
        foundAssignServices
      );
      if (assignedWorker !== this.$t('n/a')) {
        return false;
      } else {
        return true;
      }
    },

    renderSparePart(sparePart) {
      return ` ${sparePart.name} (${sparePart.measure} ${sparePart.unitOfMeasure}) `;
    },

    renderReceivedSparePart(sparePart) {
      if (sparePart.received) return this.$t('received');
      else return this.$t('onHold');
    },

    renderReceivedDateTimeOfSparePart(sparePart) {
      if (
        sparePart.received &&
        sparePart.receivedDate !== undefined &&
        sparePart.receivedDate !== null
      ) {
        return ` (${moment(sparePart.receivedDate).format(
          'DD-MM-YYYY'
        )} - ${moment(sparePart.receivedDate).format('HH:mm')})`;
      }
    },

    renderReceivedByUserOfSparePart(sparePart) {
      if (
        sparePart.received &&
        sparePart.receivedBy !== undefined &&
        sparePart.receivedBy !== null
      ) {
        return sparePart.receivedBy;
      }
    },

    calculateTotalServices(services) {
      let total = 0;
      if (services.length > 0) {
        if (services[0].isOther) {
          total = parseInt(services[0].price);
        } else {
          for (let service of services) {
            if (service.subServices !== null) {
              if (
                service.subServices.length === 0 ||
                (service.subServices.length === 1 &&
                  service.subServices[0].id.includes('other'))
              )
                total += Number(service.price);
              for (let subService of service.subServices) {
                total += parseInt(subService.price);
              }
            }
          }
        }
      }
      return total.toFixed(2);
    },

    calculateTotalSpareParts(spareParts) {
      let total = 0;
      if (spareParts !== undefined) {
        if (spareParts.length > 0) {
          spareParts.forEach(sp => {
            if (sp.price !== '' && sp.price !== null) {
              total += parseInt(sp.price);
            }
          });
        }
      }
      return total.toFixed(2);
    },

    calculateRestOfPayment(
      services,
      spareParts,
      totalPayments,
      newServicesDiscount,
      newSparePartsDiscount
    ) {
      return parseInt(
        this.getTotalServicesAndSpareParts(
          services,
          spareParts,
          newServicesDiscount,
          newSparePartsDiscount
        ) - totalPayments
      ).toFixed(2);
    },

    getRemainingServices(services, newServicesDiscount) {
      return parseInt(
        this.calculateTotalServices(services) - newServicesDiscount
      ).toFixed(2);
    },

    getRemainingSpareParts(spareParts, newSparePartsDiscount) {
      return parseInt(
        this.calculateTotalSpareParts(spareParts) - newSparePartsDiscount
      ).toFixed(2);
    },

    getSparePartsDiscount() {
      const { detail } = this.order;
      if (detail) {
        const { item } = detail;
        const { sparePartsDiscount } = item;
        if (sparePartsDiscount) return sparePartsDiscount;
        else return 0;
      } else return 0;
    },

    getBulletColor(service, isService) {
      let bulletColor;
      if (isService) {
        if (service.subServices.length === 0) {
          if (service.isFinished) {
            bulletColor = '#008000';
          } else {
            bulletColor = '#ff0000';
          }
        } else if (
          service.subServices.every(
            subService => subService.isFinished === true
          )
        ) {
          bulletColor = '#008000';
        } else {
          bulletColor = '#ff0000';
        }
      } else if (service.isFinished) {
        bulletColor = '#008000';
      } else {
        bulletColor = '#ff0000';
      }
      return {
        '--bullet-color': bulletColor
      };
    },

    parseAssignedServices() {
      if (typeof this.order.assignedServices === 'string') {
        this.assignedServices = JSON.parse(this.order.assignedServices);
      } else {
        this.assignedServices = this.order.assignedServices;
      }
    },

    printSparePart(data) {
      this.$root.$emit('print-spare-part-pdf', data.order.id);
    },

    isRenderInfoMessageForSpareParts() {
      return (
        this.isEstimatedDeliverDateNull() && this.isAllSparePartsRecieved()
      );
    },

    isRenderWarningMessageForSpareParts() {
      return (
        !this.isEstimatedDeliverDateNull() && !this.isAllSparePartsRecieved()
      );
    },

    isRenderErrorMessageForSpareParts() {
      return (
        !this.isEstimatedDeliverDateNull() &&
        this.isOrderEstimatedDeliverDateLowerThanRecieveSpareParts()
      );
    },

    isRenderErrorMessageForSparePartsAndFinishedWork() {
      return (
        !this.isEstimatedDeliverDateNull() &&
        this.isOrderEstimatedDeliverDateLowerThanRecieveSpareParts()
      );
    },

    isAllSparePartsRecieved() {
      return this.order.detail.item.spareParts.every(
        sp => sp.received === true
      );
    },

    isEstimatedDeliverDateNull() {
      return this.order.estimatedDeliverDate === null;
    },

    isOrderEstimatedDeliverDateLowerThanRecieveSpareParts() {
      return this.order.detail.item.spareParts.some(
        sp => this.order.estimatedDeliverDate < sp.receivedDate
      );
    },

    isFinishedWorkDateLowerThanRecieveSpareParts() {
      return this.order.detail.item.spareParts.some(sp =>
        this.assignedServices.some(as => as.deliverDateTime < sp.receivedDate)
      );
    },

    isOrderEstimatedDeliverDateLowerThanDeliverWork() {
      return (
        !this.isEstimatedDeliverDateNull() &&
        this.assignedServices.some(as =>
          moment(as.deliverDateTime).isAfter(this.order.estimatedDeliverDate)
        )
      );
    },

    isNotQuickService() {
      return !this.order.detail.services.some(s => s.id === 'quickService');
    },

    isOrderCancelled() {
      return this.order.canceled;
    },

    getTotalServicesAndSpareParts(
      services,
      spareParts,
      newServicesDiscount,
      newSparePartsDiscount
    ) {
      return (
        parseInt(this.getRemainingServices(services, newServicesDiscount)) +
        parseInt(this.getRemainingSpareParts(spareParts, newSparePartsDiscount))
      ).toFixed(2);
    },

    isQuickService() {
      return this.order.detail.services.find(s => s.id === 'quickService');
    },

    isEmpty(value) {
      return value !== '';
    },

    isPositive(value) {
      return value >= 0;
    },

    isLessThanTotal(value, total) {
      return value <= total;
    },

    hasDiscountChanges() {
      let totalServicesToPay = this.calculateTotalServices(
        this.order.detail.services
      );
      let totalSparePartsToPay = this.calculateTotalSpareParts(
        this.order.detail.item.spareParts
      );
      let originalServicesDiscount = this.order.servicesDiscount;
      let originalSparePartsDiscount = this.getSparePartsDiscount();

      if (
        this.newServicesDiscount !== '' &&
        this.newSparePartsDiscount !== ''
      ) {
        if (
          this.newServicesDiscount !== originalServicesDiscount ||
          this.newSparePartsDiscount !== originalSparePartsDiscount
        )
          return (
            this.isPositive(this.newServicesDiscount) &&
            this.isLessThanTotal(
              this.newServicesDiscount,
              totalServicesToPay
            ) &&
            this.isPositive(this.newSparePartsDiscount) &&
            this.isLessThanTotal(
              this.newSparePartsDiscount,
              totalSparePartsToPay
            )
          );
        else return false;
      } else return false;
    },

    isShowSaveButton() {
      return this.order.note !== this.newNote || this.hasDiscountChanges();
    },

    onFieldChange() {
      this.saveButtonVisible = this.isShowSaveButton();
    },

    save() {
      this.order.note = this.newNote;
      this.order.servicesDiscount = this.newServicesDiscount;
      this.order.detail.item.sparePartsDiscount = this.newSparePartsDiscount;
      if (this.isFromListOrCalendar === true) this.updateOrder(this.order);
      else this.updateOrderFromCalendar(this.order);
      this.close();
    }
  }
};
</script>

<style scoped>
@import './ScopedStyles.css';
</style>
