'use strict';

import axios from 'axios';
import { CLIENT_API_URL } from '../../../constants/Environment';

const retrieveAllOrderSparePartsOfOrder = async (
  { dispatch, commit },
  orderId
) => {
  dispatch('loading/setIsSparePartLoading', true, { root: true });
  try {
    await axios
      .get(`${CLIENT_API_URL}/order-spare-part/from-order/${orderId}`)
      .then(response => {
        commit('setAllOrderSpareParts', response.data);
      });
    dispatch('loading/setIsSparePartLoading', false, { root: true });
  } catch (error) {
    console.error('There was an error while retrieving spare parts' + error);
  }
};

const addOrderSparePart = async ({ commit }, orderSparePart) => {
  delete orderSparePart.id;
  return new Promise((resolve, reject) => {
    axios
      .post(`${CLIENT_API_URL}/order-spare-part`, orderSparePart)
      .then(response => {
        commit('addOrderSparePart', response.data);
        resolve(response);
      })
      .catch(error => {
        reject(error);
        console.error(
          'There was an error while posting the spare part' + error
        );
      });
  });
};

const updateOrderSparePart = async ({ commit }, orderSparePartToUpdate) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${CLIENT_API_URL}/order-spare-part/${orderSparePartToUpdate.id}`,
        orderSparePartToUpdate
      )
      .then(response => {
        commit('updateOrderSparePart', response.data);
        resolve(response);
      })
      .catch(error => {
        reject(error);
        console.error(
          'There was an error while updating the order spare part' + error
        );
      });
  });
};

const removeOrderSparePart = ({ commit }, sparePartToDeleteId) => {
  axios
    .delete(`${CLIENT_API_URL}/order-spare-part/${sparePartToDeleteId}`)
    .then(response => {
      commit('removeOrderSparePart', response.data);
    });
};

export default {
  retrieveAllOrderSparePartsOfOrder,
  addOrderSparePart,
  updateOrderSparePart,
  removeOrderSparePart
};
