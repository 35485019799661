<i18n src="./resources/locales.json"></i18n>

<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-form ref="cylinderArrangementForm" v-model="isValid">
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                class="required-field"
                v-model="cylinderArrangement.arrangement"
                :label="$t('arrangement')"
                :rules="[
                  arrangementRules.required,
                  () => isArrangementUsed() || $t('arrangementAlreadyExists')
                ]"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="primary" :disabled="!isValid" @click="save">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CylinderArrangementDetailModal',

  data: () => ({
    isValid: false,
    cylinderArrangement: {},
    dialog: false,
    defaultCylinderArrangement: {
      id: '-1',
      arrangement: ''
    },
    originalCylinderArrangement: {
      id: '-1',
      arrangement: ''
    }
  }),

  computed: {
    ...mapGetters('cylinders', ['allCylinderArrangements']),

    formTitle() {
      return this.isNewCylinderArrangement()
        ? this.$t('newArrangement')
        : this.$t('editArrangement');
    },

    arrangementRules() {
      return {
        required: value => !!value || this.$t('arrangementCanNotBeEmpty')
      };
    }
  },

  methods: {
    ...mapActions('cylinders', [
      'addCylinderArrangement',
      'updateCylinderArrangement'
    ]),

    open(cylinderArrangementToUpsert) {
      this.cylinderArrangement = Object.assign(
        {},
        cylinderArrangementToUpsert || this.defaultCylinderArrangement
      );
      this.originalCylinderArrangement = Object.assign(
        {},
        cylinderArrangementToUpsert || this.defaultCylinderArrangement
      );

      this.dialog = true;
    },

    close() {
      this.resetValidations();
      this.isValid = false;
      this.cylinderArrangement = Object.assign({}, this.defaultCylinder);
      this.originalCylinderArrangement = Object.assign(
        {},
        this.defaultCylinder
      );
      this.dialog = false;
    },

    async save() {
      this.cylinderArrangement = Object.assign({}, this.cylinderArrangement);

      if (this.isNewCylinderArrangement()) {
        try {
          await this.addCylinderArrangement(this.cylinderArrangement);
          this.$root.$emit(
            'open-status-message-snackbar',
            Object.assign(
              {},
              {
                message: `${this.$t('theCylinderArrangement')} ${
                  this.cylinderArrangement.arrangement
                } ${this.$t('wasCreatedSuccessfully')}`,
                messageType: 'success'
              }
            )
          );
        } catch (error) {
          this.$root.$emit(
            'open-status-message-snackbar',
            Object.assign(
              {},
              {
                message: this.$t('errorWhileCreatingTheCylinderArrangement'),
                messageType: 'error'
              }
            )
          );
        }
      } else {
        try {
          await this.updateCylinderArrangement(this.cylinderArrangement);
          this.$root.$emit(
            'open-status-message-snackbar',
            Object.assign(
              {},
              {
                message: this.$t(
                  'cylinderArrangementInfoWasUpdatedSuccessfully'
                ),
                messageType: 'success'
              }
            )
          );
        } catch (error) {
          this.$root.$emit(
            'open-status-message-snackbar',
            Object.assign(
              {},
              {
                message: this.$t(
                  'errorWhileUpdatingCylinderArrangementInfoPleaseTryAgain'
                ),
                messageType: 'error'
              }
            )
          );
        }
      }

      this.close();
    },

    resetValidations() {
      this.$refs.cylinderArrangementForm.resetValidation();
    },

    isArrangementUsed() {
      if (this.isArrangementExist()) {
        return (
          this.isNotNewCylinderArrangement() &&
          this.isNewArrangementEqualsAsOriginalArrangement()
        );
      } else return true;
    },

    isNewArrangementEqualsAsOriginalArrangement() {
      return (
        this.cylinderArrangement.arrangement ===
        this.originalCylinderArrangement.arrangement
      );
    },

    isArrangementExist() {
      return this.allCylinderArrangements.some(
        c => c.arrangement === this.cylinderArrangement.arrangement
      );
    },

    isNewCylinderArrangement() {
      return this.cylinderArrangement.id === '-1';
    },

    isNotNewCylinderArrangement() {
      return this.cylinderArrangement.id !== '-1';
    }
  }
};
</script>
