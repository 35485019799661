'use strict';

const setAllOrderWorkDonesAndDetailsOfOrder = (state, modelData) => {
  state.orderWorkDoneList = JSON.parse(
    JSON.stringify(modelData.orderWorkDoneList)
  );
  state.orderWorkDoneDetailList = JSON.parse(
    JSON.stringify(modelData.orderWorkDoneDetailList)
  );
  state.orderId = modelData.orderId;
};

const updateOrderWorkDoneAndDetails = (state, modelData) => {
  state.orderWorkDoneList = modelData.orderWorkDoneList;
  state.orderWorkDoneDetailList = modelData.orderWorkDoneDetailList;
  state.orderId = modelData.orderId;
};

export default {
  setAllOrderWorkDonesAndDetailsOfOrder,
  updateOrderWorkDoneAndDetails
};
