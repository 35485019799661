'use strict';

const setAllCalendarEvents = (state, calendarEvents) => {
  state.calendarEvents = calendarEvents;
};

const updateOrderOnCalendar = (state, order) => {
  const foundOrderIndex = state.calendarEvents.findIndex(
    o => o.id === order.id
  );
  if (foundOrderIndex >= 0)
    state.calendarEvents.splice(foundOrderIndex, 1, order);
  else state.calendarEvents.push(order);
};

const addAssignServiceOnCalendar = (state, assignedServiceToAdd) => {
  state.calendarEvents.push(assignedServiceToAdd);
};

const updateAssignServiceOnCalendar = (state, assignedServiceToUpdate) => {
  const foundOrderIndex = state.calendarEvents.findIndex(
    e =>
      e.orderId === assignedServiceToUpdate.orderId &&
      e.serviceId === assignedServiceToUpdate.serviceId &&
      e.subServiceId === assignedServiceToUpdate.subServiceId
  );
  if (foundOrderIndex >= 0)
    state.calendarEvents.splice(foundOrderIndex, 1, assignedServiceToUpdate);
};

export default {
  setAllCalendarEvents,
  updateOrderOnCalendar,
  addAssignServiceOnCalendar,
  updateAssignServiceOnCalendar
};
