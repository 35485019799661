<i18n src="./resources/locales.json"></i18n>

<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card class="mr-4 ml-4">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t('measures') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="save"> {{ $t('save') }} </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-list>
          <v-list-group
            v-for="unitOfMeasure in allUnitOfMeasures"
            :key="unitOfMeasure.id"
            no-action
            :value="true"
          >
            <template v-slot:activator>
              <v-list-item-action>
                <v-checkbox
                  v-model="checkedUnitOfMeasures"
                  :value="unitOfMeasure.id"
                  @click.stop=""
                ></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title
                  v-text="`${unitOfMeasure.name} (${unitOfMeasure.code})`"
                ></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="measure in getMeasuresByUnitOfMeasureId(unitOfMeasure.id)"
              :key="measure.id"
            >
              <v-list-item-action>
                <v-checkbox
                  v-model="checkedMeasures"
                  :value="measure.id"
                  :disabled="!checkedUnitOfMeasures.includes(unitOfMeasure.id)"
                ></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-text="measure.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <p v-if="allUnitOfMeasures.length === 0" class="pl-4">
            {{ $t('thereAreNoUnitsOfMeasure') }}
          </p>
        </v-list>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'MeasuresSelectionModal',

  data() {
    return {
      sparePart: {},
      defaultSparePart: {},
      checkedUnitOfMeasures: [],
      checkedMeasures: [],
      measures: {},
      defaultMeasures: {},
      unitOfMeasures: [],
      sparePartUnitOfMeasures: [],
      sparePartMeasures: [],
      initialCheckedUnitOfMeasures: [],
      initialCheckedMeasures: [],
      dialog: false
    };
  },

  computed: {
    ...mapGetters('spareParts', [
      'allUnitOfMeasures',
      'getMeasuresByUnitOfMeasureId',
      'allMeasures'
    ])
  },

  created() {
    this.retrieveAllUnitOfMeasures();
    this.retrieveAllMeasures();
  },

  methods: {
    ...mapActions('spareParts', [
      'updateSparePart',
      'retrieveAllUnitOfMeasures',
      'retrieveAllMeasures'
    ]),

    open(sparePartToUpsert) {
      this.sparePart = Object.assign({}, sparePartToUpsert);
      this.measures = Object.assign({}, JSON.parse(this.sparePart.measures));
      this.unitOfMeasures = JSON.parse(this.sparePart.unitOfMeasures);
      let selectedUnitOfMeasuresIds = [];
      let selectedMeasuresIds = [];
      this.allUnitOfMeasures.forEach(unitOfMeasure => {
        let foundUnitOfMeasure = this.unitOfMeasures.find(
          uom => uom === unitOfMeasure.code
        );
        if (foundUnitOfMeasure !== undefined) {
          selectedUnitOfMeasuresIds.push(unitOfMeasure.id);
          let measuresFromSparePart = this.measures[unitOfMeasure.code];
          let filteredMeasures = this.allMeasures.filter(
            measure => measure.unitOfMeasure === unitOfMeasure.id
          );
          filteredMeasures.forEach(measure => {
            let foundMeasure = measuresFromSparePart.find(
              measureFromSparePart => measureFromSparePart === measure.name
            );
            if (foundMeasure !== undefined) {
              selectedMeasuresIds.push(measure.id);
            }
          });
        }
      });
      this.initialCheckedUnitOfMeasures = selectedUnitOfMeasuresIds;
      this.checkedUnitOfMeasures = selectedUnitOfMeasuresIds;
      this.initialCheckedMeasures = selectedMeasuresIds;
      this.checkedMeasures = selectedMeasuresIds;
      this.dialog = true;
    },

    close() {
      this.sparePart = Object.assign({}, this.defaultSparePart);
      this.measures = Object.assign({}, this.defaultMeasures);
      this.checkedUnitOfMeasures = [];
      this.checkedMeasures = [];
      this.initialCheckedUnitOfMeasures = [];
      this.initialCheckedMeasures = [];
      this.sparePartUnitOfMeasures = [];
      this.sparePartMeasures = [];
      this.dialog = false;
    },

    async save() {
      if (
        JSON.stringify(this.initialCheckedUnitOfMeasures) !==
          JSON.stringify(this.checkedUnitOfMeasures) ||
        JSON.stringify(this.initialCheckedMeasures) !==
          JSON.stringify(this.checkedMeasures)
      ) {
        try {
          this.measures = Object.assign({}, this.defaultMeasures);
          this.sparePartUnitOfMeasures = [];
          this.sparePartUnitOfMeasures = this.allUnitOfMeasures.filter(uom =>
            this.checkedUnitOfMeasures.includes(uom.id)
          );
          this.sparePartMeasures = [];
          this.sparePartMeasures = this.allMeasures.filter(measure =>
            this.checkedMeasures.includes(measure.id)
          );
          this.unitOfMeasures = [];
          this.sparePartUnitOfMeasures.map(sparePartUnitMeasure => {
            this.unitOfMeasures.push(sparePartUnitMeasure.code);
            this.measures[sparePartUnitMeasure.code] = [];
            this.sparePartMeasures.map(sparePartMeasure => {
              if (sparePartUnitMeasure.id === sparePartMeasure.unitOfMeasure) {
                this.measures[sparePartUnitMeasure.code].push(
                  sparePartMeasure.name
                );
              }
            });
          });
          this.sparePart.measures = JSON.stringify(this.measures);
          this.sparePart.unitOfMeasures = JSON.stringify(this.unitOfMeasures);
          await this.updateSparePart(this.sparePart);
          this.$root.$emit(
            'open-status-message-snackbar',
            Object.assign(
              {},
              {
                message: this.$t(
                  'theMeasuresWereSuccessfullyAssignedToSparePart'
                ),
                messageType: 'success'
              }
            )
          );
          this.dialog = false;
        } catch (error) {
          this.$root.$emit(
            'open-status-message-snackbar',
            Object.assign(
              {},
              {
                message: this.$t('thereWasAnErrorWhileAssigningTheMeasures'),
                messageType: 'error'
              }
            )
          );
        }
      } else {
        this.sparePart = Object.assign({}, this.defaultSparePart);
        this.measures = Object.assign({}, this.defaultMeasures);
        this.checkedUnitOfMeasures = [];
        this.checkedMeasures = [];
        this.initialCheckedUnitOfMeasures = [];
        this.initialCheckedMeasures = [];
        this.sparePartUnitOfMeasures = [];
        this.sparePartMeasures = [];
        this.dialog = false;
      }
    }
  }
};
</script>
