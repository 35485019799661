<i18n src="./resources/locales.json"></i18n>

<template>
  <v-chip color="error" outlined class="mr-2" x-small dark>{{
    $t('cancelled')
  }}</v-chip>
</template>

<script>
export default {
  name: 'OrderCancelChip'
};
</script>
