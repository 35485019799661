'use strict';

const setLoading = (state, loadingToSet) => {
  state.loading = loadingToSet;
};

const setIsClientLoading = (state, loadingToSet) => {
  state.isClientLoading = loadingToSet;
};

const setIsPartLoading = (state, loadingToSet) => {
  state.isPartLoading = loadingToSet;
};

const setIsCylinderLoading = (state, loadingToSet) => {
  state.isCylinderLoading = loadingToSet;
};

const setIsCylinderArrangementLoading = (state, loadingToSet) => {
  state.isCylinderArrangementLoading = loadingToSet;
};

const setIsUnitsOfMeasureLoading = (state, loadingToSet) => {
  state.isUnitsOfMeasureLoading = loadingToSet;
};

const setIsMeasureLoading = (state, loadingToSet) => {
  state.isMeasureLoading = loadingToSet;
};

const setIsSparePartLoading = (state, loadingToSet) => {
  state.isSparePartLoading = loadingToSet;
};

const setIsServiceLoading = (state, loadingToSet) => {
  state.isServiceLoading = loadingToSet;
};

const setIsOrderStatusLoading = (state, loadingToSet) => {
  state.isOrderStatusLoading = loadingToSet;
};

const setIsOrderLoading = (state, loadingToSet) => {
  state.isOrderLoading = loadingToSet;
};

const setIsWorkerLoading = (state, loadingToSet) => {
  state.isWorkerLoading = loadingToSet;
};

const setIsBrandReportLoading = (state, loadingToSet) => {
  state.isBrandReportLoading = loadingToSet;
};

const setIsClientReportLoading = (state, loadingToSet) => {
  state.isClientReportLoading = loadingToSet;
};

const setIsEngineSeriesReportLoading = (state, loadingToSet) => {
  state.isEngineSeriesReportLoading = loadingToSet;
};

const setIsFinancialReportLoading = (state, loadingToSet) => {
  state.isFinancialReportLoading = loadingToSet;
};

const setIsServicesReportLoading = (state, loadingToSet) => {
  state.isServicesReportLoading = loadingToSet;
};

const setIsSparePartReportLoading = (state, loadingToSet) => {
  state.isSparePartReportLoading = loadingToSet;
};

const setIsOrdersReportByEngineBrandNumberCylindersLoading = (
  state,
  loadingToSet
) => {
  state.isOrdersReportByEngineBrandNumberCylinders = loadingToSet;
};

const setIsOrdersReportBySparePartsLoading = (state, loadingToSet) => {
  state.isOrdersReportBySparePartsLoading = loadingToSet;
};

const setIsWorkersReportLoading = (state, loadingToSet) => {
  state.isWorkersReportLoading = loadingToSet;
};

const setIsCalendarOrderLoading = (state, loadingToSet) => {
  state.isCalendarOrderLoading = loadingToSet;
};

const setIsDebtorsReportLoading = (state, LoadingToSet) => {
  state.isDebtorsReportLoading = LoadingToSet;
};

const setIsUnpickedOrdersReportLoading = (state, LoadingToSet) => {
  state.isUnpickedOrdersReportLoading = LoadingToSet;
};

export default {
  setLoading,
  setIsClientLoading,
  setIsPartLoading,
  setIsCylinderLoading,
  setIsCylinderArrangementLoading,
  setIsUnitsOfMeasureLoading,
  setIsMeasureLoading,
  setIsSparePartLoading,
  setIsServiceLoading,
  setIsOrderStatusLoading,
  setIsOrderLoading,
  setIsWorkerLoading,
  setIsBrandReportLoading,
  setIsClientReportLoading,
  setIsEngineSeriesReportLoading,
  setIsFinancialReportLoading,
  setIsServicesReportLoading,
  setIsSparePartReportLoading,
  setIsOrdersReportByEngineBrandNumberCylindersLoading,
  setIsWorkersReportLoading,
  setIsCalendarOrderLoading,
  setIsOrdersReportBySparePartsLoading,
  setIsDebtorsReportLoading,
  setIsUnpickedOrdersReportLoading
};
