'use strict';

const setAllParts = (state, parts) => {
  state.parts = parts;
};

const addPart = (state, part) => {
  state.parts.push(part);
};

const updatePart = (state, part) => {
  const foundPartIndex = state.parts.findIndex(pt => pt.id === part.id);
  if (foundPartIndex >= 0) {
    state.parts.splice(foundPartIndex, 1, part);
  }
};

const removePart = (state, part) => {
  state.parts = state.parts.filter(pt => pt.id !== part.id);
};

const addSubPart = (state, subPart) => {
  state.parts.map(part => {
    if (part.id === subPart.partId) {
      if (part.subParts === null) part.subParts = [];
      part.subParts.push(subPart);
    }
  });
};

const updateSubPart = (state, subPart) => {
  state.parts.map(part => {
    if (part.id === subPart.partId) {
      const foundSubPartIndex = part.subParts.findIndex(
        sp => sp.id === subPart.id
      );
      if (foundSubPartIndex >= 0)
        part.subParts.splice(foundSubPartIndex, 1, subPart);
    }
  });
};

const removeSubPart = (state, subPart) => {
  state.parts.map(part => {
    part.subParts = part.subParts?.filter(sp => sp.id !== subPart.id);
  });
};

export default {
  setAllParts,
  addPart,
  updatePart,
  removePart,
  addSubPart,
  updateSubPart,
  removeSubPart
};
