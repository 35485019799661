<i18n src="./resources/locales.json"></i18n>

<template>
  <v-snackbar
    timeout="7000"
    :color="messageType"
    bottom
    left
    v-model="snackbar"
    class="ml-5 mb-5"
  >
    {{ message }}

    <template v-slot:action="{ attrs }">
      <v-btn color="primaryVariant2" text v-bind="attrs" @click="close">
        {{ $t('close') }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'StatusMessageSnackbar',

  data() {
    return {
      message: '',
      messageType: '',
      snackbar: false
    };
  },

  created() {
    this.$root.$on('open-status-message-snackbar', this.open);
  },

  beforeDestroy() {
    this.$root.$off('open-status-message-snackbar');
  },

  methods: {
    open(snackbarInfo) {
      this.message = snackbarInfo.message;
      this.messageType = snackbarInfo.messageType;
      this.snackbar = true;
    },

    close() {
      this.snackbar = false;
    }
  }
};
</script>
