<i18n src="./resources/locales.json"></i18n>

<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-form ref="workerForm" v-model="isValid">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  class="required-field"
                  v-model="worker.name"
                  :label="$t('name')"
                  :rules="[nameRules.required]"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="primary" :disabled="!isValid" @click="save">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'WorkerDetailModal',

  data() {
    return {
      isValid: false,
      worker: {},
      dialog: false,
      defaultWorker: {
        id: '-1',
        name: '',
        relatedParts: '[]'
      }
    };
  },

  computed: {
    formTitle() {
      const { id } = this.worker;
      return id === '-1' ? this.$t('newWorker') : this.$t('editWorker');
    },

    nameRules() {
      return { required: value => !!value || this.$t('nameIsRequired') };
    }
  },

  methods: {
    ...mapActions('workers', ['addWorker', 'updateWorker']),

    open(workerToUpsert) {
      this.worker = Object.assign({}, workerToUpsert || this.defaultWorker);
      this.dialog = true;
    },

    close() {
      this.resetValidations();
      this.isValid = false;
      this.worker = Object.assign({}, this.defaultWorker);
      this.dialog = false;
    },

    async save() {
      const { id } = this.worker;
      this.worker = Object.assign({}, this.worker);

      if (id !== '-1') {
        try {
          await this.updateWorker(this.worker);
          this.$root.$emit(
            'open-status-message-snackbar',
            Object.assign(
              {},
              {
                message: this.$t('workerInfoWasUpdatedSuccessfully'),
                messageType: 'success'
              }
            )
          );
        } catch (error) {
          this.$root.$emit(
            'open-status-message-snackbar',
            Object.assign(
              {},
              {
                message: this.$t('errorWhileUpdatingWorkerInfoPleaseTryAgain'),
                messageType: 'error'
              }
            )
          );
        }
      } else {
        try {
          await this.addWorker(this.worker);
          this.$root.$emit(
            'open-status-message-snackbar',
            Object.assign(
              {},
              {
                message: `${this.$t('theWorker')} ${this.worker.name} ${this.$t(
                  'wasCreatedSuccessfully'
                )}`,
                messageType: 'success'
              }
            )
          );
        } catch (error) {
          this.$root.$emit(
            'open-status-message-snackbar',
            Object.assign(
              {},
              {
                message: this.$t('errorWhileCreatingTheWorker'),
                messageType: 'error'
              }
            )
          );
        }
      }

      this.close();
    },

    resetValidations() {
      this.$refs.workerForm.resetValidation();
    }
  }
};
</script>
