<i18n src="./resources/locales.json"></i18n>

<template>
  <v-dialog v-model="dialogDelete" max-width="500px">
    <v-card>
      <v-card-title class="headline">
        {{ title }}
      </v-card-title>
      <v-card-text>
        {{ message }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDelete">{{
          $t('cancel')
        }}</v-btn>
        <v-btn color="primary" @click="confirmAction">{{ $t('delete') }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DeleteConfirmModal',

  data() {
    return {
      id: '',
      title: '',
      message: '',
      dialogDelete: false
    };
  },

  methods: {
    open(modalInfo) {
      this.id = modalInfo.id;
      this.title = modalInfo.name;
      this.message = modalInfo.message;
      this.dialogDelete = true;
    },

    confirmAction() {
      this.$emit('confirm-action', this.id);
      this.dialogDelete = false;
    },

    closeDelete() {
      this.dialogDelete = false;
    }
  }
};
</script>
