<i18n src="./resources/locales.json"></i18n>

<template>
  <v-container fluid>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title
            >{{ $t('measuresOf') }} {{ unitOfMeasure.code }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn text @click="addMeasure">{{ $t('newMeasure') }}</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container fluid>
          <v-data-table
            :headers="headers"
            :items="getMeasuresByUnitOfMeasureId(unitOfMeasure.id)"
            item-key="name"
            sort-by="name"
            class="elevation-1"
            hide-default-footer
            disable-pagination
            :loading="getIsMeasureLoading"
            :loading-text="$t('loadingPleaseWait')"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                small
                class="mr-2"
                color="primary"
                @click="editMeasure(item)"
              >
                mdi-pencil-outline
              </v-icon>
              <!--v-icon
                small
                class="mr-2"
                color="primary"
                @click="deleteMeasure(item)"
              >
                mdi-delete
              </v-icon-->
            </template>
            <template v-slot:no-data>
              <p>
                {{ $t('thereAreNoMeasures') }}
              </p>
            </template>
            <template></template>
          </v-data-table>
        </v-container>
      </v-card>
    </v-dialog>

    <MeasureDetailModal ref="measureDetailModal" />
    <DeleteConfirmModal
      ref="deleteMeasureModal"
      @confirm-action="deleteMeasureConfirm"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DeleteConfirmModal from '@/components/reusable-components/delete-confirm-modal/DeleteConfirmModal.vue';
import MeasureDetailModal from '@/components/spare-part/measure-detail-modal/MeasureDetailModal.vue';

export default {
  name: 'MeasuresListModal',

  components: { MeasureDetailModal, DeleteConfirmModal },

  data: () => ({
    dialog: false,
    unitOfMeasure: {
      id: '-1'
    },
    defaultMeasure: {
      id: '-1',
      name: '',
      unitOfMeasure: ''
    }
  }),

  computed: {
    headers() {
      return [
        { text: this.$t('measure'), value: 'name', align: 'center' },
        {
          text: this.$t('actions'),
          value: 'actions',
          align: 'center',
          sortable: false
        }
      ];
    },

    ...mapGetters('spareParts', ['getMeasuresByUnitOfMeasureId']),
    ...mapGetters('loading', ['getIsMeasureLoading'])
  },

  created() {
    this.retrieveAllMeasures();
  },

  methods: {
    ...mapActions('spareParts', ['removeMeasure', 'retrieveAllMeasures']),

    open(unitOfMeasure) {
      this.unitOfMeasure = Object.assign({}, unitOfMeasure);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },

    addMeasure() {
      this.defaultMeasure.unitOfMeasure = this.unitOfMeasure.id;
      this.$refs.measureDetailModal.open(
        Object.assign({}, this.defaultMeasure)
      );
    },

    editMeasure(measureToUpdate) {
      this.$refs.measureDetailModal.open(Object.assign({}, measureToUpdate));
    },

    deleteMeasure(measureToDelete) {
      this.$refs.deleteMeasureModal.open(
        Object.assign(
          { message: this.$t('sureToDeleteThisMeasure') },
          measureToDelete
        )
      );
    },

    deleteMeasureConfirm(measureToDeleteId) {
      this.removeMeasure(measureToDeleteId);
    }
  }
};
</script>
