<i18n src="./resources/locales.json"></i18n>

<template>
  <v-dialog v-model="dialog" scrollable max-width="525px" persistent>
    <v-card v-if="order !== null">
      <v-card-title>{{ getTitle() }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 500px">
        <p class="mb-0 font-weight-bold">
          {{ $t('services') }}
        </p>
        <p class="mb-0">
          <span class="deliver-option-width"> {{ $t('totalServices') }} </span
          ><span class="deliver-option-width">
            {{ calculateTotalServices() | currency('BOB') }}
          </span>
          <span class="deliver-option-width"> {{ $t('discount') }} </span>
          <v-text-field
            v-model.number="servicesDiscount"
            type="number"
            :rules="[
              value =>
                isNotEmpty(value) || $t('discountMustBeMoreOrEqualToZero'),
              value => isPositive(value) || $t('discountCantBeNegative'),
              value =>
                isLessThanTotal(value, calculateTotalServices()) ||
                $t('serviceDiscountCantBeMoreThatTotalServices')
            ]"
            required
            suffix="Bolivianos"
            dense
            class="deliver-option-width"
          ></v-text-field>
          <span class="deliver-option-width"> {{ $t('balance') }}: </span
          ><span class="deliver-option-width">
            {{ getRemainingServices() | currency('BOB') }}
          </span>
        </p>

        <v-divider class="mx-6"></v-divider>

        <p class="mb-0 font-weight-bold">
          {{ $t('spareParts') }}
        </p>
        <p class="mb-0">
          <span class="deliver-option-width"> {{ $t('totalSpareParts') }} </span
          ><span class="deliver-option-width">
            {{ calculateTotalSpareParts() | currency('BOB') }}
          </span>
          <span class="deliver-option-width"> {{ $t('discount') }} </span>
          <v-text-field
            v-model.number="sparePartsDiscount"
            type="number"
            :rules="[
              value =>
                isNotEmpty(value) || $t('discountMustBeMoreOrEqualToZero'),
              value => isPositive(value) || $t('discountCantBeNegative'),
              value =>
                isLessThanTotal(value, calculateTotalSpareParts()) ||
                $t('sparePartsDiscountCantBeMoreThatTotalSpareParts')
            ]"
            required
            suffix="Bolivianos"
            dense
            class="deliver-option-width"
          ></v-text-field>
          <span class="deliver-option-width"> {{ $t('balance') }} </span
          ><span class="deliver-option-width">
            {{ getRemainingSpareParts() | currency('BOB') }}
          </span>
        </p>

        <v-divider class="mx-6"></v-divider>

        <p class="mb-0">
          <span class="mb-0 font-weight-bold">
            {{ $t('totalServicesAndSpareParts') }}
          </span>
          <br />
          <span class="deliver-option-width"> {{ $t('total') }}:</span
          ><span class="deliver-option-width">
            {{ calculateTotalServicesSpareParts() | currency('BOB') }}
          </span>
        </p>

        <v-divider></v-divider>

        <AdvancePayments
          :order="order"
          :servicesDiscount="servicesDiscount"
          :sparePartsDiscount="sparePartsDiscount"
        />

        <v-divider></v-divider>

        <v-row align="center" class="pl-3">
          <span class="font-weight-bold primary--text">
            {{ $t('deliverOrderTo') }}:
          </span>
          <v-checkbox
            v-model="order.isLoan"
            :label="`${$t('credit')}`"
            :disabled="isMakingAdvancePayment"
          ></v-checkbox>
        </v-row>

        <v-divider></v-divider>

        <v-row align="center">
          <v-col>
            <span class="font-weight-bold primary--text">
              {{ $t('paymentMethod') }}:
            </span>
            <v-radio-group v-model="paymentMethod" dense row class="mt-0">
              <v-radio :label="$t('cash')" :value="'cash'"></v-radio>
              <v-radio :label="$t('card')" :value="'card'"></v-radio>
              <v-radio :label="$t('transfer')" :value="'transfer'"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-row>
          <v-col cols="6">
            <v-text-field
              required
              class="required-field"
              :label="$t('paymentToMake')"
              v-model.number="paymentToMake"
              type="number"
              :rules="[
                value =>
                  (isNotEmpty(value) && isPositive(value)) ||
                  $t(
                    'paymentToMakeMustBeMoreOrEqualToZeroAndLessOrEqualToFinalBalance'
                  ),
                value =>
                  isTotalPaymentCompleteAndIsNotCredit() ||
                  $t(
                    'paymentToMakeMustBeMoreOrEqualToZeroAndLessOrEqualToFinalBalance'
                  ),
                value =>
                  isPaymentOfSparePartsCompleteAndIsCredit() ||
                  $t('paySpareParts')
              ]"
              suffix="Bolivianos"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <p class="mb-0">
          <span class="mb-0 font-weight-bold">
            {{ $t('finalRemaining') }}
          </span>
          <br />
          <span class="deliver-option-width"> {{ $t('finalRemaining') }}:</span
          ><span class="deliver-option-width">
            {{ calculateRestOfPayment() | currency('BOB') }}
          </span>
        </p>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" v-if="!isFromBoard" text @click="close">
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          v-if="isShowSaveButton()"
          @click="save"
          :disabled="disableSaveButton"
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <MessageToConfirmCreditModal ref="messageToConfirmCreditModal" />
  </v-dialog>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import MessageToConfirmCreditModal from '@/components/reusable-components/message-to-confirm-credit-modal/MessageToConfirmCreditModal.vue';
import AdvancePayments from '@/components/reusable-components/advance-payments/AdvancePayments.vue';

export default {
  name: 'OrderDeliverOptionModal',

  components: {
    AdvancePayments,
    MessageToConfirmCreditModal
  },

  data() {
    return {
      dialog: false,
      order: {},
      isDebitCard: false,
      paymentMethod: '',
      isFromBoard: false,
      paymentToMake: null,
      servicesDiscount: null,
      sparePartsDiscount: null,
      isMakingAdvancePayment: false,
      disableSaveButton: false
    };
  },

  watch: {
    paymentMethod(newValue) {
      this.isDebitCard = newValue === 'card';
    }
  },

  methods: {
    ...mapActions('orders', [
      'makePayment',
      'updateOrderStatusOfOrder',
      'updateOrder'
    ]),

    open(isFromBoard, isMakingAdvancePayment, orderToUpsert) {
      this.order = JSON.parse(JSON.stringify(orderToUpsert));
      this.isFromBoard = isFromBoard;
      this.paymentToMake = null;
      this.servicesDiscount = this.order.servicesDiscount;
      this.sparePartsDiscount = this.getSparePartsDiscount();
      this.isMakingAdvancePayment = isMakingAdvancePayment;
      this.dialog = true;
    },

    close() {
      this.order = Object.assign({}, {});
      this.isDebitCard = false;
      this.paymentMethod = '';
      this.isFromBoard = false;
      this.paymentToMake = null;
      this.servicesDiscount = null;
      this.sparePartsDiscount = null;
      this.isMakingAdvancePayment = false;
      this.disableSaveButton = false;
      this.dialog = false;
    },

    async save() {
      let isDiscountDifferent = false;
      if (
        this.order.servicesDiscount !== this.servicesDiscount ||
        this.order.detail.item.sparePartsDiscount !== this.sparePartsDiscount
      ) {
        this.order.servicesDiscount = this.servicesDiscount;
        this.order.detail.item.sparePartsDiscount = this.sparePartsDiscount;
        isDiscountDifferent = true;
      }

      let pay = {
        orderId: this.order.id,
        date: moment().format('YYYY-MM-DD[T]HH:mm:ss.SSSZ'),
        orderStatusId: this.order.orderStatusId,
        amount: parseInt(this.getInputPaymentToMake()),
        isDebitCard: this.isDebitCard,
        paymentMethod: this.paymentMethod
      };

      if (this.isMakingAdvancePayment) {
        this.disableSaveButton = true;
        if (isDiscountDifferent === true)
          await this.updateOrder(Object.assign({}, this.order));
        await this.makePayment({
          order: Object.assign({}, this.order),
          pay
        });
      } else {
        if (this.getIsLoan() === true) {
          this.$refs.messageToConfirmCreditModal.open({
            order: Object.assign({}, this.order),
            paymentToMake: this.paymentToMake,
            pay
          });
        } else {
          try {
            this.disableSaveButton = true;
            await this.updateOrder(Object.assign({}, this.order));
            await this.makePayment({
              order: Object.assign({}, this.order),
              pay
            });
            await this.updateOrderStatusOfOrder({
              orderToUpdate: Object.assign({}, this.order),
              date: moment().format('YYYY-MM-DD[T]HH:mm:ss.SSSZ')
            });
            this.$root.$emit(
              'open-status-message-snackbar',
              Object.assign(
                {},
                {
                  message: this.$t('orderStatusUpdatedSuccessfully'),
                  messageType: 'success'
                }
              )
            );
          } catch (error) {
            this.$root.$emit(
              'open-status-message-snackbar',
              Object.assign(
                {},
                {
                  message: this.$t('errorWhileUpdatingOrderStatus'),
                  messageType: 'error'
                }
              )
            );
          }
        }
      }
      this.close();
      if (!this.isFromBoard) {
        this.$emit('confirm-action');
      }
    },

    calculateTotalServices() {
      let total = 0;
      const { detail } = this.order;
      if (detail) {
        const { services } = detail;

        if (services.length > 0) {
          if (services[0].isOther) {
            total = parseInt(services[0].price);
          } else {
            for (let service of services) {
              if (service.subServices !== null) {
                if (
                  service.subServices.length === 0 ||
                  (service.subServices.length === 1 &&
                    service.subServices[0].id.includes('other'))
                )
                  total += Number(service.price);
                for (let subService of service.subServices) {
                  total += parseInt(subService.price);
                }
              }
            }
          }
        }
      }
      return parseInt(total).toFixed(2);
    },

    getRemainingServices() {
      return parseInt(
        this.calculateTotalServices() - this.servicesDiscount
      ).toFixed(2);
    },

    calculateTotalSpareParts() {
      let total = 0;
      const { detail } = this.order;
      if (detail) {
        const { item } = detail;
        const { spareParts } = item;

        if (spareParts !== undefined) {
          if (spareParts.length > 0) {
            spareParts.forEach(sp => {
              if (sp.price !== '' && sp.price !== null) {
                total += parseInt(sp.price);
              }
            });
          }
        }
      }
      return total.toFixed(2);
    },

    getRemainingSpareParts() {
      return parseInt(
        this.calculateTotalSpareParts() - this.sparePartsDiscount
      ).toFixed(2);
    },

    getSparePartsDiscount() {
      const { detail } = this.order;
      if (detail) {
        const { item } = detail;
        const { sparePartsDiscount } = item;
        if (sparePartsDiscount) return sparePartsDiscount;
        else return 0;
      } else return 0;
    },

    isNotEmpty(value) {
      return value !== '';
    },

    isPositive(value) {
      return value >= 0;
    },

    isLessThanTotal(value, total) {
      return value <= total;
    },

    isTotalPaymentCompleteAndIsNotCredit() {
      if (this.isMakingAdvancePayment === true) {
        return (
          this.isPositive(this.paymentToMake) &&
          this.isLessThanTotal(
            this.paymentToMake,
            this.calculateTotalServicesSpareParts()
          )
        );
      } else {
        if (this.getIsLoan() === false) return this.isTotalPaymentComplete();
        else return true;
      }
    },

    isPaymentOfSparePartsCompleteAndIsCredit() {
      if (this.isMakingAdvancePayment === true) {
        return (
          this.isPositive(this.paymentToMake) &&
          this.isLessThanTotal(
            this.paymentToMake,
            this.calculateTotalServicesSpareParts()
          )
        );
      } else {
        if (this.getIsLoan() === true)
          return this.isPaymentOfSparePartsComplete();
        else return true;
      }
    },

    calculateRestOfPayment() {
      let restOfPayment =
        this.calculateTotalServicesSpareParts() -
        this.getTotalPayments() -
        this.getInputPaymentToMake();
      return parseInt(restOfPayment).toFixed(2);
    },

    isPaymentToMakeEmptyOrNull() {
      return this.paymentToMake === null || this.paymentToMake === '';
    },

    isPaymentOfSparePartsComplete() {
      return (
        parseInt(this.getTotalPayments()) +
          parseInt(this.getInputPaymentToMake()) >=
        parseInt(this.getRemainingSpareParts())
      );
    },

    isTotalPaymentComplete() {
      return (
        parseInt(this.getInputPaymentToMake()) ===
        parseInt(
          this.calculateTotalServicesSpareParts() - this.getTotalPayments()
        )
      );
    },

    calculateTotalServicesSpareParts() {
      let total =
        parseInt(this.getRemainingServices()) +
        parseInt(this.getRemainingSpareParts());
      return total.toFixed(2);
    },

    getInputPaymentToMake() {
      return this.isPaymentToMakeEmptyOrNull()
        ? 0
        : parseInt(this.paymentToMake);
    },

    isPaymentToMakeCorrect() {
      return (
        this.paymentToMake !== '' &&
        this.paymentToMake !== null &&
        this.isPositive(this.paymentToMake) &&
        this.isTotalPaymentCompleteAndIsNotCredit() &&
        this.isPaymentOfSparePartsCompleteAndIsCredit()
      );
    },

    isShowSaveButton() {
      let totalServicesPaymentToMake = this.calculateTotalServices();
      let totalSparePartsPaymentToMake = this.calculateTotalSpareParts();
      if (this.servicesDiscount !== '' && this.sparePartsDiscount !== '') {
        return (
          this.isPaymentToMakeCorrect() &&
          this.isPositive(this.servicesDiscount) &&
          this.isLessThanTotal(
            this.servicesDiscount,
            totalServicesPaymentToMake
          ) &&
          this.isPositive(this.sparePartsDiscount) &&
          this.isLessThanTotal(
            this.sparePartsDiscount,
            totalSparePartsPaymentToMake
          )
        );
      } else {
        return false;
      }
    },

    getIsLoan() {
      return this.order.isLoan;
    },

    getTotalPayments() {
      return parseInt(this.order.totalPayments).toFixed(2);
    },

    getTitle() {
      return this.isMakingAdvancePayment === true
        ? `${this.$t('makePayment')}`
        : `${this.$t('orderDelivery')}`;
    }
  }
};
</script>

<style scoped>
@import './ScopedStyles.css';
</style>
