<i18n src="./resources/locales.json"></i18n>

<template>
  <v-container>
    <h1 class="ml-3 primary--text">{{ $t('services') }}</h1>
    <v-row
      class="mt-10 ml-16 mb-n12 mr-16"
      no-gutters
      style="flex-wrap: nowrap;"
    >
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="addService">
        {{ $t('newService') }}
      </v-btn>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="allServices"
      sort-by="name"
      class="elevation-1 ma-16"
      hide-default-footer
      disable-pagination
      :loading="getIsServiceLoading"
      :loading-text="$t('loadingPleaseWait')"
    >
      <template v-slot:[`item.price`]="{ item }">
        {{ item.price | currency(item.currencyCode) }}
      </template>
      <template v-slot:[`item.fixedPrice`]="{ item }">
        <v-simple-checkbox
          :value="item.fixedPrice"
          disabled
        ></v-simple-checkbox>
      </template>
      <template v-slot:[`item.fixedQuantity`]="{ item }">
        <v-simple-checkbox
          :value="item.fixedQuantity"
          disabled
        ></v-simple-checkbox>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          color="primary"
          @click="showSubServices(item)"
        >
          mdi-toolbox-outline
        </v-icon>
        <v-icon small class="mr-2" color="primary" @click="assignPart(item)">
          mdi-engine-outline
        </v-icon>
        <v-icon small class="mr-2" color="primary" @click="editService(item)">
          mdi-pencil-outline
        </v-icon>
        <!--v-icon small class="mr-2" color="primary" @click="deleteService(item)">
          mdi-delete
        </v-icon-->
      </template>
      <template v-slot:no-data>
        <p>
          {{ $t('thereAreNoServices') }}
        </p>
      </template>
    </v-data-table>
    <PartsAssigmentListModal
      ref="partsAssigmentListModal"
      @confirm-action="assignPartsConfirm"
    />
    <ServiceDetailModal ref="serviceDetailModal" />
    <SubServiceListModal ref="subServiceListModal" />
    <DeleteConfirmModal
      ref="deleteServiceModal"
      @confirm-action="deleteServiceConfirm"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DeleteConfirmModal from '@/components/reusable-components/delete-confirm-modal/DeleteConfirmModal.vue';
import PartsAssigmentListModal from '@/components/spare-part/parts-assignment-modal/PartsAssigmentListModal.vue';
import ServiceDetailModal from '@/components/service/service-detail-modal/ServiceDetailModal.vue';
import SubServiceListModal from '@/views/service/sub-service-list-modal/SubServiceListModal.vue';

export default {
  name: 'ServiceList',

  components: {
    DeleteConfirmModal,
    PartsAssigmentListModal,
    ServiceDetailModal,
    SubServiceListModal
  },

  computed: {
    headers() {
      return [
        { text: this.$t('name'), value: 'name', align: 'center' },
        {
          text: this.$t('currency'),
          value: 'currencyCode',
          align: 'center'
        },
        { text: this.$t('price'), value: 'price', align: 'center' },
        {
          text: this.$t('fixedPrice'),
          value: 'fixedPrice',
          align: 'center'
        },
        {
          text: this.$t('quantity'),
          value: 'quantity',
          align: 'center'
        },
        {
          text: this.$t('fixedQuantity'),
          value: 'fixedQuantity',
          align: 'center'
        },
        {
          text: this.$t('actions'),
          value: 'actions',
          align: 'center',
          sortable: false
        }
      ];
    },

    ...mapGetters('services', ['allServices']),
    ...mapGetters('loading', ['getIsServiceLoading'])
  },

  methods: {
    ...mapActions('services', ['removeService', 'updateService']),

    addService() {
      this.$refs.serviceDetailModal.open();
    },

    editService(serviceToUpdate) {
      this.$refs.serviceDetailModal.open(Object.assign({}, serviceToUpdate));
    },

    deleteService(serviceToDelete) {
      this.$refs.deleteServiceModal.open(
        Object.assign(
          { message: this.$t('sureToDeleteThisService') },
          serviceToDelete
        )
      );
    },

    deleteServiceConfirm(serviceToDeleteId) {
      this.removeService(serviceToDeleteId);
    },

    showSubServices(subServicesToShow) {
      this.$refs.subServiceListModal.open(subServicesToShow);
    },

    assignPart(serviceToAssignParts) {
      this.$refs.partsAssigmentListModal.open(serviceToAssignParts);
    },

    assignPartsConfirm(serviceToAssignParts) {
      this.updateService(serviceToAssignParts);
    }
  }
};
</script>
