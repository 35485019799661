<i18n src="./resources/locales.json"></i18n>

<template>
  <v-container>
    <h1 class="ml-3 primary--text">{{ $t('parts') }}</h1>
    <v-row
      class="mt-10 ml-16 mb-n12 mr-16"
      no-gutters
      style="flex-wrap: nowrap;"
    >
      <v-spacer></v-spacer>
      <v-col class="flex-grow-0 flex-shrink-0">
        <v-btn color="primary" @click="addPart">
          {{ $t('newPart') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="allParts"
      sort-by="name"
      class="elevation-1 ma-16"
      hide-default-footer
      disable-pagination
      :loading="getIsPartLoading"
      :loading-text="$t('loadingPleaseWait')"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small color="primary" class="mr-2" @click="showSubParts(item)">
          mdi-engine-outline
        </v-icon>
        <v-icon small color="primary" class="mr-2" @click="editPart(item)">
          mdi-pencil-outline
        </v-icon>
        <!--v-icon small color="primary" @click="deletePart(item)">
          mdi-delete
        </v-icon-->
      </template>
      <template v-slot:no-data>
        <p>
          {{ $t('thereAreNoParts') }}
        </p>
      </template>
    </v-data-table>
    <PartDetailModal ref="partDetailModal" />
    <SubPartsListModal ref="subPartsListModal" />
    <DeleteConfirmModal
      ref="deletePartModal"
      @confirm-action="deletePartConfirm"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PartDetailModal from '@/components/part/part-detail-modal/PartDetailModal.vue';
import SubPartsListModal from '@/views/part/sub-parts-list-modal/SubPartsListModal.vue';
import DeleteConfirmModal from '@/components/reusable-components/delete-confirm-modal/DeleteConfirmModal.vue';

export default {
  name: 'PartsList',

  components: { PartDetailModal, DeleteConfirmModal, SubPartsListModal },

  computed: {
    headers() {
      return [
        {
          text: this.$t('name'),
          value: 'name',
          align: 'center'
        },
        {
          text: this.$t('actions'),
          value: 'actions',
          align: 'center',
          sortable: false
        }
      ];
    },

    ...mapGetters('parts', ['allParts']),
    ...mapGetters('loading', ['getIsPartLoading'])
  },

  created() {
    this.retrieveAllParts();
  },

  methods: {
    ...mapActions('parts', ['removePart', 'retrieveAllParts']),

    addPart() {
      this.$refs.partDetailModal.open();
    },

    editPart(partToUpdate) {
      this.$refs.partDetailModal.open(Object.assign({}, partToUpdate));
    },

    deletePart(partToDelete) {
      this.$refs.deletePartModal.open(
        Object.assign(
          { message: this.$t('sureToDeleteThisPart') },
          partToDelete
        )
      );
    },

    deletePartConfirm(partToDeleteId) {
      this.removePart(partToDeleteId);
    },

    showSubParts(subPartsToShow) {
      this.$refs.subPartsListModal.open(subPartsToShow);
    }
  }
};
</script>
