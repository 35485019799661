<i18n src="./resources/locales.json"></i18n>

<template>
  <v-container>
    <h1 class="ml-3 primary--text">{{ orderTitleList }}</h1>
    <v-row class="tool-bar mt-10 ml-16 mb-n8 mr-16" no-gutters>
      <v-col cols="4">
        <v-text-field
          ref="searchBarNameOrCi"
          class="search-bar-orders"
          background-color="primaryVariant2"
          :label="$t('searchByIdName')"
          prepend-inner-icon="mdi-magnify"
          v-model="search"
          clearable
          outlined
          dense
          flat
          @keyup.enter="searchByInput"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          ref="searchBarOrderNumber"
          class="search-bar-orders"
          background-color="primaryVariant2"
          :label="$t('searchByOrderNumber')"
          prepend-inner-icon="mdi-magnify"
          v-model="searchOrderNumber"
          clearable
          outlined
          dense
          flat
          @keyup.enter="searchByInput"
        ></v-text-field>
      </v-col>

      <v-flex>
        <v-btn
          block
          color="primary"
          class="align-items: right"
          variant="tonal"
          @click="searchByInput"
          @keyup.enter="searchByInput"
        >
          {{ $t('search') }}
        </v-btn>
      </v-flex>
    </v-row>

    <v-row class="tool-bar mt-10 ml-16 mb-n3 mr-16" no-gutters>
      <v-col cols="4">
        <v-text-field
          ref="searchBarMotorSeries"
          class="search-bar-orders"
          background-color="primaryVariant2"
          :label="$t('searchByMotorSeries')"
          prepend-inner-icon="mdi-magnify"
          v-model="searchEngineSeries"
          clearable
          outlined
          dense
          flat
          @keyup.enter="searchByInput"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-menu
          ref="calendarMenu"
          v-model="calendarMenu"
          :close-on-content-click="false"
          :return-value.sync="dates"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="dateRangeText"
              :label="$t('dates')"
              ref="calendarMenuTextField"
              prepend-inner-icon="mdi-calendar"
              background-color="primaryVariant2"
              readonly
              v-bind="attrs"
              v-on="on"
              hide-details
              outlined
              dense
              flat
              class="search-bar-orders"
              clearable
              @click:clear="cleanDateField"
            ></v-text-field>
          </template>
          <v-date-picker
            ref="calendarMenuDatePicker"
            v-model="dates"
            range
            scrollable
            show-adjacent-months
            no-title
            :max="currentDate"
            :locale="$i18n.locale"
            color="primary"
            @change="verifyRangeChangesDate"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="calendarMenu = false">
              {{ $t('cancel') }}
            </v-btn>
            <v-btn color="primary" @click="savePickedDate">
              {{ $t('ok') }}
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-flex>
        <v-btn
          :disabled="isDisabled"
          block
          text
          color="primary"
          class="align-items: right"
          @click="cleanFields"
        >
          {{ $t('cleanSearchFields') }}
        </v-btn>
      </v-flex>
    </v-row>

    <v-divider class="ml-16 mt-7 mr-16"></v-divider>

    <v-row class="tool-bar mt-3 ml-16 mb-n12 mr-16" no-gutters>
      <v-col cols="4">
        <v-menu
          v-model="menuOrders"
          :close-on-content-click="false"
          :nudge-width="100"
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="ml-3" color="primary" dark v-bind="attrs" v-on="on">
              mdi-filter-variant
            </v-icon>
          </template>

          <v-card>
            <v-card-title>{{ $t('orders') }}</v-card-title>
            <v-card-text>
              <v-list>
                <v-list-item>
                  <v-radio-group
                    v-model="selectedSparePartsOption"
                    @change="setSparePartOption"
                  >
                    <v-radio
                      v-for="(sparePart, i) in sparePartsOptions"
                      :key="i"
                      :label="sparePart.name"
                      :value="sparePart.value"
                    ></v-radio>
                  </v-radio-group>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-menu>
        <v-subheader style="width: 10%; display:inline">{{
          $t('showing')
        }}</v-subheader>
        <v-subheader style="width: 30%; display:inline">
          -
          {{
            renderInformationAboutSelectedOrdersOnlyWithSpareParts()
          }}</v-subheader
        >
      </v-col>

      <v-col cols="5">
        <v-menu
          v-model="menuOrderStatus"
          :close-on-content-click="false"
          :nudge-width="100"
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="ml-3" color="primary" dark v-bind="attrs" v-on="on">
              mdi-filter-variant
            </v-icon>
          </template>

          <v-card>
            <v-card-title>{{ $t('orderStatuses') }}</v-card-title>
            <v-card-text>
              <v-list>
                <v-list-item>
                  <v-list-item-action>
                    <v-checkbox
                      :indeterminate="isAtLeastOneSelectedOrderStatuses()"
                      :input-value="isAllSelectedOrderStatuses()"
                      @change="setAllOrderStatuses"
                    ></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t('selectAllStates')
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-divider inset></v-divider>

                <v-list-item
                  v-for="(orderStatus, i) in allOrderStatuses"
                  :key="i"
                >
                  <v-list-item-action>
                    <v-checkbox
                      :value="orderStatus.id"
                      v-model="selectedOrderStatuses"
                    ></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      orderStatus.name
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-menu>

        <v-subheader style="width: 10%; display:inline">{{
          $t('showing')
        }}</v-subheader>
        <v-subheader style="width: 30%; display:inline"
          >-{{ renderInformationAboutSelectedOrderStatuses() }}</v-subheader
        >
      </v-col>

      <v-spacer></v-spacer>
      <v-btn color="primary" class="mb-2" @click="addOrder">
        {{ $t('newOrder') }}
      </v-btn>
    </v-row>
    <v-divider class="ml-16 mt-15 mr-16 mb-7"></v-divider>
    <v-row justify="center" align="center" class="mb-n10">
      <v-col cols="2" align="right">
        {{ $t('ordersPerGroup') }}
      </v-col>

      <v-col cols="1">
        <v-select
          :items="[10, 20, 50, 100]"
          v-model="pageSize"
          dense
          hide-details
          @change="getPaginatedOrders()"
        ></v-select>
      </v-col>

      <v-col cols="3">
        <v-pagination
          ref="paginationNumber"
          v-model="pageNumber"
          :length="Math.ceil(this.getHighestGroupCount / this.pageSize)"
          @input="getPaginatedOrders()"
        ></v-pagination>
      </v-col>
    </v-row>
    <v-list class="ma-16">
      <template v-for="(order, index) in allOrders">
        <Order
          :key="order.id"
          :order="order"
          :isShowDotsContextMenu="true"
          :isShowIcon="true"
          :showContextMenuOptions="true"
          :currentOrHistorical="ALL_CANCELED"
          :isShowForUnassignedServiceCalendar="false"
          @confirm-action-delete="deleteOrder"
        />
        <v-divider :key="index" :inset="true"></v-divider>
      </template>
      <p v-if="allOrders.length === 0" class="pl-4">
        {{ $t('thereAreNo') }} {{ orderTitleList.toLowerCase() }}
      </p>
    </v-list>

    <LoadingMask :isLoading="getIsOrderLoading" />
    <DeleteConfirmModal
      ref="deleteOrderModal"
      @confirm-action="deleteOrderConfirm"
    />

    <v-row justify="center" align="center" class="mb-n10">
      <v-col cols="2" align="right">
        {{ $t('ordersPerGroup') }}
      </v-col>

      <v-col cols="1">
        <v-select
          :items="[10, 20, 50, 100]"
          v-model="pageSize"
          dense
          hide-details
          @change="getPaginatedOrders()"
        ></v-select>
      </v-col>

      <v-col cols="3">
        <v-pagination
          ref="paginationNumber"
          v-model="pageNumber"
          :length="Math.ceil(this.getHighestGroupCount / this.pageSize)"
          @input="getPaginatedOrders()"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Order from '@/components/reusable-components/order/Order.vue';
import DeleteConfirmModal from '@/components/reusable-components/delete-confirm-modal/DeleteConfirmModal.vue';
import LoadingMask from '@/components/reusable-components/loading-mask/LoadingMask.vue';
import {
  BUSINESS_ID_RECTIFICATION,
  EMPTY_GUID,
  ALL_CANCELED
} from '@/constants/ConfigurationConstants';
import moment from 'moment';

export default {
  name: 'OrdersList',

  components: {
    Order,
    DeleteConfirmModal,
    LoadingMask
  },

  data: () => ({
    search: '',
    searchOrderNumber: '',
    searchEngineSeries: '',
    menuOrders: false,
    menuOrderStatus: false,
    selectedOrderStatuses: [],
    selectedSparePartsOption: 1,
    dates: [],
    currentDate: moment().format('YYYY-MM-DD'),
    calendarMenu: false,
    pageNumber: 1,
    pageSize: 10,
    parametersOfSearchingAndFilter: {
      orderSearchType: ALL_CANCELED,
      businessId: BUSINESS_ID_RECTIFICATION,
      orderStatusId: '',
      filterOrdersBy: ALL_CANCELED,
      keyword: '',
      keywordOrderNumber: '',
      keywordEngineSeries: '',
      workerId: EMPTY_GUID,
      isLoan: '',
      isPaginated: 'true',
      fromDate: '',
      toDate: '',
      pageNumber: '',
      pageSize: ''
    },
    isDisabled: true,
    ALL_CANCELED,
    backdoor: 0
  }),

  props: ['orderTitleList'],

  computed: {
    ...mapGetters('orders', [
      'allOrders',
      'allPaginatedOrders',
      'getCountOrders',
      'getTotalCountOrders',
      'getHighestGroupCount'
    ]),
    ...mapGetters('orderStatuses', ['allOrderStatuses']),
    ...mapGetters('loading', ['getIsOrderLoading']),

    sparePartsOptions() {
      return [
        {
          value: 1,
          name: this.$t('allOrders')
        },
        {
          value: 2,
          name: this.$t('onlyOrdersWithSpareParts')
        },
        {
          value: 3,
          name: this.$t('ordersWithSomeSparePartsReceived')
        },
        {
          value: 4,
          name: this.$t('ordersWithAllSparePartsReceived')
        }
      ];
    },

    dateRangeText() {
      this.backdoor;
      if (this.dates.length !== 0) {
        return this.dates.map(d => this.convertDate(d)).join(' - ');
      } else {
        return '';
      }
    },

    searchBarElements() {
      return `${this.search}|${this.searchOrderNumber}|${this.searchEngineSeries}|${this.dateRangeText}`;
    }
  },

  watch: {
    searchBarElements(newVal, oldVal) {
      if (newVal !== oldVal) {
        const [
          newSearch,
          newSearchOrderNumber,
          newSearchEngineSeries,
          newDateRangeText
        ] = newVal.split('|');

        if (
          (!newSearch || newSearch === 'null') &&
          (!newSearchOrderNumber || newSearchOrderNumber === 'null') &&
          (!newSearchEngineSeries || newSearchEngineSeries === 'null') &&
          (!newDateRangeText || newDateRangeText === 'null')
        ) {
          this.isDisabled = true;
        } else {
          this.isDisabled = false;
        }
      }
    },

    selectedOrderStatuses: {
      handler: function(newVal, oldVal) {
        if (this.isAllSelectedOrderStatuses()) {
          this.parametersOfSearchingAndFilter.orderStatusId = newVal;
          this.getPaginatedOrders();
        } else {
          if (newVal !== oldVal) {
            this.parametersOfSearchingAndFilter.orderStatusId = newVal;
            this.pageNumber = 1;
            this.searchByInput();
          }
        }
      },
      deep: true
    },

    allOrderStatuses: {
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal && oldVal.length === 0)
          this.setAllOrderStatusIdsOnSelectedOrderStatuses();
      },
      deep: true
    },

    pageSize: {
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.pageNumber = 1;
        }
      },
      deep: true
    }
  },

  created() {
    this.retrieveAllBusinessCanceledOrders({
      businessId: BUSINESS_ID_RECTIFICATION,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize
    });
  },

  mounted() {
    this.setAllOrderStatusIdsOnSelectedOrderStatuses();
  },

  methods: {
    ...mapActions('orders', [
      'retrieveAllBusinessCanceledOrders',
      'searchOrderPaginated',
      'removeOrder'
    ]),

    getPaginatedOrders() {
      if (
        !this.parametersOfSearchingAndFilter.keyword &&
        !this.parametersOfSearchingAndFilter.keywordOrderNumber &&
        !this.parametersOfSearchingAndFilter.keywordEngineSeries &&
        !this.parametersOfSearchingAndFilter.fromDate &&
        !this.isSelectedOrdersOnlyWithSpareParts() &&
        this.isAllSelectedOrderStatuses()
      ) {
        this.retrieveAllBusinessCanceledOrders({
          businessId: BUSINESS_ID_RECTIFICATION,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
          orderStatusId: this.parametersOfSearchingAndFilter.orderStatusId
        });
      } else {
        this.parametersOfSearchingAndFilter.pageNumber = this.pageNumber;
        this.parametersOfSearchingAndFilter.pageSize = this.pageSize;
        this.searchOrderPaginated(this.parametersOfSearchingAndFilter);
      }
    },

    addOrder() {
      this.$root.$emit('open-rectifier-order-detail-modal');
    },

    deleteOrder(order) {
      this.$refs.deleteOrderModal.open(
        Object.assign(
          {
            message: this.$t('sureToDeleteThisOrder')
          },
          order
        )
      );
    },

    deleteOrderConfirm(order) {
      this.removeOrder(order);
    },

    isAllSelectedOrderStatuses() {
      return this.selectedOrderStatuses.length === this.allOrderStatuses.length;
    },

    isSelectedOrderStatusesEmpty() {
      return this.selectedOrderStatuses.length === 0;
    },

    isAtLeastOneSelectedOrderStatuses() {
      return (
        this.selectedOrderStatuses.length > 0 &&
        this.selectedOrderStatuses.length <= this.allOrderStatuses.length - 1
      );
    },

    setAllOrderStatuses(e) {
      if (e) this.setAllOrderStatusIdsOnSelectedOrderStatuses();
      else this.clearSelectedOrderStatuses();
    },

    setAllOrderStatusIdsOnSelectedOrderStatuses() {
      this.clearSelectedOrderStatuses();
      this.allOrderStatuses.forEach(os => {
        this.selectedOrderStatuses.push(os.id);
      });
    },

    clearSelectedOrderStatuses() {
      this.selectedOrderStatuses = [];
    },

    renderInformationAboutSelectedOrderStatuses() {
      if (this.isAllSelectedOrderStatuses())
        return this.$t('allOrdersStatuses');
      else return this.$t('someOrderStatuses');
    },

    renderInformationAboutSelectedOrdersOnlyWithSpareParts() {
      switch (this.selectedSparePartsOption) {
        case 1:
          return this.$t('allOrders');

        case 2:
          return this.$t('onlyOrdersWithSpareParts');

        case 3:
          return this.$t('ordersWithSomeSparePartsReceived');

        case 4:
          return this.$t('ordersWithAllSparePartsReceived');
      }
    },

    isSelectedOrdersOnlyWithSpareParts() {
      return (
        this.selectedSparePartsOption === 2 ||
        this.selectedSparePartsOption === 3 ||
        this.selectedSparePartsOption === 4
      );
    },

    setSparePartOption() {
      switch (this.selectedSparePartsOption) {
        case 1:
          this.parametersOfSearchingAndFilter.filterOrdersBy = 'All';
          break;
        case 2:
          this.parametersOfSearchingAndFilter.filterOrdersBy = 'sparePartsOnly';
          break;
        case 3:
          this.parametersOfSearchingAndFilter.filterOrdersBy = 'someSpareParts';
          break;
        case 4:
          this.parametersOfSearchingAndFilter.filterOrdersBy = 'allSpareParts';
          break;
      }

      if (this.selectedSparePartsOption !== 1) {
        this.searchByInput();
      } else {
        this.pageNumber = 1;
        this.parametersOfSearchingAndFilter.pageNumber = 1;
        this.parametersOfSearchingAndFilter.pageSize = this.pageSize;
        this.makeSearch();
      }
    },

    makeSearch() {
      if (
        !this.parametersOfSearchingAndFilter.keyword &&
        !this.parametersOfSearchingAndFilter.keywordOrderNumber &&
        !this.parametersOfSearchingAndFilter.keywordEngineSeries &&
        !this.parametersOfSearchingAndFilter.fromDate &&
        !this.isSelectedOrdersOnlyWithSpareParts() &&
        this.isAllSelectedOrderStatuses()
      ) {
        this.retrieveAllBusinessCanceledOrders({
          businessId: BUSINESS_ID_RECTIFICATION,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize
        });
      } else {
        this.parametersOfSearchingAndFilter.pageNumber = 1;
        this.pageNumber = 1;
        this.parametersOfSearchingAndFilter.pageSize = this.pageSize;
        this.searchOrderPaginated(this.parametersOfSearchingAndFilter);
      }
    },

    searchByInput() {
      const [first, second] = this.dates;
      let parameters = {};
      if (first) parameters.fromDate = moment(first).format();
      if (second) parameters.toDate = this.setDateUntilLastMinute(second);
      else parameters.toDate = this.setDateUntilLastMinute(first);

      this.parametersOfSearchingAndFilter.fromDate = parameters.fromDate;
      this.parametersOfSearchingAndFilter.toDate = parameters.toDate;

      this.parametersOfSearchingAndFilter.keyword = this.search;
      this.parametersOfSearchingAndFilter.keywordOrderNumber = this.searchOrderNumber;
      this.parametersOfSearchingAndFilter.keywordEngineSeries = this.searchEngineSeries;

      this.makeSearch();
    },

    cleanDateField() {
      this.calendarMenu = false;
      this.dates.length = 0;
      this.parametersOfSearchingAndFilter.fromDate = '';
      this.parametersOfSearchingAndFilter.toDate = '';
      this.backdoor++;
    },

    cleanFields() {
      this.$refs.searchBarNameOrCi.reset();
      this.$refs.searchBarOrderNumber.reset();
      this.$refs.searchBarMotorSeries.reset();
      this.parametersOfSearchingAndFilter.keyword = '';
      this.parametersOfSearchingAndFilter.keywordOrderNumber = '';
      this.parametersOfSearchingAndFilter.keywordEngineSeries = '';
      this.cleanDateField();
      this.pageNumber = 1;
      this.makeSearch(this.parametersOfSearchingAndFilter);
    },

    savePickedDate() {
      this.$refs.calendarMenu.save(this.dates);
    },

    convertDate(date) {
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },

    verifyRangeChangesDate() {
      const [first, second] = this.dates;
      if (first !== undefined && second !== undefined) {
        if (moment(first).isSame(second)) this.dates = [first];
        if (moment(first).isAfter(second)) this.dates = [second, first];
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
    },

    getFormatDate(date) {
      return moment(date).format('YYYY-MM-DD');
    },

    setDateUntilLastMinute(date) {
      return moment(date)
        .set({ hour: 23, minute: 59, second: 59 })
        .format();
    }
  }
};
</script>

<style scoped>
@import './ScopedStyles.css';
</style>
