'use strict';

const setLoadingOnTrue = ({ commit }) => {
  commit('setLoading', true);
};

const setLoadingOnFalse = ({ commit }) => {
  commit('setLoading', false);
};

const setIsClientLoading = ({ commit }, loadingToSet) => {
  commit('setIsClientLoading', loadingToSet);
};

const setIsPartLoading = ({ commit }, loadingToSet) => {
  commit('setIsPartLoading', loadingToSet);
};

const setIsCylinderLoading = ({ commit }, loadingToSet) => {
  commit('setIsCylinderLoading', loadingToSet);
};

const setIsCylinderArrangementLoading = ({ commit }, loadingToSet) => {
  commit('setIsCylinderArrangementLoading', loadingToSet);
};

const setIsUnitsOfMeasureLoading = ({ commit }, loadingToSet) => {
  commit('setIsUnitsOfMeasureLoading', loadingToSet);
};

const setIsMeasureLoading = ({ commit }, loadingToSet) => {
  commit('setIsMeasureLoading', loadingToSet);
};

const setIsSparePartLoading = ({ commit }, loadingToSet) => {
  commit('setIsSparePartLoading', loadingToSet);
};

const setIsServiceLoading = ({ commit }, loadingToSet) => {
  commit('setIsServiceLoading', loadingToSet);
};

const setIsOrderStatusLoading = ({ commit }, loadingToSet) => {
  commit('setIsOrderStatusLoading', loadingToSet);
};

const setIsOrderLoading = ({ commit }, loadingToSet) => {
  commit('setIsOrderLoading', loadingToSet);
};

const setIsWorkerLoading = ({ commit }, loadingToSet) => {
  commit('setIsWorkerLoading', loadingToSet);
};

const setIsBrandReportLoading = ({ commit }, loadingToSet) => {
  commit('setIsBrandReportLoading', loadingToSet);
};

const setIsClientReportLoading = ({ commit }, loadingToSet) => {
  commit('setIsClientReportLoading', loadingToSet);
};

const setIsEngineSeriesReportLoading = ({ commit }, loadingToSet) => {
  commit('setIsEngineSeriesReportLoading', loadingToSet);
};

const setIsFinancialReportLoading = ({ commit }, loadingToSet) => {
  commit('setIsFinancialReportLoading', loadingToSet);
};

const setIsServicesReportLoading = ({ commit }, loadingToSet) => {
  commit('setIsServicesReportLoading', loadingToSet);
};

const setIsSparePartReportLoading = ({ commit }, loadingToSet) => {
  commit('setIsSparePartReportLoading', loadingToSet);
};

const setIsOrdersReportByEngineBrandNumberCylindersLoading = (
  { commit },
  loadingToSet
) => {
  commit('setIsOrdersReportByEngineBrandNumberCylindersLoading', loadingToSet);
};

const setIsOrdersReportBySparePartsLoading = ({ commit }, loadingToSet) => {
  commit('setIsOrdersReportBySparePartsLoading', loadingToSet);
};

const setIsWorkersReportLoading = ({ commit }, loadingToSet) => {
  commit('setIsWorkersReportLoading', loadingToSet);
};

const setIsCalendarOrderLoading = ({ commit }, loadingToSet) => {
  commit('setIsCalendarOrderLoading', loadingToSet);
};

const setIsDebtorsReportLoading = ({ commit }, loadingToSet) => {
  commit('setIsDebtorsReportLoading', loadingToSet);
};

const setIsUnpickedOrdersReportLoading = ({ commit }, loadingToSet) => {
  commit('setIsUnpickedOrdersReportLoading', loadingToSet);
};

export default {
  setLoadingOnTrue,
  setLoadingOnFalse,
  setIsClientLoading,
  setIsPartLoading,
  setIsCylinderLoading,
  setIsCylinderArrangementLoading,
  setIsUnitsOfMeasureLoading,
  setIsMeasureLoading,
  setIsSparePartLoading,
  setIsServiceLoading,
  setIsOrderStatusLoading,
  setIsOrderLoading,
  setIsWorkerLoading,
  setIsBrandReportLoading,
  setIsClientReportLoading,
  setIsEngineSeriesReportLoading,
  setIsFinancialReportLoading,
  setIsServicesReportLoading,
  setIsSparePartReportLoading,
  setIsOrdersReportByEngineBrandNumberCylindersLoading,
  setIsWorkersReportLoading,
  setIsCalendarOrderLoading,
  setIsOrdersReportBySparePartsLoading,
  setIsDebtorsReportLoading,
  setIsUnpickedOrdersReportLoading
};
