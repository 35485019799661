<i18n src="./resources/locales.json"></i18n>

<template>
  <v-menu
    v-model="menu"
    absolute
    close-on-click
    close-on-content-click
    :position-x="position.x"
    :position-y="position.y"
  >
    <v-card v-if="menu">
      <v-card-title>{{ $t('filters') }}</v-card-title>
      <v-card-text>
        <v-radio-group :value="selectedFilterOption.value">
          <v-radio
            v-for="(option, i) in filterOptions"
            :key="i"
            :label="option.label"
            :value="option.value"
            @change="changeOption(orderStatusId, option)"
          ></v-radio>
        </v-radio-group>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { ALL, FIVE_LAST_DAYS, TODAY } from '@/constants/ConfigurationConstants';

export default {
  name: 'BoardFilterMenu',

  data() {
    return {
      menu: false,
      orderStatusId: null,
      selectedFilterOption: null,
      position: {
        x: 0,
        y: 0
      }
    };
  },

  computed: {
    filterOptions() {
      return [
        {
          label: this.$t('today'),
          value: TODAY
        },
        {
          label: this.$t('fiveLastDays'),
          value: FIVE_LAST_DAYS
        },
        {
          label: this.$t('allOrders'),
          value: ALL
        }
      ];
    }
  },

  methods: {
    open(event, orderStatusId, selectedFilterOption) {
      this.orderStatusId = orderStatusId;
      this.selectedFilterOption = selectedFilterOption;
      this.position.x = event.clientX;
      this.position.y = event.clientY;
      this.menu = true;
    },

    changeOption(orderStatusId, selectedOption) {
      this.$emit('change-option', orderStatusId, selectedOption);
      this.menu = false;
    }
  }
};
</script>
