<template>
  <div></div>
</template>

<script>
import { CLIENT_API_URL } from '../../../constants/Environment';
import html2pdf from 'html2pdf.js';
import printJS from 'print-js';
import axios from 'axios';

export default {
  name: 'PrintFinancialReport',

  created() {
    this.$root.$on('print-financial-report-pdf', this.printPDF);
  },

  beforeDestroy() {
    this.$root.$off('print-financial-report-pdf');
  },

  methods: {
    async printPDF(parameters) {
      let objectToPrint = await this._fetchObjectToPrint(
        parameters.fromDate,
        parameters.toDate,
        parameters.printOrderNumber,
        parameters.printCredit,
        parameters.printDebit
      );
      var opt = {
        html2canvas: { y: 0, scrollY: 0 },
        filename: objectToPrint.fileName + '.pdf'
      };
      html2pdf()
        .set(opt)
        .from(objectToPrint.htmlData)
        .outputPdf()
        .then(function(pdf) {
          printJS({
            printable: btoa(pdf),
            type: 'pdf',
            base64: true
          });
        });
    },

    async _fetchObjectToPrint(
      fromDate,
      toDate,
      printOrderNumber,
      printCredit,
      printDebit
    ) {
      let objectToPrintData;
      let url = `${CLIENT_API_URL}/orders/print-financial-report?printOrderNumber=${printOrderNumber}&printCredit=${printCredit}&printDebit=${printDebit}`;
      if (fromDate) url += `&fromDate=${fromDate}`;
      if (toDate) url += `&toDate=${toDate}`;
      try {
        objectToPrintData = await axios.get(url);
      } catch (error) {
        console.error(
          'There was an error while retrieving the object to print' + error
        );
      }
      return objectToPrintData.data;
    }
  }
};
</script>
