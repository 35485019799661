'use strict';

const setAllWorkDoneDetails = (state, workDoneDetails) => {
  state.workDoneDetails = workDoneDetails;
};

const addWorkDoneDetail = (state, workDoneDetailToAdd) => {
  state.workDoneDetails.push(workDoneDetailToAdd);
};

const updateWorkDoneDetail = (state, workDoneDetailToUpdate) => {
  const foundWorkDoneDetailIndex = state.workDoneDetails.findIndex(
    wd => wd.id === workDoneDetailToUpdate.id
  );
  if (foundWorkDoneDetailIndex >= 0) {
    state.workDoneDetails.splice(
      foundWorkDoneDetailIndex,
      1,
      workDoneDetailToUpdate
    );
  }
};

const removeWorkDoneDetail = (state, workDoneDetailToRemove) => {
  state.workDoneDetails.filter(wd => wd.id !== workDoneDetailToRemove.id);
};

export default {
  setAllWorkDoneDetails,
  addWorkDoneDetail,
  updateWorkDoneDetail,
  removeWorkDoneDetail
};
