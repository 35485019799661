<i18n src="./resources/locales.json"></i18n>

<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-form ref="clientForm" v-model="isValid">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  class="required-field"
                  v-model="client.name"
                  :label="$t('name')"
                  :rules="[nameRules.required]"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  class="required-field"
                  v-model="client.lastName"
                  :label="$t('lastName')"
                  :rules="[lastNameRules.required]"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  class="required-field"
                  v-model.number="client.phone"
                  :label="$t('phone')"
                  type="number"
                  :rules="[phoneRules.required]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="client.address"
                  :label="`${$t('address')} (${$t('optional')})`"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  class="required-field"
                  v-model.number="client.ci"
                  :label="$t('id')"
                  type="number"
                  :rules="[idRules.required]"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="primary" :disabled="!isValid" @click="save">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ClientDetailModal',

  data() {
    return {
      isValid: false,
      client: {},
      dialog: false,
      defaultClient: {
        id: '-1',
        name: '',
        lastName: '',
        phone: null,
        address: '',
        ci: null
      }
    };
  },

  computed: {
    formTitle() {
      const { id } = this.client;
      return id === '-1' ? this.$t('newClient') : this.$t('editClient');
    },

    nameRules() {
      return { required: value => !!value || this.$t('nameIsRequired') };
    },

    lastNameRules() {
      return { required: value => !!value || this.$t('lastNameIsRequired') };
    },

    phoneRules() {
      return { required: value => !!value || this.$t('phoneIsRequired') };
    },

    idRules() {
      return { required: value => !!value || this.$t('idIsRequired') };
    }
  },

  methods: {
    ...mapActions('clients', ['addClient', 'updateClient']),

    open(clientToUpsert) {
      this.client = Object.assign({}, clientToUpsert || this.defaultClient);
      this.dialog = true;
    },

    close() {
      this.resetValidations();
      this.isValid = false;
      this.client = Object.assign({}, this.defaultClient);
      this.dialog = false;
    },

    async save() {
      const { id } = this.client;
      this.client = Object.assign({}, this.client);

      if (id !== '-1') {
        try {
          await this.updateClient(this.client);
          this.$root.$emit(
            'open-status-message-snackbar',
            Object.assign(
              {},
              {
                message: this.$t('clientInfoWasUpdatedSuccessfully'),
                messageType: 'success'
              }
            )
          );
        } catch (error) {
          this.$root.$emit(
            'open-status-message-snackbar',
            Object.assign(
              {},
              {
                message: this.$t('errorWhileUpdatingClientInfoPleaseTryAgain'),
                messageType: 'error'
              }
            )
          );
        }
      } else {
        try {
          await this.addClient(this.client);
          this.$root.$emit(
            'open-status-message-snackbar',
            Object.assign(
              {},
              {
                message: `${this.$t('theClient')} ${this.client.name} ${
                  this.client.lastName
                } ${this.$t('wasCreatedSuccessfully')}`,
                messageType: 'success'
              }
            )
          );
        } catch (error) {
          this.$root.$emit(
            'open-status-message-snackbar',
            Object.assign(
              {},
              {
                message: this.$t('errorWhileCreatingTheClient'),
                messageType: 'error'
              }
            )
          );
        }
      }

      this.close();
    },

    resetValidations() {
      this.$refs.clientForm.resetValidation();
    }
  }
};
</script>
