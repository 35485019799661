'use strict';

const setAllServices = (state, services) => {
  state.services = services;
};

const addService = (state, serviceToAdd) => {
  state.services.push(serviceToAdd);
};

const updateService = (state, serviceToUpdate) => {
  const foundServiceIndex = state.services.findIndex(
    service => service.id === serviceToUpdate.id
  );
  if (foundServiceIndex >= 0)
    state.services.splice(foundServiceIndex, 1, serviceToUpdate);
};

const removeService = (state, serviceId) => {
  state.services = state.services.filter(service => service.id !== serviceId);
};

const addSubService = (state, subServiceToAdd) => {
  state.services.map(service => {
    if (service.id === subServiceToAdd.serviceId) {
      if (service.subServices === null) service.subServices = [];
      service.subServices.push(subServiceToAdd);
    }
  });
};

const updateSubService = (state, subServiceToUpdate) => {
  state.services.map(service => {
    if (service.id === subServiceToUpdate.serviceId) {
      const foundSubServiceIndex = service.subServices.findIndex(
        subService => subService.id === subServiceToUpdate.id
      );
      if (foundSubServiceIndex >= 0)
        service.subServices.splice(foundSubServiceIndex, 1, subServiceToUpdate);
    }
  });
};

const removeSubService = (state, subServiceToDeleteId) => {
  state.services.map(service => {
    service.subServices = service.subServices?.filter(
      subService => subService.id !== subServiceToDeleteId
    );
  });
};

export default {
  setAllServices,
  addService,
  updateService,
  removeService,
  addSubService,
  updateSubService,
  removeSubService
};
