'use strict';

const setUsersWithPassword = (state, users) => {
  state.users = users;
};

const setUserInfo = (state, userData) => {
  state.fullName = userData.fullName;
  state.email = userData.email;
  state.roleId = userData.roleId;
};

const addUser = (state, userToAdd) => {
  state.users.push(userToAdd);
};

const updateUser = (state, userToUpdate) => {
  const foundUserIndex = state.users.findIndex(
    user => user.id === userToUpdate.id
  );
  if (foundUserIndex >= 0) {
    state.users.splice(foundUserIndex, 1, userToUpdate);
  }
};

const setQuickValidationResponse = (state, quickValidationResponse) => {
  state.quickValidationResponse = quickValidationResponse;
};

export default {
  setUsersWithPassword,
  setUserInfo,
  addUser,
  updateUser,
  setQuickValidationResponse
};
