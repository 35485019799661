<i18n src="./resources/locales.json"></i18n>

<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>{{ $t('loginMessage') }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form ref="loginForm" v-model="valid">
              <v-text-field
                v-model="user.email"
                :label="$t('email')"
                prepend-icon="mdi-account"
                :error="isUserStorageRoleIdIncorrect()"
                @keyup.enter="login"
              />

              <v-text-field
                v-model="user.password"
                :label="$t('password')"
                :type="getTypePassword()"
                :append-icon="getAppendIconForPassword()"
                @click:append="showPassword = !showPassword"
                prepend-icon="mdi-lock"
                :error="isUserStorageRoleIdIncorrect()"
                @keyup.enter="login"
              />
              <p v-if="isUserStorageRoleIdIncorrect()">
                <span class="default-alert">
                  {{ $t('invalidEmailOrPassword') }}
                </span>
              </p>
              <p v-if="isWorker()">
                <span class="default-alert">
                  {{ $t('youDontHaveAccessToTheWebApp') }}
                </span>
              </p>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @keyup.enter="login"
              @click="login"
              :loading="isLoading()"
              :disabled="isLoading()"
              >{{ $t('loginMessage') }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import roles from '../../mixins/roles.js';

export default {
  name: 'Login',

  mixins: [roles],

  data: () => ({
    valid: true,
    user: {
      email: '',
      password: ''
    },
    showPassword: false
  }),

  computed: {
    ...mapGetters('userStorage', ['userStorageRoleId']),
    ...mapGetters('loading', ['getLoading'])
  },

  watch: {
    userStorageRoleId: {
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal) {
          if (this.isUserStorageRoleIdCorrect(newVal)) {
            if (this.isAdmin()) this.$router.push('/clients');
            if (this.isSupervisor() || this.isCashier())
              this.$router.push('/rectifier-orders');
            this.setLoadingOnFalse();
          }
          if (this.isUserStorageRoleIdIncorrect()) this.setLoadingOnFalse();
        }
      },
      deep: true
    }
  },

  methods: {
    ...mapActions('userStorage', ['loginUser', 'setDefaultUser']),
    ...mapActions('loading', ['setLoadingOnFalse', 'setLoadingOnTrue']),

    validate() {
      this.$refs.form.validate();
    },

    login() {
      this.setDefaultUser();

      if (this.validate) {
        this.setLoadingOnTrue();
        this.user = Object.assign({}, this.user);
        this.loginUser(this.user);
      }
    },

    getTypePassword() {
      return this.showPassword ? 'text' : 'password';
    },

    getAppendIconForPassword() {
      return this.showPassword ? 'mdi-eye' : 'mdi-eye-off';
    },

    encryptUser() {
      const { email, password } = this.user;
      let userData = `${email}:${password}`;
      return btoa(userData);
    },

    isUserStorageRoleIdCorrect(userStorageRoleId) {
      return userStorageRoleId !== '' && userStorageRoleId !== null;
    },

    isUserStorageRoleIdIncorrect() {
      return this.userStorageRoleId === null;
    },

    isLoading() {
      return this.getLoading;
    }
  }
};
</script>
