<i18n src="./resources/locales.json"></i18n>

<template>
  <v-dialog persistent v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title class="headline">
        {{ title }}
      </v-card-title>
      <v-card-text>
        {{ message }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="accept">{{ $t('accept') }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'InfoMessageModal',

  data() {
    return {
      message: '',
      title: '',
      dialog: false
    };
  },

  methods: {
    open(modalInfo) {
      this.message = modalInfo.message;
      this.title = modalInfo.title;
      this.dialog = true;
    },

    accept() {
      this.dialog = false;
      this.$emit('confirm-action');
    }
  }
};
</script>
