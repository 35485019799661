'use strict';

const setAllBrands = (state, brands) => {
  state.brands = brands;
};

const addBrand = (state, brandToAdd) => {
  state.brands.push(brandToAdd);
};

const updateBrand = (state, brandToUpdate) => {
  const foundBrandIndex = state.brands.findIndex(
    brand => brand.id === brandToUpdate.id
  );
  if (foundBrandIndex >= 0) {
    state.brands.splice(foundBrandIndex, 1, brandToUpdate);
  }
};

const removeBrand = (state, brandToDeleteId) => {
  state.brands = state.brands.filter(brand => brand.id !== brandToDeleteId);
};

export default {
  setAllBrands,
  addBrand,
  updateBrand,
  removeBrand
};
