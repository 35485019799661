import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = () => ({
  fullName: '',
  email: '',
  roleId: '',
  users: [],
  quickValidationResponse: {
    response: false,
    id: '00000000-0000-0000-0000-000000000000',
    fullName: ''
  }
});

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
