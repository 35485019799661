<i18n src="./resources/locales.json"></i18n>

<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-form ref="orderStatusForm" v-model="isValid">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  class="required-field"
                  v-model="orderStatus.name"
                  :label="$t('name')"
                  :rules="[nameRules.required]"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p class="body-1">{{ $t('color') }}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col align="center">
                <v-color-picker
                  elevation="10"
                  hide-inputs
                  dot-size="20"
                  v-model="orderStatus.color"
                ></v-color-picker>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="primary" :disabled="!isValid" @click="save">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'OrderStatusDetailModal',

  props: ['businessId'],

  data() {
    return {
      isValid: false,
      orderStatus: {},
      dialog: false,
      defaultOrderStatus: {
        id: '-1',
        name: '',
        businessId: '',
        position: 0,
        isInitial: false,
        isFinal: false,
        color: '#000000'
      }
    };
  },

  computed: {
    formTitle() {
      const { id } = this.orderStatus;
      return id === '-1'
        ? this.$t('newOrderStatus')
        : this.$t('editOrderStatus');
    },

    nameRules() {
      return { required: value => !!value || this.$t('nameIsRequired') };
    }
  },

  methods: {
    ...mapActions('orderStatuses', ['addOrderStatus', 'updateOrderStatus']),

    open(orderStatusToUpsert) {
      this.defaultOrderStatus.businessId = this.businessId;
      this.orderStatus = Object.assign(
        {},
        orderStatusToUpsert || this.defaultOrderStatus
      );
      this.dialog = true;
    },

    close() {
      this.resetValidations();
      this.isValid = false;
      this.orderStatus = Object.assign({}, this.defaultOrderStatus);
      this.dialog = false;
    },

    async save() {
      const { id } = this.orderStatus;
      this.orderStatus = Object.assign({}, this.orderStatus);

      if (id !== '-1') {
        try {
          await this.updateOrderStatus(this.orderStatus);
          this.$root.$emit(
            'open-status-message-snackbar',
            Object.assign(
              {},
              {
                message: this.$t('orderStatusInfoWasUpdatedSuccessfully'),
                messageType: 'success'
              }
            )
          );
        } catch (error) {
          this.$root.$emit(
            'open-status-message-snackbar',
            Object.assign(
              {},
              {
                message: this.$t(
                  'errorWhileUpdatingOrderStatusInfoPleaseTryAgain'
                ),
                messageType: 'error'
              }
            )
          );
        }
      } else {
        try {
          await this.addOrderStatus(this.orderStatus);
          this.$root.$emit(
            'open-status-message-snackbar',
            Object.assign(
              {},
              {
                message: `${this.$t('theOrderStatus')} ${
                  this.orderStatus.name
                } ${this.$t('wasCreatedSuccessfully')}`,
                messageType: 'success'
              }
            )
          );
        } catch (error) {
          this.$root.$emit(
            'open-status-message-snackbar',
            Object.assign(
              {},
              {
                message: this.$t('errorWhileCreatingTheOrderStatus'),
                messageType: 'error'
              }
            )
          );
        }
      }

      this.close();
    },

    resetValidations() {
      this.$refs.orderStatusForm.resetValidation();
    }
  }
};
</script>
