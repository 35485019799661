<i18n src="./resources/locales.json"></i18n>

<template>
  <v-menu
    ref="datePickerMenu"
    v-model="datePickerMenu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :class="dateTextFieldClass"
        v-model="date"
        :label="labelText"
        type="date"
        :prepend-icon="icon"
        readonly
        :disabled="disabledDate"
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      :locale="$i18n.locale"
      :max="maxDate"
      :min="minDate"
      no-title
      scrollable
    >
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="datePickerMenu = false">
        {{ $t('cancel') }}
      </v-btn>
      <v-btn color="primary" @click="$refs.datePickerMenu.save(date)">
        {{ $t('ok') }}
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>
<script>
export default {
  name: 'DatePicker',

  props: [
    'labelText',
    'givenDate',
    'maxDate',
    'minDate',
    'icon',
    'dateTextFieldClass',
    'disabledDate'
  ],

  data() {
    return {
      datePickerMenu: false,
      date: this.givenDate
    };
  },

  watch: {
    date: {
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$emit('update:givenDate', this.date);
        }
      },
      immediate: true
    },

    givenDate: {
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.date = newVal;
        }
      },
      immediate: true
    }
  }
};
</script>
