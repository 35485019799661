<i18n src="./resources/locales.json"></i18n>

<template>
  <v-container>
    <h1 class="ml-3 primary--text">{{ $t('unitsOfMeasure') }}</h1>
    <v-row
      class="mt-10 ml-16 mb-n12 mr-16"
      no-gutters
      style="flex-wrap: nowrap;"
    >
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="addUnitOfMeasure">
        {{ $t('newUnitOfMeasure') }}
      </v-btn>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="allUnitOfMeasures"
      sort-by="name"
      class="elevation-1 ma-16"
      hide-default-footer
      disable-pagination
      :loading="getIsUnitsOfMeasureLoading"
      :loading-text="$t('loadingPleaseWait')"
    >
      <template v-slot:[`item.default`]="{ item }">
        <v-simple-checkbox :value="item.default" disabled></v-simple-checkbox>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small color="primary" class="mr-2" @click="showMeasures(item)">
          mdi-tape-measure
        </v-icon>
        <v-icon
          small
          color="primary"
          class="mr-2"
          @click="editUnitOfMeasure(item)"
        >
          mdi-pencil-outline
        </v-icon>
        <!--v-tooltip
          top
          :disabled="getMeasuresByUnitOfMeasureId(item.id).length === 0"
        >
          <template v-slot:activator="{ on }">
            <div v-on="on" class="d-inline-block">
              <v-icon
                small
                color="primary"
                @click="deleteUnitOfMeasure(item)"
                :disabled="getMeasuresByUnitOfMeasureId(item.id).length > 0"
              >
                mdi-delete
              </v-icon>
            </div>
          </template>
          <span>{{ $t('canNotDeleteUnitsOfMeasureWithCreatedMeasure') }}</span>
        </v-tooltip-->
      </template>
      <template v-slot:no-data>
        <p>{{ $t('thereAreNoUnitsOfMeasure') }}</p>
      </template>
    </v-data-table>

    <MeasuresListModal ref="measuresListModal" />
    <UnitOfMeasureDetailModal ref="unitOfMeasureDetailModal" />
    <DeleteConfirmModal
      ref="deleteUnitOfMeasureModal"
      @confirm-action="deleteUnitOfMeasureConfirm"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import UnitOfMeasureDetailModal from '@/components/spare-part/unit-of-measure-detail-modal/UnitOfMeasureDetailModal.vue';
import DeleteConfirmModal from '@/components/reusable-components/delete-confirm-modal/DeleteConfirmModal.vue';
import MeasuresListModal from '@/views/spare-part/measures-list-modal/MeasuresListModal.vue';

export default {
  name: 'UnitOfMeasuresList',

  components: {
    UnitOfMeasureDetailModal,
    DeleteConfirmModal,
    MeasuresListModal
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t('name'),
          value: 'name',
          align: 'center'
        },
        {
          text: this.$t('code'),
          value: 'code',
          align: 'center'
        },
        {
          text: this.$t('description'),
          value: 'description',
          align: 'center'
        },
        {
          text: this.$t('defaultUnit'),
          value: 'default',
          align: 'center'
        },
        {
          text: this.$t('actions'),
          value: 'actions',
          align: 'center',
          sortable: false
        }
      ];
    },

    ...mapGetters('spareParts', [
      'allUnitOfMeasures',
      'getMeasuresByUnitOfMeasureId'
    ]),
    ...mapGetters('loading', ['getIsUnitsOfMeasureLoading'])
  },

  created() {
    this.retrieveAllUnitOfMeasures();
  },

  methods: {
    ...mapActions('spareParts', [
      'removeUnitOfMeasure',
      'retrieveAllUnitOfMeasures'
    ]),

    addUnitOfMeasure() {
      this.$refs.unitOfMeasureDetailModal.open();
    },

    editUnitOfMeasure(unitOfMeasureToUpdate) {
      this.$refs.unitOfMeasureDetailModal.open(
        Object.assign({}, unitOfMeasureToUpdate)
      );
    },

    deleteUnitOfMeasure(unitOfMeasureToDelete) {
      this.$refs.deleteUnitOfMeasureModal.open(
        Object.assign(
          { message: this.$t('sureToDeleteThisUnitOfMeasure') },
          unitOfMeasureToDelete
        )
      );
    },

    deleteUnitOfMeasureConfirm(unitOfMeasureToDeleteId) {
      this.removeUnitOfMeasure(unitOfMeasureToDeleteId);
    },

    showMeasures(unitOfMeasure) {
      this.$refs.measuresListModal.open(unitOfMeasure);
    }
  }
};
</script>
