import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = () => ({
  clients: [],
  clientsPaginationProps: {
    count: null,
    totalCount: null
  },
  allClients: [],
  allClientsPaginatedProps: {
    count: null,
    totalCount: null
  }
});

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
