<i18n src="./resources/locales.json"></i18n>

<template>
  <v-dialog persistent v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title class="headline">
        {{ $t('oops') }}
      </v-card-title>
      <br />
      <v-card-subtitle>
        {{ subTitle }}
      </v-card-subtitle>
      <v-card-text>
        {{ message }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="accept">{{ $t('accept') }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ErrorMessageModal',

  data() {
    return {
      isOrder: false,
      message: '',
      subTitle: '',
      dialog: false
    };
  },

  methods: {
    open(modalInfo) {
      this.isOrder = modalInfo.isOrder;
      this.message = modalInfo.message;
      this.subTitle = modalInfo.subTitle;
      this.dialog = true;
    },

    accept() {
      this.dialog = false;
      if (this.isOrder) {
        this.$emit('back-to-rectifier-order-detail');
      }
    }
  }
};
</script>
