<i18n src="./resources/locales.json"></i18n>

<template>
  <HistoricalOrdersList :orderTitleList="orderTitleList" />
</template>

<script>
import HistoricalOrdersList from '@/components/reusable-components/historical-orders-list/HistoricalOrdersList.vue';

export default {
  name: 'RectifierHistoricalHistoricalOrdersList',

  components: { HistoricalOrdersList },

  computed: {
    orderTitleList() {
      return this.$t('engineRebuildingOrderHistory');
    }
  }
};
</script>
