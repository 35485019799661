'use strict';

const setAllWorkers = (state, workers) => {
  state.workers = workers;
};

const addWorker = (state, workerToAdd) => {
  state.workers.push(workerToAdd);
};

const updateWorker = (state, workerToUpdate) => {
  const foundWorkerIndex = state.workers.findIndex(
    worker => worker.id === workerToUpdate.id
  );
  if (foundWorkerIndex >= 0) {
    state.workers.splice(foundWorkerIndex, 1, workerToUpdate);
  }
};

const removeWorker = (state, workerToDeleteId) => {
  state.workers = state.workers.filter(
    worker => worker.id !== workerToDeleteId
  );
};

export default {
  setAllWorkers,
  addWorker,
  updateWorker,
  removeWorker
};
