<i18n src="./resources/locales.json"></i18n>

<template>
  <v-dialog persistent v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title>
        {{ $t('printConfiguration') }}
      </v-card-title>
      <v-card-text>
        {{ $t('chooseHowYouWouldLikeToPrintTheOrder') }}
      </v-card-text>
      <v-radio-group v-model="selectedOption" column class="pl-10 mt-n2">
        <v-radio :label="$t('withDiscounts')" value="true"></v-radio>
        <v-radio :label="$t('withoutDiscounts')" value="false"></v-radio>
      </v-radio-group>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close">{{ $t('cancel') }}</v-btn>
        <v-btn color="primary" @click="print">{{ $t('print') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'PrintConfigurationModal',

  data() {
    return {
      selectedOption: 'true',
      dialog: false,
      orderId: null
    };
  },

  methods: {
    open(orderId) {
      this.dialog = true;
      this.orderId = orderId;
    },

    print() {
      this.$root.$emit('print-order-pdf', this.orderId, this.selectedOption);
      this.close();
    },

    close() {
      this.selectedOption = 'true';
      this.orderId = null;
      this.dialog = false;
    }
  }
};
</script>
