'use strict';

const setAllSpareParts = (state, spareParts) => {
  state.spareParts = spareParts;
};

const addSparePart = (state, sparePart) => {
  state.spareParts.push(sparePart);
};

const updateSparePart = (state, sparePart) => {
  const foundSparePartIndex = state.spareParts.findIndex(
    sp => sp.id === sparePart.id
  );
  if (foundSparePartIndex >= 0) {
    state.spareParts.splice(foundSparePartIndex, 1, sparePart);
  }
};

const removeSparePart = (state, sparePart) => {
  state.spareParts = state.spareParts.filter(sp => sp.id !== sparePart.id);
};

const setAllUnitOfMeasures = (state, unitOfMeasures) => {
  state.unitOfMeasures = unitOfMeasures;
};

const addUnitOfMeasure = (state, unitOfMeasure) => {
  state.unitOfMeasures.push(unitOfMeasure);
};

const updateUnitOfMeasure = (state, unitOfMeasure) => {
  const foundUnitOfMeasureIndex = state.unitOfMeasures.findIndex(
    uom => uom.id === unitOfMeasure.id
  );
  if (foundUnitOfMeasureIndex >= 0) {
    state.unitOfMeasures.splice(foundUnitOfMeasureIndex, 1, unitOfMeasure);
  }
};

const removeUnitOfMeasure = (state, unitOfMeasure) => {
  state.unitOfMeasures = state.unitOfMeasures.filter(
    uom => uom.id !== unitOfMeasure.id
  );
};

const setAllMeasures = (state, measures) => {
  state.measures = measures;
};

const addMeasure = (state, measure) => {
  state.measures.push(measure);
};

const updateMeasure = (state, measure) => {
  const foundMeasureIndex = state.measures.findIndex(m => m.id === measure.id);
  if (foundMeasureIndex >= 0) {
    state.measures.splice(foundMeasureIndex, 1, measure);
  }
};

const removeMeasure = (state, measure) => {
  state.measures = state.measures.filter(m => m.id !== measure.id);
};

export default {
  setAllSpareParts,
  addSparePart,
  updateSparePart,
  removeSparePart,
  setAllUnitOfMeasures,
  addUnitOfMeasure,
  updateUnitOfMeasure,
  removeUnitOfMeasure,
  setAllMeasures,
  addMeasure,
  updateMeasure,
  removeMeasure
};
