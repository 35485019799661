'use strict';

import axios from 'axios';
import { CLIENT_API_URL } from '../../../constants/Environment';

const retrieveAllWorkers = async ({ dispatch, commit }) => {
  dispatch('loading/setIsWorkerLoading', true, { root: true });
  try {
    await axios.get(`${CLIENT_API_URL}/workers`).then(response => {
      commit('setAllWorkers', response.data);
    });
    dispatch('loading/setIsWorkerLoading', false, { root: true });
  } catch (error) {
    console.error('There was an error while retrieving workers' + error);
  }
};

const addWorker = async ({ commit }, workerToAdd) => {
  delete workerToAdd.id;
  return new Promise((resolve, reject) => {
    axios
      .post(`${CLIENT_API_URL}/workers`, workerToAdd)
      .then(response => {
        commit('addWorker', response.data);
        resolve(response);
      })
      .catch(error => {
        reject(error);
        console.error('There was an error while posting the worker' + error);
      });
  });
};

const updateWorker = async ({ commit }, workerToUpdate) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${CLIENT_API_URL}/workers/${workerToUpdate.id}`, workerToUpdate)
      .then(response => {
        commit('updateWorker', response.data);
        resolve(response);
      })
      .catch(error => {
        reject(error);
        console.error('There was an error while updating the worker' + error);
      });
  });
};

const removeWorker = ({ commit }, workerId) => {
  axios.delete(`${CLIENT_API_URL}/workers/${workerId}`).then(response => {
    commit('removeWorker', response.data.id);
  });
};

const configAccount = async (
  { commit, dispatch },
  { workerToUpdate, userToUpdate }
) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${CLIENT_API_URL}/workers/${workerToUpdate.id}/configure-account`,
        userToUpdate
      )
      .then(response => {
        workerToUpdate.userId = response.data.id;
        commit('updateWorker', workerToUpdate);
        let userToAdd = Object.assign(
          {},
          {
            id: response.data.id,
            fullName: response.data.fullName,
            email: response.data.email,
            password: userToUpdate.password,
            roleId: response.data.roleId
          }
        );
        dispatch('userStorage/setNewUser', userToAdd, { root: true });
        resolve(response);
      })
      .catch(error => {
        reject(error);
        console.error(
          'There was an error while configuring the account' + error
        );
      });
  });
};

export default {
  retrieveAllWorkers,
  addWorker,
  updateWorker,
  removeWorker,
  configAccount
};
