<i18n src="./resources/locales.json"></i18n>

<template>
  <v-container>
    <div>
      <div style="display: inline-block">
        <h1 class="ml-3 primary--text">{{ $t('ordersCalendar') }}</h1>
      </div>
      <div style="display: inline-block; margin-left: 40px" class="select-bar">
        <v-select
          background-color="primaryVariant2"
          :items="optionsCalendar"
          item-text="label"
          return-object
          :label="$t('showCalendarOption')"
          v-model="selectedOptionCalendar"
          hide-details
          @change="updateRange"
          outlined
          flat
          dense
          class="search-bar"
        ></v-select>
      </div>
    </div>

    <div class="calendar-container">
      <div v-if="isSelectedWorkersCalendar()" class="calendar-workers-box">
        <div class="calendar-workers">
          <div class="workers-section select-bar">
            <v-subheader class="font-weight-bold pb-8 ml-n4">{{
              $t('workers')
            }}</v-subheader>
            <v-list
              dense
              :style="getCalendarStyle()"
              class="list-filter-calendar-height overflow-y-auto pt-0 mt-n3"
            >
              <template v-for="(worker, index) in workersName">
                <v-checkbox
                  v-model="selectedWorkers"
                  :label="worker"
                  :value="worker"
                  :color="getGeneratedColor(worker)"
                  :key="index"
                  class="ml-4"
                  @change="applyFilterOnWorkersEvent()"
                  hide-details
                ></v-checkbox>
              </template>
            </v-list>
          </div>

          <div class="order-numbers-section select-bar mt-8">
            <v-subheader class="font-weight-bold pb-10 ml-n4 pt-5">{{
              $t('orderNumbers')
            }}</v-subheader>
            <v-list
              dense
              :style="getCalendarStyle()"
              class="list-filter-calendar-height overflow-y-auto pt-0 mt-n3"
            >
              <template v-for="(orderNumber, index) in orderNumbers">
                <v-checkbox
                  v-model="selectedOrderNumbers"
                  :label="orderNumber"
                  :value="orderNumber"
                  color="primary"
                  :key="index"
                  class="ml-4"
                  @change="applyFilterOnWorkersEvent()"
                  hide-details
                ></v-checkbox>
              </template>
            </v-list>
          </div>
        </div>
      </div>
      <div class="calendar-box" :style="getCalendarContainerStyles()">
        <div class="calendar">
          <v-sheet height="64">
            <v-toolbar flat>
              <v-btn class="mr-4" color="primary" @click="setToday">
                {{ $t('today') }}
              </v-btn>
              <v-btn fab text small color="primary" @click="prev">
                <v-icon small> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn fab text small color="primary" @click="next">
                <v-icon small> mdi-chevron-right </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title | capitalize }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" v-bind="attrs" v-on="on">
                    <span v-if="type === 'day'">{{ $t('day') }}</span>
                    <span v-if="type === 'week'">{{ $t('week') }}</span>
                    <span v-if="type === 'month'">{{ $t('month') }}</span>
                    <v-icon right> mdi-menu-down </v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title>{{ $t('day') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>{{ $t('week') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>{{ $t('month') }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
          </v-sheet>
          <v-sheet :style="getCalendarStyle()" class="calendar-height">
            <v-calendar
              ref="calendar"
              v-model="value"
              color="primary"
              :event-color="getEventColor"
              :first-interval="8"
              :interval-minutes="60"
              :interval-count="10"
              :weekdays="weekdays"
              :type="type"
              :events="events"
              :locale="$i18n.locale"
              :interval-format="convertTimeToAmPm"
              :event-more-text="getMoreText()"
              event-overlap-mode="column"
              @contextmenu:event="openContextMenu"
              @click:more="viewDay"
              @click:date="viewDay"
              @click:day="addEventFromMonth"
              @change="updateRange"
              @mousedown:event="startDrag"
              @mousedown:time="startTime"
              @mousemove:time="mouseMove"
              @mouseup:time="endDrag"
              @mouseleave.native="cancelDrag"
              @mousedown:day="startTimeMouseDown"
              @mousemove:day="mouseMove"
              @mouseup:day="endDrag"
            >
              <template v-slot:event="{ event, timed }">
                <div
                  class="v-event-draggable"
                  v-if="isSelectedOrdersCalendar()"
                >
                  <span class="font-weight-bold">{{ event.orderNumber }}</span>
                  <span>, {{ event.hourToStart }}</span>
                  <span> ({{ event.order.orderStatus.name }})</span>
                </div>
                <div
                  class="v-event-draggable"
                  v-if="isSelectedWorkersCalendar()"
                >
                  <span class="font-weight-bold">{{
                    event.assignedService.orderNumber
                  }}</span>
                  <span>, {{ event.assignedService.worker.name }}</span>
                  <span>
                    - {{ renderAssignedService(event.assignedService) }}</span
                  >
                </div>
                <div
                  v-if="isSelectedWorkersCalendar() && timed"
                  class="v-event-drag-bottom"
                  @mousedown.stop="extendBottom(event)"
                ></div>
              </template>

              <template v-slot:day-body="{ date, week, timeToY }">
                <div
                  class="v-current-time"
                  :class="{ first: date === week[0].date }"
                  :style="{ top: nowY(timeToY) }"
                >
                  <div class="v-current-time-time">
                    {{ nowTime() }}
                  </div>
                </div>
              </template>
            </v-calendar>
          </v-sheet>
        </div>
      </div>
    </div>

    <AlertAssignedServices ref="alertAssignedServices" />
    <LoadingMask :isLoading="getIsCalendarOrderLoading" />
    <OrderListSearchAndSelect
      ref="orderListSearchAndSelect"
      @confirm-action="addDateOnEvent"
    />
    <UnassignedServicesList ref="unassignedServicesList" />
    <InfoMessageModal ref="infoMessageModal" />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import AlertAssignedServices from '@/components/reusable-components/alert-assigned-services/AlertAssignedServices.vue';
import LoadingMask from '@/components/reusable-components/loading-mask/LoadingMask.vue';
import OrderListSearchAndSelect from '@/components/order/order-list-search-and-select/OrderListSearchAndSelect.vue';
import InfoMessageModal from '@/components/reusable-components/info-message-modal/InfoMessageModal.vue';
import UnassignedServicesList from '@/components/unassigned-services-list/UnassignedServicesList.vue';

import { ORDERS, WORKERS } from '@/constants/ConfigurationConstants';
import color from '../../mixins/color.js';

export default {
  name: 'CalendarOrders',

  components: {
    AlertAssignedServices,
    LoadingMask,
    OrderListSearchAndSelect,
    InfoMessageModal,
    UnassignedServicesList
  },

  data() {
    return {
      value: '',
      ready: false,
      type: 'week',
      selectedType: {
        text: 'week',
        value: 'week'
      },
      events: [],
      selectedEvent: {},
      dragEvent: null,
      dragTime: null,
      createEvent: null,
      createStart: null,
      extendOriginal: null,
      originalDrag: null,
      selectedOptionCalendar: {
        text: this.$t('orders'),
        value: ORDERS
      },
      WORKERS,
      latestStart: undefined,
      latestEnd: undefined,
      weekdays: [1, 2, 3, 4, 5, 6],
      workersName: [],
      selectedWorkers: [],
      orderNumbers: [],
      selectedOrderNumbers: []
    };
  },

  watch: {
    allCalendarEvents: {
      handler: function() {
        if (this.isSelectedOrdersCalendar()) this.formatOrdersAsEvents();
        else this.formatWorkersAsEvents();
      },
      deep: true
    }
  },

  computed: {
    ...mapGetters('loading', ['getIsCalendarOrderLoading']),
    ...mapGetters('calendar', ['allCalendarEvents']),
    ...mapGetters('orders', ['getOrderById']),

    cal() {
      return this.ready ? this.$refs.calendar : null;
    },

    optionsCalendar() {
      return [
        {
          label: this.$t('orders'),
          value: ORDERS
        },
        {
          label: this.$t('workers'),
          value: WORKERS
        }
      ];
    }
  },

  mounted() {
    this.$refs.calendar.checkChange();
    this.ready = true;
    this.scrollToTime();
    this.updateTime();
  },

  mixins: [color],

  methods: {
    ...mapActions('calendar', [
      'changeEstimatedDeliverDateForCalendarOrder',
      'retrieveAllBusinessOrdersByDateRange',
      'retrieveAllWorkersAssignedServicesByDateRange',
      'updateAssignServiceOnCalendar'
    ]),

    ...mapActions('orders', ['retrieveOrderById']),

    viewDay({ date }) {
      this.value = date;
      this.type = 'day';
    },

    getEventColor(event) {
      return event.color;
    },

    setToday() {
      this.value = '';
      this.type = 'day';
    },

    prev() {
      this.$refs.calendar.prev();
    },

    next() {
      this.$refs.calendar.next();
    },

    async showEvent({ nativeEvent, event }) {
      let orderToShow;
      let isToUpdateOnCalendar;
      if (this.isSelectedOrdersCalendar()) {
        orderToShow = JSON.parse(JSON.stringify(event.order));
        isToUpdateOnCalendar = false;
      } else {
        nativeEvent.stopPropagation();
        await this.retrieveOrderById(event.assignedService.orderId);
        orderToShow = this.getOrderById;
        isToUpdateOnCalendar = true;
      }
      if (orderToShow) {
        this.$root.$emit(
          'open-order-detail-modal-info',
          Object.assign({}, orderToShow),
          isToUpdateOnCalendar
        );
      }
      nativeEvent.stopPropagation();
    },

    async updateRange({ start, end }) {
      this.events = [];

      if (start !== undefined) this.latestStart = start;
      if (end !== undefined) this.latestEnd = end;

      const min = moment(this.latestStart.date)
        .set({ hour: 0, minute: 0, second: 0 })
        .format();
      const max = moment(this.latestEnd.date)
        .set({ hour: 23, minute: 59, second: 59 })
        .format();

      if (this.isSelectedOrdersCalendar()) {
        await this.retrieveAllBusinessOrdersByDateRange({
          fromDate: min,
          toDate: max
        });
      } else {
        await this.retrieveAllWorkersAssignedServicesByDateRange({
          fromDate: min,
          toDate: max
        });
      }
    },

    formatOrdersAsEvents() {
      this.workersName = [];
      const events = [];

      this.allCalendarEvents.forEach(order => {
        if (order.deliverDate === null) {
          events.push({
            start: moment(order.estimatedDeliverDate).toDate(),
            end: moment(order.estimatedDeliverDate)
              .add(15, 'm')
              .toDate(),
            color: order.orderStatus.color,
            timed: true,
            order: JSON.parse(JSON.stringify(order)),
            orderNumber: this.numberFormatter(order.orderNumber),
            hourToStart: this._getHourFormatToRender(
              order.estimatedDeliverDate
            ),
            isFinished: false
          });
        } else {
          events.push({
            start: moment(order.deliverDate).toDate(),
            end: moment(order.deliverDate)
              .add(15, 'm')
              .toDate(),
            color: order.orderStatus.color,
            timed: true,
            order: JSON.parse(JSON.stringify(order)),
            orderNumber: this.numberFormatter(order.orderNumber),
            hourToStart: this._getHourFormatToRender(order.deliverDate),
            isFinished: true
          });
        }
      });
      this.events = events;
    },

    formatWorkersAsEvents() {
      this.workersName = [];
      this.orderNumbers = [];

      var events = this.getFormattedWorkersAsEvent();

      this.formatWorkersNameFromCalendarEvents(events);
      this.formatOrderNumbersFromCalendarEvents(events);

      this.events = events;
    },

    _getHourFormatToRender(date) {
      return moment(date).format('HH:mm');
    },

    numberFormatter(orderNumber) {
      return orderNumber.toString().length < 5
        ? this.numberFormatter('0' + orderNumber)
        : orderNumber;
    },

    startDrag({ event, timed }) {
      if (event || timed) {
        this.dragEvent = event;
        this.dragTime = null;
        this.extendOriginal = null;
        this.originalDrag = JSON.parse(JSON.stringify(event));
      }
    },

    startTime(tms) {
      let choice = moment(tms.date + tms.time, 'YYYY-MM-DDLT').format(
        'YYYY-MM-DD[T]HH:mm'
      );
      let now = moment().format('YYYY-MM-DD[T]HH:mm');

      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start;
        this.dragTime = mouse - start;
      } else if (moment(choice).isAfter(now)) {
        this.createStart = this.roundTime(mouse);
        if (this.isSelectedOrdersCalendar())
          this.$refs.orderListSearchAndSelect.open(this.createStart, true);
        else this.$refs.unassignedServicesList.open(this.createStart, true);
      }
    },

    extendBottom(event) {
      this.createEvent = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;
    },

    startTimeMouseDown(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start;
        this.dragTime = mouse - start;
      }
    },

    mouseMove(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;
        if (
          moment(newStart).isSameOrAfter(moment().format('YYYY-MM-DD')) &&
          this.dragEvent.isFinished === false
        ) {
          this.dragEvent.start = new Date(newStart);
          this.dragEvent.end = new Date(newEnd);
          if (this.isSelectedOrdersCalendar())
            this.dragEvent.hourToStart = this._getHourFormatToRender(
              new Date(newStart)
            );
        }
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);
        if (moment(max).isSameOrAfter(moment().format('YYYY-MM-DD'))) {
          this.createEvent.start = min;
          this.createEvent.end = max;
        }
      }
    },

    endDrag() {
      var event = this.dragEvent ?? this.createEvent;

      if (event !== null) {
        if (event.isFinished === false) {
          var afterDate;
          var beforeDate;
          if (this.isSelectedOrdersCalendar()) {
            afterDate = moment(event.start).format(
              'YYYY-MM-DD[T]HH:mm:ss.SSSZ'
            );
            beforeDate = moment(
              this.originalDrag.order.estimatedDeliverDate
            ).toDate();
          } else {
            let eventBefore = this.originalDrag
              ? this.originalDrag.assignedService.deliverDateTime
              : this.extendOriginal;
            afterDate = moment(event.end).format('YYYY-MM-DD[T]HH:mm:ss.SSSZ');
            beforeDate = moment(eventBefore).toDate();
          }

          if (this.isSelectedOrdersCalendar()) {
            if (
              this.isAssignServicesDateHigherThanOrderDeliverDate(
                event.order,
                event.start
              )
            ) {
              this.$refs.alertAssignedServices.open({
                message: this.$t(
                  'assignServicesDateAreHigherThanEstimatedDeliverDate'
                ),
                blockedServices: this.getAssignServicesDateHigherThanOrderDeliverDate(
                  event.order,
                  event.start
                )
              });
              this.originalDrag.start = moment(
                this.originalDrag.order.estimatedDeliverDate
              ).toDate();
              this.originalDrag.end = moment(
                this.originalDrag.order.estimatedDeliverDate
              )
                .add(15, 'm')
                .toDate();
              this.originalDrag.hourToStart = this._getHourFormatToRender(
                this.originalDrag.order.estimatedDeliverDate
              );
              const foundEventIndex = this.events.findIndex(
                e => e.orderNumber === this.originalDrag.orderNumber
              );
              if (foundEventIndex >= 0) {
                this.events.splice(foundEventIndex, 1, this.originalDrag);
              }
            } else if (!moment(afterDate).isSame(beforeDate)) {
              let order = event.order;
              order.estimatedDeliverDate = moment(event.start).format(
                'YYYY-MM-DD[T]HH:mm:ss.SSSZ'
              );
              this.updateOrderDate(order);
            }
          } else {
            if (
              moment(event.end).isAfter(
                event.assignedService.estimatedDeliverDateOfOrder
              )
            ) {
              this.$refs.infoMessageModal.open({
                message: `${this.$t(
                  'newAssignedDeliverDateIsHiggerThanOrderEstimatedDeliverDate'
                )}`,
                title: this.$t('alert')
              });

              if (this.createEvent !== null) {
                this.createEvent.start = this.createStart;
                this.createEvent.end = this.extendOriginal;
              }
              if (this.dragEvent !== null) {
                this.originalDrag.start = moment(
                  this.originalDrag.assignedService.assignedDateTime
                ).toDate();

                this.originalDrag.end = moment(
                  this.originalDrag.assignedService.deliverDateTime
                ).toDate();

                const foundEventIndex = this.events.findIndex(
                  e =>
                    e.orderNumber === this.originalDrag.orderNumber &&
                    e.assignedService.serviceId ===
                      this.originalDrag.assignedService.serviceId &&
                    e.assignedService.subServiceId ===
                      this.originalDrag.assignedService.subServiceId
                );
                if (foundEventIndex >= 0) {
                  this.events.splice(foundEventIndex, 1, this.originalDrag);
                }
              }
            } else if (!moment(afterDate).isSame(beforeDate)) {
              const {
                serviceId,
                subServiceId,
                orderId,
                note,
                worker
              } = event.assignedService;
              let updatedAssignedService = {
                serviceId: serviceId,
                subServiceId: subServiceId,
                workerId: worker.id,
                assignedDateTime: moment(event.start).format(
                  'YYYY-MM-DD[T]HH:mm:ss.SSSZ'
                ),
                deliverDateTime: moment(event.end).format(
                  'YYYY-MM-DD[T]HH:mm:ss.SSSZ'
                ),
                finishedDateTime: null,
                note: note,
                orderId: orderId
              };
              this.updateAssignServiceOnCalendar(updatedAssignedService);
            }
          }
        }
      }
      this.dragTime = null;
      this.dragEvent = null;
      this.createEvent = null;
      this.createStart = null;
      this.extendOriginal = null;
      this.originalDrag = null;
    },

    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal;
        } else {
          const i = this.events.indexOf(this.createEvent);
          if (i !== -1) {
            this.events.splice(i, 1);
          }
        }
      }

      this.createEvent = null;
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;
    },

    roundTime(time, down = true) {
      const roundToMinutes = 15;
      const roundDownTime = roundToMinutes * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },

    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },

    async addDateOnEvent(order) {
      this.endDrag();
      this.updateOrderDate(order);
    },

    async updateOrderDate(order) {
      await this.changeEstimatedDeliverDateForCalendarOrder(order);
      this.formatOrdersAsEvents();
    },

    convertTimeToAmPm(time) {
      var suffix = time.hour >= 12 ? ' PM' : ' AM';
      return ((time.hour + 11) % 12) + 1 + suffix;
    },

    getMoreText() {
      if (this.isSelectedOrdersCalendar())
        return `{0} ${this.$t('moreOrders')}.`;
      else return `{0} ${this.$t('moreWorkers')}.`;
    },

    addEventFromMonth(val) {
      if (moment(val.date).isAfter(moment().format('YYYY-MM-DD'))) {
        if (this.isSelectedOrdersCalendar())
          this.$refs.orderListSearchAndSelect.open(val.date, false);
        else {
          this.$refs.unassignedServicesList.open(val.date, false);
        }
      }
    },

    nowY(timeToY) {
      return this.cal ? timeToY(this.cal.times.now) + 'px' : '-10px';
    },

    getCurrentTime() {
      return this.cal
        ? this.cal.times.now.hour * 60 + this.cal.times.now.minute
        : 0;
    },

    scrollToTime() {
      const time = this.getCurrentTime();
      const first = Math.max(0, time - (time % 30) - 30);

      this.cal.scrollToTime(first);
    },

    updateTime() {
      setInterval(() => this.cal.updateTimes(), 60 * 1000);
    },

    nowTime() {
      const cal = this.$refs.calendar;
      if (!cal && !this.isMounted) {
        return -1;
      }
      return cal.formatTime(cal.times.now);
    },

    isAssignServicesDateHigherThanOrderDeliverDate(
      order,
      posibleOrderDeliverDate
    ) {
      let servicesBlocked = this.getAssignServicesDateHigherThanOrderDeliverDate(
        order,
        posibleOrderDeliverDate
      );
      return servicesBlocked.length > 0;
    },

    getAssignServicesDateHigherThanOrderDeliverDate(
      order,
      posibleOrderDeliverDate
    ) {
      let servicesBlocked = [];
      if (order !== undefined) {
        order.assignedServices.forEach(assign => {
          let fomattedEstimatedDeliverDate = moment(
            posibleOrderDeliverDate
          ).format('YYYY-MM-DD[T]HH:mm:ss.SSSZ');
          if (
            moment(assign.deliverDateTime).isAfter(fomattedEstimatedDeliverDate)
          ) {
            let serviceName = this.getServiceName(
              assign.serviceId,
              assign.subServiceId,
              order
            );
            servicesBlocked.push(serviceName);
          }
        });
      }
      return servicesBlocked;
    },

    getServiceName(serviceId, subServiceId, order) {
      let serviceName = '';
      let foundService = order.detail.services.find(
        service => service.id === serviceId
      );
      if (foundService !== undefined) {
        serviceName = foundService.name;
        if (subServiceId !== null) {
          let foundSubService = foundService.subServices.find(
            subService => subService.id === subServiceId
          );
          if (foundSubService !== undefined) {
            serviceName = `${serviceName} (${foundSubService.name})`;
          }
        }
      }
      return serviceName;
    },

    isSelectedOrdersCalendar() {
      return this.selectedOptionCalendar.value === ORDERS;
    },

    isSelectedWorkersCalendar() {
      return this.selectedOptionCalendar.value === WORKERS;
    },

    getCalendarStyle() {
      return {
        '--window-height': window.innerHeight,
        '--occupied-height-calendar': 135,
        '--occupied-height-list': 100 + 'px'
      };
    },

    renderAssignedService(event) {
      let assignedService = ` ${event.serviceName}`;
      if (event.subServiceName !== null)
        assignedService += ` (${event.subServiceName})`;
      return assignedService;
    },

    formatWorkersNameFromCalendarEvents(events) {
      this.workersName = events.map(
        ({ assignedService }) => assignedService.worker.name
      );
      this.workersName = [...new Set(this.workersName)];
      this.workersName.sort();
      this.selectedWorkers = this.workersName;
    },

    formatOrderNumbersFromCalendarEvents(events) {
      this.orderNumbers = events.map(({ orderNumber }) => orderNumber);
      this.orderNumbers = [...new Set(this.orderNumbers)];
      this.orderNumbers.sort();
      this.selectedOrderNumbers = this.orderNumbers;
    },

    applyFilterOnWorkersEvent() {
      var events = this.getFormattedWorkersAsEvent();

      events = events.filter(
        event =>
          this.selectedWorkers.includes(event.assignedService.worker.name) &&
          this.selectedOrderNumbers.includes(event.orderNumber)
      );

      this.events = events;
    },

    getFormattedWorkersAsEvent() {
      var events = [];

      this.allCalendarEvents.forEach(assignedService => {
        events.push({
          assignedService,
          start: moment(assignedService.assignedDateTime).toDate(),
          end: moment(assignedService.deliverDateTime).toDate(),
          color: this.getGeneratedColor(assignedService.worker.name),
          timed: true,
          orderNumber: this.numberFormatter(assignedService.orderNumber),
          isFinished: false
        });
      });

      return events;
    },

    openContextMenu(event) {
      this.$root.$emit('open-context-menu', {
        actions: [
          {
            label: this.$t('seeOrderDetail'),
            action: this.showEvent,
            icon: 'mdi-text-box-multiple-outline',
            show: true,
            isAction: true
          }
        ],
        data: event,
        positionX: event.nativeEvent.clientX,
        positionY: event.nativeEvent.clientY
      });
    },

    getCalendarContainerStyles() {
      let calendarContainerWidth = this.isSelectedWorkersCalendar ? 100 : 87;
      return {
        '--calendar-width': calendarContainerWidth + '%'
      };
    }
  }
};
</script>

<style scoped>
@import './ScopedStyles.css';
</style>
