<template>
  <v-chip class="mr-2" x-small dark :color="orderStatus.color">
    {{ orderStatus.name }}
  </v-chip>
</template>
<script>
export default {
  name: 'OrderStatusChip',

  props: ['orderStatus']
};
</script>
