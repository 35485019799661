<i18n src="./resources/locales.json"></i18n>

<template>
  <v-container>
    <h1 class="ml-3 primary--text">{{ orderTitleList }}</h1>
    <v-row class="tool-bar mt-10 ml-16 mb-n8 mr-16" no-gutters>
      <v-col cols="4">
        <v-text-field
          ref="searchBarNameOrCi"
          class="search-bar-orders"
          flat
          background-color="primaryVariant2"
          :label="$t('searchByIdName')"
          prepend-inner-icon="mdi-magnify"
          v-model="search"
          clearable
          outlined
          dense
          @keyup.enter="searchByInput"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          ref="searchBarOrderNumber"
          class="search-bar-orders"
          background-color="primaryVariant2"
          :label="$t('searchByOrderNumber')"
          prepend-inner-icon="mdi-magnify"
          v-model="searchOrderNumber"
          clearable
          outlined
          dense
          flat
          @keyup.enter="searchByInput"
        ></v-text-field>
      </v-col>
      <v-flex>
        <v-btn
          block
          color="primary"
          class="align-items: right"
          variant="tonal"
          @click="searchByInput"
          @keyup.enter="searchByInput"
        >
          {{ $t('search') }}
        </v-btn>
      </v-flex>
    </v-row>
    <v-row class="tool-bar mt-10 ml-16 mb-n3 mr-16" no-gutters>
      <v-col cols="4">
        <v-text-field
          ref="searchBarMotorSeries"
          class="search-bar-orders"
          background-color="primaryVariant2"
          :label="$t('searchByMotorSeries')"
          prepend-inner-icon="mdi-magnify"
          v-model="searchEngineSeries"
          clearable
          outlined
          dense
          flat
          @keyup.enter="searchByInput"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-menu
          ref="calendarMenu"
          v-model="calendarMenu"
          :close-on-content-click="false"
          :return-value.sync="dates"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="dateRangeText"
              :label="$t('dates')"
              prepend-inner-icon="mdi-calendar"
              background-color="primaryVariant2"
              readonly
              v-bind="attrs"
              v-on="on"
              hide-details
              outlined
              dense
              flat
              class="search-bar-orders"
              clearable
              @click:clear="cleanDateField"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dates"
            range
            scrollable
            show-adjacent-months
            no-title
            :max="currentDate"
            :locale="$i18n.locale"
            color="primary"
            @change="verifyRangeChangesDate"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="calendarMenu = false">
              {{ $t('cancel') }}
            </v-btn>
            <v-btn color="primary" @click="savePickedDate">
              {{ $t('ok') }}
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-flex>
        <v-btn
          :disabled="isDisabledSearchButton"
          block
          color="primary"
          class="align-items: right"
          @click="cleanFields"
          text
        >
          {{ $t('cleanSearchFields') }}
        </v-btn>
      </v-flex>
    </v-row>
    <v-divider class="ml-16 mt-7 mr-16"></v-divider>
    <v-row class="tool-bar mt-3 ml-16 mb-n12 mr-16" no-gutters>
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="100"
        offset-x
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="primary" dark v-bind="attrs" v-on="on">
            mdi-filter-variant
          </v-icon>
        </template>

        <v-card>
          <v-card-title>{{ $t('filters') }}</v-card-title>
          <v-card-text>
            <v-list>
              <v-subheader class="subHeaderColor">{{
                $t('orders')
              }}</v-subheader>
              <v-list-item>
                <v-radio-group
                  v-model="selectedPaymentFilterOption"
                  @change="setPaymentFilterOption"
                >
                  <v-radio
                    v-for="(l, i) in paymentFilterOptions"
                    :key="i"
                    :label="l.label"
                    :value="l.value"
                  ></v-radio>
                </v-radio-group>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-menu>
      <v-subheader>{{ $t('showing') }}</v-subheader>
      <v-subheader>
        - {{ renderInformationAboutPaymentFilterOption() }}</v-subheader
      >
    </v-row>
    <v-divider class="ml-16 mt-15 mr-16 mb-7"></v-divider>
    <v-row justify="center" align="center" class="mb-n10">
      <v-col cols="2" align="right">
        {{ $t('ordersPerPage') }}
      </v-col>

      <v-col cols="1">
        <v-select
          :items="[10, 20, 50, 100]"
          v-model="perPage"
          dense
          hide-details
          @change="getPaginatedOrders()"
        ></v-select>
      </v-col>

      <v-col cols="3">
        <v-pagination
          v-model="page"
          :length="Math.ceil(getTotalCountOrders / perPage)"
          @input="getPaginatedOrders()"
        ></v-pagination>
      </v-col>
    </v-row>
    <v-list three-line class="ma-16">
      <template v-for="(order, index) in allPaginatedOrders">
        <Order
          :key="order.id"
          :order="order"
          :isShowDotsContextMenu="true"
          :isShowIcon="true"
          :showContextMenuOptions="false"
          :currentOrHistorical="HISTORICAL"
          :isShowForUnassignedServiceCalendar="false"
          @confirm-action-delete="deleteOrder"
        />
        <v-divider :key="index" :inset="true"></v-divider>
      </template>
      <p v-if="allPaginatedOrders.length === 0" class="pl-4">
        {{ $t('thereIsNotYetA') }} {{ orderTitleList.toLowerCase() }}
      </p>
    </v-list>

    <DeleteConfirmModal
      ref="deleteOrderModal"
      @confirm-action="deleteOrderConfirm"
    />

    <v-row justify="center" align="center">
      <v-col cols="2" align="right">
        {{ $t('ordersPerPage') }}
      </v-col>

      <v-col cols="1">
        <v-select
          :items="[10, 20, 50, 100]"
          v-model="perPage"
          dense
          hide-details
          @change="getPaginatedOrders()"
        ></v-select>
      </v-col>

      <v-col cols="3">
        <v-pagination
          v-model="page"
          :length="Math.ceil(getTotalCountOrders / perPage)"
          @input="getPaginatedOrders()"
        ></v-pagination>
      </v-col>
    </v-row>

    <LoadingMask :isLoading="getIsOrderLoading" />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Order from '@/components/reusable-components/order/Order.vue';
import DeleteConfirmModal from '@/components/reusable-components/delete-confirm-modal/DeleteConfirmModal.vue';
import LoadingMask from '@/components/reusable-components/loading-mask/LoadingMask.vue';
import {
  ALL_OPTIONS,
  EMPTY_GUID,
  BUSINESS_ID_RECTIFICATION,
  HISTORICAL,
  CREDIT_WITH_MISSING_PAYMENTS
} from '@/constants/ConfigurationConstants';
import moment from 'moment';

export default {
  name: 'HistoricalOrdersList',

  components: { Order, DeleteConfirmModal, LoadingMask },

  data: () => ({
    search: '',
    searchOrderNumber: '',
    searchEngineSeries: '',
    isDisabledSearchButton: true,
    isDisabledPagination: true,
    page: 1,
    perPage: 10,
    menu: false,
    dates: [],
    currentDate: moment().format('YYYY-MM-DD'),
    calendarMenu: false,
    selectedPaymentFilterOption: ALL_OPTIONS,
    parametersOfSearchingAndFilter: {
      orderSearchType: HISTORICAL,
      businessId: BUSINESS_ID_RECTIFICATION,
      orderStatusId: '',
      filterOrdersBy: '',
      keyword: '',
      keywordOrderNumber: '',
      keywordEngineSeries: '',
      workerId: EMPTY_GUID,
      isGroupPaginated: 'false',
      fromDate: '',
      toDate: '',
      pageNumber: '',
      pageSize: ''
    },
    HISTORICAL,
    backdoor: 0
  }),

  props: ['orderTitleList'],

  computed: {
    ...mapGetters('orders', [
      'allPaginatedOrders',
      'getCountOrders',
      'getTotalCountOrders'
    ]),
    ...mapGetters('loading', ['getIsOrderLoading']),

    paymentFilterOptions() {
      return [
        {
          value: ALL_OPTIONS,
          label: this.$t('allOrders')
        },
        {
          value: CREDIT_WITH_MISSING_PAYMENTS,
          label: this.$t('creditOrdersWithMissingPayments')
        }
      ];
    },

    dateRangeText() {
      if (this.dates.length !== 0) {
        this.backdoor;
        return this.dates.map(d => this.convertDate(d)).join(' - ');
      } else {
        return '';
      }
    },

    searchBarElements() {
      return `${this.search}|${this.searchOrderNumber}|${this.searchEngineSeries}|${this.dateRangeText}`;
    }
  },

  watch: {
    searchBarElements(newVal, oldVal) {
      if (newVal !== oldVal) {
        const [
          newSearch,
          newSearchOrderNumber,
          newSearchEngineSeries,
          newDateRangeText
        ] = newVal.split('|');

        if (
          (!newSearch || newSearch === 'null') &&
          (!newSearchOrderNumber || newSearchOrderNumber === 'null') &&
          (!newSearchEngineSeries || newSearchEngineSeries === 'null') &&
          (!newDateRangeText || newDateRangeText === 'null')
        ) {
          this.isDisabledSearchButton = true;
        } else {
          this.isDisabledSearchButton = false;
        }
      }
    },

    perPage: {
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.page = 1;
        }
      },
      deep: true
    }
  },

  created() {
    this.getAllPaginatedHistoricalOrders();
    this.retrieveAllBusinessAndSpecialOrderStatuses(BUSINESS_ID_RECTIFICATION);
  },

  methods: {
    ...mapActions('orders', [
      'retrieveAllBusinessHistoricalOrders',
      'searchOrderPaginated',
      'removeOrder'
    ]),

    ...mapActions('orderStatuses', [
      'retrieveAllBusinessAndSpecialOrderStatuses'
    ]),

    deleteOrder(order) {
      this.$refs.deleteOrderModal.open(
        Object.assign({ message: this.$t('sureToDeleteThisOrder') }, order)
      );
    },

    deleteOrderConfirm(order) {
      this.removeOrder(order);
    },

    makeSearch() {
      this.searchOrderPaginated(this.parametersOfSearchingAndFilter);
    },

    getPaginatedOrders() {
      if (
        !this.parametersOfSearchingAndFilter.keyword &&
        !this.parametersOfSearchingAndFilter.keywordOrderNumber &&
        !this.parametersOfSearchingAndFilter.keywordEngineSeries &&
        !this.parametersOfSearchingAndFilter.fromDate &&
        !this.isCreditWithMissingPaymentsSelected()
      ) {
        this.getAllPaginatedHistoricalOrders();
      } else {
        this.parametersOfSearchingAndFilter.pageNumber = this.page;
        this.parametersOfSearchingAndFilter.pageSize = this.perPage;
        this.searchOrderPaginated(this.parametersOfSearchingAndFilter);
      }
    },

    searchByInput() {
      const [first, second] = this.dates;
      let parameters = {};
      if (first) parameters.fromDate = moment(first).format();
      if (second) parameters.toDate = this.setDateUntilLastMinute(second);
      else parameters.toDate = this.setDateUntilLastMinute(first);

      this.parametersOfSearchingAndFilter.fromDate = parameters.fromDate;
      this.parametersOfSearchingAndFilter.toDate = parameters.toDate;

      this.parametersOfSearchingAndFilter.keyword = this.search;
      this.parametersOfSearchingAndFilter.keywordOrderNumber = this.searchOrderNumber;
      this.parametersOfSearchingAndFilter.keywordEngineSeries = this.searchEngineSeries;
      this.parametersOfSearchingAndFilter.pageNumber = 1;
      this.page = 1;
      this.parametersOfSearchingAndFilter.pageSize = this.perPage;

      this.getPaginatedOrders();
    },

    cleanDateField() {
      this.calendarMenu = false;
      this.dates.length = 0;
      this.parametersOfSearchingAndFilter.fromDate = '';
      this.parametersOfSearchingAndFilter.toDate = '';
      this.backdoor++;
    },

    cleanFields() {
      this.$refs.searchBarNameOrCi.reset();
      this.$refs.searchBarOrderNumber.reset();
      this.$refs.searchBarMotorSeries.reset();
      this.parametersOfSearchingAndFilter.keyword = '';
      this.parametersOfSearchingAndFilter.keywordOrderNumber = '';
      this.parametersOfSearchingAndFilter.keywordEngineSeries = '';
      this.cleanDateField();
      this.page = 1;
      this.getHistoricalOrders();
    },

    getAllPaginatedHistoricalOrders() {
      this.retrieveAllBusinessHistoricalOrders({
        pageNumber: this.page,
        pageSize: this.perPage
      });
    },

    savePickedDate() {
      this.$refs.calendarMenu.save(this.dates);
      this.isDisabledSearchButton = false;
    },

    convertDate(date) {
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },

    verifyRangeChangesDate() {
      const [first, second] = this.dates;
      if (first !== undefined && second !== undefined) {
        if (moment(first).isSame(second)) this.dates = [first];
        if (moment(first).isAfter(second)) this.dates = [second, first];
        this.isDisabledSearchButton = false;
      } else {
        this.isDisabledSearchButton = true;
      }
    },

    getFormatDate(date) {
      return moment(date).format('YYYY-MM-DD');
    },

    setDateUntilLastMinute(date) {
      return moment(date)
        .set({ hour: 23, minute: 59, second: 59 })
        .format();
    },

    isCreditWithMissingPaymentsSelected() {
      return this.selectedPaymentFilterOption === CREDIT_WITH_MISSING_PAYMENTS;
    },

    setPaymentFilterOption() {
      if (this.isCreditWithMissingPaymentsSelected())
        this.parametersOfSearchingAndFilter.filterOrdersBy = CREDIT_WITH_MISSING_PAYMENTS;
      else this.parametersOfSearchingAndFilter.filterOrdersBy = 'All';

      this.getHistoricalOrders();
    },

    renderInformationAboutPaymentFilterOption() {
      if (this.isCreditWithMissingPaymentsSelected())
        return this.$t('creditOrdersWithMissingPayments');
      else return this.$t('allOrders');
    },

    getHistoricalOrders() {
      this.page = 1;
      if (
        !this.parametersOfSearchingAndFilter.keyword &&
        !this.parametersOfSearchingAndFilter.keywordOrderNumber &&
        !this.parametersOfSearchingAndFilter.keywordEngineSeries &&
        !this.parametersOfSearchingAndFilter.fromDate &&
        !this.isCreditWithMissingPaymentsSelected()
      ) {
        this.getAllPaginatedHistoricalOrders();
      } else {
        this.parametersOfSearchingAndFilter.pageNumber = 1;
        this.makeSearch();
      }
    }
  }
};
</script>
