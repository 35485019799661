<i18n src="./resources/locales.json"></i18n>

<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-form ref="serviceForm" v-model="isValid">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  class="required-field"
                  v-model="service.name"
                  :label="$t('name')"
                  :rules="[nameRules.required]"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  class="required-field"
                  v-model.number="service.price"
                  :label="$t('price')"
                  type="number"
                  :rules="[priceRules.required, priceRules.positive]"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-select
                  class="required-field"
                  :items="currencies"
                  v-model="service.currencyCode"
                  :label="$t('currency')"
                  :rules="[currencyRules.required]"
                  required
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-checkbox
                  v-model="service.fixedPrice"
                  :label="$t('fixedPrice')"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8">
                <v-text-field
                  class="required-field"
                  v-model.number="service.quantity"
                  :label="$t('quantity')"
                  type="number"
                  :rules="[quantityRules.required, quantityRules.positive]"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-checkbox
                  v-model="service.fixedQuantity"
                  :label="$t('fixedQuantity')"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="primary" :disabled="!isValid" @click="save">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ServiceDetailModal',

  data: () => ({
    isValid: false,
    service: {},
    dialog: false,
    defaultService: {
      id: '-1',
      name: '',
      price: null,
      currencyCode: '',
      quantity: null,
      fixedPrice: false,
      fixedQuantity: false
    },
    currencies: ['BOB', 'USD']
  }),

  computed: {
    formTitle() {
      const { id, serviceId } = this.service;

      if (id === '-1') {
        if (serviceId) return this.$t('newSubService');
        else return this.$t('newService');
      } else {
        if (serviceId) return this.$t('editSubService');
        else return this.$t('editService');
      }
    },

    nameRules() {
      return { required: value => !!value || this.$t('nameIsRequired') };
    },

    priceRules() {
      return {
        required: value => !!value || this.$t('priceIsRequired'),
        positive: value => value >= 0 || this.$t('priceMustBePositive')
      };
    },

    currencyRules() {
      return { required: value => !!value || this.$t('currencyIsRequired') };
    },

    quantityRules() {
      return {
        required: value => !!value || this.$t('quantityIsRequired'),
        positive: value => value >= 0 || this.$t('quantityMustBePositive')
      };
    }
  },

  methods: {
    ...mapActions('services', [
      'addService',
      'updateService',
      'addSubService',
      'updateSubService'
    ]),

    open(serviceToUpsert) {
      this.service = Object.assign({}, serviceToUpsert || this.defaultService);
      this.dialog = true;
    },

    close() {
      this.resetValidations();
      this.isValid = false;
      this.service = Object.assign({}, this.defaultService);
      this.dialog = false;
    },

    async save() {
      this.service = Object.assign({}, this.service);
      const { id, serviceId } = this.service;

      if (id === '-1') {
        if (serviceId) {
          try {
            await this.addSubService(this.service);
            this.$root.$emit(
              'open-status-message-snackbar',
              Object.assign(
                {},
                {
                  message: `${this.$t('theSubService')} ${
                    this.service.name
                  } ${this.$t('wasCreatedSuccessfully')}`,
                  messageType: 'success'
                }
              )
            );
          } catch (error) {
            this.$root.$emit(
              'open-status-message-snackbar',
              Object.assign(
                {},
                {
                  message: this.$t('errorWhileCreatingTheSubService'),
                  messageType: 'error'
                }
              )
            );
          }
        } else {
          try {
            await this.addService(this.service);
            this.$root.$emit(
              'open-status-message-snackbar',
              Object.assign(
                {},
                {
                  message: `${this.$t('theService')} ${
                    this.service.name
                  } ${this.$t('wasCreatedSuccessfully')}`,
                  messageType: 'success'
                }
              )
            );
          } catch (error) {
            this.$root.$emit(
              'open-status-message-snackbar',
              Object.assign(
                {},
                {
                  message: this.$t('errorWhileCreatingTheService'),
                  messageType: 'error'
                }
              )
            );
          }
        }
      } else {
        if (serviceId) {
          try {
            await this.updateSubService(this.service);
            this.$root.$emit(
              'open-status-message-snackbar',
              Object.assign(
                {},
                {
                  message: this.$t('subServiceInfoWasUpdatedSuccessfully'),
                  messageType: 'success'
                }
              )
            );
          } catch (error) {
            this.$root.$emit(
              'open-status-message-snackbar',
              Object.assign(
                {},
                {
                  message: this.$t(
                    'errorWhileUpdatingSubServiceInfoPleaseTryAgain'
                  ),
                  messageType: 'error'
                }
              )
            );
          }
        } else {
          try {
            await this.updateService(this.service);
            this.$root.$emit(
              'open-status-message-snackbar',
              Object.assign(
                {},
                {
                  message: this.$t('serviceInfoWasUpdatedSuccessfully'),
                  messageType: 'success'
                }
              )
            );
          } catch (error) {
            this.$root.$emit(
              'open-status-message-snackbar',
              Object.assign(
                {},
                {
                  message: this.$t(
                    'errorWhileUpdatingServiceInfoPleaseTryAgain'
                  ),
                  messageType: 'error'
                }
              )
            );
          }
        }
      }

      this.close();
    },

    resetValidations() {
      this.$refs.serviceForm.resetValidation();
    }
  }
};
</script>
