import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = () => ({
  brandsReport: [],
  clientsReport: [],
  finantialReport: {},
  pendingBillsReport: {},
  sparePartsReport: [],
  serviceReport: [],
  subServiceReport: [],
  ordersReport: {},
  sparePartTrendReport: {},
  workersReport: [],
  debtorsReport: [],
  unpickedOrdersReport: []
});

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
