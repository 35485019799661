<i18n src="./resources/locales.json"></i18n>

<template>
  <v-container>
    <h1 class="ml-3 primary--text">{{ $t('clients') }}</h1>
    <v-row
      class="mt-10 ml-16 mb-n12 mr-16"
      no-gutters
      style="flex-wrap: nowrap;"
    >
      <v-col class="flex-grow-0 flex-shrink-0">
        <v-text-field
          flat
          background-color="primaryVariant2"
          class="search-bar"
          :label="$t('searchByIdNameLastName')"
          prepend-inner-icon="mdi-magnify"
          v-model="search"
          clearable
          outlined
          dense
          @keyup.enter="searchByInput"
        ></v-text-field>
      </v-col>
      <v-col class="flex-grow-1 flex-shrink-0">
        <v-btn
          :disabled="isDisabled"
          color="primary"
          @click="searchByInput"
          @keyup.enter="searchByInput"
          text
        >
          {{ $t('search') }}
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="flex-grow-0 flex-shrink-0">
        <v-btn color="primary" @click="addClient" v-if="!isSupervisor()">
          {{ $t('newClient') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headersToShow"
      :items="allClients"
      :options.sync="options"
      :server-items-length="getClientListPaginationProps.totalCount"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 20, 50, 100],
        itemsPerPageText: $t('rowsPerPage')
      }"
      :items-per-page="10"
      class="elevation-1 ma-16"
      :loading="getIsClientLoading"
      :loading-text="$t('loadingPleaseWait')"
      :hide-default-footer="isSearching"
    >
      <template v-slot:[`item.actions`]="{ item }" v-if="isAdmin()">
        <v-icon small color="primary" class="mr-2" @click="editClient(item)">
          mdi-pencil-outline
        </v-icon>
        <!--v-icon small color="primary" @click="deleteClient(item)">
          mdi-delete
        </v-icon-->
      </template>
      <template v-slot:[`footer.page-text`]="items">
        {{ items.pageStart }} - {{ items.pageStop }} {{ $t('of') }}
        {{ items.itemsLength }}
      </template>
      <template v-slot:no-data>
        <p>{{ $t('thereAreNoClients') }}</p>
      </template>
    </v-data-table>

    <ClientDetailModal ref="clientDetail" />
    <DeleteConfirmModal
      ref="deleteClientModal"
      @confirm-action="deleteClientConfirm"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ClientDetailModal from '@/components/client/client-detail-modal/ClientDetailModal.vue';
import DeleteConfirmModal from '@/components/reusable-components/delete-confirm-modal/DeleteConfirmModal.vue';
import roles from '../../../mixins/roles.js';

export default {
  name: 'ClientsList',

  components: { ClientDetailModal, DeleteConfirmModal },

  mixins: [roles],

  data() {
    return {
      search: '',
      keyword: '',
      isSearching: false,
      isDisabled: true,
      options: {}
    };
  },

  watch: {
    options: {
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.retrieveAllClients(this.options);
        }
      },
      deep: true
    },

    search: {
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal) {
          if (newVal === null || newVal === '') {
            this.keyword = '';
            this.isSearching = false;
            this.options = Object.assign({}, { page: 1, itemsPerPage: 10 });
            this.retrieveAllClients(this.options);
            this.isDisabled = true;
          } else {
            this.isDisabled = false;
          }
        }
      },
      deep: true
    },

    keyword: {
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal) {
          if (newVal !== '') {
            this.makeSearch();
          }
        }
      },
      deep: true
    }
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t('name'),
          value: 'name',
          align: 'center',
          sortable: false
        },
        {
          text: this.$t('lastName'),
          value: 'lastName',
          align: 'center',
          sortable: false
        },
        {
          text: this.$t('phone'),
          value: 'phone',
          align: 'center',
          sortable: false
        },
        {
          text: this.$t('address'),
          value: 'address',
          align: 'center',
          sortable: false
        },
        { text: this.$t('id'), value: 'ci', align: 'center', sortable: false },
        {
          text: this.$t('actions'),
          value: 'actions',
          align: 'center',
          sortable: false
        }
      ];
    },

    ...mapGetters('clients', ['allClients', 'getClientListPaginationProps']),
    ...mapGetters('loading', ['getIsClientLoading']),
    ...mapGetters('paginationStorage', ['getStoredClientPageSize']),

    headersToShow() {
      if (this.isSupervisor())
        return this.headers.filter(h => h.value !== 'actions');
      else return this.headers;
    }
  },

  mounted() {
    this.retrieveAllClients(this.options);
  },

  methods: {
    ...mapActions('clients', [
      'removeClient',
      'retrieveAllClients',
      'searchClientsByNameLastNameId'
    ]),

    addClient() {
      this.$refs.clientDetail.open();
    },

    editClient(clientToUpdate) {
      this.$refs.clientDetail.open(Object.assign({}, clientToUpdate));
    },

    deleteClient(clientToDelete) {
      this.$refs.deleteClientModal.open(
        Object.assign(
          { message: this.$t('sureToDeleteThisClient') },
          { id: clientToDelete.id },
          { name: clientToDelete.name + ' ' + clientToDelete.lastName }
        )
      );
    },

    deleteClientConfirm(clientToDeleteId) {
      this.removeClient(clientToDeleteId);
    },

    makeSearch() {
      this.searchClientsByNameLastNameId(this.keyword);
    },

    searchByInput() {
      this.keyword = this.search;
      this.isSearching = true;
    }
  }
};
</script>

<style scoped>
@import './ScopedStyles.css';
</style>
