<i18n src="./resources/locales.json"></i18n>

<template>
  <v-container fluid>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title
            >{{ $t('subPartsOf') }} {{ part.name }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn text @click="addSubPart">{{ $t('newSubPart') }}</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container fluid>
          <v-data-table
            :headers="headers"
            :items="getSubPartsByPartId(part.id)"
            sort-by="name"
            class="elevation-1"
            hide-default-footer
            disable-pagination
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                small
                class="mr-2"
                color="primary"
                @click="editSubPart(item)"
              >
                mdi-pencil-outline
              </v-icon>
              <!--v-icon
                small
                class="mr-2"
                color="primary"
                @click="deleteSubPart(item)"
              >
                mdi-delete
              </v-icon-->
            </template>
            <template v-slot:no-data>
              <p>
                {{ $t('thereAreNoSubParts') }}
              </p>
            </template>
          </v-data-table>
        </v-container>
      </v-card>
    </v-dialog>

    <PartDetailModal ref="partDetailModal" />
    <DeleteConfirmModal
      ref="deleteSubPartModal"
      @confirm-action="deleteSubPartConfirm"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DeleteConfirmModal from '@/components/reusable-components/delete-confirm-modal/DeleteConfirmModal.vue';
import PartDetailModal from '@/components/part/part-detail-modal/PartDetailModal.vue';

export default {
  name: 'SubPartsListModal',

  components: { PartDetailModal, DeleteConfirmModal },

  data: () => ({
    dialog: false,
    part: {
      id: '-1'
    },
    defaultSubPart: {
      id: '-1',
      name: '',
      partId: ''
    }
  }),

  computed: {
    headers() {
      return [
        { text: this.$t('subPart'), value: 'name', align: 'center' },
        {
          text: this.$t('actions'),
          value: 'actions',
          align: 'center',
          sortable: false
        }
      ];
    },

    ...mapGetters('parts', ['getSubPartsByPartId'])
  },

  methods: {
    ...mapActions('parts', ['removeSubPart']),

    open(part) {
      this.part = Object.assign({}, part);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },

    addSubPart() {
      this.defaultSubPart.partId = this.part.id;
      this.$refs.partDetailModal.open(Object.assign({}, this.defaultSubPart));
    },

    editSubPart(subPartToUpdate) {
      this.$refs.partDetailModal.open(Object.assign({}, subPartToUpdate));
    },

    deleteSubPart(subPartToDelete) {
      this.$refs.deleteSubPartModal.open(
        Object.assign(
          { message: this.$t('sureToDeleteThisSubPart') },
          subPartToDelete
        )
      );
    },

    deleteSubPartConfirm(subPartToDeleteId) {
      this.removeSubPart(subPartToDeleteId);
    }
  }
};
</script>
