'use strict';

import axios from 'axios';
import { SERVICE_API_URL } from '../../../constants/Environment';

const retrieveAllServices = async ({ dispatch, commit }) => {
  dispatch('loading/setIsServiceLoading', true, { root: true });
  try {
    await axios.get(`${SERVICE_API_URL}/services`).then(response => {
      commit('setAllServices', response.data);
    });
    dispatch('loading/setIsServiceLoading', false, { root: true });
  } catch (error) {
    console.error('There was an error while retrieving services' + error);
  }
};

const addService = async ({ commit }, serviceToAdd) => {
  delete serviceToAdd.id;
  return new Promise((resolve, reject) => {
    axios
      .post(`${SERVICE_API_URL}/services`, serviceToAdd)
      .then(response => {
        commit('addService', response.data);
        resolve(response);
      })
      .catch(error => {
        reject(error);
        console.error('There was an error while posting the service' + error);
      });
  });
};

const updateService = async ({ commit }, serviceToUpdate) => {
  if (serviceToUpdate.parts === null) delete serviceToUpdate.parts;

  return new Promise((resolve, reject) => {
    axios
      .put(`${SERVICE_API_URL}/services/${serviceToUpdate.id}`, serviceToUpdate)
      .then(response => {
        commit('updateService', response.data);
        resolve(response);
      })
      .catch(error => {
        reject(error);
        console.error('There was an error while updating the service' + error);
      });
  });
};

const removeService = ({ commit }, serviceId) => {
  axios.delete(`${SERVICE_API_URL}/services/${serviceId}`).then(response => {
    commit('removeService', response.data.id);
  });
};

const addSubService = async ({ commit }, subServiceToAdd) => {
  delete subServiceToAdd.id;
  return new Promise((resolve, reject) => {
    axios
      .post(`${SERVICE_API_URL}/sub-services`, subServiceToAdd)
      .then(response => {
        commit('addSubService', response.data);
        resolve(response);
      })
      .catch(error => {
        reject(error);
        console.error(
          'There was an error while posting the sub service' + error
        );
      });
  });
};

const updateSubService = async ({ commit }, subServiceToUpdate) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${SERVICE_API_URL}/sub-services/${subServiceToUpdate.id}`,
        subServiceToUpdate
      )
      .then(response => {
        commit('updateSubService', response.data);
        resolve(response);
      })
      .catch(error => {
        reject(error);
        console.error(
          'There was an error while updating the sub service' + error
        );
      });
  });
};

const removeSubService = ({ commit }, subServiceToDeleteId) => {
  axios
    .delete(`${SERVICE_API_URL}/sub-services/${subServiceToDeleteId}`)
    .then(response => {
      commit('removeSubService', response.data.id);
    });
};

export default {
  retrieveAllServices,
  addService,
  updateService,
  removeService,
  addSubService,
  updateSubService,
  removeSubService
};
