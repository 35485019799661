<i18n src="./resources/locales.json"></i18n>

<template>
  <OrdersList :orderTitleList="orderTitleList" />
</template>

<script>
import OrdersList from '@/components/reusable-components/orders-list/OrdersList.vue';

export default {
  name: 'RectifierOrdersList',

  components: { OrdersList },

  computed: {
    orderTitleList() {
      return this.$t('engineRebuildingOrders');
    }
  }
};
</script>
