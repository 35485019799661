<script>
import { HorizontalBar, mixins } from 'vue-chartjs';

const { reactiveProp } = mixins;

export default {
  extends: HorizontalBar,

  name: 'BarChart',

  data: () => ({
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: { display: false }
    }
  }),

  mixins: [reactiveProp],

  props: ['chartData'],

  mounted() {
    this.renderChart(this.chartData, this.options);
  }
};
</script>
