<i18n src="./resources/locales.json"></i18n>

<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-form ref="unitOfMeasureForm" v-model="isValid">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  class="required-field"
                  v-model="unitOfMeasure.name"
                  :label="$t('name')"
                  :rules="[nameRules.required]"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  class="required-field"
                  v-model="unitOfMeasure.code"
                  :label="$t('code')"
                  :rules="[codeRules.required]"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="unitOfMeasure.description"
                  :label="`${$t('description')} (${$t('optional')})`"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-checkbox
                  v-model="unitOfMeasure.default"
                  :label="$t('defaultUnit')"
                  :disabled="isDisabled"
                ></v-checkbox>
              </v-col>
            </v-row>
            <p v-if="isDisabled">
              <span class="default-alert">*</span>
              {{ $t('defaultUnitAlreadyExists') }}
            </p>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="primary" :disabled="!isValid" @click="save">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'UnitOfMeasureDetailModal',

  data() {
    return {
      isValid: false,
      unitOfMeasure: {},
      dialog: false,
      defaultUnitOfMeasure: {
        id: '-1',
        name: '',
        code: '',
        description: '',
        default: null
      }
    };
  },

  computed: {
    ...mapGetters('spareParts', ['getUnitOfMeasureByDefault']),

    formTitle() {
      const { id } = this.unitOfMeasure;
      return id === '-1'
        ? this.$t('newUnitOfMeasure')
        : this.$t('editUnitOfMeasure');
    },

    nameRules() {
      return { required: value => !!value || this.$t('nameIsRequired') };
    },

    codeRules() {
      return { required: value => !!value || this.$t('codeIsRequired') };
    },

    isDisabled() {
      let isDisabled = false;
      if (this.getUnitOfMeasureByDefault.id !== undefined) {
        if (this.unitOfMeasure.id === this.getUnitOfMeasureByDefault.id) {
          isDisabled = false;
        } else {
          isDisabled = true;
        }
      } else {
        isDisabled = false;
      }
      return isDisabled;
    }
  },

  created() {
    this.retrieveAllUnitOfMeasures();
  },

  methods: {
    ...mapActions('spareParts', [
      'addUnitOfMeasure',
      'updateUnitOfMeasure',
      'retrieveAllUnitOfMeasures'
    ]),

    open(unitOfMeasureToUpsert) {
      this.unitOfMeasure = Object.assign(
        {},
        unitOfMeasureToUpsert || this.defaultUnitOfMeasure
      );
      this.dialog = true;
    },

    close() {
      this.resetValidations();
      this.isValid = false;
      this.unitOfMeasure = Object.assign({}, this.defaultUnitOfMeasure);
      this.dialog = false;
    },

    async save() {
      const { id } = this.unitOfMeasure;
      this.unitOfMeasure = Object.assign({}, this.unitOfMeasure);

      if (id !== '-1') {
        try {
          await this.updateUnitOfMeasure(this.unitOfMeasure);
          this.$root.$emit(
            'open-status-message-snackbar',
            Object.assign(
              {},
              {
                message: this.$t('unitOfMeasureInfoWasUpdatedSuccessfully'),
                messageType: 'success'
              }
            )
          );
        } catch (error) {
          this.$root.$emit(
            'open-status-message-snackbar',
            Object.assign(
              {},
              {
                message: this.$t(
                  'errorWhileUpdatingUnitOfMeasureInfoPleaseTryAgain'
                ),
                messageType: 'error'
              }
            )
          );
        }
      } else {
        try {
          await this.addUnitOfMeasure(this.unitOfMeasure);
          this.$root.$emit(
            'open-status-message-snackbar',
            Object.assign(
              {},
              {
                message: `${this.$t('theUnitOfMeasure')} ${
                  this.unitOfMeasure.name
                } ${this.$t('wasCreatedSuccessfully')}`,
                messageType: 'success'
              }
            )
          );
        } catch (error) {
          this.$root.$emit(
            'open-status-message-snackbar',
            Object.assign(
              {},
              {
                message: this.$t('errorWhileCreatingTheUnitOfMeasure'),
                messageType: 'error'
              }
            )
          );
        }
      }

      this.close();
    },

    resetValidations() {
      this.$refs.unitOfMeasureForm.resetValidation();
    }
  }
};
</script>
