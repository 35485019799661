<i18n src="./resources/locales.json"></i18n>

<template>
  <CanceledOrdersList :orderTitleList="orderTitleList" />
</template>

<script>
import CanceledOrdersList from '../../../../components/reusable-components/canceled-orders-list/CanceledOrdersList.vue';
export default {
  name: 'RectifierCanceledOrdersList',

  components: { CanceledOrdersList },

  computed: {
    orderTitleList() {
      return this.$t('engineRebuildingCanceledOrders');
    }
  }
};
</script>
