import { render, staticRenderFns } from "./MoveOrderStatusMessageModal.vue?vue&type=template&id=e2a300da"
import script from "./MoveOrderStatusMessageModal.vue?vue&type=script&lang=js"
export * from "./MoveOrderStatusMessageModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./resources/locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fgithub%2Fworkspace%2Fsrc%2Fcomponents%2Freusable-components%2Fmove-order-status-message-modal%2FMoveOrderStatusMessageModal.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports