'use strict';

import axios from 'axios';
import { ITEM_API_URL } from '../../../constants/Environment';

const retrieveAllParts = async ({ dispatch, commit }) => {
  dispatch('loading/setIsPartLoading', true, { root: true });
  try {
    await axios.get(`${ITEM_API_URL}/parts`).then(response => {
      commit('setAllParts', response.data);
    });
    dispatch('loading/setIsPartLoading', false, { root: true });
  } catch (error) {
    console.error('There was an error while retrieving parts' + error);
  }
};

const addPart = async ({ commit }, part) => {
  delete part.id;
  return new Promise((resolve, reject) => {
    axios
      .post(`${ITEM_API_URL}/parts`, part)
      .then(response => {
        commit('addPart', response.data);
        resolve(resolve);
      })
      .catch(error => {
        reject(error);
        console.error('There was an error while posting the part' + error);
      });
  });
};

const updatePart = ({ commit }, partToUpdate) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${ITEM_API_URL}/parts/${partToUpdate.id}`, partToUpdate)
      .then(response => {
        commit('updatePart', response.data);
        resolve(response);
      })
      .catch(error => {
        reject(error);
        console.error('There was an error while updating the part' + error);
      });
  });
};

const removePart = ({ commit }, partToDeleteId) => {
  axios.delete(`${ITEM_API_URL}/parts/${partToDeleteId}`).then(response => {
    commit('removePart', response.data);
  });
};

const addSubPart = ({ commit }, subPart) => {
  delete subPart.id;
  return new Promise((resolve, reject) => {
    axios
      .post(`${ITEM_API_URL}/sub-parts`, subPart)
      .then(response => {
        commit('addSubPart', response.data);
        resolve(response);
      })
      .catch(error => {
        reject(error);
        console.error('There was an error while posting the sub part' + error);
      });
  });
};

const updateSubPart = ({ commit }, subPartToUpdate) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${ITEM_API_URL}/sub-parts/${subPartToUpdate.id}`, subPartToUpdate)
      .then(response => {
        commit('updateSubPart', response.data);
        resolve(response);
      })
      .catch(error => {
        reject(error);
        console.error('There was an error while updating the sub part' + error);
      });
  });
};

const removeSubPart = ({ commit }, subPartToDeleteId) => {
  axios
    .delete(`${ITEM_API_URL}/sub-parts/${subPartToDeleteId}`)
    .then(response => {
      commit('removeSubPart', response.data);
    });
};

export default {
  retrieveAllParts,
  addPart,
  updatePart,
  removePart,
  addSubPart,
  updateSubPart,
  removeSubPart
};
