<i18n src="./resources/locales.json"></i18n>

<template>
  <div @mouseover="hover = true" @mouseleave="hover = false">
    <v-list-item :key="order.id">
      <v-list-item-avatar :color="getAvatarBackgroundColor(order.client.ci)">
        <span class="white--text">{{ getAvatarText(order.client.name) }}</span>
      </v-list-item-avatar>

      <v-list-item-content @click="openOrderDetail(order)">
        <v-list-item-title>
          <span class="font-weight-bold">
            {{ numberFormatter(order.orderNumber) }}
          </span>
          - {{ order.client.name }} {{ order.client.lastName }}
          {{ getOrderTitle(order.detail) }}</v-list-item-title
        >
        <v-list-item-subtitle
          v-if="isShowForUnassignedServiceCalendar === false"
          >{{ getOrderSubtitle(order) }}</v-list-item-subtitle
        >
        <v-list-item-subtitle v-else>{{
          renderUnassignedService()
        }}</v-list-item-subtitle>
        <v-list-item-subtitle>
          <OrderStatusChip :orderStatus="order.orderStatus" />

          <v-icon
            :color="getIconColorForDeadline(order)"
            small
            class="float-right"
            v-if="isShowIcon"
            >{{ getIconForDeadline(order) }}</v-icon
          >
          <v-icon
            :color="getIconColorForServicesFinished()"
            small
            class="float-right"
            v-if="isShowIcon"
            >mdi-order-bool-ascending-variant</v-icon
          >
          <v-icon
            :color="getIconColorForServices(order)"
            small
            class="float-right"
            v-if="isShowIcon"
            >mdi-account-hard-hat</v-icon
          >

          <v-icon
            :color="getIconColorForSpareParts(order)"
            small
            class="float-right"
            v-if="isShowIcon"
            >mdi-car-door</v-icon
          >
          <v-icon
            color="info"
            small
            class="float-right"
            v-if="isShowIcon && order.note && order.note.trim()"
            >mdi-note</v-icon
          >
          <v-icon
            v-if="isShowIcon && order.hasPriority"
            color="red"
            small
            class="float-right"
            >mdi-flag</v-icon
          >
          <v-icon
            v-if="isShowIcon && isOrderCancelled()"
            color="error"
            small
            class="float-right"
            >mdi-alpha-c-circle</v-icon
          >
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action v-if="isShowDotsContextMenu">
        <v-icon
          v-if="hover"
          small
          color="primary"
          class="mr-2"
          @click="openContextMenu($event, order)"
        >
          mdi-dots-vertical
        </v-icon>
      </v-list-item-action>
    </v-list-item>
    <ConfirmationModal
      ref="confirmationModal"
      @confirm-action="confirmationModalConfirmAction"
    />
    <ManageJobListModal ref="manageJobListModal" />
    <OrderDeliverOptionModal ref="deliverOptionModal" />
    <PrintConfigurationModal ref="printConfirmationModal" />
    <VerificationModal
      ref="verificationModal"
      @confirm-action="cancelOrderFromMenu({ order })"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import OrderStatusChip from '@/components/reusable-components/order-status-chip/OrderStatusChip.vue';
import roles from '../../../mixins/roles.js';
import moment from 'moment';
import ConfirmationModal from '@/components/reusable-components/confirmation-modal/ConfirmationModal.vue';
import PrintConfigurationModal from '@/components/reusable-components/printer/PrintConfigurationModal.vue';
import ManageJobListModal from '@/components/reusable-components/manage-job-list-modal/ManageJobListModal.vue';
import OrderDeliverOptionModal from '@/components/order/order-deliver-option-modal/OrderDeliverOptionModal.vue';
import { CANCEL_CREDIT } from '@/constants/ContextMenuConstants';
import OrderMixin from '../../../mixins/OrderMixin.js';
import VerificationModal from '@/components/reusable-components/verification-modal/VerificationModal.vue';

export default {
  name: 'Order',

  components: {
    OrderStatusChip,
    ConfirmationModal,
    ManageJobListModal,
    OrderDeliverOptionModal,
    PrintConfigurationModal,
    VerificationModal
  },

  props: [
    'order',
    'isShowDotsContextMenu',
    'isShowIcon',
    'showContextMenuOptions',
    'currentOrHistorical',
    'isShowForUnassignedServiceCalendar',
    'unassignedServiceForCalendar'
  ],

  data() {
    return {
      hover: false,
      isConfirmModalAction: null,
      defaultQuickValidationResponse: {
        response: false,
        id: '00000000-0000-0000-0000-000000000000',
        fullName: ''
      }
    };
  },

  computed: {
    ...mapGetters('orderStatuses', ['getFinalOrderStatus']),
    ...mapGetters('orders', ['getOrderById']),
    ...mapGetters('orderWorkDonesAndDetails', ['allOrderWorkDoneList']),
    ...mapGetters('userStorage', ['getQuickValidationResponse'])
  },

  methods: {
    ...mapActions('orders', [
      'cancelOrder',
      'enableOrder',
      'retrieveOrderById',
      'updateIsLoanOfAnOrderAsFalse',
      'updateOrderStatusOfOrder'
    ]),

    ...mapActions('orderStatuses', ['retrieveFinalOrderStatus']),

    ...mapActions('orderSpareParts', ['retrieveAllOrderSparePartsOfOrder']),
    ...mapActions('workDone', ['retrieveAllWorkDones']),
    ...mapActions('workDoneDetail', ['retrieveAllWorkDoneDetails']),
    ...mapActions('orderWorkDonesAndDetails', [
      'retrieveAllOrderWorkDonesAndDetailsOfOrder'
    ]),
    ...mapMutations('userStorage', ['setQuickValidationResponse']),

    getAvatarText(name) {
      let result = '';
      const firstName = name.trim().split(' ')[0];
      const lastName = name.trim().split(' ')[1];

      if (firstName) result += firstName.charAt(0);
      if (lastName) result += lastName.charAt(0);
      else if (firstName.length > 1) result += firstName.charAt(1);

      return result.toUpperCase();
    },

    numberFormatter(orderNumber) {
      return orderNumber.toString().length < 5
        ? this.numberFormatter('0' + orderNumber)
        : orderNumber;
    },

    getOrderTitle(detail) {
      return this.renderItem(detail);
    },

    renderItem(detail) {
      let result = '';
      const { item } = detail;

      if (item) {
        const { specification } = item;
        const { engine, brand, numberOfCylinders } = specification;

        if (engine !== '') result = ` - ${engine}`;
        if (brand !== '') result += ` - ${brand}`;
        if (numberOfCylinders !== '') result += ` - ${numberOfCylinders}`;
      }

      return result;
    },

    getOrderSubtitle(order) {
      return this.renderServices(order) || this.renderProducts(order);
    },

    isSpareParts(spareParts) {
      return spareParts.length > 0;
    },

    renderServices(order) {
      let result = '';
      const { detail } = order;
      const { services } = detail;
      if (services) {
        if (services.length === 0) result = this.$t('thereAreNoServices');
        else {
          services.forEach(service => {
            if (
              service.subServices !== null &&
              service.subServices.length !== 0
            )
              result += `${service.name} (${this.renderSubServices(service)}) `;
            else result += ` ${service.name} `;
          });
        }
      }
      return result;
    },

    renderSubServices(service) {
      let subServices = service.subServices.filter(
        subservice => subservice.name !== ''
      );
      return subServices.map(subservice => subservice.name).join(', ');
    },

    renderProducts(order) {
      const { products } = order;
      if (products) {
        let result = '';
        products.forEach(product => {
          result += ` ${product.name}, `;
        });
        return result;
      }
    },

    _getHueFromString(string = '') {
      let hash = 0;
      for (let i = 0; i < string.length; i++) {
        hash = string.charCodeAt(i) + ((hash << 20) - hash);
      }
      return hash % 360;
    },

    _getColorFromString(string) {
      const saturation = 65;
      const lightness = 70;
      const hue = this._getHueFromString(string);
      return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    },

    getAvatarBackgroundColor(clientCI) {
      const stringForColor =
        clientCI.toString().length > 5
          ? clientCI.toString().substring(0, 5)
          : clientCI.toString().charAt(0);
      return this._getColorFromString(stringForColor);
    },

    getIconColorForServices(order) {
      const { detail } = order;
      let totalServicesCounter = 0;
      if (detail && detail.services) {
        detail.services.forEach(service => {
          if (service.subServices.length === 0) {
            totalServicesCounter += 1;
          } else {
            totalServicesCounter += service.subServices.length;
          }
        });
        if (
          order.assignedServices.length < totalServicesCounter &&
          order.orderStatus.position === -1
        ) {
          return 'grey';
        } else if (
          order.assignedServices.length === totalServicesCounter &&
          order.orderStatus.position === -1
        ) {
          return 'blue';
        } else if (totalServicesCounter === order.assignedServices.length) {
          return 'success';
        } else if (order.assignedServices.length === 0) {
          return 'error';
        } else {
          return 'warning';
        }
      }
    },

    getIconColorForDeadline(order) {
      if (order.estimatedDeliverDate !== null) {
        let indicator =
          moment(order.estimatedDeliverDate).diff(order.creationDate, 'hours') /
          3;
        let leftTime = moment(order.estimatedDeliverDate).diff(
          moment().format('YYYY-MM-DD[T]HH:mm:ss.SSSZ'),
          'hours'
        );
        if (leftTime >= 0) {
          if (leftTime <= indicator * 3 && leftTime > indicator * 2) {
            return 'success';
          } else if (leftTime <= indicator * 2 && leftTime > indicator * 1) {
            return 'yellow';
          } else if (leftTime <= indicator * 1) {
            return 'red';
          }
        } else {
          return 'red';
        }
      } else {
        return 'grey';
      }
    },

    openCancelVerificationModal() {
      this.$refs.verificationModal.open();
    },

    getIconForDeadline(order) {
      if (order.estimatedDeliverDate !== null) {
        let leftTime = moment(order.estimatedDeliverDate).diff(
          moment().format('YYYY-MM-DD[T]HH:mm:ss.SSSZ'),
          'hours'
        );
        if (leftTime < 0) {
          return 'mdi-alert';
        } else {
          return 'mdi-checkbox-blank-circle';
        }
      } else {
        return 'mdi-checkbox-blank-circle';
      }
    },

    getIconColorForSpareParts(order) {
      const { detail } = order;
      const { item } = detail;
      if (item) {
        const { spareParts } = item;

        if (spareParts.length === 0) return 'grey';
        else if (this._isYellow(spareParts)) return 'yellow';
        else if (this._isRed(spareParts)) return 'red';
        else if (this._isGreen(spareParts)) return 'success';
      }
    },

    _isYellow(spareParts) {
      return (
        this._isSomeOrderSparePartsReceived(spareParts) &&
        !this._isAllOrderSparePartsReceived(spareParts)
      );
    },

    _isRed(spareParts) {
      return this._isAllOrderSparePartsNotReceived(spareParts);
    },

    _isGreen(spareParts) {
      return this._isAllOrderSparePartsReceived(spareParts);
    },

    _isAllOrderSparePartsReceived(spareParts) {
      return spareParts.every(this._isSparePartReceived);
    },

    _isSomeOrderSparePartsReceived(spareParts) {
      return spareParts.some(this._isSparePartReceived);
    },

    _isAllOrderSparePartsNotReceived(spareParts) {
      return spareParts.every(this._isSparePartNotReceived);
    },

    _isSparePartReceived(sparePart) {
      return sparePart.received === true;
    },

    _isSparePartNotReceived(sparePart) {
      return sparePart.received === false;
    },

    _isNotAllOrderPartsNeedOrderSpareParts(
      orderParts,
      completeInformationOfOrderSparePartsList
    ) {
      return !this._isAllOrderPartsNeedOrderSpareParts(
        orderParts,
        completeInformationOfOrderSparePartsList
      );
    },

    _isAllOrderPartsNeedOrderSpareParts(
      orderParts,
      completeInformationOfOrderSparePartsList
    ) {
      return orderParts.every(op =>
        completeInformationOfOrderSparePartsList.some(mmx =>
          mmx.parts.some(mmxp => mmxp.id === op.id)
        )
      );
    },

    isOrderCancelled() {
      return this.order.canceled;
    },

    async openOrderDetail(order) {
      await this.retrieveOrderById(order.id);
      this.$root.$emit(
        'open-order-detail-modal-info',
        Object.assign({}, this.getOrderById),
        true
      );
    },

    isAbleToPrintSpareParts(order) {
      let isAble = true;
      if (order && order.detail && order.detail.item.spareParts.length > 0) {
        if (
          order.detail.item.spareParts.every(
            sparePart => sparePart.price !== ''
          )
        ) {
          isAble = false;
        }
      }
      if (order && order.detail && order.detail.item.spareParts.length === 0) {
        isAble = false;
      }
      return isAble;
    },

    editOrder(data) {
      this.$root.$emit(
        'open-rectifier-order-detail-modal',
        Object.assign({}, data.order)
      );
    },

    deleteOrder(data) {
      this.$emit('confirm-action-delete', data.order);
    },

    assignWorker(data) {
      this.$root.$emit('open-worker-assigment-modal', data.order);
    },

    receiveSpareParts(data) {
      this.$root.$emit(
        'open-receive-spare-parts-modal',
        Object.assign({}, data.order)
      );
    },

    changeOrderStatus(data) {
      this.$root.$emit('open-order-status-selection-modal', data.order);
    },

    printOrder(order, isWithDiscount) {
      this.$root.$emit('print-order-pdf', order.id, isWithDiscount);
    },

    printSparePart(data) {
      this.$root.$emit('print-spare-part-pdf', data.order.id);
    },

    async openQualityAssuranceModal(data) {
      await this.retrieveAllOrderWorkDonesAndDetailsOfOrder(data.order.id);
      await this.retrieveAllOrderSparePartsOfOrder(data.order.id);
      await this.retrieveAllWorkDones();
      await this.retrieveAllWorkDoneDetails();

      this.$root.$emit(
        'open-quality-assurance-modal',
        Object.assign({}, data.order, this.allOrderWorkDoneList)
      );
    },

    async cancelOrderFromMenu(data) {
      try {
        await this.cancelOrder({
          orderToCancelId: data.order.id,
          currentOrHistorical: this.currentOrHistorical,
          canceledBy: this.getQuickValidationResponse.fullName,
          canceledById: this.getQuickValidationResponse.id
        });
        this.$root.$emit(
          'open-status-message-snackbar',
          Object.assign(
            {},
            {
              message: this.$t('orderWasCancelledSuccessfully'),
              messageType: 'success'
            }
          )
        );
      } catch (error) {
        this.$root.$emit(
          'open-status-message-snackbar',
          Object.assign(
            {},
            {
              message: this.$t('errorWhileCancellingTheOrder'),
              messageType: 'error'
            }
          )
        );
      } finally {
        this.setQuickValidationResponse(this.defaultQuickValidationResponse);
      }
    },

    async enableOrderFromMenu(data) {
      try {
        await this.enableOrder(data.order.id);
        this.$root.$emit(
          'open-status-message-snackbar',
          Object.assign(
            {},
            {
              message: this.$t('orderWasEnabledSuccessfully'),
              messageType: 'success'
            }
          )
        );
      } catch (error) {
        this.$root.$emit(
          'open-status-message-snackbar',
          Object.assign(
            {},
            {
              message: this.$t('errorWhileEnablingTheOrder'),
              messageType: 'error'
            }
          )
        );
      }
    },

    hasSpareParts() {
      let hasSpareParts = false;
      if (this.order.detail && this.order.detail.item) {
        if (this.order.detail.item.spareParts.length > 0) {
          hasSpareParts = true;
        }
      }
      return hasSpareParts;
    },

    openContextMenu(event, order) {
      this.$root.$emit('open-context-menu', {
        actions: [
          {
            label: this.$t('editOrder'),
            action: this.editOrder,
            icon: 'mdi-pencil-outline',
            show:
              this.showContextMenuOptions &&
              this.isAdmin() &&
              !this.isOrderCancelled(),
            isAction: true
          },
          /*{
            label: this.$t('deleteOrder'),
            action: this.deleteOrder,
            icon: 'mdi-delete',
            show:
              this.showContextMenuOptions &&
              (this.isAdmin() || this.isCashier()) &&
              !this.isOrderCancelled(),
            isAction: true
          },*/
          {
            show: true,
            isAction: false
          },
          {
            label: this.$t('receiveSpareParts'),
            action: this.receiveSpareParts,
            icon: 'mdi-book-check-outline',
            show:
              this.showContextMenuOptions &&
              (this.isAdmin() || this.isSupervisor()) &&
              this.hasSpareParts() &&
              !this.isOrderCancelled(),
            isAction: true
          },
          {
            label: this.$t('assignWorker'),
            action: this.assignWorker,
            icon: 'mdi-account-hard-hat-outline',
            show:
              this.showContextMenuOptions &&
              (this.isAdmin() || this.isSupervisor()) &&
              !this.isOrderCancelled(),
            isAction: true
          },
          {
            label: this.$t('manageJob'),
            action: this.openManageJobsModal,
            icon: 'mdi-briefcase-check-outline',
            show:
              this.showContextMenuOptions &&
              (this.isAdmin() || this.isSupervisor()) &&
              !this.isOrderCancelled(),
            isAction: true
          },
          {
            show: true,
            isAction: false
          },
          {
            label: this.$t('qualityControl'),
            action: this.openQualityAssuranceModal,
            icon: 'mdi-check-decagram',
            show:
              (this.isAdmin() || this.isSupervisor()) &&
              !this.isOrderCancelled(),
            isAction: true
          },
          {
            show: true,
            isAction: false
          },
          {
            label: this.$t('changeOrderStatus'),
            action: this.changeOrderStatus,
            icon: 'mdi-form-select',
            show:
              (this.isAdmin() || this.isSupervisor()) &&
              !this.isOrderCancelled(),
            isAction: true
          },
          {
            show: true,
            isAction: false
          },
          {
            label: this.$t('printOrder'),
            action: this.openConfirmationModalPrintOrder,
            icon: 'mdi-printer-outline',
            show:
              (this.isAdmin() || this.isSupervisor() || this.isCashier()) &&
              !this.isOrderCancelled(),
            isAction: true
          },
          {
            label: this.$t('printSpareParts'),
            action: this.isAbleToPrintSpareParts(order)
              ? this.printSparePart
              : null,
            icon: 'mdi-printer-outline',
            show:
              (this.isAdmin() || this.isSupervisor() || this.isCashier()) &&
              !this.isOrderCancelled(),
            isAction: true
          },
          {
            show: true,
            isAction: false
          },
          {
            label: this.$t('deliverToCustomer'),
            action: this.deliverToCustomerModal,
            icon: 'mdi-account-arrow-left-outline',
            show:
              (this.isAdmin() || this.isSupervisor() || this.isCashier()) &&
              !this.isOrderCancelled() &&
              this.isOrderDifferentToLastState(),
            isAction: true
          },
          {
            show: true,
            isAction: false
          },
          {
            label: this.$t('makePayment'),
            action: this.makePaymentModal,
            icon: 'mdi-credit-card-outline',
            show:
              (this.isAdmin() || this.isSupervisor() || this.isCashier()) &&
              !this.isOrderCancelled() &&
              !this.isAllPaymentComplete(order),
            isAction: true
          },
          {
            show: true,
            isAction: false
          },
          {
            label: this.$t('cancelCredit'),
            action: this.openConfirmationModalCancelCredit,
            icon: 'mdi-card-bulleted-off-outline',
            show:
              (this.isAdmin() || this.isSupervisor() || this.isCashier()) &&
              !this.isOrderCancelled() &&
              this.isOrderLoan(),
            isAction: true
          },
          {
            label: this.$t('enableOrder'),
            action: this.enableOrderFromMenu,
            icon: 'mdi-check-outline',
            show: this.isOrderCancelled(),
            isAction: true
          },
          {
            label: this.$t('cancelOrder'),
            action: this.openCancelVerificationModal,
            icon: 'mdi-cancel',
            show: !this.isOrderCancelled(),
            isAction: true
          }
        ],
        data: { order },
        positionX: event.clientX,
        positionY: event.clientY
      });
    },

    getIconColorForServicesFinished() {
      const { detail } = this.order;
      let totalServicesCounter = 0;
      let totalFinishedServicesCounter = 0;
      if (detail && detail.services) {
        detail.services.forEach(service => {
          if (service.subServices.length === 0) {
            if (service.isFinished !== undefined) {
              if (service.isFinished === true) {
                totalFinishedServicesCounter++;
              }
            }
            totalServicesCounter++;
          } else {
            service.subServices.forEach(subService => {
              if (subService.isFinished !== undefined) {
                if (subService.isFinished === true) {
                  totalFinishedServicesCounter++;
                }
              }
              totalServicesCounter++;
            });
          }
        });
        if (totalFinishedServicesCounter === 0) {
          return 'error';
        } else if (totalServicesCounter == totalFinishedServicesCounter) {
          return 'success';
        } else {
          return 'warning';
        }
      }
    },

    openConfirmationModalPrintOrder() {
      this.$refs.printConfirmationModal.open(this.order.id);
    },

    openConfirmationModal(message, isOverOtherModal, title) {
      this.$refs.confirmationModal.open(
        Object.assign({}, { message, isOverOtherModal, title })
      );
    },

    openManageJobsModal(data) {
      this.$refs.manageJobListModal.open(data.order);
    },

    makePaymentModal(data) {
      let isFromBoard = false;
      this.$refs.deliverOptionModal.open(
        isFromBoard,
        true,
        Object.assign({}, data.order)
      );
    },

    async deliverToCustomerModal(data) {
      let isFromBoard = false;
      let orderToDeliver = JSON.parse(JSON.stringify(data.order));
      await this.retrieveFinalOrderStatus();
      orderToDeliver.orderStatusId = this.getFinalOrderStatus.id;
      this.$refs.deliverOptionModal.open(isFromBoard, false, orderToDeliver);
    },

    confirmationModalConfirmAction() {
      switch (this.isConfirmModalAction) {
        case CANCEL_CREDIT:
          this.updateIsLoanOfAnOrderAsFalse(this.order);
          break;
        default:
          break;
      }
      this.isConfirmModalAction = null;
    },

    openConfirmationModalCancelCredit() {
      this.isConfirmModalAction = CANCEL_CREDIT;
      let message = this.$t('cancelOrderMessage');
      let isOverOtherModal = false;
      let title = this.$t('cancelCredit');
      this.openConfirmationModal(message, isOverOtherModal, title);
    },

    isOrderLoan() {
      return this.order.isLoan;
    },

    isOrderDifferentToLastState() {
      return this.order.orderStatus.isFinal === false;
    },

    renderUnassignedService() {
      let assignedService = ` ${this.unassignedServiceForCalendar.serviceName}`;
      if (this.unassignedServiceForCalendar.subServiceName !== null)
        assignedService += ` (${this.unassignedServiceForCalendar.subServiceName})`;
      return assignedService;
    }
  },

  mixins: [roles, OrderMixin]
};
</script>
