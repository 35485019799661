'use strict';

const getUsersWithPassword = state => {
  return state.users.slice();
};

const getUserWithPasswordById = state => userId => {
  return Object.assign(
    {},
    state.users.find(user => user.id === userId)
  );
};

const userStorageRoleId = state => {
  return state.roleId;
};

const getUserStorageFullName = state => {
  return state.fullName;
};

const getQuickValidationResponse = state => {
  return state.quickValidationResponse;
};

export default {
  getUsersWithPassword,
  getUserWithPasswordById,
  userStorageRoleId,
  getUserStorageFullName,
  getQuickValidationResponse
};
