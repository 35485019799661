<i18n src="./resources/locales.json"></i18n>

<template>
  <v-dialog v-model="dialog" scrollable max-width="400px" persistent>
    <v-card>
      <v-card-title> {{ $t('orderWorks') }} </v-card-title>

      <v-divider></v-divider>

      <v-card-text style="height: 400px">
        <v-list dense flat subheader v-if="order !== null">
          <v-list-item-content
            v-for="(service, index) in servicesWithSubServices"
            :key="index"
            class="mt-n2"
          >
            <template>
              <p class="">{{ service.name }}</p>
              <v-list-item
                v-for="(subService, index) in service.subServices"
                :key="index"
                class="mt-n4"
              >
                <template>
                  <v-checkbox
                    :input-value="subService.isFinished"
                    :label="subService.name"
                    @change="selectSubService(service.id, subService.id)"
                    hide-details
                  ></v-checkbox>
                </template>
              </v-list-item>
            </template>
          </v-list-item-content>

          <v-divider
            class=""
            v-if="servicesWithoutSubServices.length > 0"
          ></v-divider>

          <v-list-item-content v-if="servicesWithoutSubServices.length > 0">
            <template>
              <p class="">{{ $t('others') }}</p>
              <v-list-item
                v-for="(service, index) in servicesWithoutSubServices"
                :key="index"
                class="mt-n4"
              >
                <template>
                  <v-checkbox
                    :input-value="service.isFinished"
                    :label="service.name"
                    @change="selectOtherService(service.id)"
                    hide-details
                  ></v-checkbox>
                </template>
              </v-list-item>
            </template>
          </v-list-item-content>
        </v-list>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close"> {{ $t('cancel') }} </v-btn>
        <v-btn color="primary" @click="save"> {{ $t('save') }} </v-btn>
      </v-card-actions>
    </v-card>

    <MoveOrderStatusMessageModal
      ref="moveOrderStatusMessageModal"
      @confirm-move-order="confirmMoveOrder()"
      @maintain-order="close"
    />
  </v-dialog>
</template>

<script>
import moment from 'moment';
import {
  BUSINESS_ID_RECTIFICATION,
  SECOND_ORDER_STATE_POSITION,
  THIRD_ORDER_STATE_POSITION
} from '@/constants/ConfigurationConstants';
import { mapActions, mapGetters } from 'vuex';
import MoveOrderStatusMessageModal from '@/components/reusable-components/move-order-status-message-modal/MoveOrderStatusMessageModal.vue';

export default {
  name: 'ManageJobListModal',

  components: {
    MoveOrderStatusMessageModal
  },

  data: () => ({
    dialog: false,
    order: null,
    newServices: [],
    newAssignedServices: [],
    servicesWithSubServices: [],
    servicesWithoutSubServices: []
  }),

  methods: {
    ...mapActions('orders', [
      'updateOrder',
      'moveToComplete',
      'moveToInProgress'
    ]),

    ...mapActions('orderStatuses', [
      'retrieveAllBusinessAndSpecialOrderStatuses'
    ]),

    ...mapGetters('orderStatuses', ['getSecondOrderStatePosition']),

    open(order) {
      this.order = JSON.parse(JSON.stringify(order));
      const { detail, assignedServices } = this.order;
      if (detail) {
        const { services } = detail;
        this.newServices = services;
        services.forEach(service => {
          if (service.subServices.length > 0) {
            this.servicesWithSubServices.push(service);
          } else {
            this.servicesWithoutSubServices.push(service);
          }
        });
      }
      this.newAssignedServices = assignedServices;
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.order = null;
      this.newServices = [];
      this.newAssignedServices = [];
      this.servicesWithSubServices = [];
      this.servicesWithoutSubServices = [];
    },

    selectSubService(serviceId, subServiceId) {
      let isServiceFinished = true;
      let newServices = this.newServices.map(service => {
        let newService = { ...service };
        if (newService.id === serviceId) {
          let newSubServices = newService.subServices.map(subServ => {
            let newSubService = { ...subServ };
            if (newSubService.id === subServiceId) {
              if (newSubService.isFinished !== null) {
                newSubService.isFinished = !newSubService.isFinished;
                if (!newSubService.isFinished) {
                  isServiceFinished = false;
                }
              } else {
                newSubService.isFinished = true;
              }
            }
            return newSubService;
          });
          newService.subServices = newSubServices;
        }
        return newService;
      });

      let newAssignedServices = this.newAssignedServices.map(
        assignedService => {
          let newAssignedService = { ...assignedService };
          if (
            newAssignedService.subServiceId === subServiceId &&
            newAssignedService.serviceId === serviceId
          ) {
            if (isServiceFinished) {
              newAssignedService.finishedDateTime = moment().format(
                'YYYY-MM-DD[T]HH:mm:ss.SSSZ'
              );
            } else {
              newAssignedService.finishedDateTime = null;
            }
          }
          return newAssignedService;
        }
      );

      this.newServices = newServices;
      this.newAssignedServices = newAssignedServices;
    },

    selectOtherService(serviceId) {
      let isServiceFinished = true;
      let newServices = this.newServices.map(service => {
        let newService = { ...service };
        if (newService.id === serviceId) {
          if (newService.isFinished !== null) {
            newService.isFinished = !newService.isFinished;
            if (!newService.isFinished) {
              isServiceFinished = false;
            }
          } else {
            newService.isFinished = true;
          }
        }
        return newService;
      });

      let newAssignedServices = this.newAssignedServices.map(
        assignedService => {
          let newAssignedService = { ...assignedService };
          if (newAssignedService.serviceId === serviceId) {
            if (isServiceFinished) {
              newAssignedService.finishedDateTime = moment().format(
                'YYYY-MM-DD[T]HH:mm:ss.SSSZ'
              );
            } else {
              newAssignedService.finishedDateTime = null;
            }
          }
          return newAssignedService;
        }
      );
      this.newServices = newServices;
      this.newAssignedServices = newAssignedServices;
    },

    async save() {
      const { detail } = this.order;
      if (detail) {
        const { services } = detail;
        let oldServices = services;
        if (this._isDiferentListsOfServices(oldServices, this.newServices)) {
          try {
            this.order.detail.services = this.newServices;
            this.order.assignedServices = this.newAssignedServices;

            let orderToEdit = JSON.parse(JSON.stringify(this.order));
            await this.updateOrder(orderToEdit);

            this.$root.$emit(
              'open-status-message-snackbar',
              Object.assign(
                {},
                {
                  message: this.$t('theJobsWereFinishedSuccessfully'),
                  messageType: 'success'
                }
              )
            );

            if (this._isMoveToSecondOrderStatus()) {
              await this.moveToComplete(orderToEdit);
              this.close();
            } else {
              if (this._isMoveToThirdOrderStatus(oldServices)) {
                await this.retrieveAllBusinessAndSpecialOrderStatuses(
                  BUSINESS_ID_RECTIFICATION
                );
                this.$refs.moveOrderStatusMessageModal.open({
                  message: `${this.$t('confirmReturnState')}: (${
                    this.getSecondOrderStatePosition().name
                  }) `
                });
                this.dialog = false;
              } else {
                this.close();
              }
            }
          } catch (error) {
            this.$root.$emit(
              'open-status-message-snackbar',
              Object.assign(
                {},
                {
                  message: this.$t('errorWhileFinishingTheJobs'),
                  messageType: 'error'
                }
              )
            );
          }
        } else {
          this.close();
        }
      }
    },

    _isDiferentListsOfServices(list1, list2) {
      let res = false;
      for (let i = 0; i < list1.length; i++) {
        if (list1[i].subServices.length === 0) {
          if (list1[i].isFinished !== list2[i].isFinished) {
            res = true;
            break;
          }
        } else {
          for (let y = 0; y < list1[i].subServices.length; y++) {
            if (
              list1[i].subServices[y].isFinished !==
              list2[i].subServices[y].isFinished
            ) {
              res = true;
              break;
            }
          }
          if (res) {
            break;
          }
        }
      }
      return res;
    },

    _isMoveToSecondOrderStatus() {
      const { orderStatus } = this.order;
      const { position } = orderStatus;
      if (position === SECOND_ORDER_STATE_POSITION) {
        if (this._checkFinishedServices(this.newServices)) {
          return true;
        }
      }
      return false;
    },

    _isMoveToThirdOrderStatus(services) {
      const { orderStatus } = this.order;
      const { position } = orderStatus;

      if (position === THIRD_ORDER_STATE_POSITION) {
        if (this._checkFinishedServices(services)) {
          return true;
        }
      }
      return false;
    },

    _checkFinishedServices(services) {
      let countOfServices = 0;
      let countOfFinishedServices = 0;
      services.forEach(service => {
        countOfServices += 1;
        if (service.subServices.length === 0) {
          if (service.isFinished) {
            countOfFinishedServices += 1;
          }
        } else {
          let allSubServicesFinished = service.subServices.every(
            subService => subService.isFinished === true
          );
          if (allSubServicesFinished) {
            countOfFinishedServices += 1;
          }
        }
      });
      return countOfFinishedServices === countOfServices;
    },

    confirmMoveOrder() {
      this.moveToInProgress(JSON.parse(JSON.stringify(this.order)));
      this.close();
    }
  }
};
</script>
