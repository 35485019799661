import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = () => ({
  loading: false,
  isClientLoading: false,
  isPartLoading: false,
  isCylinderLoading: false,
  isCylinderArrangementLoading: false,
  isUnitsOfMeasureLoading: false,
  isMeasureLoading: false,
  isSparePartLoading: false,
  isServiceLoading: false,
  isOrderStatusLoading: false,
  isOrderLoading: false,
  isWorkerLoading: false,
  isBrandReportLoading: false,
  isClientReportLoading: false,
  isEngineSeriesReportLoading: false,
  isFinancialReportLoading: false,
  isServicesReportLoading: false,
  isSparePartReportLoading: false,
  isOrdersReportByEngineBrandNumberCylinders: false,
  isWorkersReportLoading: false,
  isCalendarOrderLoading: false,
  isOrdersReportBySparePartsLoading: false,
  isDebtorsReportLoading: false,
  isUnpickedOrdersReportLoading: false
});

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
