'use strict';

import axios from 'axios';
import { ITEM_API_URL } from '../../../constants/Environment';

const retrieveAllBrands = ({ commit }) => {
  axios.get(`${ITEM_API_URL}/brands`).then(response => {
    commit('setAllBrands', response.data);
  });
};

const addBrand = ({ commit }, brandToAdd) => {
  delete brandToAdd.id;
  axios.post(`${ITEM_API_URL}/brands`, brandToAdd).then(response => {
    commit('addBrand', response.data);
  });
};

const updateBrand = ({ commit }, brandToUpdate) => {
  axios
    .put(`${ITEM_API_URL}/brands?id=${brandToUpdate.id}`, brandToUpdate)
    .then(response => {
      commit('updateBrand', response.data);
    });
};

const removeBrand = ({ commit }, brandId) => {
  axios.delete(`${ITEM_API_URL}/brands?id=${brandId}`).then(response => {
    commit('removeBrand', response.data.id);
  });
};

export default {
  retrieveAllBrands,
  addBrand,
  updateBrand,
  removeBrand
};
