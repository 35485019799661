import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('userStorage', ['userStorageRoleId']),
    ...mapGetters('roles', [
      'allRoles',
      'getAdminId',
      'getWorkerId',
      'getSupervisorId',
      'getCashierId'
    ])
  },

  methods: {
    isAdmin() {
      return (
        this.getAdminId !== undefined &&
        this.userStorageRoleId === this.getAdminId
      );
    },

    isWorker() {
      return (
        this.getWorkerId !== undefined &&
        this.userStorageRoleId === this.getWorkerId
      );
    },

    isSupervisor() {
      return (
        this.getSupervisorId !== undefined &&
        this.userStorageRoleId === this.getSupervisorId
      );
    },

    isCashier() {
      return (
        this.getCashierId !== undefined &&
        this.userStorageRoleId === this.getCashierId
      );
    }
  }
};
