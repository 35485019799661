<template>
  <v-menu
    ref="hourPickerMenu"
    v-model="hourPickerMenu"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="hour"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :class="hourTextFieldClass"
        v-model="hour"
        :label="labelText"
        type="time"
        :prepend-icon="icon"
        readonly
        :disabled="disabledTime"
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-time-picker
      v-if="hourPickerMenu"
      v-model="hour"
      full-width
      @click:minute="$refs.hourPickerMenu.save(hour)"
    ></v-time-picker>
  </v-menu>
</template>
<script>
export default {
  name: 'HourPicker',

  props: [
    'labelText',
    'givenHour',
    'icon',
    'hourTextFieldClass',
    'disabledTime'
  ],

  data() {
    return {
      hourPickerMenu: false,
      hour: this.givenHour
    };
  },

  watch: {
    hour: {
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$emit('update:givenHour', this.hour);
        }
      },
      immediate: true
    },

    givenHour: {
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.hour = newVal;
        }
      },
      immediate: true
    }
  }
};
</script>
