export default {
  methods: {
    isAllPaymentComplete(order) {
      return this.calculateRestOfPayment(order) === parseInt(0).toFixed(2);
    },

    calculateRestOfPayment(order) {
      let restOfPayment =
        this.calculateTotalServicesSpareParts(order) - order.totalPayments;
      return parseInt(restOfPayment).toFixed(2);
    },

    calculateTotalServicesSpareParts(
      order,
      servicesDiscount,
      sparePartsDiscount
    ) {
      let total =
        parseInt(this.calculateRemainingServices(order, servicesDiscount)) +
        parseInt(this.calculateRemainingSpareParts(order, sparePartsDiscount));
      return total.toFixed(2);
    },

    calculateRemainingServices(order, discount) {
      let servicesDiscount = order.servicesDiscount;
      if (discount !== undefined) servicesDiscount = discount;
      return parseInt(
        this.calculateTotalServices(order) - servicesDiscount
      ).toFixed(2);
    },

    calculateRemainingSpareParts(order, discount) {
      let sparePartsDiscount = this._getSparePartsDiscount(order);
      if (discount !== undefined) sparePartsDiscount = discount;
      return parseInt(
        this.calculateTotalSpareParts(order) - sparePartsDiscount
      ).toFixed(2);
    },

    calculateTotalServices(order) {
      let total = 0;
      const { detail } = order;
      if (detail) {
        const { services } = detail;

        if (services.length > 0) {
          if (services[0].isOther) {
            total = parseInt(services[0].price);
          } else {
            for (let service of services) {
              if (service.subServices !== null) {
                if (
                  service.subServices.length === 0 ||
                  (service.subServices.length === 1 &&
                    service.subServices[0].id.includes('other'))
                )
                  total += Number(service.price);
                for (let subService of service.subServices) {
                  total += parseInt(subService.price);
                }
              }
            }
          }
        }
      }
      return parseInt(total).toFixed(2);
    },

    calculateTotalSpareParts(order) {
      let total = 0;
      const { detail } = order;
      if (detail) {
        const { item } = detail;
        const { spareParts } = item;

        if (spareParts !== undefined) {
          if (spareParts.length > 0) {
            spareParts.forEach(sp => {
              if (sp.price !== '' && sp.price !== null) {
                total += parseInt(sp.price);
              }
            });
          }
        }
      }
      return total.toFixed(2);
    },

    _getSparePartsDiscount(order) {
      const { detail } = order;
      if (detail) {
        const { item } = detail;
        const { sparePartsDiscount } = item;
        if (sparePartsDiscount) return sparePartsDiscount;
        else return 0;
      } else return 0;
    },

    numberFormatter(orderNumber) {
      return orderNumber?.toString().length < 5
        ? this.numberFormatter('0' + orderNumber)
        : orderNumber;
    }
  }
};
