<i18n src="./resources/locales.json"></i18n>

<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" scrollable max-width="525px" persistent>
      <v-card>
        <v-card-title> {{ $t('alert') }} </v-card-title>
        <v-card-text>{{ message }}</v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" v-if="!isFromBoard" text @click="close">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="primary" @click="save">
            {{ $t('accept') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <OrderDeliverOptionModal ref="deliverOptionModal" @confirm-action="close" />
  </v-row>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import OrderDeliverOptionModal from '@/components/order/order-deliver-option-modal/OrderDeliverOptionModal.vue';

export default {
  name: 'OrderStatusChangeConfirmationModal',

  components: { OrderDeliverOptionModal },

  data() {
    return {
      dialog: false,
      message: '',
      order: {},
      isFromBoard: false
    };
  },

  computed: {
    ...mapGetters('orderStatuses', ['allOrderStatuses', 'getFinalOrderStatus'])
  },

  methods: {
    ...mapActions('orders', ['updateOrderStatusOfOrder']),
    ...mapActions('orderStatuses', ['retrieveFinalOrderStatus']),

    open(messageToUpsert, isFromBoard, orderToUpsert) {
      this.order = Object.assign({}, orderToUpsert);
      this.message = messageToUpsert;
      this.isFromBoard = isFromBoard;
      this.dialog = true;
    },

    close() {
      this.message = '';
      this.order = Object.assign({}, {});
      this.isFromBoard = false;
      this.dialog = false;
      if (!this.isFromBoard) {
        this.$emit('confirm-action');
      }
    },

    async save() {
      if (this.order.orderStatusId === this.getFinalOrderStatus.id) {
        this.$refs.deliverOptionModal.open(
          this.isFromBoard,
          false,
          Object.assign({}, this.order)
        );
      } else {
        try {
          await this.updateOrderStatusOfOrder({
            orderToUpdate: Object.assign({}, this.order),
            date: moment().format('YYYY-MM-DD[T]HH:mm:ss.SSSZ')
          });
          this.$root.$emit(
            'open-status-message-snackbar',
            Object.assign(
              {},
              {
                message: this.$t('orderStatusUpdatedSuccessfully'),
                messageType: 'success'
              }
            )
          );
        } catch (error) {
          this.$root.$emit(
            'open-status-message-snackbar',
            Object.assign(
              {},
              {
                message: this.$t('errorWhileUpdatingOrderStatus'),
                messageType: 'error'
              }
            )
          );
        }
        this.close();
      }
    }
  }
};
</script>
