import { render, staticRenderFns } from "./UnitOfMeasuresList.vue?vue&type=template&id=ea17a958"
import script from "./UnitOfMeasuresList.vue?vue&type=script&lang=js"
export * from "./UnitOfMeasuresList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./resources/locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fgithub%2Fworkspace%2Fsrc%2Fviews%2Fspare-part%2Funit-of-measures-list%2FUnitOfMeasuresList.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports