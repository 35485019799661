<i18n src="./resources/locales.json"></i18n>

<template>
  <v-dialog persistent v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title class="headline">
        {{ title }}
      </v-card-title>
      <br />
      <v-card-subtitle v-if="subTitle">
        {{ subTitle }}
      </v-card-subtitle>
      <v-card-text>
        {{ message }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close()">{{ $t('close') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'MessageModal',

  data() {
    return {
      title: '',
      subTitle: '',
      message: '',
      dialog: false
    };
  },

  created() {
    this.$root.$on('open-message-modal', this.open);
  },

  beforeDestroy() {
    this.$root.$off('open-message-modal');
  },

  methods: {
    open(modalInfo) {
      this.title = modalInfo.title;
      this.subTitle = modalInfo.subTitle;
      this.message = modalInfo.message;
      this.dialog = true;
    },

    close() {
      this.title = '';
      this.subTitle = '';
      this.message = '';
      this.dialog = false;
    }
  }
};
</script>
