<i18n src="./resources/locales.json"></i18n>

<template>
  <v-container>
    <h1 class="ml-3 primary--text">{{ $t('cylinders') }}</h1>
    <v-row
      class="mt-10 ml-16 mb-n12 mr-16"
      no-gutters
      style="flex-wrap: nowrap;"
    >
      <v-col cols="6" align="right">
        <v-btn color="primary" style="margin-right: 9%;" @click="addCylinder">
          {{ $t('newNumber') }}
        </v-btn>
      </v-col>
      <v-col cols="6" align="right">
        <v-btn color="primary" @click="addCylinderArrangement">
          {{ $t('newArrangement') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-data-table
          :headers="headers"
          :items="allCylinders"
          class="elevation-1 ma-16"
          hide-default-footer
          disable-pagination
          :loading="getIsCylinderLoading"
          :loading-text="$t('loadingPleaseWait')"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editCylinder(item)"
              color="primary"
            >
              mdi-pencil-outline
            </v-icon>
            <!--v-icon small @click="deleteCylinder(item)" color="primary">
              mdi-delete
            </v-icon-->
          </template>

          <template v-slot:no-data>
            <p>{{ $t('thereAreNoCylinders') }}</p>
          </template>
        </v-data-table>

        <CylinderDetailModal ref="cylinderDetail" />
        <DeleteConfirmModal
          ref="deleteCylinderModal"
          @confirm-action="deleteCylinderConfirm"
        />
      </v-col>

      <v-col cols="6">
        <v-data-table
          :headers="headerArrangements"
          :items="allCylinderArrangements"
          class="elevation-1 ma-16"
          hide-default-footer
          disable-pagination
          :loading="getIsCylinderArrangementLoading"
          :loading-text="$t('loadingPleaseWait')"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editCylinderArrangement(item)"
              color="primary"
            >
              mdi-pencil-outline
            </v-icon>
            <v-icon
              small
              @click="deleteCylinderArrangement(item)"
              color="primary"
            >
              mdi-delete
            </v-icon>
          </template>

          <template v-slot:no-data>
            <p>{{ $t('thereAreNoCylinderArrangements') }}</p>
          </template>
        </v-data-table>

        <CylinderArrangementDetailModal ref="cylinderArrangementDetail" />
        <DeleteConfirmModal
          ref="deleteCylinderArrangementModal"
          @confirm-action="deleteCylinderArrangementConfirm"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import CylinderDetailModal from '@/components/cylinder/cylinder-detail-modal/CylinderDetailModal.vue';
import CylinderArrangementDetailModal from '@/components/cylinder/cylinder-arrangement-detail-modal/CylinderArrangementDetailModal.vue';
import DeleteConfirmModal from '@/components/reusable-components/delete-confirm-modal/DeleteConfirmModal.vue';

export default {
  name: 'CylindersList',

  components: {
    CylinderDetailModal,
    CylinderArrangementDetailModal,
    DeleteConfirmModal
  },

  computed: {
    headers() {
      return [
        { text: this.$t('number'), value: 'number', align: 'center' },
        {
          text: this.$t('actions'),
          value: 'actions',
          align: 'center',
          sortable: false
        }
      ];
    },

    headerArrangements() {
      return [
        { text: this.$t('arrangement'), value: 'arrangement', align: 'center' },
        {
          text: this.$t('actions'),
          value: 'actions',
          align: 'center',
          sortable: false
        }
      ];
    },

    ...mapGetters('cylinders', ['allCylinders', 'allCylinderArrangements']),
    ...mapGetters('loading', [
      'getIsCylinderLoading',
      'getIsCylinderArrangementLoading'
    ])
  },

  methods: {
    ...mapActions('cylinders', ['removeCylinder', 'removeCylinderArrangement']),

    addCylinder() {
      this.$refs.cylinderDetail.open();
    },

    addCylinderArrangement() {
      this.$refs.cylinderArrangementDetail.open();
    },

    editCylinder(cylinderToUpdate) {
      this.$refs.cylinderDetail.open(Object.assign({}, cylinderToUpdate));
    },

    editCylinderArrangement(cylinderArrangementToUpdate) {
      this.$refs.cylinderArrangementDetail.open(
        Object.assign({}, cylinderArrangementToUpdate)
      );
    },

    deleteCylinder(cylinderToDelete) {
      cylinderToDelete.name = cylinderToDelete.number;
      this.$refs.deleteCylinderModal.open(
        Object.assign(
          { message: this.$t('sureToDeleteThisCylinder') },
          cylinderToDelete
        )
      );
    },

    deleteCylinderConfirm(cylinderToDeleteId) {
      this.removeCylinder(cylinderToDeleteId);
    },

    deleteCylinderArrangement(cylinderArrangementToDelete) {
      this.$refs.deleteCylinderArrangementModal.open(
        Object.assign(
          { message: this.$t('sureToDeleteThisCylinderArrangement') },
          cylinderArrangementToDelete
        )
      );
    },

    deleteCylinderArrangementConfirm(cylinderArrangementToDeleteId) {
      this.removeCylinderArrangement(cylinderArrangementToDeleteId);
    }
  }
};
</script>
