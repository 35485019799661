import { render, staticRenderFns } from "./SparePartsList.vue?vue&type=template&id=53621ab6"
import script from "./SparePartsList.vue?vue&type=script&lang=js"
export * from "./SparePartsList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./resources/locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fgithub%2Fworkspace%2Fsrc%2Fviews%2Fspare-part%2Fspare-parts-list%2FSparePartsList.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports