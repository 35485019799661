'use strict';

import axios from 'axios';
import moment from 'moment';

import { CLIENT_API_URL } from '../../../constants/Environment';
import {
  BUSINESS_ID_RECTIFICATION,
  HISTORICAL
} from '@/constants/ConfigurationConstants';

const parseOrders = ordersToBeParsed => {
  return ordersToBeParsed.map(order => {
    return {
      ...order,
      detail: JSON.parse(order.detail),
      assignedServices: JSON.parse(order.assignedServices)
    };
  });
};

const retrieveAllBusinessOrders = async ({ dispatch, commit }, businessId) => {
  dispatch('loading/setIsOrderLoading', true, { root: true });
  try {
    let ordersData = await axios.get(
      `${CLIENT_API_URL}/orders/business/${businessId}`
    );
    commit('setAllOrders', parseOrders(ordersData.data));
    dispatch('loading/setIsOrderLoading', false, { root: true });
  } catch (error) {
    console.error('There was an error while retrieving orders' + error);
  }
};

const retrieveAllBusinessCurrentOrders = async (
  { dispatch, commit },
  businessId
) => {
  dispatch('loading/setIsOrderLoading', true, { root: true });
  try {
    let ordersData = await axios.get(
      `${CLIENT_API_URL}/orders/current/${businessId}`
    );
    commit('setAllOrders', parseOrders(ordersData.data));

    dispatch('loading/setIsOrderLoading', false, { root: true });
  } catch (error) {
    console.error('There was an error while retrieving orders' + error);
  }
};

const retrieveAllBusinessCurrentOrdersForPagination = async (
  { dispatch, commit },
  { businessId, pageNumber, pageSize, orderStatusId }
) => {
  dispatch('loading/setIsOrderLoading', true, { root: true });
  try {
    let ordersData = await axios.get(
      `${CLIENT_API_URL}/orders/current-paginated/${businessId}?pageNumber=${pageNumber}&pageSize=${pageSize}&isGroupPaginated=true&orderStatusId=${orderStatusId}`
    );

    ordersData = ordersData.data;
    ordersData.dtoData = parseOrders(ordersData.dtoData);

    commit('setAllOrders', ordersData.dtoData);
    commit('setCount', ordersData.count);
    commit('setTotalCount', ordersData.totalCount);
    commit('setHighestGroup', ordersData.highestGroup);

    dispatch('loading/setIsOrderLoading', false, { root: true });
  } catch (error) {
    console.error('There was an error while retrieving orders' + error);
  }
};

const retrieveAllBusinessHistoricalOrders = async (
  { dispatch, commit },
  { pageNumber, pageSize }
) => {
  dispatch('loading/setIsOrderLoading', true, { root: true });
  try {
    let ordersData = await axios.get(
      `${CLIENT_API_URL}/orders/history/${BUSINESS_ID_RECTIFICATION}?pageNumber=${pageNumber}&pageSize=${pageSize}`
    );

    ordersData = ordersData.data;
    ordersData.dtoData = parseOrders(ordersData.dtoData);

    commit('setAllOrders', ordersData.dtoData);
    commit('setCount', ordersData.count);
    commit('setTotalCount', ordersData.totalCount);

    dispatch('loading/setIsOrderLoading', false, { root: true });
  } catch (error) {
    console.error('There was an error while retrieving orders ' + error);
  }
};

const retrieveAllBusinessCanceledOrders = async (
  { dispatch, commit },
  { pageNumber, pageSize }
) => {
  dispatch('loading/setIsOrderLoading', true, { root: true });
  try {
    let ordersData = await axios.get(
      `${CLIENT_API_URL}/orders/canceled/${BUSINESS_ID_RECTIFICATION}?pageNumber=${pageNumber}&pageSize=${pageSize}`
    );

    ordersData = ordersData.data;
    ordersData.dtoData = parseOrders(ordersData.dtoData);

    commit('setAllOrders', ordersData.dtoData);
    commit('setCount', ordersData.count);
    commit('setTotalCount', ordersData.totalCount);

    dispatch('loading/setIsOrderLoading', false, { root: true });
  } catch (error) {
    console.error('There was an error while retrieving orders ' + error);
  }
};

const addOrder = async (
  { commit, dispatch },
  { orderToAdd, amount, isDebitCard, paymentMethod }
) => {
  let order = JSON.parse(JSON.stringify(orderToAdd));
  order.detail = JSON.stringify(order.detail);
  delete order.id;
  delete order.assignedServices;
  return new Promise((resolve, reject) => {
    axios
      .post(`${CLIENT_API_URL}/orders`, order)
      .then(response => {
        response.data.detail = JSON.parse(response.data.detail);
        response.data.assignedServices = JSON.parse(
          response.data.assignedServices
        );
        commit('addOrder', response.data);
        let pay = {
          orderId: response.data.id,
          date: moment().format('YYYY-MM-DD[T]HH:mm:ss.SSSZ'),
          orderStatusId: response.data.orderStatusId,
          amount: parseInt(amount),
          isDebitCard,
          paymentMethod
        };
        dispatch(
          'orders/makePayment',
          { order: response.data, pay },
          { root: true }
        );
        resolve(response);
      })
      .catch(error => {
        reject(error);
        console.error('There was an error while posting the order' + error);
      });
  });
};

const updateOrder = async ({ commit }, orderToUpdate) => {
  orderToUpdate.detail = JSON.stringify(orderToUpdate.detail);
  orderToUpdate.assignedServices = JSON.stringify(
    orderToUpdate.assignedServices
  );
  return new Promise((resolve, reject) => {
    axios
      .put(`${CLIENT_API_URL}/orders/${orderToUpdate.id}`, orderToUpdate)
      .then(response => {
        response.data.detail = JSON.parse(response.data.detail);
        response.data.assignedServices = JSON.parse(
          response.data.assignedServices
        );
        commit('updateOrder', response.data);
        resolve(response);
      })
      .catch(error => {
        reject(error);
        console.error('There was an error while updating the order' + error);
      });
  });
};

const enableOrder = async ({ commit }, orderToEnableId) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${CLIENT_API_URL}/orders/${orderToEnableId}/enable-request`)
      .then(response => {
        response.data.detail = JSON.parse(response.data.detail);
        response.data.assignedServices = JSON.parse(
          response.data.assignedServices
        );
        if (response.data.orderStatus.isFinal) {
          commit('updateOrder', response.data);
        } else {
          commit('removeOrder', response.data.id);
        }
        resolve(response);
      })
      .catch(error => {
        reject(error);
        console.error('There was an error while enabling this order' + error);
      });
  });
};

const cancelOrder = async (
  { commit },
  { orderToCancelId, currentOrHistorical, canceledBy, canceledById }
) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${CLIENT_API_URL}/orders/${orderToCancelId}/cancel-request-with-info?canceledBy=${canceledBy}&canceledById=${canceledById}`
      )
      .then(response => {
        response.data.detail = JSON.parse(response.data.detail);
        response.data.assignedServices = JSON.parse(
          response.data.assignedServices
        );
        if (currentOrHistorical === HISTORICAL) {
          commit('updateOrder', response.data);
        } else {
          commit('removeOrder', response.data.id);
        }
        resolve(response);
      })
      .catch(error => {
        reject(error);
        console.error('There was an error while canceling this order' + error);
      });
  });
};

const updateOrderStatusOfOrder = async (
  { commit },
  { orderToUpdate, date }
) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${CLIENT_API_URL}/orders/${orderToUpdate.id}/order-status/${orderToUpdate.orderStatusId}?date=${date}`
      )
      .then(response => {
        response.data.detail = JSON.parse(response.data.detail);
        response.data.assignedServices = JSON.parse(
          response.data.assignedServices
        );
        commit('updateOrder', response.data);
        resolve(response);
      })
      .catch(error => {
        reject(error);
        console.error(
          'There was an error while updating the order status of this order' +
            error
        );
      });
  });
};

const searchOrder = async (
  { dispatch, commit },
  {
    orderSearchType,
    businessId,
    orderStatusId,
    filterOrdersBy,
    keyword,
    keywordOrderNumber,
    keywordEngineSeries,
    workerId,
    isLoan,
    isPaginated,
    pageNumber,
    pageSize,
    fromDate,
    toDate
  }
) => {
  dispatch('loading/setIsOrderLoading', true, { root: true });
  try {
    let searchUrl = `${CLIENT_API_URL}/orders/search?currentOrHistorical=${orderSearchType}&businessId=${businessId}&workerId=${workerId}`;
    if (isLoan !== '') searchUrl = searchUrl + `&isLoan=${isLoan}`;
    if (keyword !== '' && keyword !== null)
      searchUrl = searchUrl + `&keyword=${keyword}`;
    if (keywordOrderNumber !== '' && keywordOrderNumber !== null)
      searchUrl = searchUrl + `&keywordOrderNumber=${keywordOrderNumber}`;
    if (keywordEngineSeries !== '' && keywordEngineSeries !== null)
      searchUrl = searchUrl + `&keywordEngineSeries=${keywordEngineSeries}`;
    if (orderStatusId.length !== 0)
      searchUrl = searchUrl + `&orderStatusId=${orderStatusId}`;
    if (filterOrdersBy !== '')
      searchUrl = searchUrl + `&filterOrdersBy=${filterOrdersBy}`;
    if (isPaginated) searchUrl += `&isPaginated=${isPaginated}`;
    if (pageNumber) searchUrl += `&pageNumber=${pageNumber}`;
    if (pageSize) searchUrl += `&pageSize=${pageSize}`;
    if (fromDate) searchUrl += `&fromDate=${fromDate}`;
    if (toDate) searchUrl += `&toDate=${toDate}`;
    let ordersData = await axios.get(searchUrl);
    commit('setAllOrders', parseOrders(ordersData.data));
    dispatch('loading/setIsOrderLoading', false, { root: true });
  } catch (error) {
    console.error('There was an error while searching orders' + error);
  }
};

const searchOrderPaginated = async (
  { dispatch, commit },
  {
    orderSearchType,
    businessId,
    orderStatusId,
    filterOrdersBy,
    keyword,
    keywordOrderNumber,
    keywordEngineSeries,
    workerId,
    isLoan,
    isPaginated,
    pageNumber,
    pageSize,
    fromDate,
    toDate
  }
) => {
  dispatch('loading/setIsOrderLoading', true, { root: true });
  try {
    let searchUrl = `${CLIENT_API_URL}/orders/search-paginated?currentOrHistorical=${orderSearchType}&businessId=${businessId}&workerId=${workerId}`;
    if (isLoan !== '') searchUrl = searchUrl + `&isLoan=${isLoan}`;
    if (keyword !== '' && keyword !== null)
      searchUrl = searchUrl + `&keyword=${keyword}`;
    if (keywordOrderNumber !== '' && keywordOrderNumber !== null)
      searchUrl = searchUrl + `&keywordOrderNumber=${keywordOrderNumber}`;
    if (keywordEngineSeries !== '' && keywordEngineSeries !== null)
      searchUrl = searchUrl + `&keywordEngineSeries=${keywordEngineSeries}`;
    if (orderStatusId.length !== 0)
      searchUrl = searchUrl + `&orderStatusId=${orderStatusId}`;
    if (filterOrdersBy !== '')
      searchUrl = searchUrl + `&filterOrdersBy=${filterOrdersBy}`;
    if (isPaginated) searchUrl += `&isPaginated=${isPaginated}`;
    if (pageNumber) searchUrl += `&pageNumber=${pageNumber}`;
    if (pageSize) searchUrl += `&pageSize=${pageSize}`;
    if (fromDate) searchUrl += `&fromDate=${fromDate}`;
    if (toDate) searchUrl += `&toDate=${toDate}`;
    let ordersData = await axios.get(searchUrl);

    ordersData = ordersData.data;
    ordersData.dtoData = parseOrders(ordersData.dtoData);

    commit('setCount', ordersData.count);
    commit('setTotalCount', ordersData.totalCount);
    commit('setHighestGroup', ordersData.highestGroup);

    commit('setAllOrders', ordersData.dtoData);

    dispatch('loading/setIsOrderLoading', false, { root: true });
  } catch (error) {
    console.error('There was an error while searching orders' + error);
  }
};

const assignServicesToOrder = async ({ commit }, orderToUpdate) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${CLIENT_API_URL}/orders/${orderToUpdate.id}/assign-services`,
        orderToUpdate.assignedServices
      )
      .then(response => {
        response.data.detail = JSON.parse(response.data.detail);
        response.data.assignedServices = JSON.parse(
          response.data.assignedServices
        );

        response.data.orderStatusStateList = orderToUpdate.orderStatusStateList;
        commit('updateOrder', response.data);
        resolve(response);
      })
      .catch(error => {
        reject(error);
        console.error(
          'There was an error while assigning services to this order' + error
        );
      });
  });
};

const retrieveAllOrders = async ({ commit }) => {
  try {
    let ordersData = await axios.get(`${CLIENT_API_URL}/orders`);
    commit('setAllOrders', parseOrders(ordersData.data));
  } catch (error) {
    console.error('There was an error while retrieving orders' + error);
  }
};

const removeOrder = ({ commit }, orderToDeleteId) => {
  axios.delete(`${CLIENT_API_URL}/orders/${orderToDeleteId}`).then(() => {
    commit('removeOrder', orderToDeleteId);
  });
};

const retrieveNextOrderNumber = ({ commit }) => {
  axios
    .get(`${CLIENT_API_URL}/orders/next-order-number`)
    .then(response => {
      commit('setNextOrderNumber', response.data.nextOrderNumber);
    })
    .catch(error => {
      console.error(
        'There was an error while retrieving next order number' + error
      );
    });
};

const moveToComplete = async ({ commit }, orderToUpdate) => {
  await axios
    .put(`${CLIENT_API_URL}/orders/${orderToUpdate.id}/in-progress-complete`)
    .then(response => {
      response.data.detail = JSON.parse(response.data.detail);
      response.data.assignedServices = JSON.parse(
        response.data.assignedServices
      );

      commit('updateOrder', response.data);
    })
    .catch(error => {
      console.error(
        'There was an error while updating the order status of this order' +
          error
      );
    });
};

const moveToInProgress = async ({ commit }, orderToUpdate) => {
  await axios
    .put(`${CLIENT_API_URL}/orders/${orderToUpdate.id}/move-to-in-progress`)
    .then(response => {
      response.data.detail = JSON.parse(response.data.detail);
      response.data.assignedServices = JSON.parse(
        response.data.assignedServices
      );
      commit('updateOrder', response.data);
    })
    .catch(error => {
      console.error(
        'There was an error while updating the order status of this order' +
          error
      );
    });
};

const changeEstimatedDeliverDate = async ({ commit }, orderToUpdate) => {
  await axios
    .put(
      `${CLIENT_API_URL}/orders/${orderToUpdate.id}/estimated-deliver-date?date=${orderToUpdate.estimatedDeliverDate}`
    )
    .then(response => {
      response.data.detail = JSON.parse(response.data.detail);
      response.data.assignedServices = JSON.parse(
        response.data.assignedServices
      );

      commit('updateOrder', response.data);
    })
    .catch(error => {
      console.error(
        'There was an error changing estimated order deliver date' + error
      );
    });
};

const makePayment = async ({ commit }, { order, pay }) => {
  await axios
    .post(`${CLIENT_API_URL}/orders/make-payment/${order.id}`, pay)
    .then(response => {
      response.data.detail = JSON.parse(response.data.detail);
      response.data.assignedServices = JSON.parse(
        response.data.assignedServices
      );
      commit('updateOrder', response.data);
    })
    .catch(error => {
      console.error('There was an error paying the order' + error);
    });
};

const updateIsLoanOfAnOrderAsTrue = async ({ commit }, orderToUpdate) => {
  await axios
    .put(`${CLIENT_API_URL}/orders/${orderToUpdate.id}/loan-status?isLoan=true`)
    .then(response => {
      response.data.detail = JSON.parse(response.data.detail);
      response.data.assignedServices = JSON.parse(
        response.data.assignedServices
      );
      commit('updateOrder', response.data);
    })
    .catch(error => {
      console.error(
        'There was an error updating loan of an order as true',
        error
      );
    });
};

const updateIsLoanOfAnOrderAsFalse = async ({ commit }, orderToUpdate) => {
  await axios
    .put(
      `${CLIENT_API_URL}/orders/${orderToUpdate.id}/loan-status?isLoan=false`
    )
    .then(response => {
      response.data.detail = JSON.parse(response.data.detail);
      response.data.assignedServices = JSON.parse(
        response.data.assignedServices
      );
      commit('updateOrder', response.data);
    })
    .catch(error => {
      console.error(
        'There was an error updating loan of an order as false',
        error
      );
    });
};

const retrieveAllBusinessOrdersFromLastFiveAbleDays = async (
  { dispatch, commit },
  businessId
) => {
  dispatch('loading/setIsOrderLoading', true, { root: true });
  try {
    let ordersData = await axios.get(
      `${CLIENT_API_URL}/orders/last-5-able-days/${businessId}`
    );
    commit('setAllOrders', parseOrders(ordersData.data));
    dispatch('loading/setIsOrderLoading', false, { root: true });
  } catch (error) {
    console.error(
      'There was an error while retrieving orders from last five able days' +
        error
    );
  }
};

const retrieveFilteredBusinessOrdersByOrderStatus = async (
  { dispatch, commit },
  { businessId, orderStatusId, filterBy }
) => {
  dispatch('loading/setIsOrderLoading', true, { root: true });
  try {
    let ordersData = await axios.get(
      `${CLIENT_API_URL}/orders/filtered-for-order-status/${businessId}?orderStatusId=${orderStatusId}&filterBy=${filterBy}`
    );
    commit('updateFilteredOrdersByOrderStatusId', {
      orders: parseOrders(ordersData.data),
      orderStatusId
    });
    dispatch('loading/setIsOrderLoading', false, { root: true });
  } catch (error) {
    console.error(
      'There was an error while retrieving orders given a filter for a given order status' +
        error
    );
  }
};

const retrieveOrderById = async ({ commit }, orderId) => {
  await axios
    .get(`${CLIENT_API_URL}/orders/${orderId}`)
    .then(response => {
      response.data.detail = JSON.parse(response.data.detail);
      response.data.assignedServices = JSON.parse(
        response.data.assignedServices
      );
      commit('setSingleOrderById', response.data);
    })
    .catch(error => {
      console.error('There was an error retrieving order by id', error);
    });
};

const retrieveAllUnassignedServices = async ({ dispatch, commit }) => {
  dispatch('loading/setIsOrderLoading', true, { root: true });
  try {
    let ordersData = await axios.get(
      `${CLIENT_API_URL}/orders/unassigned-services`
    );
    commit('setAllUnassignedServices', ordersData.data);
    dispatch('loading/setIsOrderLoading', false, { root: true });
  } catch (error) {
    console.error(
      'There was an error while retriving unassigned services ' + error
    );
  }
};

const setAllUnassignedServicesAsEmpty = async ({ commit }) => {
  commit('setAllUnassignedServices', []);
};

const setOrderByIdAsEmpty = ({ commit }) => {
  commit('setSingleOrderById', {});
};

const setAllOrdersAsEmpty = async ({ commit }) => {
  commit('setAllOrders', []);
};

export default {
  retrieveAllBusinessOrders,
  retrieveAllBusinessCurrentOrders,
  retrieveAllBusinessCurrentOrdersForPagination,
  retrieveAllBusinessHistoricalOrders,
  updateOrder,
  searchOrder,
  searchOrderPaginated,
  updateOrderStatusOfOrder,
  assignServicesToOrder,
  retrieveAllOrders,
  addOrder,
  removeOrder,
  enableOrder,
  cancelOrder,
  retrieveNextOrderNumber,
  moveToComplete,
  moveToInProgress,
  changeEstimatedDeliverDate,
  makePayment,
  updateIsLoanOfAnOrderAsTrue,
  updateIsLoanOfAnOrderAsFalse,
  retrieveAllBusinessOrdersFromLastFiveAbleDays,
  retrieveFilteredBusinessOrdersByOrderStatus,
  retrieveOrderById,
  retrieveAllUnassignedServices,
  setOrderByIdAsEmpty,
  setAllUnassignedServicesAsEmpty,
  setAllOrdersAsEmpty,
  retrieveAllBusinessCanceledOrders
};
