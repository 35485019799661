<i18n src="./resources/locales.json"></i18n>

<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent scrollable max-width="455px">
      <v-card>
        <v-card-title>{{ $t('selectServicesAndAssignWorker') }}</v-card-title>
        <v-divider class="mb-3"></v-divider>
        <v-card-text>
          <Alert
            v-if="isEstimatedDeliverDateHourSet()"
            color="warning"
            icon="mdi-alert"
            :message="$t('beforeAssignCheck')"
          />
          <Alert
            v-if="isFinishedService(selectedService.finishedDateTime)"
            color="error"
            icon="mdi-alert"
            :message="$t('serviceWasFinished')"
          />
          <Alert
            v-if="isOrderDeliverDateLowerThanDeliverWork()"
            color="error"
            icon="mdi-alert"
            :message="$t('orderDeliverDateIsLess')"
          ></Alert>
          <v-row class="mt-2">
            <v-col cols="4" sm="5">
              <DatePicker
                :labelText="$t('orderReceptionDate')"
                :givenDate.sync="orderReceptionDate"
                :minDate="undefined"
                :icon="undefined"
                :dateTextFieldClass="`mt-n3`"
                :disabledDate="true"
              />
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="4" sm="5">
              <HourPicker
                :labelText="$t('orderReceptionHour')"
                :givenHour.sync="orderReceptionHour"
                :icon="undefined"
                :hourTextFieldClass="`mt-n3`"
                :disabledTime="true"
              />
            </v-col>
          </v-row>
          <v-row class="mt-n5">
            <v-col cols="4" sm="5">
              <DatePicker
                :labelText="$t('orderDeliverDate')"
                :givenDate.sync="orderDeliverDate"
                :maxDate="undefined"
                :minDate="getMinDate()"
                :icon="undefined"
                :dateTextFieldClass="`my-n3`"
              />
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="4" sm="5">
              <HourPicker
                :labelText="$t('orderDeliverHour')"
                :givenHour.sync="orderDeliverHour"
                :icon="undefined"
                :hourTextFieldClass="`my-n3`"
              />
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-select
            v-model="selectedService"
            :items="listOfServices"
            item-text="name"
            return-object
            :label="$t('servicesForThisOrder')"
            :disabled="isEstimatedDeliverDateHourSet()"
          ></v-select>
          <v-row>
            <v-col cols="4" sm="5">
              <DatePicker
                :labelText="$t('assignmentDate')"
                :givenDate.sync="assignmentDate"
                :maxDate="undefined"
                :minDate="undefined"
                :icon="undefined"
                :dateTextFieldClass="`mt-n3`"
                :disabledDate="
                  isFinishedService(selectedService.finishedDateTime) ||
                    isEstimatedDeliverDateHourSet()
                "
              />
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="4" sm="5">
              <HourPicker
                :labelText="$t('assignmentHour')"
                :givenHour.sync="assignmentHour"
                :icon="undefined"
                :hourTextFieldClass="`mt-n3`"
                :disabledTime="
                  isFinishedService(selectedService.finishedDateTime) ||
                    isEstimatedDeliverDateHourSet()
                "
              />
            </v-col>
          </v-row>
          <v-row class="mt-n5">
            <v-col cols="4" sm="5">
              <DatePicker
                :labelText="$t('deliverDate')"
                :givenDate.sync="deliverDate"
                :minDate="getMinDate()"
                :maxDate="getMaxDeliverDate()"
                :icon="undefined"
                :dateTextFieldClass="`mt-n3`"
                :disabledDate="
                  isFinishedService(selectedService.finishedDateTime) ||
                    isEstimatedDeliverDateHourSet()
                "
              />
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="4" sm="5">
              <HourPicker
                :labelText="$t('deliverHour')"
                :givenHour.sync="deliverHour"
                :icon="undefined"
                :hourTextFieldClass="`mt-n3`"
                :disabledTime="
                  isFinishedService(selectedService.finishedDateTime) ||
                    isEstimatedDeliverDateHourSet()
                "
              />
            </v-col>
          </v-row>
          <v-row>
            <v-textarea
              class="mt-n3 ml-3 mr-3"
              :label="$t('note')"
              v-model="note"
              no-resize
              rows="2"
              height="60"
              outlined
              :disabled="
                isFinishedService(selectedService.finishedDateTime) ||
                  isEstimatedDeliverDateHourSet()
              "
            ></v-textarea>
          </v-row>
          <v-divider></v-divider>
          <v-autocomplete
            v-model="selectedWorkerId"
            :items="searchedWorkers"
            hide-no-data
            hide-selected
            item-text="name"
            item-value="id"
            :label="$t('searchWorker')"
            prepend-inner-icon="mdi-magnify"
            clearable
            @click:clear="clearSearchWorker"
            :search-input.sync="workerToSearch"
            :disabled="
              isFinishedService(selectedService.finishedDateTime) ||
                isEstimatedDeliverDateHourSet()
            "
            @change="changeWorkerForAssignedService()"
          ></v-autocomplete>
        </v-card-text>
        <v-card-text id="scroll-target" class="overflow-y-auto mt-n7">
          <v-radio-group
            v-scroll:#scroll-target="() => {}"
            style="height: 300px"
            v-model="selectedWorkerId"
            column
            class="mt-1 mb-n3"
            :disabled="
              isFinishedService(selectedService.finishedDateTime) ||
                isEstimatedDeliverDateHourSet()
            "
            @change="changeWorkerForAssignedService()"
          >
            <v-list-item
              class="workers-bottom-dividers mt-2"
              v-for="worker in workers"
              :key="worker.id"
            >
              <v-avatar
                class="mb-2 mr-2"
                :color="getAvatarBackgroundColor(worker.name)"
              >
                <span class="white--text">{{
                  getAvatarText(worker.name)
                }}</span>
              </v-avatar>
              <v-radio :label="worker.name" :value="worker.id"> </v-radio>
            </v-list-item>
          </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            color="primary"
            @click="save"
            :disabled="
              isEstimatedDeliverDateHourSet() ||
                isOrderDeliverDateLowerThanDeliverWork()
            "
          >
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ErrorMessageModal ref="errorMessageModal" />
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import Alert from '@/components/reusable-components/alert/Alert.vue';
import DatePicker from '@/components/reusable-components/date-picker/DatePicker.vue';
import HourPicker from '@/components/reusable-components/hour-picker/HourPicker.vue';
import ErrorMessageModal from '@/components/reusable-components/error-message-modal/ErrorMessageModal.vue';
const NOT_ASSIGNED_ID = 'notAssign';

export default {
  name: 'WorkersAssigmentListModal',

  components: { DatePicker, HourPicker, Alert, ErrorMessageModal },

  data() {
    return {
      dialog: false,
      assignmentDatePickerMenu: false,
      assignmentHourPickerMenu: false,
      deliverDatePickerMenu: false,
      deliverHourPickerMenu: false,
      assignmentDate: null,
      assignmentHour: null,
      deliverDate: null,
      deliverHour: null,
      orderDeliverDate: null,
      orderDeliverHour: null,
      orderReceptionDate: null,
      orderReceptionHour: null,
      order: {},
      defaultOrder: {},
      note: '',
      workerToSearch: '',
      selectedWorkerId: '',
      searchedWorkers: [],
      services: [],
      selectedService: {},
      listOfServices: [],
      workers: [],
      assignedServices: []
    };
  },

  computed: {
    ...mapGetters('workers', ['allWorkers'])
  },

  created() {
    this.$root.$on('open-worker-assigment-modal', this.open);
  },

  beforeDestroy() {
    this.$root.$off('open-worker-assigment-modal');
  },

  watch: {
    workerToSearch: {
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal && newVal !== null) this.searchWorker();
        if (newVal === null) this.clearSearchWorker();
      },
      deep: true
    },

    selectedService: {
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal) {
          let foundService = this.searchSelectedService();
          let foundWorker = this.workers.find(
            worker => worker.id === foundService.workerId
          );
          this.selectedWorkerId = foundWorker.id;
          this.assignmentDate = foundService.assignmentDate;
          this.assignmentHour = foundService.assignmentHour;
          this.deliverDate = foundService.deliverDate;
          this.deliverHour = foundService.deliverHour;
          this.note = foundService.note;
        }
      }
    },

    assignmentDate: {
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal) {
          let newServices = [];
          newServices = this.services.map(service => {
            let newService = { ...service };
            if (newService.serviceId === this.selectedService.serviceId) {
              if (newService.subServiceId === null) {
                newService.assignmentDate = newVal;
              } else {
                if (
                  newService.subServiceId === this.selectedService.subServiceId
                ) {
                  newService.assignmentDate = newVal;
                }
              }
            }
            return newService;
          });
          this.services = newServices;
        }
      },
      immediate: true
    },

    assignmentHour: {
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal) {
          let newServices = [];
          newServices = this.services.map(service => {
            let newService = { ...service };
            if (newService.serviceId === this.selectedService.serviceId) {
              if (newService.subServiceId === null) {
                newService.assignmentHour = newVal;
              } else {
                if (
                  newService.subServiceId === this.selectedService.subServiceId
                ) {
                  newService.assignmentHour = newVal;
                }
              }
            }
            return newService;
          });
          this.services = newServices;
        }
      },
      immediate: true
    },

    deliverDate: {
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal) {
          let newServices = [];
          newServices = this.services.map(service => {
            let newService = { ...service };
            if (newService.serviceId === this.selectedService.serviceId) {
              if (newService.subServiceId === null) {
                newService.deliverDate = newVal;
              } else {
                if (
                  newService.subServiceId === this.selectedService.subServiceId
                ) {
                  newService.deliverDate = newVal;
                }
              }
            }
            return newService;
          });
          this.services = newServices;
        }
      },
      immediate: true
    },

    deliverHour: {
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal) {
          let newServices = [];
          newServices = this.services.map(service => {
            let newService = { ...service };
            if (newService.serviceId === this.selectedService.serviceId) {
              if (newService.subServiceId === null) {
                newService.deliverHour = newVal;
              } else {
                if (
                  newService.subServiceId === this.selectedService.subServiceId
                ) {
                  newService.deliverHour = newVal;
                }
              }
            }
            return newService;
          });
          this.services = newServices;
        }
      },
      immediate: true
    },

    note: {
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal) {
          let newServices = [];
          newServices = this.services.map(service => {
            let newService = { ...service };
            if (newService.serviceId === this.selectedService.serviceId) {
              if (newService.subServiceId === null) {
                newService.note = newVal;
              } else {
                if (
                  newService.subServiceId === this.selectedService.subServiceId
                ) {
                  newService.note = newVal;
                }
              }
            }
            return newService;
          });
          this.services = newServices;
        }
      }
    },

    orderDeliverDate: {
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal) {
          let newServices = [];
          newServices = this.services.map(service => {
            let newService = { ...service };
            if (
              newService.deliverDate === null ||
              (this.order.estimatedDeliverDate === null && newVal !== null)
            ) {
              newService.deliverDate = newVal;
              this.deliverDate = newVal;
              this.deliverHour = this.orderDeliverHour;
            }
            if (
              moment(newService.deliverDate).isAfter(newVal) &&
              !this.isFinishedService(newService.finishedDateTime)
            ) {
              newService.deliverDate = null;
              newService.deliverHour = null;
            }
            if (
              moment(this.deliverDate).isAfter(newVal) &&
              !this.isFinishedService(this.selectedService.finishedDateTime)
            ) {
              this.deliverDate = null;
              this.deliverHour = null;
            }
            return newService;
          });
          this.services = newServices;
        }
      },
      immediate: true
    },

    orderDeliverHour: {
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal) {
          let newServices = [];
          newServices = this.services.map(service => {
            let newService = { ...service };
            if (
              newService.deliverHour === null ||
              (this.order.estimatedDeliverDate === null && newVal !== null)
            ) {
              newService.deliverHour = newVal;
              this.deliverHour = newVal;
            }
            return newService;
          });
          this.services = newServices;
        }
      },
      immediate: true
    }
  },

  methods: {
    ...mapActions('orders', [
      'assignServicesToOrder',
      'moveToComplete',
      'changeEstimatedDeliverDate',
      'setOrderByIdAsEmpty'
    ]),

    open(orderToUpsert) {
      this.order = Object.assign({}, orderToUpsert);
      if (this.order.estimatedDeliverDate) {
        this.parseAssignedServices();
        this.orderDeliverDate = moment(this.order.estimatedDeliverDate).format(
          'YYYY-MM-DD'
        );
        this.orderDeliverHour = moment(this.order.estimatedDeliverDate).format(
          'HH:mm'
        );
      }
      this.orderReceptionDate = this._getDateFormat(this.order.creationDate);
      this.orderReceptionHour = this._getHourFormat(this.order.creationDate);
      this.workers = this.parseWorkers();
      this.listOfServices = this.createListOfServices();
      this.$nextTick(function() {
        this.selectedService = Object.assign({}, this.listOfServices[0]);
      });
      this.dialog = true;
    },

    close() {
      this.order = Object.assign({}, this.defaultOrder);
      this.assignmentDatePickerMenu = null;
      this.assignmentHourPickerMenu = null;
      this.deliverDatePickerMenu = null;
      this.deliverHourPickerMenu = null;
      this.assignmentDate = null;
      this.assignmentHour = null;
      this.deliverDate = null;
      this.deliverHour = null;
      this.services = [];
      this.orderDeliverDate = null;
      this.orderDeliverHour = null;
      this.searchedWorkers = [];
      this.selectedWorkerId = '';
      this.workerToSearch = '';
      this.note = '';
      this.listOfServices = [];
      this.workers = [];
      this.assignedServices = [];
      this.setOrderByIdAsEmpty();
      this.dialog = false;
    },

    async save() {
      let assignedServicesToSave = [];
      this.services.forEach(service => {
        if (service.workerId !== NOT_ASSIGNED_ID) {
          let concatedAssignmentDate = moment(
            service.assignmentDate + service.assignmentHour,
            'YYYY-MM-DDLT'
          );
          service.assignedDateTime = moment(concatedAssignmentDate).format(
            'YYYY-MM-DD[T]HH:mm:ss.SSSZ'
          );
          if (service.deliverDate === null) {
            service.deliverDate = moment().format('YYYY-MM-DD');
          }
          if (service.deliverHour === null) {
            service.deliverHour = moment().format('HH:mm');
          }
          let concatedDeliverDate = moment(
            service.deliverDate + service.deliverHour,
            'YYYY-MM-DDLT'
          );
          service.deliverDateTime = moment(concatedDeliverDate).format(
            'YYYY-MM-DD[T]HH:mm:ss.SSSZ'
          );
          if (service.assignedDateTime !== null) {
            const defaultDate = moment('0000-01-01').format(
              'YYYY-MM-DD[T]HH:mm:ss.SSSZ'
            );
            let assignedDateTime = service.assignedDateTime;
            if (moment(defaultDate).diff(assignedDateTime, 'years') === 0) {
              service.assignedDateTime = moment().format(
                'YYYY-MM-DD[T]HH:mm:ss.SSSZ'
              );
            }
          }
          if (service.deliverDateTime !== null) {
            const defaultDate = moment('0000-01-01').format(
              'YYYY-MM-DD[T]HH:mm:ss.SSSZ'
            );
            let deliverDateTime = service.deliverDateTime;
            if (moment(defaultDate).diff(deliverDateTime, 'years') === 0) {
              service.deliverDateTime = null;
            }
          }
          assignedServicesToSave.push({
            serviceId: service.serviceId,
            subServiceId: service.subServiceId,
            workerId: service.workerId,
            assignedDateTime: service.assignedDateTime,
            deliverDateTime: service.deliverDateTime,
            finishedDateTime: service.finishedDateTime,
            note: service.note
          });
        }
      });
      this.order.assignedServices = assignedServicesToSave;
      var oldDate = this._getDateFormat(this.order.estimatedDeliverDate);
      var oldHour = this._getHourFormat(this.order.estimatedDeliverDate);

      if (
        this.orderDeliverDate !== oldDate ||
        this.orderDeliverHour !== oldHour
      ) {
        this.order.estimatedDeliverDate = this.getMaxDeliverDate();
        await this.changeEstimatedDeliverDate(this.order);
      }

      if (
        this.order.orderStatus.isInitial === true &&
        this._isAllSparePartsReceived(this.order.detail.item.spareParts) &&
        this._isAllServicesAssigned()
      ) {
        try {
          await this.assignServicesToOrder(this.order);
          await this.moveToComplete(this.order);
          this.$root.$emit(
            'open-status-message-snackbar',
            Object.assign(
              {},
              {
                message: this.$t(
                  'theAssignationToOrderServicesWasSuccessfully'
                ),
                messageType: 'success'
              }
            )
          );
        } catch (error) {
          this.$root.$emit(
            'open-status-message-snackbar',
            Object.assign(
              {},
              {
                message: this.$t('errorWhileAssigningTheOrderServices'),
                messageType: 'error'
              }
            )
          );
        }
      } else {
        try {
          await this.assignServicesToOrder(this.order);
          this.$root.$emit(
            'open-status-message-snackbar',
            Object.assign(
              {},
              {
                message: this.$t(
                  'theAssignationToOrderServicesWasSuccessfully'
                ),
                messageType: 'success'
              }
            )
          );
        } catch (error) {
          this.$root.$emit(
            'open-status-message-snackbar',
            Object.assign(
              {},
              {
                message: this.$t('errorWhileAssigningTheOrderServices'),
                messageType: 'error'
              }
            )
          );
        }
      }

      this.close();
    },

    searchWorker() {
      let workerToFind = this.workerToSearch.toLowerCase();
      this.workers.forEach(item => {
        let isIncluded = item.name.toLowerCase().includes(workerToFind);
        if (isIncluded) this.searchedWorkers.push(item);
      });
    },

    clearSearchWorker() {
      this.searchedWorkers = [];
      this.selectedWorkerId = NOT_ASSIGNED_ID;
      this.changeWorkerForAssignedService();
    },

    _assignWorker(isService, serviceId, foundAssignServices) {
      let foundAssign;
      let foundWorker = null;
      if (isService) {
        foundAssign = foundAssignServices.find(
          assign => assign.serviceId === serviceId
        );
      } else {
        foundAssign = foundAssignServices.find(
          assign => assign.subServiceId === serviceId
        );
      }
      if (foundAssign !== undefined) {
        foundWorker = this.workers.find(
          worker => worker.id === foundAssign.workerId
        );
        if (foundWorker === undefined) {
          foundWorker = null;
        }
      }
      if (foundWorker === null) {
        foundWorker = {
          id: NOT_ASSIGNED_ID,
          name: this.$t('notAssigned')
        };
      }
      return foundWorker;
    },

    parseWorkers() {
      let newWorkers = [{ id: NOT_ASSIGNED_ID, name: this.$t('notAssigned') }];
      this.allWorkers.forEach(worker => newWorkers.push(worker));
      return newWorkers;
    },

    createListOfServices() {
      let listOfServices = [];
      if (this.order.detail && this.order.detail.services) {
        this.order.detail.services.forEach(service => {
          let foundAssignServices = this.assignedServices.filter(
            assign => assign.serviceId === service.id
          );
          let worker;
          let finishedDateTime = null;
          let assignedDate = moment().format('YYYY-MM-DD[T]HH:mm:ss.SSSZ');
          let deliverDate =
            this.order.estimatedDeliverDate === null
              ? null
              : this.order.estimatedDeliverDate;
          let foundService;
          let note = '';
          if (service.subServices.length === 0) {
            foundService = this.searchService(
              true,
              service.id,
              foundAssignServices
            );
            worker = this._assignWorker(true, service.id, foundAssignServices);
            if (foundService !== undefined) {
              if (
                foundService.assignedDateTime !== undefined &&
                foundService.assignedDateTime !== null
              ) {
                assignedDate = foundService.assignedDateTime;
              }
              if (
                foundService.deliverDateTime !== undefined &&
                foundService.deliverDateTime !== null
              ) {
                deliverDate = foundService.deliverDateTime;
              }
              if (
                foundService.finishedDateTime !== undefined &&
                foundService.finishedDateTime !== null
              ) {
                finishedDateTime = foundService.finishedDateTime;
              }
              if (foundService.note !== undefined) {
                note = foundService.note;
              }
            }

            listOfServices.push({
              serviceId: service.id,
              subServiceId: null,
              workerId: worker.id,
              name: service.name,
              assignmentDate: this._getDateFormat(assignedDate),
              assignmentHour: this._getHourFormat(assignedDate),
              deliverDate: this._getDateFormat(deliverDate),
              deliverHour: this._getHourFormat(deliverDate),
              assignedDateTime: null,
              deliverDateTime: null,
              finishedDateTime,
              note
            });
          } else {
            service.subServices.forEach(subService => {
              finishedDateTime = null;
              assignedDate = moment().format('YYYY-MM-DD[T]HH:mm:ss.SSSZ');
              deliverDate = this.order.estimatedDeliverDate;
              note = '';
              foundService = this.searchService(
                false,
                subService.id,
                foundAssignServices
              );
              worker = this._assignWorker(
                false,
                subService.id,
                foundAssignServices
              );
              if (foundService !== undefined) {
                if (
                  foundService.assignedDateTime !== undefined &&
                  foundService.assignedDateTime !== null
                ) {
                  assignedDate = foundService.assignedDateTime;
                }
                if (
                  foundService.deliverDateTime !== undefined &&
                  foundService.deliverDateTime !== null
                ) {
                  deliverDate = foundService.deliverDateTime;
                }
                if (
                  foundService.finishedDateTime !== undefined &&
                  foundService.finishedDateTime !== null
                ) {
                  finishedDateTime = foundService.finishedDateTime;
                }
                if (foundService.note !== undefined) {
                  note = foundService.note;
                }
              }
              listOfServices.push({
                serviceId: service.id,
                subServiceId: subService.id,
                workerId: worker.id,
                name: `${service.name}(${subService.name})`,
                assignmentDate: this._getDateFormat(assignedDate),
                assignmentHour: this._getHourFormat(assignedDate),
                deliverDate: this._getDateFormat(deliverDate),
                deliverHour: this._getHourFormat(deliverDate),
                assignedDateTime: null,
                deliverDateTime: null,
                finishedDateTime,
                note
              });
            });
          }
        });
        this.services = listOfServices;
      }
      return listOfServices;
    },

    searchSelectedService() {
      let foundService;
      this.services.forEach(service => {
        if (service.serviceId === this.selectedService.serviceId) {
          if (service.subServiceId === null) {
            foundService = service;
          } else {
            if (service.subServiceId === this.selectedService.subServiceId) {
              foundService = service;
            }
          }
        }
      });
      return foundService;
    },

    searchService(isService, serviceId, foundAssignServices) {
      let foundAssign;
      if (isService) {
        foundAssign = foundAssignServices.find(
          assign => assign.serviceId === serviceId
        );
      } else {
        foundAssign = foundAssignServices.find(
          assign => assign.subServiceId === serviceId
        );
      }
      return foundAssign;
    },

    parseAssignedServices() {
      if (typeof this.order.assignedServices === 'string') {
        this.assignedServices = JSON.parse(this.order.assignedServices);
      } else {
        this.assignedServices = this.order.assignedServices;
      }
    },

    getMaxDeliverDate() {
      let maxDeliverDate;
      let concatedDeadline = moment(
        this.orderDeliverDate + this.orderDeliverHour,
        'YYYY-MM-DDLT'
      );
      maxDeliverDate = moment(concatedDeadline).format(
        'YYYY-MM-DD[T]HH:mm:ss.SSSZ'
      );
      return maxDeliverDate;
    },

    getAvatarText(name) {
      let result = '';
      const firstName = name.trim().split(' ')[0];
      const lastName = name.trim().split(' ')[1];

      if (firstName) result += firstName[0];
      if (lastName) result += lastName[0];
      else if (firstName.length > 1) result += firstName[1];

      if (name === this.$t('notAssigned')) {
        result = `${firstName[0]}/${lastName[0]}`;
      }
      return result.toUpperCase();
    },

    getAvatarBackgroundColor(name) {
      const stringForColor =
        name.length > 5 ? name.substring(0, 5) : name.charAt(0);
      return this._getColorFromString(stringForColor);
    },

    isFinishedService(serviceDateTime) {
      if (serviceDateTime) {
        return true;
      } else {
        return false;
      }
    },

    _getHueFromString(string = '') {
      let hash = 0;
      for (let i = 0; i < string.length; i++) {
        hash = string.charCodeAt(i) + ((hash << 20) - hash);
      }
      return hash % 360;
    },

    _getColorFromString(string) {
      const saturation = 65;
      const lightness = 70;
      const hue = this._getHueFromString(string);
      return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    },

    _isAllSparePartsReceived(spareParts) {
      return spareParts.every(sparePart => sparePart.received === true);
    },

    _isAllServicesAssigned() {
      return this.services.every(
        service => service.workerId !== NOT_ASSIGNED_ID
      );
    },

    getMinDate() {
      return moment().format('YYYY-MM-DD');
    },

    isEstimatedDeliverDateHourSet() {
      return this.orderDeliverDate === null || this.orderDeliverHour === null;
    },

    _getConcatenatedDateHourOrder(date, hour) {
      let concatedDeadline = moment(date + hour, 'YYYY-MM-DDLT');
      return moment(concatedDeadline).format('YYYY-MM-DD[T]HH:mm:ss.SSSZ');
    },

    _getDateFormat(date) {
      if (date === null) return null;
      else return moment(date).format('YYYY-MM-DD');
    },

    _getHourFormat(date) {
      if (date === null) return null;
      else return moment(date).format('HH:mm');
    },

    isOrderDeliverDateLowerThanDeliverWork() {
      return this.services.some(s => {
        return (
          s.deliverDate === null &&
          s.workerId !== NOT_ASSIGNED_ID &&
          !this.isFinishedService(s.finishedDateTime)
        );
      });
    },

    changeWorkerForAssignedService() {
      let newServices = [];
      newServices = this.services.map(service => {
        let newService = { ...service };
        if (newService.serviceId === this.selectedService.serviceId) {
          if (newService.subServiceId === null) {
            newService.workerId = this.selectedWorkerId;
            newService.assignmentDate = moment().format('YYYY-MM-DD');
            newService.assignmentHour = moment().format('HH:mm');
            this.assignmentDate = newService.assignmentDate;
            this.assignmentHour = newService.assignmentHour;
          } else {
            if (newService.subServiceId === this.selectedService.subServiceId) {
              newService.workerId = this.selectedWorkerId;
              newService.assignmentDate = moment().format('YYYY-MM-DD');
              newService.assignmentHour = moment().format('HH:mm');
              this.assignmentDate = newService.assignmentDate;
              this.assignmentHour = newService.assignmentHour;
            }
          }
        }
        return newService;
      });
      this.services = newServices;
    }
  }
};
</script>

<style scoped>
@import './ScopedStyles.css';
</style>
