import { render, staticRenderFns } from "./ServicesMoneyReport.vue?vue&type=template&id=25ae2844&scoped=true"
import script from "./ServicesMoneyReport.vue?vue&type=script&lang=js"
export * from "./ServicesMoneyReport.vue?vue&type=script&lang=js"
import style0 from "./ServicesMoneyReport.vue?vue&type=style&index=0&id=25ae2844&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25ae2844",
  null
  
)

/* custom blocks */
import block0 from "./resources/locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fgithub%2Fworkspace%2Fsrc%2Fviews%2Freports%2Fservice-money-report%2FServicesMoneyReport.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports