<i18n src="./resources/locales.json"></i18n>

<template>
  <v-container>
    <h1 class="ml-3 primary--text">{{ $t('debtorsReport') }}</h1>
    <v-row>
      <v-col class="ml-13">
        <v-data-table
          :headers="headers"
          :items="allDebtorsReport"
          :footer-props="{
            itemsPerPageOptions: [10, 20],
            itemsPerPageText: $t('rowsPerPage')
          }"
          :items-per-page="10"
          class="elevation-1 ma-8"
          @click:row="openOrderDetail"
        >
          <template v-slot:[`footer.page-text`]="items">
            {{ items.pageStart }} - {{ items.pageStop }} {{ $t('of') }}
            {{ items.itemsLength }}
          </template>
          <template v-slot:no-data>
            <p>{{ $t('NoOrdersInDateRange') }}</p>
          </template>
        </v-data-table>
        <LoadingMask :isLoading="getIsDebtorsReportLoading" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import LoadingMask from '@/components/reusable-components/loading-mask/LoadingMask.vue';

export default {
  name: 'WorkersReport',

  props: ['order'],

  data() {
    return {};
  },

  components: {
    LoadingMask
  },

  computed: {
    ...mapGetters('reports', ['allDebtorsReport']),
    ...mapGetters('loading', ['getIsDebtorsReportLoading']),
    ...mapGetters('orders', ['getOrderById']),

    headers() {
      return [
        {
          text: this.$t('orderNumber'),
          value: 'orderNumber',
          align: 'center',
          sortable: true,
          class: 'primary--text'
        },
        {
          text: this.$t('customerName'),
          value: 'customerName',
          align: 'center',
          sortable: false,
          class: 'primary--text'
        },
        {
          text: this.$t('customerPhone'),
          value: 'customerPhone',
          align: 'center',
          sortable: false,
          class: 'primary--text'
        },
        {
          text: this.$t('totalPrice'),
          value: 'totalPrice',
          align: 'center',
          sortable: false,
          class: 'primary--text'
        },
        {
          text: this.$t('credit'),
          value: 'credit',
          align: 'center',
          sortable: false,
          class: 'primary--text'
        },
        {
          text: this.$t('debit'),
          value: 'debit',
          align: 'center',
          sortable: false,
          class: 'primary--text'
        }
      ];
    }
  },

  created() {
    this.retrieveDebtorsReport();
  },

  methods: {
    ...mapActions('reports', ['retrieveAllDebtorsReport']),
    ...mapActions('orders', ['retrieveOrderById']),

    retrieveDebtorsReport() {
      this.retrieveAllDebtorsReport();
    },

    async openOrderDetail(row) {
      await this.retrieveOrderById(row.orderId);
      this.$root.$emit(
        'open-order-detail-modal-info',
        Object.assign({}, this.getOrderById),
        true
      );
    }
  }
};
</script>
