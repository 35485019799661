<i18n src="./resources/locales.json"></i18n>

<template>
  <v-dialog persistent v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title> {{ $t('giveCredit') }} </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <p class="mb-0">
          {{ $t('giveAnOrder') }}
          <span class="font-weight-bold">
            {{ numberFormatter(order.orderNumber) }}</span
          >
          {{ $t('followingDetail') }}
        </p>

        <v-divider></v-divider>

        <p class="mb-0">
          <span class="deliver-option-width"> {{ $t('totalServices') }}</span>
          <span class="deliver-option-width">
            {{ calculateRemainingServices(order) | currency('BOB') }}
          </span>
        </p>

        <v-divider></v-divider>
        <p class="mb-0">
          <span class="deliver-option-width"> {{ $t('totalSpareParts') }}</span>
          <span class="deliver-option-width">
            {{ calculateRemainingSpareParts(order) | currency('BOB') }}
          </span>
        </p>

        <v-divider></v-divider>
        <p class="mb-0">
          <span class="deliver-option-width"> {{ $t('totalToReceive') }}</span>
          <span class="deliver-option-width">
            {{ calculateTotalServicesSpareParts(order) | currency('BOB') }}
          </span>
        </p>

        <v-divider></v-divider>
        <p class="mb-0">
          <span class="deliver-option-width"> {{ $t('advancePayments') }}</span>
          <span class="deliver-option-width">
            {{ calculateTotalPayments() | currency('BOB') }}
          </span>
        </p>

        <v-divider></v-divider>
        <p class="mb-0">
          <span class="deliver-option-width font-weight-bold">
            {{ $t('creditToFinance') }}</span
          >
          <span class="deliver-option-width">
            {{ calculateCreditToFinance() | currency('BOB') }}
          </span>
        </p>

        <v-divider></v-divider>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="rejectAction">{{ $t('no') }}</v-btn>
        <v-btn color="primary" @click="confirmAction">{{ $t('yes') }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import OrderMixin from '../../../mixins/OrderMixin.js';
import moment from 'moment';
import { mapActions } from 'vuex';

export default {
  name: 'MessageToConfirmCreditModal',

  mixins: [OrderMixin],

  data() {
    return {
      dialog: false,
      order: {},
      paymentToMake: 0,
      pay: {}
    };
  },

  methods: {
    ...mapActions('orders', [
      'makePayment',
      'updateOrderStatusOfOrder',
      'updateOrder'
    ]),

    open({ order, paymentToMake, pay }) {
      this.order = JSON.parse(JSON.stringify(order));
      this.paymentToMake = paymentToMake;
      this.pay = pay;
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.order = {};
      this.paymentToMake = 0;
      this.pay = {};
    },

    async confirmAction() {
      try {
        await this.updateOrder(Object.assign({}, this.order));
        await this.makePayment({
          order: Object.assign({}, this.order),
          pay: this.pay
        });
        await this.updateOrderStatusOfOrder({
          orderToUpdate: Object.assign({}, this.order),
          date: moment().format('YYYY-MM-DD[T]HH:mm:ss.SSSZ')
        });
        this.$root.$emit(
          'open-status-message-snackbar',
          Object.assign(
            {},
            {
              message: this.$t('orderStatusUpdatedSuccessfully'),
              messageType: 'success'
            }
          )
        );
      } catch (error) {
        this.$root.$emit(
          'open-status-message-snackbar',
          Object.assign(
            {},
            {
              message: this.$t('errorWhileUpdatingOrderStatus'),
              messageType: 'error'
            }
          )
        );
      }
      this.close();
    },

    rejectAction() {
      this.close();
    },

    calculateCreditToFinance() {
      let total =
        this.calculateTotalServicesSpareParts(this.order) -
        this.order.totalPayments -
        this.paymentToMake;
      return parseInt(total).toFixed(2);
    },

    calculateTotalPayments() {
      let total = this.order.totalPayments + this.paymentToMake;
      return parseInt(total).toFixed(2);
    }
  }
};
</script>
