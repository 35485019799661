<i18n src="./resources/locales.json"></i18n>

<template>
  <v-container>
    <h1 class="ml-3 primary--text">{{ businessOrderStatusTitleList }}</h1>
    <v-row
      class="mt-10 ml-16 mb-n12 mr-16"
      no-gutters
      style="flex-wrap: nowrap;"
    >
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="addOrderStatus">
        {{ $t('newOrderStatus') }}
      </v-btn>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="allOrderStatuses"
      sort-by="name"
      class="elevation-1 ma-16"
      hide-default-footer
      disable-pagination
      :loading="getIsOrderStatusLoading"
      :loading-text="$t('loadingPleaseWait')"
    >
      <template v-slot:[`item.isInitial`]="{ item }">
        <v-simple-checkbox :value="item.isInitial" disabled></v-simple-checkbox>
      </template>
      <template v-slot:[`item.isFinal`]="{ item }">
        <v-simple-checkbox :value="item.isFinal" disabled></v-simple-checkbox>
      </template>
      <template v-slot:[`item.color`]="{ item }">
        <v-sheet
          :color="item.color"
          elevation="1"
          height="30"
          width="30"
          class="rounded-circle d-inline-block"
        ></v-sheet>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          color="primary"
          @click="editOrderStatus(item)"
        >
          mdi-pencil-outline
        </v-icon>
        <!--v-tooltip
          top
          :disabled="
            getOrdersByOrderStatusId(item.id).length === 0 &&
              !item.isInitial &&
              !item.isFinal
          "
        >
          <template v-slot:activator="{ on }">
            <div v-on="on" class="d-inline-block">
              <v-icon
                small
                color="primary"
                @click="deleteOrderStatus(item)"
                :disabled="
                  item.isFinal ||
                    item.isInitial ||
                    getOrdersByOrderStatusId(item.id).length > 0
                "
              >
                mdi-delete
              </v-icon>
            </div>
          </template>
          <span>{{ $t('initialFinalOrAssignedOrdersCanNotBeDeleted') }}</span>
        </v-tooltip-->
      </template>
      <template v-slot:no-data>
        <p>
          {{ $t('thereAreNoOrderStatuses') }}
        </p>
      </template>
    </v-data-table>

    <OrderStatusDetailModal :businessId="businessId" ref="orderStatusDetail" />
    <DeleteConfirmModal
      ref="deleteOrderStatusModal"
      @confirm-action="deleteOrderStatusConfirm"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DeleteConfirmModal from '@/components/reusable-components/delete-confirm-modal/DeleteConfirmModal.vue';
import OrderStatusDetailModal from '@/components/order-status/order-status-detail-modal/OrderStatusDetailModal.vue';

export default {
  name: 'OrderStatusesList',

  components: { DeleteConfirmModal, OrderStatusDetailModal },

  props: ['businessOrderStatusTitleList', 'businessId'],

  created() {
    this.retrieveAllBusinessAndSpecialOrderStatuses(this.businessId);
    this.retrieveAllBusinessOrders(this.businessId);
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t('name'),
          value: 'name',
          align: 'center'
        },
        {
          text: this.$t('initialState'),
          value: 'isInitial',
          align: 'center'
        },
        {
          text: this.$t('finalState'),
          value: 'isFinal',
          align: 'center'
        },
        {
          text: this.$t('color'),
          value: 'color',
          align: 'center'
        },
        {
          text: this.$t('actions'),
          value: 'actions',
          align: 'center',
          sortable: false
        }
      ];
    },

    ...mapGetters('orderStatuses', ['allOrderStatuses']),
    ...mapGetters('orders', ['getOrdersByOrderStatusId']),
    ...mapGetters('loading', ['getIsOrderStatusLoading'])
  },

  methods: {
    ...mapActions('orderStatuses', [
      'removeOrderStatus',
      'retrieveAllBusinessAndSpecialOrderStatuses'
    ]),
    ...mapActions('orders', ['retrieveAllBusinessOrders']),

    addOrderStatus() {
      this.$refs.orderStatusDetail.open();
    },

    editOrderStatus(orderStatusToUpdate) {
      this.$refs.orderStatusDetail.open(Object.assign({}, orderStatusToUpdate));
    },

    deleteOrderStatus(orderStatusToDelete) {
      this.$refs.deleteOrderStatusModal.open(
        Object.assign(
          { message: this.$t('sureToDeleteThisOrderStatus') },
          orderStatusToDelete
        )
      );
    },

    deleteOrderStatusConfirm(orderStatusToDeleteId) {
      this.removeOrderStatus(orderStatusToDeleteId);
    }
  }
};
</script>
