'use strict';

import axios from 'axios';
import { ITEM_API_URL } from '../../../constants/Environment';

const retrieveAllSpareParts = async ({ dispatch, commit }) => {
  dispatch('loading/setIsSparePartLoading', true, { root: true });
  try {
    await axios.get(`${ITEM_API_URL}/spare-parts`).then(response => {
      commit('setAllSpareParts', response.data);
    });
    dispatch('loading/setIsSparePartLoading', false, { root: true });
  } catch (error) {
    console.error('There was an error while retrieving spare parts' + error);
  }
};

const addSparePart = async ({ commit }, sparePart) => {
  delete sparePart.id;
  return new Promise((resolve, reject) => {
    axios
      .post(`${ITEM_API_URL}/spare-parts`, sparePart)
      .then(response => {
        commit('addSparePart', response.data);
        resolve(response);
      })
      .catch(error => {
        reject(error);
        console.error(
          'There was an error while posting the spare part' + error
        );
      });
  });
};

const updateSparePart = async ({ commit }, sparePartToUpdate) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${ITEM_API_URL}/spare-parts/${sparePartToUpdate.id}`,
        sparePartToUpdate
      )
      .then(response => {
        commit('updateSparePart', response.data);
        resolve(response);
      })
      .catch(error => {
        reject(error);
        console.error(
          'There was an error while updating the spare part' + error
        );
      });
  });
};

const removeSparePart = ({ commit }, sparePartToDeleteId) => {
  axios
    .delete(`${ITEM_API_URL}/spare-parts/${sparePartToDeleteId}`)
    .then(response => {
      commit('removeSparePart', response.data);
    });
};

const retrieveAllUnitOfMeasures = async ({ dispatch, commit }) => {
  dispatch('loading/setIsUnitsOfMeasureLoading', true, { root: true });
  try {
    await axios.get(`${ITEM_API_URL}/unit-of-measures`).then(response => {
      commit('setAllUnitOfMeasures', response.data);
    });
    dispatch('loading/setIsUnitsOfMeasureLoading', false, { root: true });
  } catch (error) {
    console.error(
      'There was an error while retrieving unit of measures' + error
    );
  }
};

const addUnitOfMeasure = async ({ commit }, unitOfMeasure) => {
  delete unitOfMeasure.id;
  return new Promise((resolve, reject) => {
    axios
      .post(`${ITEM_API_URL}/unit-of-measures`, unitOfMeasure)
      .then(response => {
        commit('addUnitOfMeasure', response.data);
        resolve(response);
      })
      .catch(error => {
        reject(error);
        console.error(
          'There was an error while posting the unit of measure' + error
        );
      });
  });
};

const updateUnitOfMeasure = async ({ commit }, unitOfMeasureToUpdate) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${ITEM_API_URL}/unit-of-measures/${unitOfMeasureToUpdate.id}`,
        unitOfMeasureToUpdate
      )
      .then(response => {
        commit('updateUnitOfMeasure', response.data);
        resolve(response);
      })
      .catch(error => {
        reject(error);
        console.error(
          'There was an error while updating the unit of measure' + error
        );
      });
  });
};

const removeUnitOfMeasure = ({ commit }, unitOfMeasureToDeleteId) => {
  axios
    .delete(`${ITEM_API_URL}/unit-of-measures/${unitOfMeasureToDeleteId}`)
    .then(response => {
      commit('removeUnitOfMeasure', response.data);
    });
};

const retrieveAllMeasures = async ({ dispatch, commit }) => {
  dispatch('loading/setIsMeasureLoading', true, { root: true });
  try {
    await axios.get(`${ITEM_API_URL}/measures`).then(response => {
      commit('setAllMeasures', response.data);
    });
    dispatch('loading/setIsMeasureLoading', false, { root: true });
  } catch (error) {
    console.error('There was an error while retrieving measures' + error);
  }
};

const addMeasure = async ({ commit }, measure) => {
  delete measure.id;
  return new Promise((resolve, reject) => {
    axios
      .post(`${ITEM_API_URL}/measures`, measure)
      .then(response => {
        commit('addMeasure', response.data);
        resolve(response);
      })
      .catch(error => {
        reject(error);
        console.error('There was an error while posting the measure' + error);
      });
  });
};

const updateMeasure = async ({ commit }, measureToUpdate) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${ITEM_API_URL}/measures/${measureToUpdate.id}`, measureToUpdate)
      .then(response => {
        commit('updateMeasure', response.data);
        resolve(response);
      })
      .catch(error => {
        reject(error);
        console.error('There was an error while updating the measure' + error);
      });
  });
};

const removeMeasure = ({ commit }, measureToDeleteId) => {
  axios
    .delete(`${ITEM_API_URL}/measures/${measureToDeleteId}`)
    .then(response => {
      commit('removeMeasure', response.data);
    });
};

export default {
  retrieveAllSpareParts,
  addSparePart,
  updateSparePart,
  removeSparePart,
  retrieveAllUnitOfMeasures,
  addUnitOfMeasure,
  updateUnitOfMeasure,
  removeUnitOfMeasure,
  retrieveAllMeasures,
  addMeasure,
  updateMeasure,
  removeMeasure
};
