'use strict';

const setAllCylinders = (state, cylinders) => {
  state.cylinders = cylinders;
};

const setAllCylinderArrangements = (state, cylinderArrangements) => {
  state.cylinderArrangements = cylinderArrangements;
};

const addCylinder = (state, cylinderToAdd) => {
  state.cylinders.push(cylinderToAdd);
};

const addCylinderArrangement = (state, cylinderArrangementToAdd) => {
  state.cylinderArrangements.push(cylinderArrangementToAdd);
};

const updateCylinder = (state, cylinderToUpdate) => {
  const foundCylinderIndex = state.cylinders.findIndex(
    cylinder => cylinder.id === cylinderToUpdate.id
  );
  if (foundCylinderIndex >= 0) {
    state.cylinders.splice(foundCylinderIndex, 1, cylinderToUpdate);
  }
};

const updateCylinderArrangement = (state, cylinderArrangementToUpdate) => {
  const foundCylinderArrangementIndex = state.cylinderArrangements.findIndex(
    cylinderArrangement =>
      cylinderArrangement.id === cylinderArrangementToUpdate.id
  );
  if (foundCylinderArrangementIndex >= 0) {
    state.cylinderArrangements.splice(
      foundCylinderArrangementIndex,
      1,
      cylinderArrangementToUpdate
    );
  }
};

const removeCylinder = (state, cylinderToDeleteId) => {
  state.cylinders = state.cylinders.filter(
    cylinder => cylinder.id !== cylinderToDeleteId
  );
};

const removeCylinderArrangement = (state, cylinderArrangementToDeleteId) => {
  state.cylinderArrangements = state.cylinderArrangements.filter(
    cylinderArrangement =>
      cylinderArrangement.id !== cylinderArrangementToDeleteId
  );
};

export default {
  setAllCylinders,
  setAllCylinderArrangements,
  addCylinder,
  addCylinderArrangement,
  updateCylinder,
  updateCylinderArrangement,
  removeCylinder,
  removeCylinderArrangement
};
