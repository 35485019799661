<i18n src="./resources/locales.json"></i18n>

<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-form ref="partForm" v-model="isValid">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  class="required-field"
                  v-model="part.name"
                  :label="$t('name')"
                  :rules="[nameRules.required]"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="primary" :disabled="!isValid" @click="save">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'PartDetailModal',

  data() {
    return {
      isValid: false,
      part: {},
      dialog: false,
      defaultPart: {
        id: '-1',
        name: ''
      }
    };
  },

  computed: {
    formTitle() {
      const { id, partId } = this.part;

      if (id === '-1') {
        if (partId) return this.$t('newSubPart');
        else return this.$t('newPart');
      } else {
        if (partId) return this.$t('editSubPart');
        else return this.$t('editPart');
      }
    },

    nameRules() {
      return { required: value => !!value || this.$t('nameIsRequired') };
    }
  },

  methods: {
    ...mapActions('parts', [
      'addPart',
      'updatePart',
      'addSubPart',
      'updateSubPart'
    ]),

    open(partToUpsert) {
      this.part = Object.assign({}, partToUpsert || this.defaultPart);
      this.dialog = true;
    },

    close() {
      this.resetValidations();
      this.isValid = false;
      this.part = Object.assign({}, this.defaultPart);
      this.dialog = false;
    },

    async save() {
      this.part = Object.assign({}, this.part);
      const { id, partId } = this.part;

      if (id === '-1') {
        if (partId) {
          try {
            await this.addSubPart(this.part);
            this.$root.$emit(
              'open-status-message-snackbar',
              Object.assign(
                {},
                {
                  message: `${this.$t('theSubPart')} ${
                    this.part.name
                  } ${this.$t('wasCreatedSuccessfully')}`,
                  messageType: 'success'
                }
              )
            );
          } catch (error) {
            this.$root.$emit(
              'open-status-message-snackbar',
              Object.assign(
                {},
                {
                  message: this.$t('errorWhileCreatingTheSubPart'),
                  messageType: 'error'
                }
              )
            );
          }
        } else {
          try {
            await this.addPart(this.part);
            this.$root.$emit(
              'open-status-message-snackbar',
              Object.assign(
                {},
                {
                  message: `${this.$t('thePart')} ${this.part.name} ${this.$t(
                    'wasCreatedSuccessfully'
                  )}`,
                  messageType: 'success'
                }
              )
            );
          } catch (error) {
            this.$root.$emit(
              'open-status-message-snackbar',
              Object.assign(
                {},
                {
                  message: this.$t('errorWhileCreatingThePart'),
                  messageType: 'error'
                }
              )
            );
          }
        }
      } else {
        if (partId) {
          try {
            await this.updateSubPart(this.part);
            this.$root.$emit(
              'open-status-message-snackbar',
              Object.assign(
                {},
                {
                  message: this.$t('subPartInfoWasUpdatedSuccessfully'),
                  messageType: 'success'
                }
              )
            );
          } catch (error) {
            this.$root.$emit(
              'open-status-message-snackbar',
              Object.assign(
                {},
                {
                  message: this.$t(
                    'errorWhileUpdatingSubPartInfoPleaseTryAgain'
                  ),
                  messageType: 'error'
                }
              )
            );
          }
        } else {
          try {
            await this.updatePart(this.part);
            this.$root.$emit(
              'open-status-message-snackbar',
              Object.assign(
                {},
                {
                  message: this.$t('partInfoWasUpdatedSuccessfully'),
                  messageType: 'success'
                }
              )
            );
          } catch (error) {
            this.$root.$emit(
              'open-status-message-snackbar',
              Object.assign(
                {},
                {
                  message: this.$t('errorWhileUpdatingPartInfoPleaseTryAgain'),
                  messageType: 'error'
                }
              )
            );
          }
        }
      }

      this.close();
    },

    resetValidations() {
      this.$refs.partForm.resetValidation();
    }
  }
};
</script>
