'use strict';

import axios from 'axios';
import { CLIENT_API_URL } from '../../../constants/Environment';

const parseOrders = ordersToBeParsed => {
  return ordersToBeParsed.map(order => {
    return {
      ...order,
      detail: JSON.parse(order.detail),
      assignedServices: JSON.parse(order.assignedServices)
    };
  });
};

const changeEstimatedDeliverDateForCalendarOrder = async (
  { commit },
  orderToUpdate
) => {
  await axios
    .put(
      `${CLIENT_API_URL}/orders/${orderToUpdate.id}/estimated-deliver-date?date=${orderToUpdate.estimatedDeliverDate}`
    )
    .then(response => {
      response.data.detail = JSON.parse(response.data.detail);
      response.data.assignedServices = JSON.parse(
        response.data.assignedServices
      );

      commit('updateOrderOnCalendar', response.data);
    })
    .catch(error => {
      console.error(
        'There was an error changing estimated order deliver date for calendar order' +
          error
      );
    });
};

const retrieveAllBusinessOrdersByDateRange = async (
  { dispatch, commit },
  { fromDate, toDate }
) => {
  dispatch('loading/setIsCalendarOrderLoading', true, { root: true });
  try {
    let urlOrdersByDateRange = `${CLIENT_API_URL}/orders/estimated-deliver-date-within-date-range`;
    if (fromDate) urlOrdersByDateRange += `?fromDate=${fromDate}`;
    if (toDate) urlOrdersByDateRange += `&toDate=${toDate}`;
    let ordersData = await axios.get(urlOrdersByDateRange);
    commit('setAllCalendarEvents', parseOrders(ordersData.data));
    dispatch('loading/setIsCalendarOrderLoading', false, { root: true });
  } catch (error) {
    console.error('There was an error while retriving calendar orders' + error);
  }
};

const retrieveAllWorkersAssignedServicesByDateRange = async (
  { dispatch, commit },
  { fromDate, toDate }
) => {
  dispatch('loading/setIsCalendarOrderLoading', true, { root: true });
  try {
    let urlOrdersByDateRange = `${CLIENT_API_URL}/orders/assignment`;
    if (fromDate) urlOrdersByDateRange += `?fromDate=${fromDate}`;
    if (toDate) urlOrdersByDateRange += `&toDate=${toDate}`;
    let ordersData = await axios.get(urlOrdersByDateRange);
    commit('setAllCalendarEvents', ordersData.data);
    dispatch('loading/setIsCalendarOrderLoading', false, { root: true });
  } catch (error) {
    console.error('There was an error while retriving calendar orders' + error);
  }
};

const updateOrderFromCalendar = async ({ commit }, orderToUpdate) => {
  orderToUpdate.detail = JSON.stringify(orderToUpdate.detail);
  orderToUpdate.assignedServices = JSON.stringify(
    orderToUpdate.assignedServices
  );
  await axios
    .put(`${CLIENT_API_URL}/orders/${orderToUpdate.id}`, orderToUpdate)
    .then(response => {
      response.data.detail = JSON.parse(response.data.detail);
      response.data.assignedServices = JSON.parse(
        response.data.assignedServices
      );
      commit('updateOrderOnCalendar', response.data);
    })
    .catch(error => {
      console.error(
        'There was an error while updating the order from calendar' + error
      );
    });
};

const addAssignServiceOnCalendar = async ({ commit }, assignedServiceToAdd) => {
  await axios
    .post(`${CLIENT_API_URL}/orders/assignment`, assignedServiceToAdd)
    .then(response => {
      commit('addAssignServiceOnCalendar', response.data);
    })
    .catch(error => {
      console.error(
        'There was an error while adding new assigned service on calendar' +
          error
      );
    });
};

const updateAssignServiceOnCalendar = async (
  { commit },
  assignedServiceToUpdate
) => {
  await axios
    .put(`${CLIENT_API_URL}/orders/assignment`, assignedServiceToUpdate)
    .then(response => {
      commit('updateAssignServiceOnCalendar', response.data);
    })
    .catch(error => {
      console.error(
        'There was an error while updating assigned service on calendar' + error
      );
    });
};

export default {
  changeEstimatedDeliverDateForCalendarOrder,
  retrieveAllBusinessOrdersByDateRange,
  retrieveAllWorkersAssignedServicesByDateRange,
  updateOrderFromCalendar,
  addAssignServiceOnCalendar,
  updateAssignServiceOnCalendar
};
