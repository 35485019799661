<template>
  <v-alert dense outlined :color="color" :icon="icon" border="left">
    {{ message }}
  </v-alert>
</template>

<script>
export default {
  name: 'Alert',
  props: ['color', 'icon', 'message']
};
</script>
