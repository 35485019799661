<i18n src="./resources/locales.json"></i18n>

<template>
  <v-dialog v-model="dialog" fullscreen scrollable persistent>
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="addToCalendar" :disabled="!radioGroup">
              {{ $t('addToCalendar') }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </v-card-title>

      <v-card-title class="pa-0 justify-center">
        <v-col cols="3" class="d-flex justify-space-around">
          <DatePicker
            :labelText="$t('orderEstimatedDeliverDate')"
            :givenDate.sync="orderEstimatedDeliverDate"
            :maxDate="undefined"
            :minDate="getMinDate()"
            :icon="undefined"
          />
        </v-col>
        <v-col cols="3" class="d-flex justify-space-around">
          <HourPicker
            :labelText="$t('orderEstimatedDeliverHour')"
            :givenHour.sync="orderEstimatedDeliverHour"
            :icon="undefined"
          />
        </v-col>
      </v-card-title>

      <v-card-title class="pa-0">
        <v-toolbar flat>
          <v-toolbar-title>{{ $t('searchOrder') }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field
            class="mx-3 mt-5"
            flat
            :label="$t('searchByIdNameOrderNumber')"
            prepend-inner-icon="mdi-magnify"
            v-model="search"
            clearable
            @keyup.enter="searchByInput"
          ></v-text-field>
          <v-btn
            :disabled="isDisabled"
            color="primary"
            @click="searchByInput"
            @keyup.enter="searchByInput"
            text
          >
            {{ $t('search') }}
          </v-btn>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-width="100"
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="ml-3"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
              >
                mdi-filter-variant
              </v-icon>
            </template>

            <v-card>
              <v-card-title>{{ $t('filters') }}</v-card-title>
              <v-card-text>
                <v-list>
                  <v-subheader class="subHeaderColor">{{
                    $t('orders')
                  }}</v-subheader>
                  <v-list-item>
                    <v-radio-group
                      v-model="selectedSparePartsOption"
                      @change="setSparePartOption"
                    >
                      <v-radio
                        v-for="(sparePart, i) in sparePartsOptions"
                        :key="i"
                        :label="sparePart.name"
                        :value="sparePart.value"
                      ></v-radio>
                    </v-radio-group>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-subheader class="subHeaderColor">{{
                    $t('orderStatuses')
                  }}</v-subheader>
                  <v-list-item>
                    <v-list-item-action>
                      <v-checkbox
                        :indeterminate="isAtLeastOneSelectedOrderStatuses()"
                        :input-value="isAllSelectedOrderStatuses()"
                        @change="setAllOrderStatuses"
                      ></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        $t('selectAllStates')
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-divider inset></v-divider>

                  <v-list-item
                    v-for="(orderStatus, i) in allOrderStatuses"
                    :key="i"
                  >
                    <v-list-item-action>
                      <v-checkbox
                        :value="orderStatus.id"
                        v-model="selectedOrderStatuses"
                      ></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        orderStatus.name
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-menu>
          <v-subheader>{{ $t('showing') }}</v-subheader>
          <v-subheader>
            - {{ renderInformationAboutSelectedOrdersOnlyWithSpareParts()
            }}<br />-
            {{ renderInformationAboutSelectedOrderStatuses() }}</v-subheader
          >
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-radio-group v-model="radioGroup">
          <template v-for="(order, index) in allOrders">
            <v-row :key="index" align="center">
              <v-col cols="11" class="py-0">
                <Order
                  :key="order.id"
                  :order="order"
                  :isShowDotsContextMenu="false"
                  :isShowIcon="false"
                  :showContextMenuOptions="true"
                  :currentOrHistorical="CURRENT"
                  :isShowForUnassignedServiceCalendar="false"
                />
              </v-col>
              <v-col cols="1" class="pl-4">
                <v-radio :value="order"></v-radio>
              </v-col>
              <v-divider :inset="true"> </v-divider>
            </v-row>
          </template>
        </v-radio-group>

        <p v-if="allOrders.length === 0" class="pl-4">
          {{ $t('thereAreNoResultsForSearching') }}
        </p>
      </v-card-text>
    </v-card>
    <LoadingMask :isLoading="getIsOrderLoading" />
    <AlertAssignedServices
      ref="alertAssignedServices"
      @confirm-action="setOverflowHiden()"
    />
    <InfoMessageModal
      ref="infoMessageModal"
      @confirm-action="setOverflowHiden()"
    />
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import DatePicker from '@/components/reusable-components/date-picker/DatePicker.vue';
import HourPicker from '@/components/reusable-components/hour-picker/HourPicker.vue';
import Order from '@/components/reusable-components/order/Order.vue';
import LoadingMask from '@/components/reusable-components/loading-mask/LoadingMask.vue';
import AlertAssignedServices from '@/components/reusable-components/alert-assigned-services/AlertAssignedServices.vue';
import InfoMessageModal from '@/components/reusable-components/info-message-modal/InfoMessageModal.vue';

import {
  BUSINESS_ID_RECTIFICATION,
  CURRENT,
  EMPTY_GUID
} from '@/constants/ConfigurationConstants';

export default {
  name: 'OrderListSearchAndSelect',

  components: {
    Order,
    LoadingMask,
    DatePicker,
    HourPicker,
    AlertAssignedServices,
    InfoMessageModal
  },

  data: () => ({
    dialog: false,
    search: '',
    menu: false,
    selectedOrderStatuses: [],
    selectedSparePartsOption: 1,
    parametersOfSearchingAndFilter: {
      orderSearchType: 'Current',
      businessId: BUSINESS_ID_RECTIFICATION,
      orderStatusId: '',
      filterOrdersBy: 'All',
      keyword: '',
      workerId: EMPTY_GUID,
      isLoan: ''
    },
    isDisabled: true,
    radioGroup: null,
    orderEstimatedDeliverDate: null,
    orderEstimatedDeliverHour: null,
    CURRENT
  }),

  computed: {
    ...mapGetters('orders', ['allOrders']),
    ...mapGetters('orderStatuses', ['allOrderStatuses']),
    ...mapGetters('loading', ['getIsOrderLoading']),

    sparePartsOptions() {
      return [
        {
          value: 1,
          name: this.$t('allOrders')
        },
        {
          value: 2,
          name: this.$t('onlyOrdersWithSpareParts')
        },
        {
          value: 3,
          name: this.$t('ordersWithSomeSparePartsReceived')
        },
        {
          value: 4,
          name: this.$t('ordersWithAllSparePartsReceived')
        }
      ];
    }
  },

  watch: {
    search: {
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal) {
          if (newVal === null || newVal === '') {
            this.parametersOfSearchingAndFilter.keyword = '';
            this.isDisabled = true;
          } else {
            this.isDisabled = false;
          }
        }
      },
      deep: true
    },

    parametersOfSearchingAndFilter: {
      handler() {
        this.makeSearch();
      },
      deep: true
    },

    selectedOrderStatuses: {
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal)
          this.parametersOfSearchingAndFilter.orderStatusId = newVal;
      },
      deep: true
    },

    allOrderStatuses: {
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal && oldVal.length === 0)
          this.setAllOrderStatusIdsOnSelectedOrderStatuses();
      },
      deep: true
    }
  },

  mounted() {
    this.setAllOrderStatusIdsOnSelectedOrderStatuses();
  },

  methods: {
    ...mapActions('orders', [
      'retrieveAllBusinessCurrentOrders',
      'searchOrder',
      'setAllOrdersAsEmpty'
    ]),

    async open(estimatedDeliverDate, isDisableHourNotNull) {
      this.search = '';
      this.parametersOfSearchingAndFilter.keyword = '';
      this.isDisabled = true;
      this.orderEstimatedDeliverDate = moment(estimatedDeliverDate).format(
        'YYYY-MM-DD'
      );
      if (isDisableHourNotNull)
        this.orderEstimatedDeliverHour = moment(estimatedDeliverDate).format(
          'HH:mm'
        );
      this.dialog = true;
      this.retrieveAllBusinessCurrentOrders(BUSINESS_ID_RECTIFICATION);
    },

    close() {
      this.dialog = false;
      this.setAllOrdersAsEmpty();
      this.orderEstimatedDeliverDate = null;
      this.orderEstimatedDeliverHour = null;
      this.radioGroup = null;
      this.search = '';
      this.parametersOfSearchingAndFilter.keyword = '';
      this.isDisabled = true;
    },

    isAllSelectedOrderStatuses() {
      return this.selectedOrderStatuses.length === this.allOrderStatuses.length;
    },

    isSelectedOrderStatusesEmpty() {
      return this.selectedOrderStatuses.length === 0;
    },

    isAtLeastOneSelectedOrderStatuses() {
      return (
        this.selectedOrderStatuses.length > 0 &&
        this.selectedOrderStatuses.length <= this.allOrderStatuses.length - 1
      );
    },

    setAllOrderStatuses(e) {
      if (e) this.setAllOrderStatusIdsOnSelectedOrderStatuses();
      else this.clearSelectedOrderStatuses();
    },

    setAllOrderStatusIdsOnSelectedOrderStatuses() {
      this.clearSelectedOrderStatuses();
      this.allOrderStatuses.forEach(os => {
        this.selectedOrderStatuses.push(os.id);
      });
    },

    clearSelectedOrderStatuses() {
      this.selectedOrderStatuses = [];
    },

    renderInformationAboutSelectedOrderStatuses() {
      if (this.isAllSelectedOrderStatuses())
        return this.$t('allOrdersStatuses');
      else return this.$t('someOrderStatuses');
    },

    renderInformationAboutSelectedOrdersOnlyWithSpareParts() {
      switch (this.selectedSparePartsOption) {
        case 1:
          return this.$t('allOrders');

        case 2:
          return this.$t('onlyOrdersWithSpareParts');

        case 3:
          return this.$t('ordersWithSomeSparePartsReceived');

        case 4:
          return this.$t('ordersWithAllSparePartsReceived');
      }
    },

    isSelectedOrdersOnlyWithSpareParts() {
      return (
        this.selectedSparePartsOption === 2 ||
        this.selectedSparePartsOption === 3 ||
        this.selectedSparePartsOption === 4
      );
    },

    setSparePartOption() {
      switch (this.selectedSparePartsOption) {
        case 1:
          this.parametersOfSearchingAndFilter.filterOrdersBy = 'All';
          break;
        case 2:
          this.parametersOfSearchingAndFilter.filterOrdersBy = 'sparePartsOnly';
          break;
        case 3:
          this.parametersOfSearchingAndFilter.filterOrdersBy = 'someSpareParts';
          break;
        case 4:
          this.parametersOfSearchingAndFilter.filterOrdersBy = 'allSpareParts';
          break;
      }
    },

    makeSearch() {
      if (
        this.parametersOfSearchingAndFilter.keyword === '' &&
        !this.isSelectedOrdersOnlyWithSpareParts() &&
        this.isAllSelectedOrderStatuses()
      )
        this.retrieveAllBusinessCurrentOrders(BUSINESS_ID_RECTIFICATION);
      else this.searchOrder(this.parametersOfSearchingAndFilter);
    },

    searchByInput() {
      this.parametersOfSearchingAndFilter.keyword = this.search;
    },

    addToCalendar() {
      let order = this.radioGroup;
      if (this.isAssignServicesDateHigherThanOrderDeliverDate()) {
        this.$refs.alertAssignedServices.open({
          message: this.$t(
            'assignServicesDateAreHigherThanEstimatedDeliverDate'
          ),
          blockedServices: this.getAssignServicesDateHigherThanOrderDeliverDate()
        });
      } else if (this.orderEstimatedDeliverHour === null) {
        this.$refs.infoMessageModal.open({
          message: `${this.$t('selectEstimatedDeliverHour')}`,
          title: this.$t('alert')
        });
      } else {
        let concatedDeadline = moment(
          this.orderEstimatedDeliverDate + this.orderEstimatedDeliverHour,
          'YYYY-MM-DDLT'
        );
        order.estimatedDeliverDate = moment(concatedDeadline).format(
          'YYYY-MM-DD[T]HH:mm:ss.SSSZ'
        );
        this.$emit('confirm-action', order);
        this.close();
      }
    },

    isAssignServicesDateHigherThanOrderDeliverDate() {
      let servicesBlocked = this.getAssignServicesDateHigherThanOrderDeliverDate();
      return servicesBlocked.length > 0;
    },

    getAssignServicesDateHigherThanOrderDeliverDate() {
      let order = this.radioGroup;
      let servicesBlocked = [];
      if (order !== undefined) {
        order.assignedServices.forEach(assign => {
          let concatedDeadline = moment(
            this.orderEstimatedDeliverDate + this.orderEstimatedDeliverHour,
            'YYYY-MM-DDLT'
          );
          concatedDeadline = moment(concatedDeadline).format(
            'YYYY-MM-DD[T]HH:mm:ss.SSSZ'
          );

          if (moment(assign.deliverDateTime).isAfter(concatedDeadline)) {
            let serviceName = this.getServiceName(
              assign.serviceId,
              assign.subServiceId
            );
            servicesBlocked.push(serviceName);
          }
        });
      }
      return servicesBlocked;
    },

    getServiceName(serviceId, subServiceId) {
      let order = this.radioGroup;

      let serviceName = '';
      let foundService = order.detail.services.find(
        service => service.id === serviceId
      );
      if (foundService !== undefined) {
        serviceName = foundService.name;
        if (subServiceId !== null) {
          let foundSubService = foundService.subServices.find(
            subService => subService.id === subServiceId
          );
          if (foundSubService !== undefined) {
            serviceName = `${serviceName} (${foundSubService.name})`;
          }
        }
      }
      return serviceName;
    },

    setOverflowHiden() {
      this.$nextTick(function() {
        var root = document.getElementsByTagName('html')[0];
        root.setAttribute('class', 'overflow-y-hidden');
      });
    },

    getMinDate() {
      return moment().format('YYYY-MM-DD');
    }
  }
};
</script>
