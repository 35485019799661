'use strict';

import axios from 'axios';
import { CLIENT_API_URL } from '../../../constants/Environment';

const retrieveAllWorkDones = async ({ commit }) => {
  try {
    await axios.get(`${CLIENT_API_URL}/work-done`).then(response => {
      commit('setAllWorkDones', response.data);
    });
  } catch (error) {
    console.error('There was an error while retrieving work dones' + error);
  }
};

const addWorkDone = async ({ commit }, WorkDone) => {
  delete WorkDone.id;
  return new Promise((resolve, reject) => {
    axios
      .post(`${CLIENT_API_URL}/order-spare-part`, WorkDone)
      .then(response => {
        commit('addWorkDone', response.data);
        resolve(response);
      })
      .catch(error => {
        reject(error);
        console.error(
          'There was an error while posting the spare part' + error
        );
      });
  });
};

const updateWorkDone = async ({ commit }, WorkDoneToUpdate) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${CLIENT_API_URL}/order-spare-part/${WorkDoneToUpdate.id}`,
        WorkDoneToUpdate
      )
      .then(response => {
        commit('updateWorkDone', response.data);
        resolve(response);
      })
      .catch(error => {
        reject(error);
        console.error(
          'There was an error while updating the order spare part' + error
        );
      });
  });
};

const removeWorkDone = ({ commit }, workDoneToDeleteId) => {
  axios
    .delete(`${CLIENT_API_URL}/work-done/${workDoneToDeleteId}`)
    .then(response => {
      commit('removeWorkDone', response.data);
    });
};

export default {
  retrieveAllWorkDones,
  addWorkDone,
  updateWorkDone,
  removeWorkDone
};
