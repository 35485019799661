'use strict';

const allCylinders = state => {
  return state.cylinders.sort((c1, c2) => c1.number - c2.number).slice();
};

const allCylinderArrangements = state => {
  return state.cylinderArrangements;
};

export default {
  allCylinders,
  allCylinderArrangements
};
