'use strict';

const allWorkDoneDetails = state => {
  return state.workDoneDetails.slice();
};

const workDoneDetailsOfWorkDone = state => workDoneId => {
  return state.workDoneDetails
    .slice()
    .filter(wdd => wdd.workDoneId === workDoneId);
};

export default {
  allWorkDoneDetails,
  workDoneDetailsOfWorkDone
};
