'use strict';

const setAllClients = (state, clients) => {
  state.clients = clients;
};

const setAllDataClients = (state, clients) => {
  state.allClients = clients;
};

const setClientListPaginationProps = (state, { count, totalCount }) => {
  state.clientsPaginationProps.count = count;
  state.clientsPaginationProps.totalCount = totalCount;
};

const setAllDataClientListPaginationProps = (state, { count, totalCount }) => {
  state.allClientsPaginatedProps.count = count;
  state.allClientsPaginatedProps.totalCount = totalCount;
};

const addClient = (state, clientToAdd) => {
  state.clients.push(clientToAdd);
};

const updateClient = (state, clientToUpdate) => {
  const foundClientIndex = state.clients.findIndex(
    c => c.id === clientToUpdate.id
  );
  if (foundClientIndex >= 0) {
    state.clients.splice(foundClientIndex, 1, clientToUpdate);
  }
};

const removeClient = (state, clientToDeleteId) => {
  state.clients = state.clients.filter(c => c.id !== clientToDeleteId);
};

export default {
  setAllClients,
  setAllDataClients,
  setClientListPaginationProps,
  setAllDataClientListPaginationProps,
  addClient,
  updateClient,
  removeClient
};
