'use strict';

import axios from 'axios';
import { CLIENT_API_URL } from '../../../constants/Environment';

const retrieveAllClients = async ({ dispatch, commit }, options) => {
  dispatch('loading/setIsClientLoading', true, { root: true });
  try {
    const { page, itemsPerPage } = options;
    await axios
      .get(
        `${CLIENT_API_URL}/clients?pageNumber=${page}&pageSize=${itemsPerPage}`
      )
      .then(response => {
        commit('setAllClients', response.data.dtoData);
        commit('setClientListPaginationProps', {
          count: response.data.count,
          totalCount: response.data.totalCount
        });
      });
    dispatch('loading/setIsClientLoading', false, { root: true });
  } catch (error) {
    console.error('There was an error while retrieving clients' + error);
  }
};

const retrieveAllDataClients = async ({ dispatch, commit }) => {
  dispatch('loading/setIsClientLoading', true, { root: true });
  try {
    await axios.get(`${CLIENT_API_URL}/clients`).then(response => {
      commit('setAllDataClients', response.data.dtoData);
      commit('setAllDataClientListPaginationProps', {
        count: response.data.count,
        totalCount: response.data.totalCount
      });
    });
    dispatch('loading/setIsClientLoading', false, { root: true });
  } catch (error) {
    console.error('There was an error while retrieving clients' + error);
  }
};

const addClient = async ({ commit }, clientToAdd) => {
  delete clientToAdd.id;
  return new Promise((resolve, reject) => {
    axios
      .post(`${CLIENT_API_URL}/clients`, clientToAdd)
      .then(response => {
        commit('addClient', response.data);
        resolve(response);
      })
      .catch(error => {
        reject(error);
        console.error('There was an error while posting the client' + error);
      });
  });
};

const updateClient = async ({ commit }, clientToUpdate) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${CLIENT_API_URL}/clients/${clientToUpdate.id}`, clientToUpdate)
      .then(response => {
        commit('updateClient', response.data);
        resolve(response);
      })
      .catch(error => {
        reject(error);
        console.error('There was an error while updating the client' + error);
      });
  });
};

const removeClient = ({ commit }, clientToDeleteId) => {
  axios
    .delete(`${CLIENT_API_URL}/clients/${clientToDeleteId}`)
    .then(response => {
      commit('removeClient', response.data.id);
    });
};

const searchClientsByNameLastNameId = async ({ dispatch, commit }, keyword) => {
  dispatch('loading/setIsClientLoading', true, { root: true });
  try {
    await axios
      .get(`${CLIENT_API_URL}/clients/search?keyword=${keyword}`)
      .then(response => {
        commit('setAllClients', response.data.dtoData);
        commit('setClientListPaginationProps', {
          count: response.data.count,
          totalCount: response.data.totalCount
        });
      });
    dispatch('loading/setIsClientLoading', false, { root: true });
  } catch (error) {
    console.error('There was an error while retrieving clients' + error);
  }
};

export default {
  retrieveAllClients,
  retrieveAllDataClients,
  addClient,
  updateClient,
  removeClient,
  searchClientsByNameLastNameId
};
