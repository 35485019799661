<i18n src="./resources/locales.json"></i18n>

<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      eager
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="discardChanges()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title
            >{{
              $t('qualityAssurance') +
                ' ' +
                $t('forOrder#') +
                this.order.orderNumber
            }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text :disabled="!canBePrinted" @click="printQualityAssuranceForm">
              {{ $t('printForm') }}
            </v-btn>
            <v-btn dark text :disabled="!isValid" @click="save">
              {{ $t('saveChanges') }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-expansion-panels v-model="selectedPanel" accordion focusable>
          <v-expansion-panel v-if="allOrderSpareParts.length !== 0">
            <v-expansion-panel-header>
              <template v-slot:default="{ open }">
                <span
                  v-if="open"
                  class="opened-section-name-color font-weight-bold"
                >
                  {{ $t('spareParts') }}
                </span>
                <span v-else>
                  {{ $t('spareParts') }}
                </span>
              </template>
            </v-expansion-panel-header>
            <v-sheet class="list-content-background-color">
              <v-expansion-panel-content
                :style="getSectionStyle()"
                class="align-self-start overflow-y-auto section-height-width list-content-background-color content-padding"
                v-scroll.self="() => {}"
                eager
              >
                <v-container>
                  <v-row
                    v-for="sparePart in allOrderSpareParts"
                    :key="sparePart.id"
                    class="list-content-background-color mt-1"
                  >
                    <v-sheet class="mt-3">
                      <v-row>
                        <v-col cols="12" class="list-content-background-color">
                          <p class="list-content-background-color">
                            <span class="font-weight-bold">
                              {{ sparePart.quantity }}x
                            </span>
                            <span>
                              {{ sparePart.name }}
                            </span>
                          </p>
                          <p style="margin-top: -10%">
                            <span
                              v-if="sparePart.brandName"
                              class="opened-section-name-color"
                            >
                              {{ $t('brand') }}:
                            </span>
                            <span v-if="sparePart.brandName">
                              {{ sparePart.brandName }}
                            </span>
                            <span v-else>
                              {{ $t('noBrand') }}
                            </span>
                          </p>
                        </v-col>
                      </v-row>
                    </v-sheet>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-sheet>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <template v-slot:default="{ open }">
                <span
                  v-if="open"
                  class="opened-section-name-color font-weight-bold"
                >
                  {{ $t('form') }}
                </span>
                <span v-else>
                  {{ $t('form') }}
                </span>
              </template>
            </v-expansion-panel-header>
            <v-sheet class="list-content-background-color">
              <v-expansion-panel-content>
                <v-form ref="qualityAssuranceForm" v-model="isValid">
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-sheet
                          class="width-section list-content-background-color mt-3"
                        >
                          <v-sheet
                            v-for="workDone in workDonesToBeSelected"
                            :key="workDone.id"
                            class="list-content-background-color "
                            fill-width
                          >
                            <v-row>
                              <v-col cols="12" class="py-1">
                                <v-checkbox
                                  v-model="workDone.isChecked"
                                  hide-details
                                >
                                  <template v-slot:label>
                                    {{ workDone.name }}
                                  </template>
                                </v-checkbox>
                                  <v-sheet
                                    v-if="workDone.isChecked"
                                    class="list-content-background-color pl-8 pt-1"
                                  >
                                    <v-row>
                                        <v-sheet
                                          v-for="workDoneDetail in WorkDoneDetailsToBeSelectedOfOrder(
                                            workDone.id
                                          )"
                                          :key="workDoneDetail.id"
                                          class="list-content-background-color ml-2 mr-2"
                                        >
                                        <v-col cols="12">
                                          <v-row class="pb-1">
                                            <v-col cols="12" class="py-1">
                                              <v-checkbox
                                                v-model="
                                                  workDoneDetail.isChecked
                                                "
                                                hide-details
                                              >
                                                <template v-slot:label>
                                                  {{ workDoneDetail.name }}
                                                </template>
                                              </v-checkbox>
                                              <v-row>
                                                <v-col cols="12">
                                                  <v-row>
                                                    <v-col cols="8">
                                                      <v-text-field
                                                        v-model="
                                                          workDoneDetail.measure
                                                        "
                                                        :label="$t('measure')"
                                                        :rules="[
                                                          MeasureRules.positive,
                                                          MeasureRules.onlyNumbers
                                                        ]"
                                                      >
                                                      </v-text-field>
                                                    </v-col>
                                                    <v-col cols="4">
                                                      <v-select
                                                        v-model="
                                                          workDoneDetail.unitOfMeasure
                                                        "
                                                        :items="unitsOfMeasure"
                                                        item-value="unitOfMeasure"
                                                        :label="$t('unitOfMeasure')"
                                                      >
                                                      </v-select>
                                                    </v-col>
                                                  </v-row>
                                                </v-col>
                                              </v-row>
                                            </v-col>
                                          </v-row>
                                        </v-col>
                                        </v-sheet>                                     
                                    </v-row>
                                  </v-sheet>
                              </v-col>
                            </v-row>
                            <v-row v-if="workDone.isChecked" class="ml-5">
                              <v-col cols="12">
                                <v-text-field
                                  v-model="workDone.observations"
                                  :label="$t('observations')"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                          </v-sheet>
                        </v-sheet>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-expansion-panel-content>
            </v-sheet>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-dialog>
    <RectifierOrderResumeModal
      ref="rectifierOrderResume"
      @confirm-action="close"
    />
    <ConfirmationModal
      ref="confirmationModal"
      @confirm-action="save"
      @reject-action="close"
    />
    <ErrorMessageModal
      ref="errorMessageModal"
      @back-to-rectifier-order-detail="setOverflowHiden"
    />
  </div>
</template>

<script>
import moment from 'moment';
import {
  BUSINESS_ID_RECTIFICATION,
  DEFAULT_CURRENCY,
  EMPTY_GUID
} from '@/constants/ConfigurationConstants';
import { mapActions, mapGetters } from 'vuex';
import RectifierOrderResumeModal from '@/components/order/rectifier-order-resume-modal/RectifierOrderResumeModal.vue';
import ConfirmationModal from '@/components/reusable-components/confirmation-modal/ConfirmationModal.vue';
import ErrorMessageModal from '@/components/reusable-components/error-message-modal/ErrorMessageModal.vue';

export default {
  name: 'QualityAssuranceModal',

  components: {
    RectifierOrderResumeModal,
    ConfirmationModal,
    ErrorMessageModal
  },

  data: () => ({
    selectedPanel: 0,
    dialog: false,
    hasPriority: false,
    number: '',
    isValid: false,
    arrangement: '',
    order: {
      id: '-1',
      client: {
        id: EMPTY_GUID,
        name: '',
        phone: null,
        address: '',
        ci: null
      },
      detail: {
        item: {
          specification: {
            engine: '',
            brand: '',
            numberOfCylinders: ''
          },
          parts: [],
          spareParts: [],
          sparePartsDiscount: 0
        },
        services: []
      },
      currencyCode: DEFAULT_CURRENCY,
      businessId: BUSINESS_ID_RECTIFICATION,
      orderStatusId: null,
      creationDate: moment().format('YYYY-MM-DD[T]HH:mm:ss.SSSZ'),
      hasPriority: false,
      estimatedDeliverDate: null,
      servicesDiscount: 0,
      receivedBy: '',
      receivedById: '',
      note: ''
    },
    defaultOrder: {
      id: '-1',
      client: {
        id: EMPTY_GUID,
        name: '',
        phone: null,
        address: '',
        ci: null
      },
      detail: {
        item: {
          specification: {
            engine: '',
            brand: '',
            numberOfCylinders: ''
          },
          parts: [],
          spareParts: [],
          sparePartsDiscount: 0
        },
        services: []
      },
      currencyCode: DEFAULT_CURRENCY,
      businessId: BUSINESS_ID_RECTIFICATION,
      orderStatusId: null,
      creationDate: moment().format('YYYY-MM-DD[T]HH:mm:ss.SSSZ'),
      hasPriority: false,
      estimatedDeliverDate: null,
      servicesDiscount: 0,
      receivedBy: '',
      receivedById: '',
      note: ''
    },
    orderWorkDone: {
      id: '',
      workDoneId: '',
      orderId: '',
      observations: ''
    },
    orderWorkDoneDetail: {
      id: '',
      workDoneDetailId: '',
      orderId: '',
      measure: 0,
      unitOfMeasure: ''
    },
    errorMessage: '',
    modalWillBeCreated: true,
    workDonesToBeSelected: [],
    workDoneDetailsToBeSelected: [],
    orderWorkDoneModal: {
      orderWorkDoneList: [],
      orderWorkDoneDetailList: [],
      orderId: ''
    },
    orderId: '',
    unitsOfMeasure: ['mm', 'pulg.', 'PZA'],
    canBePrinted: false,
  }),

  watch: {
    allWorkDones: {
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.setallWorkDonesToWorkDonesToBeSelected();
        }
      },
      deep: true
    },

    allWorkDoneDetails: {
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.setallWorkDoneDetailsToWorkDoneDetailsToBeSelected();
        }
      },
      deep: true
    }
  },

  computed: {
    ...mapGetters('spareParts', [
      'allSpareParts',
      'allUnitOfMeasures',
      'getMeasuresByUnitOfMeasureId'
    ]),
    ...mapGetters('orderSpareParts', ['allOrderSpareParts']),
    ...mapGetters('workDone', ['allWorkDones']),
    ...mapGetters('workDoneDetail', [
      'allWorkDoneDetails',
      'workDoneDetailsOfWorkDone'
    ]),
    ...mapGetters('orderWorkDonesAndDetails', [
      'allOrderWorkDoneList',
      'allOrderWorkDoneDetailList',
      'orderIdOfModal'
    ]),

    MeasureRules() {
      return {
        required: value => !!value || this.$t('MeasureIsRequired'),
        positive: value => value >= 0 || this.$t('MeasureMustBePositive'),
        onlyNumbers: value =>
          /^\d+(\.\d{1,99})|\d$/.test(value) || this.$t('MeasureMustBeNumber')
      };
    }
  },

  created() {
    this.$root.$on('open-quality-assurance-modal', this.open);
  },

  methods: {
    ...mapActions('orderSpareParts', ['retrieveAllOrderSparePartsOfOrder']),
    ...mapActions('workDone', ['retrieveAllWorkDones']),
    ...mapActions('workDoneDetail', ['retrieveAllWorkDoneDetails']),
    ...mapActions('orderWorkDonesAndDetails', [
      'retrieveAllOrderWorkDonesAndDetailsOfOrder',
      'addOrderWorkDoneAndDetails',
      'updateOrderWorkDoneAndDetails'
    ]),

    printQualityAssuranceForm(){
      this.$root.$emit('print-quality-assurance-pdf', this.order.id);
    },

    open(orderToUpsert) {
      this.orderId = orderToUpsert.id;
      this.order = JSON.parse(JSON.stringify(orderToUpsert));

      if (this.allOrderWorkDoneList.length !== 0) {
        this.modalWillBeCreated = false;
      }
      this.dialog = true;
    },

    setallWorkDonesToWorkDonesToBeSelected() {
      this.workDonesToBeSelected = this.allWorkDones.map(wd => ({
        ...wd,
        isChecked: false,
        observations: '',
        existingOrderWorkDoneId: ''
      }));

      if (this.allOrderWorkDoneList.length !== 0) {
        this.allOrderWorkDoneList.forEach(owd => {
          this.workDonesToBeSelected.map(wdtbs => {
            if (wdtbs.id === owd.workDoneId) {
              wdtbs.existingOrderWorkDoneId = owd.id;
              wdtbs.isChecked = true;
              wdtbs.observations = owd.observations;
            }
          });
        });
        this.canBePrinted = true;
      }
      else
      {
        this.canBePrinted = false;
      }
    },

    setallWorkDoneDetailsToWorkDoneDetailsToBeSelected() {
      this.workDoneDetailsToBeSelected = this.allWorkDoneDetails.map(wdd => ({
        ...wdd,
        isChecked: false,
        measure: 0,
        existingOrderWorkDoneDetailId: '',
        unitOfMeasure: ''
      }));

      if (this.allOrderWorkDoneDetailList.length !== 0) {
        this.allOrderWorkDoneDetailList.forEach(owdd => {
          this.workDoneDetailsToBeSelected.map(wddtbs => {
            if (wddtbs.id === owdd.workDoneDetailId) {
              wddtbs.existingOrderWorkDoneDetailId = owdd.id;
              wddtbs.isChecked = true;
              wddtbs.measure = owdd.measure;
              wddtbs.unitOfMeasure = owdd.unitOfMeasure;
            }
          });
        });
      }
    },

    WorkDoneDetailsToBeSelectedOfOrder(workDoneId) {
      return this.workDoneDetailsToBeSelected.filter(
        wddtbs => wddtbs.workDoneId === workDoneId
      );
    },
    setOverflowHiden() {
      this.$nextTick(function() {
        var root = document.getElementsByTagName('html')[0];
        root.setAttribute('class', 'overflow-y-hidden');
      });
    },

    async save() {
      await this.loadOrderWorkDoneAndDetailsFromForm();

      if (this.modalWillBeCreated === false) {
        await this.updateOrderWorkDoneAndDetails(this.orderWorkDoneModal);
        this.$root.$emit(
          'open-status-message-snackbar',
          Object.assign(
            {},
            {
              message: this.$t('theQAFormHasBeenUpdatedSuccesfully'),
              messageType: 'success'
            }
          )
        );
        this.close();
      } else {
        await this.addOrderWorkDoneAndDetails(this.orderWorkDoneModal);
        this.$root.$emit(
          'open-status-message-snackbar',
          Object.assign(
            {},
            {
              message: this.$t('theQAFormHasBeenCreatedSuccesfully'),
              messageType: 'success'
            }
          )
        );

        this.close();
      }
    },

    async loadOrderWorkDoneAndDetailsFromForm() {
      this.workDonesToBeSelected.forEach(wdtbs => {
        if (wdtbs.isChecked) {
          if (wdtbs.existingOrderWorkDoneId) {
            this.orderWorkDone.id = wdtbs.existingOrderWorkDoneId;
          } else {
            this.orderWorkDone.id = '00000000-0000-0000-0000-000000000000';
          }
          this.orderWorkDone.workDoneId = wdtbs.id;
          this.orderWorkDone.orderId = this.orderId;
          this.orderWorkDone.observations = wdtbs.observations;

          this.orderWorkDoneModal.orderWorkDoneList.push(
            JSON.parse(JSON.stringify(this.orderWorkDone))
          );
        }
      });

      this.workDoneDetailsToBeSelected.forEach(wddtbs => {
        if (wddtbs.isChecked) {
          if (wddtbs.existingOrderWorkDoneDetailId) {
            this.orderWorkDoneDetail.id = wddtbs.existingOrderWorkDoneDetailId;
          } else {
            this.orderWorkDoneDetail.id =
              '00000000-0000-0000-0000-000000000000';
          }

          this.orderWorkDoneDetail.workDoneDetailId = wddtbs.id;
          this.orderWorkDoneDetail.orderId = this.orderId;
          this.orderWorkDoneDetail.measure = Number(wddtbs.measure);
          this.orderWorkDoneDetail.unitOfMeasure = wddtbs.unitOfMeasure;

          this.orderWorkDoneModal.orderWorkDoneDetailList.push(
            JSON.parse(JSON.stringify(this.orderWorkDoneDetail))
          );
        }
      });

      this.orderWorkDoneModal.orderId = this.orderId;
    },

    close() {
      this.dialog = false;
    },

    discardChanges() {
      if (this.modalWillBeCreated) this.close();
      else {
        let message;
        message = `${this.$t(
          'doYouWantToSaveTheChangesMadeToThisOrderBeforeLeaving'
        )}`;
        this.$refs.confirmationModal.open(
          Object.assign(
            { message: message },
            { isOverOtherModal: false },
            { title: this.$t('attention') }
          )
        );
      }
    },

    getSectionStyle() {
      return {
        '--window-height': window.innerHeight + 'px',
        '--window-width': window.innerWidth + 'px',
        '--sections-height': 380 + 'px'
      };
    }
  }
};
</script>

<style scoped>
@import './ScopedStyles.css';
</style>
