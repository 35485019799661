import { render, staticRenderFns } from "./RectifierOrdersList.vue?vue&type=template&id=63c543f4"
import script from "./RectifierOrdersList.vue?vue&type=script&lang=js"
export * from "./RectifierOrdersList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./resources/locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fgithub%2Fworkspace%2Fsrc%2Fviews%2Forder%2Forders-list%2Frectifier-orders-list%2FRectifierOrdersList.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports