export default {
  methods: {
    _getHueFromString(string = '') {
      let hash = 0;
      for (let i = 0; i < string.length; i++) {
        hash = string.charCodeAt(i) + ((hash << 20) - hash);
      }
      return hash % 360;
    },

    _getColorFromString(string) {
      const saturation = 65;
      const lightness = 70;
      const hue = this._getHueFromString(string);
      return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    },

    getGeneratedColor(colorLabel) {
      const stringForColor =
        colorLabel.toString().length > 5
          ? colorLabel.toString().substring(0, 5)
          : colorLabel.toString().charAt(0);
      return this._getColorFromString(stringForColor);
    }
  }
};
