'use strict';

const allServices = state => {
  return state.services.slice();
};

const allSubServicesByServiceId = state => serviceIdToSearch => {
  let subServices = state.services.find(
    service => service.id === serviceIdToSearch
  )?.subServices;
  return subServices !== undefined && subServices !== null
    ? subServices.slice()
    : [].slice();
};

export default {
  allServices,
  allSubServicesByServiceId
};
