'use strict';

const allOrderWorkDoneList = state => {
  return state.orderWorkDoneList.slice();
};

const allOrderWorkDoneDetailList = state => {
  return state.orderWorkDoneDetailList.slice();
};

const orderIdOfModal = state => {
  return state.orderId;
};

export default {
  allOrderWorkDoneList,
  allOrderWorkDoneDetailList,
  orderIdOfModal
};
