<i18n src="./resources/locales.json"></i18n>

<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      eager
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="discardChanges()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title
            >{{ formTitle }}
            <span
              v-if="this.order.id === '-1'"
              class="font-weight-bold error--text"
            >
              - {{ this.orderNumberFormatter(this.getNextOrderNumber) }}</span
            ></v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text :disabled="isNotValidOrder()" @click="openResume">
              {{ $t('next') }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-expansion-panels v-model="selectedPanel" accordion focusable>
          <ClientSection
            ref="clientSection"
            @send-quick-service="sendQuickService"
            :orderId="order.id"
            :note="order.note"
            :sectionName="$t('client')"
            @validated-client-data="validateClientData"
            @update:note="updateNote"
          />
          <PartsSection
            v-if="!isQuickService"
            ref="partsSection"
            :sectionName="$t('parts')"
          />
          <ServicesSection
            v-if="!isQuickService"
            ref="servicesSection"
            :sectionName="$t('services')"
            @validated-service-data="validateServiceData"
          />
          <SparePartsSection
            v-if="!isQuickService"
            ref="sparePartsSection"
            :sectionName="$t('spareParts')"
            @validated-spare-part-data="validateSparePartData"
          />
        </v-expansion-panels>
      </v-card>
    </v-dialog>
    <RectifierOrderResumeModal
      ref="rectifierOrderResume"
      @confirm-action="close"
    />
    <ConfirmationModal
      ref="confirmationModal"
      @confirm-action="save"
      @reject-action="close"
    />
    <ErrorMessageModal
      ref="errorMessageModal"
      @back-to-rectifier-order-detail="setOverflowHiden"
    />
  </div>
</template>

<script>
import moment from 'moment';
import {
  BUSINESS_ID_RECTIFICATION,
  DEFAULT_CURRENCY,
  EMPTY_GUID
} from '@/constants/ConfigurationConstants';
import { mapActions, mapGetters } from 'vuex';
import RectifierOrderResumeModal from '@/components/order/rectifier-order-resume-modal/RectifierOrderResumeModal.vue';
import ClientSection from '@/components/order/rectifier-order-detail-modal/sections/client-section/ClientSection.vue';
import PartsSection from '@/components/order/rectifier-order-detail-modal/sections/parts-section/PartsSection.vue';
import ServicesSection from '@/components/order/rectifier-order-detail-modal/sections/services-section/ServicesSection.vue';
import SparePartsSection from '@/components/order/rectifier-order-detail-modal/sections/spare-parts-section/SparePartsSection.vue';
import ConfirmationModal from '@/components/reusable-components/confirmation-modal/ConfirmationModal.vue';
import ErrorMessageModal from '@/components/reusable-components/error-message-modal/ErrorMessageModal.vue';

export default {
  name: 'RectifierOrderDetailModal',
  components: {
    RectifierOrderResumeModal,
    ClientSection,
    PartsSection,
    ServicesSection,
    SparePartsSection,
    ConfirmationModal,
    ErrorMessageModal
  },

  data: () => ({
    isClientDataValid: false,
    isServiceDataValid: false,
    isSparePartDataValid: false,
    selectedPanel: 0,
    dialog: false,
    hasPriority: false,
    number: '',
    arrangement: '',
    order: {
      id: '-1',
      client: {
        id: EMPTY_GUID,
        name: '',
        phone: null,
        address: '',
        ci: null
      },
      detail: {
        item: {
          specification: {
            engine: '',
            brand: '',
            numberOfCylinders: ''
          },
          parts: [],
          spareParts: [],
          sparePartsDiscount: 0
        },
        services: []
      },
      currencyCode: DEFAULT_CURRENCY,
      businessId: BUSINESS_ID_RECTIFICATION,
      orderStatusId: null,
      creationDate: moment().format('YYYY-MM-DD[T]HH:mm:ss.SSSZ'),
      hasPriority: false,
      estimatedDeliverDate: null,
      servicesDiscount: 0,
      receivedBy: '',
      receivedById: '',
      note: ''
    },
    defaultOrder: {
      id: '-1',
      client: {
        id: EMPTY_GUID,
        name: '',
        phone: null,
        address: '',
        ci: null
      },
      detail: {
        item: {
          specification: {
            engine: '',
            brand: '',
            numberOfCylinders: ''
          },
          parts: [],
          spareParts: [],
          sparePartsDiscount: 0
        },
        services: []
      },
      currencyCode: DEFAULT_CURRENCY,
      businessId: BUSINESS_ID_RECTIFICATION,
      orderStatusId: null,
      creationDate: moment().format('YYYY-MM-DD[T]HH:mm:ss.SSSZ'),
      hasPriority: false,
      estimatedDeliverDate: null,
      servicesDiscount: 0,
      receivedBy: '',
      receivedById: '',
      note: ''
    },
    quickService: {
      id: 'quickService',
      isOther: true,
      name: '',
      price: null,
      subServices: [],
      currencyCode: 'BOB',
      quantity: 1,
      isFinished: false
    },
    isQuickService: false,
    defaultQuickService: {
      id: 'quickService',
      isOther: true,
      name: '',
      price: null,
      subServices: [],
      currencyCode: 'BOB',
      quantity: 1,
      isFinished: false
    },
    errorMessage: '',
    onAccount: 0,
    isDebitCard: false,
    paymentMethod: ''
  }),

  created() {
    this.$root.$on('open-rectifier-order-detail-modal', this.open);
    this.retrieveInitialOrderStatus();
    this.$root.$on('set-data-from-resume-modal', this.setDataFromResumeModal);
  },

  beforeDestroy() {
    this.$root.$off('open-rectifier-order-detail-modal');
    this.$root.$off('set-data-from-resume-modal');
  },

  computed: {
    ...mapGetters('orders', ['getNextOrderNumber']),
    ...mapGetters('brands', ['allBrands']),

    formTitle() {
      const { id } = this.order;
      return id === '-1' ? `${this.$t('newOrder')}` : this.$t('editOrder');
    }
  },

  methods: {
    ...mapActions('orderStatuses', ['retrieveInitialOrderStatus']),
    ...mapActions('orders', [
      'makePayment',
      'retrieveNextOrderNumber',
      'updateOrder'
    ]),
    ...mapActions('brands', ['addBrand']),

    open(orderToUpsert) {
      this.order = Object.assign({}, orderToUpsert || this.defaultOrder);
      const { id } = this.order;
      if (id !== '-1') {
        const { detail } = orderToUpsert;
        const { services } = detail;

        this.hasPriority = orderToUpsert.hasPriority;

        let quickService = services.find(s => s.id === 'quickService');
        if (quickService) {
          this.isQuickService = true;
          this.quickService = Object.assign({}, quickService);
          this.setOverflowHiden();
        } else {
          this.isQuickService = false;
        }
        this.setClientSectionDataToEdit();
        this.setPartsSectionDataToEdit();
        this.setServicesSectionDataToEdit();
        this.setSparePartsSectionDataToEdit();
      } else {
        this.retrieveNextOrderNumber();
      }
      this.selectedPanel = 0;
      this.isDebitCard = false;
      this.paymentMethod = '';
      this.onAccount = 0;
      this.dialog = true;
    },

    close() {
      this.$refs.clientSection.cleanSection();
      if (!this.isQuickService) {
        this.$refs.partsSection.cleanSection();
        this.$refs.servicesSection.cleanSection();
        this.$refs.sparePartsSection.cleanSection();
      }
      this.order = Object.assign({}, this.defaultOrder);
      this.quickService = Object.assign({}, this.defaultQuickService);
      this.isQuickService = false;
      this.selectedPanel = 0;
      this.hasPriority = false;
      this.order.detail.item.sparePartsDiscount = 0;
      this.defaultOrder.detail.item.sparePartsDiscount = 0;
      this.order.detail.item.specification = Object.assign(
        {},
        {
          engine: '',
          brand: '',
          numberOfCylinders: ''
        }
      );
      this.onAccount = 0;
      this.dialog = false;
    },

    openResume() {
      const { id } = this.order;
      if (id === '-1') {
        this.order.creationDate = moment().format('YYYY-MM-DD[T]HH:mm:ss.SSSZ');
      }
      if (!this.isQuickService) {
        if (
          JSON.stringify(this.getAssignedServices()) !==
          JSON.stringify(this.order.assignedServices)
        ) {
          this.order.assignedServices = this.getAssignedServices();
        }
      }

      this.$refs.rectifierOrderResume.open(
        this.order,
        this.getClient(),
        this.getEngine(),
        this.getParts(),
        this.getServices(),
        this.getSpareParts(),
        this.isQuickService,
        this.quickService,
        this.getNextOrderNumber,
        this.onAccount,
        this.isDebitCard,
        this.paymentMethod
      );
    },

    getClient() {
      const {
        client,
        hasPriority,
        quickService
      } = this.$refs.clientSection.sendClientAndEngineData();
      this.order.hasPriority = hasPriority;
      this.quickService = Object.assign({}, quickService);
      return Object.assign({}, client);
    },

    getEngine() {
      const {
        specification
      } = this.$refs.clientSection.sendClientAndEngineData();
      return Object.assign({}, specification);
    },

    getParts() {
      return this.isQuickService ? [] : this.$refs.partsSection.sendPartsData();
    },

    getServices() {
      return this.isQuickService
        ? []
        : this.$refs.servicesSection.sendServicesData();
    },

    getAssignedServices() {
      return this.$refs.servicesSection.sendAssignedServicesData();
    },

    getSpareParts() {
      return this.isQuickService
        ? []
        : this.$refs.sparePartsSection.sendSparePartsData();
    },

    isNotValidOrder() {
      if (
        !this.isClientDataValid ||
        !this.isServiceDataValid ||
        !this.isSparePartDataValid
      ) {
        return true;
      } else {
        return false;
      }
    },

    sendQuickService(e) {
      this.isQuickService = e;
      this.setOverflowHiden();
    },

    setClientSectionDataToEdit() {
      const { client, detail } = this.order;
      const { item } = detail;
      const { specification } = item;

      this.$refs.clientSection.setClientDataToEdit(Object.assign({}, client));
      this.$refs.clientSection.setSpecificationDataToEdit(
        Object.assign({}, specification)
      );
      this.$refs.clientSection.setIsQuickServiceDataToEdit(this.isQuickService);
      this.$refs.clientSection.setQuickServiceDataToEdit(
        Object.assign({}, this.quickService)
      );
      this.$refs.clientSection.setHasPriorityDataToEdit(this.hasPriority);
    },

    setPartsSectionDataToEdit() {
      const { detail } = this.order;
      const { item } = detail;
      const { parts } = item;
      this.$refs.partsSection.setPartsDataToEdit(parts);
    },

    setServicesSectionDataToEdit() {
      const { detail, assignedServices, orderNumber } = this.order;
      const { services } = detail;
      this.$refs.servicesSection.setServicesDataToEdit(
        services,
        assignedServices,
        orderNumber
      );
    },

    setSparePartsSectionDataToEdit() {
      const { detail } = this.order;
      const { item } = detail;
      const { spareParts } = item;
      this.$refs.sparePartsSection.setSparePartsDataToEdit(spareParts);
    },

    setOverflowHiden() {
      this.$nextTick(function() {
        var root = document.getElementsByTagName('html')[0];
        root.setAttribute('class', 'overflow-y-hidden');
      });
    },

    validateClientData(validatedClientData) {
      this.isClientDataValid = validatedClientData;
    },

    validateServiceData(validatedServiceData) {
      this.isServiceDataValid = validatedServiceData;
    },

    validateSparePartData(validatedSparePartData) {
      this.isSparePartDataValid = validatedSparePartData;
    },

    orderNumberFormatter(orderNumber) {
      return orderNumber.toString().length < 5
        ? this.orderNumberFormatter('0' + orderNumber)
        : orderNumber;
    },

    setDataFromResumeModal(data) {
      const {
        deadlineDate,
        deadlineHour,
        onAccount,
        servicesDiscount,
        isDebitCard,
        paymentMethod
      } = data;
      this.onAccount = onAccount;
      this.order.servicesDiscount = servicesDiscount;
      this.isDebitCard = isDebitCard;
      this.paymentMethod = paymentMethod;
      if (deadlineDate !== null && deadlineHour !== null)
        this.order.estimatedDeliverDate = moment(
          deadlineDate + deadlineHour,
          'YYYY-MM-DDLT'
        );
      if (deadlineDate !== null && deadlineHour === null)
        this.order.estimatedDeliverDate = moment(
          deadlineDate + moment().format('HH:mm'),
          'YYYY-MM-DDLT'
        );
      if (deadlineDate === null && deadlineHour !== null)
        this.order.estimatedDeliverDate = moment(
          moment().format('YYYY-MM-DD') + deadlineHour,
          'YYYY-MM-DDLT'
        );
      if (deadlineDate === null && deadlineHour === null)
        this.order.estimatedDeliverDate = null;
    },

    discardChanges() {
      if (this.order.id === '-1') this.close();
      else {
        let message;
        message = `${this.$t(
          'doYouWantToSaveTheChangesMadeToThisOrderBeforeLeaving'
        )}`;
        this.$refs.confirmationModal.open(
          Object.assign(
            { message: message },
            { isOverOtherModal: false },
            { title: this.$t('attention') }
          )
        );
      }
    },

    async save() {
      this.errorMessage = '';
      if (this.isNotQuickService()) {
        this.validateOrder();
        this.validatePartsData();
        this.validateServicesData();
        this.validateServicesPricesData();
        this.validateTotalServices();
        this.validateTotalSpareParts();
      }
      if (this.errorMessage === '') {
        let orderToUpdate = JSON.parse(JSON.stringify(this.order));
        this.assignClientToOrder(orderToUpdate);
        this.assignSpecificationToOrder(orderToUpdate);
        this.assignPartsToOrder(orderToUpdate);
        this.assignServicesToOrder(orderToUpdate);
        this.assignSparePartsToOrder(orderToUpdate);
        this.createNewBrand(orderToUpdate);
        this.checkAssignedServicesData(orderToUpdate);
        await this.updateOrder(Object.assign({}, orderToUpdate));
        if (this.onAccount > 0) {
          let pay = {
            orderId: this.order.id,
            date: moment().format('YYYY-MM-DD[T]HH:mm:ss.SSSZ'),
            orderStatusId: this.order.orderStatusId,
            amount: parseInt(this.onAccount),
            isDebitCard: this.isDebitCard,
            paymentMethod: this.paymentMethod
          };
          await this.makePayment({
            order: Object.assign({}, this.order),
            pay
          });
        }
        this.close();
      } else {
        let message = this.$t('pleaseCheckThatYourOrder');
        let concatedMessage = message.concat(': ', this.errorMessage);
        this.$refs.errorMessageModal.open(
          Object.assign(
            { message: concatedMessage },
            {
              isOrder: true,
              subTitle: this.$t('somethingWentWrongCreatingTheOrder')
            }
          )
        );
        this.setOverflowHiden();
      }
    },

    validateOrder() {
      if (this.isNotValidOrder()) {
        this.buildErrorMessage(
          this.$t('oneOrMoreRequiredFieldsHaveNotBeenFilled')
        );
      }
    },

    validatePartsData() {
      if (!this.areSomePartsSelected()) {
        this.buildErrorMessage(this.$t('atLeastAPartIsSelected'));
      }
    },

    validateServicesData() {
      if (!this.areSomeServicesSelected()) {
        this.buildErrorMessage(this.$t('atLeastAServiceIsSelected'));
      } else {
        let existSubServices = true;
        let checkedServices = this.getServices().filter(
          service => service.isChecked === true
        );
        checkedServices.forEach(service => {
          if (service.subServices.length !== 1) {
            if (!this.areSomeSubServicesSelected(service.subServices)) {
              if (!this.isOtherSubServiceSelected(service.subServices)) {
                existSubServices = false;
              }
            }
          }
        });
        if (!existSubServices) {
          this.buildErrorMessage(
            this.$t('atLeastASubServiceFromSelectedServicesIsSelected')
          );
        }
      }
    },

    validateServicesPricesData() {
      if (this.areSomeServicesSelected()) {
        let checkedServices = this.getServices().filter(
          service => service.isChecked === true
        );
        checkedServices.forEach(service => {
          if (service.subServices.length !== 1) {
            const subServices = [...service.subServices];
            let existCheckedPrices = true;
            let existNegativePrices = false;
            subServices.forEach(subService => {
              if (
                !subService.id.includes('other') &&
                subService.price === null &&
                subService.isChecked
              ) {
                existCheckedPrices = false;
              }
              if (
                !subService.id.includes('other') &&
                subService.price < 0 &&
                subService.isChecked
              ) {
                existNegativePrices = true;
              }
              if (
                subService.id.includes('other') &&
                subService.name !== '' &&
                subService.price === null
              ) {
                existCheckedPrices = false;
              }
              if (
                subService.id.includes('other') &&
                subService.name !== '' &&
                subService.price < 0
              ) {
                existNegativePrices = true;
              }
            });
            if (!existCheckedPrices || existNegativePrices) {
              this.buildErrorMessage(
                this.$t('selectedSubServicesHaveAPriceAndPriceMustBePositive')
              );
            }
          } else {
            if (service.price === null) {
              this.buildErrorMessage(this.$t('selectedServicesHaveAPrice'));
            } else {
              if (service.price < 0) {
                this.buildErrorMessage(
                  this.$t('selectedServicesHavePositivePrice')
                );
              }
            }
          }
        });
      }
    },

    validateTotalServices() {
      if (
        this.getTotalSubService() <
        this.onAccount + this.order.servicesDiscount
      ) {
        this.buildErrorMessage(
          this.$t(
            'totalServicesIsLessThanTheSumOfDiscountServicesPlusOnAccount'
          )
        );
      }
    },

    validateTotalSpareParts() {
      if (
        this.getTotalSpareParts() < this.order.detail.item.sparePartsDiscount
      ) {
        this.buildErrorMessage(
          this.$t('totalSparePartsIsLessThanTheDiscountSpareParts')
        );
      }
    },

    getTotalSubService() {
      let total = 0;
      if (this.isQuickService) {
        total = this.quickService.price;
      } else {
        this.getServices().forEach(s => {
          if (s.isChecked) {
            if (s.subServices !== null) {
              if (s.subServices.length === 1) total += Number(s.price);
              s.subServices.forEach(ss => {
                if (ss.isChecked || (ss.id.includes('other') && ss.name !== ''))
                  total += Number(ss.price);
              });
            }
          }
        });
      }
      return total;
    },

    getTotalSpareParts() {
      let total = 0;
      this.getSpareParts().forEach(sp => {
        if (sp.isChecked) total += Number(sp.price);
      });
      return total;
    },

    areSomeSubServicesSelected(subServices) {
      return subServices.some(service => service.isChecked === true);
    },

    isOtherSubServiceSelected(subServices) {
      return subServices.some(
        service => service.id.includes('other') && service.name !== ''
      );
    },

    areSomePartsSelected() {
      return this.getParts().some(part => part.isChecked === true);
    },

    areSomeServicesSelected() {
      return this.getServices().some(service => service.isChecked === true);
    },

    buildErrorMessage(message) {
      if (this.errorMessage === '') {
        this.errorMessage = message;
      }
    },

    assignClientToOrder(orderToUpdate) {
      orderToUpdate.client = Object.assign({}, this.getClient());
    },

    assignSpecificationToOrder(orderToUpdate) {
      orderToUpdate.detail.item.specification = Object.assign(
        {},
        this.getEngine()
      );
    },

    assignPartsToOrder(orderToUpdate) {
      orderToUpdate.detail.item.parts = this.getParts();

      orderToUpdate.detail.item.parts = orderToUpdate.detail.item.parts.filter(
        p => p.isChecked === true
      );

      orderToUpdate.detail.item.parts.forEach(
        p =>
          (p.subParts = p.subParts.filter(
            sp => sp.isChecked || (sp.id === '' && sp.name !== '')
          ))
      );

      orderToUpdate.detail.item.parts.forEach(p => delete p.isChecked);

      orderToUpdate.detail.item.parts.forEach(p =>
        p.subParts.forEach(sp => delete sp.isChecked)
      );
    },

    assignServicesToOrder(orderToUpdate) {
      if (this.isQuickService) {
        orderToUpdate.detail.services = [];
        orderToUpdate.detail.services.push(this.quickService);
      } else {
        orderToUpdate.detail.services = this.getServices();

        orderToUpdate.detail.services = orderToUpdate.detail.services.filter(
          s => s.isChecked === true
        );

        orderToUpdate.detail.services.forEach(s => {
          if (s.subServices !== null) {
            s.subServices = s.subServices.filter(
              ss => ss.isChecked || (ss.id.includes('other') && ss.name !== '')
            );
          }
        });

        orderToUpdate.detail.services.forEach(s => delete s.isChecked);

        orderToUpdate.detail.services.forEach(s => {
          if (s.subServices !== null) {
            s.subServices.forEach(ss => {
              delete ss.currencyCode;
              delete ss.fixedPrice;
              delete ss.fixedQuantity;
              delete ss.serviceId;
              delete ss.isChecked;
            });
          }
        });
      }
    },

    assignSparePartsToOrder(orderToUpdate) {
      orderToUpdate.detail.item.spareParts = this.getSpareParts().filter(
        sp => sp.isChecked === true
      );

      orderToUpdate.detail.item.spareParts = orderToUpdate.detail.item.spareParts.map(
        sparePart => {
          return {
            name: sparePart.name,
            measure: sparePart.selectedMeasure,
            unitOfMeasure: sparePart.selectedUnitOfMeasure,
            received: sparePart.received,
            new: sparePart.new,
            quantity: sparePart.quantity,
            parts: sparePart.parts,
            price: sparePart.price === '' ? '' : parseInt(sparePart.price),
            receivedDate: sparePart.receivedDate,
            brandName: sparePart.brandName,
            receivedBy: sparePart.receivedBy,
            receivedById: sparePart.receivedById
          };
        }
      );
    },

    createNewBrand(orderToUpdate) {
      if (orderToUpdate.detail.item.specification.brand) {
        let brands = this.allBrands.map(brand => {
          return brand.name.toLowerCase();
        });
        if (
          !brands.includes(
            orderToUpdate.detail.item.specification.brand.toLowerCase()
          )
        ) {
          this.addBrand({
            name: orderToUpdate.detail.item.specification.brand
          });
        }
      }
    },

    checkAssignedServicesData(orderToUpdate) {
      if (orderToUpdate.assignedServices !== undefined) {
        const { assignedServices } = orderToUpdate;
        let newAssignedServices = assignedServices.slice();
        if (assignedServices.length > 0) {
          assignedServices.forEach(assigned => {
            let foundService;
            if (assigned.subServiceId !== null) {
              foundService = orderToUpdate.detail.services.find(
                service => service.id === assigned.serviceId
              );
              if (foundService !== undefined) {
                foundService = foundService.subServices.find(
                  subService => subService.id === assigned.subServiceId
                );
                if (foundService === undefined) {
                  let foundServiceIndex = newAssignedServices.findIndex(
                    newAssigned =>
                      JSON.stringify(newAssigned) === JSON.stringify(assigned)
                  );
                  newAssignedServices.splice(foundServiceIndex, 1);
                }
              }
            } else {
              foundService = orderToUpdate.detail.services.find(
                service => service.id === assigned.serviceId
              );
              if (foundService === undefined) {
                let foundServiceIndex = newAssignedServices.findIndex(
                  newAssigned =>
                    JSON.stringify(newAssigned) === JSON.stringify(assigned)
                );
                newAssignedServices.splice(foundServiceIndex, 1);
              }
            }
          });
          orderToUpdate.assignedServices = newAssignedServices;
        }
      }
    },

    isNotQuickService() {
      return !this.isQuickService;
    },

    updateNote(newNote) {
      this.order.note = newNote;
    }
  }
};
</script>

<style scoped>
@import './ScopedStyles.css';
</style>
