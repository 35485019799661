import { render, staticRenderFns } from "./MessageToConfirmCreditModal.vue?vue&type=template&id=276bc6f6"
import script from "./MessageToConfirmCreditModal.vue?vue&type=script&lang=js"
export * from "./MessageToConfirmCreditModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./resources/locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fgithub%2Fworkspace%2Fsrc%2Fcomponents%2Freusable-components%2Fmessage-to-confirm-credit-modal%2FMessageToConfirmCreditModal.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports