<i18n src="./resources/locales.json"></i18n>

<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="ma-3"
        dark
        text
        color="primary"
        v-bind="attrs"
        v-on="on"
        @click="open()"
      >
        {{ $t('add') }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t('newSparePart') }}</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="sparePartWizardForm" v-model="isValid">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  class="required-field"
                  v-model="sparePart.name"
                  :label="$t('name')"
                  :rules="[nameRules.required]"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-select
                  :items="allUnitOfMeasures"
                  item-text="name"
                  item-value="name"
                  :label="$t('unitsOfMeasure')"
                  v-model="sparePart.selectedUnitOfMeasure"
                  @change="
                    assignFirstMeasureForUnitOfMeasure(
                      $event,
                      getMeasuresByUnitOfMeasureId(unitOfMeasureId),
                      sparePart
                    )
                  "
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  :items="getMeasuresByUnitOfMeasureId(unitOfMeasureId)"
                  item-text="name"
                  item-value="name"
                  :label="$t('measures')"
                  v-model="sparePart.selectedMeasure"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close">
          {{ $t('cancel') }}
        </v-btn>
        <v-btn color="primary" :disabled="!isValid" @click="save">
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'SparePartWizardDetailModal',

  data: () => ({
    isValid: false,
    sparePart: {},
    dialog: false,
    defaultsparePart: {
      name: '',
      selectedMeasure: '',
      selectedUnitOfMeasure: '',
      received: false,
      new: true,
      quantity: 1,
      isChecked: true,
      selectedMeasures: [],
      receivedDate: null,
      price: 0
    }
  }),

  computed: {
    ...mapGetters('spareParts', [
      'allUnitOfMeasures',
      'getMeasuresByUnitOfMeasureId',
      'allMeasures'
    ]),

    unitOfMeasureId() {
      const foundUnitOfMeasureIndex = this.allUnitOfMeasures.findIndex(
        um => um.name === this.sparePart.selectedUnitOfMeasure
      );
      if (foundUnitOfMeasureIndex == -1) return 0;
      return this.allUnitOfMeasures[foundUnitOfMeasureIndex].id;
    },

    nameRules() {
      return {
        required: value => !!value || this.$t('nameIsRequired')
      };
    }
  },

  created() {
    this.retrieveAllUnitOfMeasures();
    this.retrieveAllMeasures();
  },

  methods: {
    ...mapActions('spareParts', [
      'retrieveAllUnitOfMeasures',
      'retrieveAllMeasures'
    ]),

    open() {
      this.sparePart = Object.assign({}, this.defaultsparePart);
      this.sparePart.selectedUnitOfMeasure = this.allUnitOfMeasures[0].name;
      this.sparePart.selectedMeasure = this.getMeasuresByUnitOfMeasureId(
        this.unitOfMeasureId
      )[0].name;
      this.dialog = true;
    },

    close() {
      this.resetValidations();
      this.isValid = false;
      this.sparePart = Object.assign({}, this.defaultsparePart);
      this.dialog = false;
      this.$nextTick(function() {
        var root = document.getElementsByTagName('html')[0];
        root.setAttribute('class', 'overflow-y-hidden');
      });
    },

    save() {
      this.sparePart.measures = this.getMeasuresByUnitOfMeasureId(
        this.unitOfMeasureId
      ).map(m => m.name);
      this.$emit('confirm-action', this.sparePart);
      this.close();
    },

    assignFirstMeasureForUnitOfMeasure(value, items, sparePart) {
      sparePart.selectedMeasure = items[0].name;
    },

    resetValidations() {
      this.$refs.sparePartWizardForm.resetValidation();
    }
  }
};
</script>
