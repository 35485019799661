import { render, staticRenderFns } from "./RectifierOrderStatusesList.vue?vue&type=template&id=5cb3db78"
import script from "./RectifierOrderStatusesList.vue?vue&type=script&lang=js"
export * from "./RectifierOrderStatusesList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./resources/locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fgithub%2Fworkspace%2Fsrc%2Fviews%2Forder-status%2Frectifier-order-statuses-list%2FRectifierOrderStatusesList.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports