<i18n src="./resources/locales.json"></i18n>

<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-form ref="cylinderForm" v-model="isValid">
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                class="required-field"
                v-model.number="cylinder.number"
                :label="$t('number')"
                type="number"
                :rules="[
                  numberRules.required,
                  numberRules.positive,
                  () => isnumberUsed() || $t('numberAlreadyExists')
                ]"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="primary" :disabled="!isValid" @click="save">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CylinderDetailModal',

  data: () => ({
    isValid: false,
    cylinder: {},
    dialog: false,
    defaultCylinder: {
      id: '-1',
      number: -1,
      name: ''
    },
    originalCylinder: {
      id: '-1',
      number: -1,
      name: ''
    }
  }),

  computed: {
    ...mapGetters('cylinders', ['allCylinders']),

    formTitle() {
      return this.isNewCylinder()
        ? this.$t('newCylinder')
        : this.$t('editCylinder');
    },

    numberRules() {
      return {
        required: value =>
          !!value || value === 0 || this.$t('numberCanNotBeEmpty'),
        positive: value => value >= 0 || this.$t('numberMustBePositive')
      };
    },

    nameRules() {
      return { required: value => !!value || this.$t('nameIsRequired') };
    }
  },

  methods: {
    ...mapActions('cylinders', ['addCylinder', 'updateCylinder']),

    open(cylinderToUpsert) {
      this.cylinder = Object.assign(
        {},
        cylinderToUpsert || this.defaultCylinder
      );
      this.originalCylinder = Object.assign(
        {},
        cylinderToUpsert || this.defaultCylinder
      );

      if (this.isNewCylinder())
        this.cylinder.number =
          this.allCylinders[this.allCylinders.length - 1].number + 1;

      this.dialog = true;
    },

    close() {
      this.resetValidations();
      this.isValid = false;
      this.cylinder = Object.assign({}, this.defaultCylinder);
      this.originalCylinder = Object.assign({}, this.defaultCylinder);
      this.dialog = false;
    },

    async save() {
      this.cylinder = Object.assign({}, this.cylinder);

      if (this.isNewCylinder()) {
        try {
          await this.addCylinder(this.cylinder);
          this.$root.$emit(
            'open-status-message-snackbar',
            Object.assign(
              {},
              {
                message: `${this.$t('theCylinder')} ${
                  this.cylinder.name
                } ${this.$t('wasCreatedSuccessfully')}`,
                messageType: 'success'
              }
            )
          );
        } catch (error) {
          this.$root.$emit(
            'open-status-message-snackbar',
            Object.assign(
              {},
              {
                message: this.$t('errorWhileCreatingTheCylinder'),
                messageType: 'error'
              }
            )
          );
        }
      } else {
        try {
          await this.updateCylinder(this.cylinder);
          this.$root.$emit(
            'open-status-message-snackbar',
            Object.assign(
              {},
              {
                message: this.$t('cylinderInfoWasUpdatedSuccessfully'),
                messageType: 'success'
              }
            )
          );
        } catch (error) {
          this.$root.$emit(
            'open-status-message-snackbar',
            Object.assign(
              {},
              {
                message: this.$t(
                  'errorWhileUpdatingCylinderInfoPleaseTryAgain'
                ),
                messageType: 'error'
              }
            )
          );
        }
      }

      this.close();
    },

    resetValidations() {
      this.$refs.cylinderForm.resetValidation();
    },

    isnumberUsed() {
      if (this.isPosistionExist()) {
        return (
          this.isNotNewCilinder() && this.isNewnumberEqualsAsOriginalnumber()
        );
      } else return true;
    },

    isNewnumberEqualsAsOriginalnumber() {
      return this.cylinder.number === this.originalCylinder.number;
    },

    isPosistionExist() {
      return this.allCylinders.some(c => c.number === this.cylinder.number);
    },

    isNewCylinder() {
      return this.cylinder.id === '-1';
    },

    isNotNewCilinder() {
      return this.cylinder.id !== '-1';
    }
  }
};
</script>
