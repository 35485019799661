'use strict';

const allOrders = state => {
  return state.orders;
};

const getOrdersByOrderStatusId = state => orderStatusId => {
  let orderList = state.orders.filter(
    order => order.orderStatusId === orderStatusId
  );
  if (orderList.length > 0) {
    orderList = orderList.sort(function(a, b) {
      if (a.orderStatusStateList.length === 0) {
        return 1;
      }
      if (b.orderStatusStateList.length === 0) {
        return -1;
      }
      if (
        b.orderStatusStateList.length === 0 &&
        a.orderStatusStateList.length === 0
      ) {
        return 0;
      } else {
        let firstDate =
          a.orderStatusStateList[a.orderStatusStateList.length - 1].dateTime;
        let secondDate =
          b.orderStatusStateList[b.orderStatusStateList.length - 1].dateTime;
        if (firstDate > secondDate) {
          return 1;
        }
        if (firstDate < secondDate) {
          return -1;
        }
        return 0;
      }
    });
  }
  return orderList;
};

const allPaginatedOrders = state => {
  return state.orders;
};

const getCountOrders = state => {
  return state.count;
};

const getTotalCountOrders = state => {
  return state.totalCount;
};

const getNextOrderNumber = state => {
  return state.nextOrderNumber;
};

const getHighestGroupCount = state => {
  return state.highestGroup;
};

const getOrderById = state => {
  return JSON.parse(JSON.stringify(state.singleOrder));
};

const allUnassignedServices = state => {
  return state.unassignedServices.slice();
};

export default {
  allOrders,
  getOrdersByOrderStatusId,
  allPaginatedOrders,
  getCountOrders,
  getTotalCountOrders,
  getHighestGroupCount,
  getNextOrderNumber,
  getOrderById,
  allUnassignedServices
};
