import { render, staticRenderFns } from "./OrderListSearchAndSelect.vue?vue&type=template&id=1ed6ae5a"
import script from "./OrderListSearchAndSelect.vue?vue&type=script&lang=js"
export * from "./OrderListSearchAndSelect.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./resources/locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fgithub%2Fworkspace%2Fsrc%2Fcomponents%2Forder%2Forder-list-search-and-select%2FOrderListSearchAndSelect.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports