'use strict';

import axios from 'axios';
import { CLIENT_API_URL } from '../../../constants/Environment';

const retrieveAllOrderWorkDonesAndDetailsOfOrder = async (
  { commit },
  orderId
) => {
  await axios
    .get(`${CLIENT_API_URL}/order-work-done-and-details/${orderId}`)
    .then(response => {
      commit('setAllOrderWorkDonesAndDetailsOfOrder', response.data);
    });
};

const addOrderWorkDoneAndDetails = async (
  { commit },
  orderWorkDoneAndDetailsToAdd
) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${CLIENT_API_URL}/order-work-done-and-details/modal`,
        orderWorkDoneAndDetailsToAdd
      )
      .then(response => {
        commit('addOrderWorkDonesAndDetailsOfOrder', response.data);
        resolve(response);
      })
      .catch(error => {
        reject(error);
        console.error(
          'There was an error while posting the order work done and details' +
            error
        );
      });
  });
};

const updateOrderWorkDoneAndDetails = async (
  { commit },
  orderWorkDoneAndDetailsToUpdate
) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${CLIENT_API_URL}/order-work-done-and-details/modal?orderId=${orderWorkDoneAndDetailsToUpdate.orderId}`,
        orderWorkDoneAndDetailsToUpdate
      )
      .then(response => {
        commit('setAllOrderWorkDonesAndDetailsOfOrder', response.data);
        resolve(response);
      })
      .catch(error => {
        reject(error);
        console.error(
          'There was an error while updating the order work done and details'
        );
      });
  });
};

export default {
  retrieveAllOrderWorkDonesAndDetailsOfOrder,
  addOrderWorkDoneAndDetails,
  updateOrderWorkDoneAndDetails
};
