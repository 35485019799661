import Vue from 'vue';

Vue.filter('currency', (price, currencyCode) => {
  var formatter = new Intl.NumberFormat('bo-BO', {
    style: 'currency',
    currency: currencyCode
  });

  return formatter
    .formatToParts(price)
    .map(({ type, value }) => {
      switch (type) {
        case 'currency':
          if (currencyCode == 'USD') return '$ ';
          if (currencyCode == 'BOB') return 'Bs. ';
          break;
        default:
          return value;
      }
    })
    .reduce((string, part) => string + part);
});

Vue.filter('leftCurrency', (price, currencyCode) => {
  var formatter = new Intl.NumberFormat('bo-BO', {
    style: 'currency',
    currency: currencyCode
  });

  let array = formatter.formatToParts(price).map(({ type, value }) => {
    switch (type) {
      case 'currency':
        if (currencyCode == 'USD') return '$ ';
        if (currencyCode == 'BOB') return 'Bs. ';
        break;
      default:
        return value;
    }
  });

  const [first] = array;

  if (first === '-') {
    let firstElement = array.shift();
    array.splice(1, 1, firstElement);
  }
  return array.reduce((string, part) => string + part);
});
