'use strict';

const allSpareParts = state => {
  return state.spareParts.slice();
};

const allUnitOfMeasures = state => {
  return state.unitOfMeasures.slice();
};

const getUnitOfMeasureByDefault = state => {
  return Object.assign(
    {},
    state.unitOfMeasures.find(uom => uom.default === true)
  );
};

const allMeasures = state => {
  return state.measures.slice();
};

const getMeasuresByUnitOfMeasureId = state => unitOfMeasureId => {
  const filteredMeasures = state.measures.filter(
    measure => measure.unitOfMeasure === unitOfMeasureId
  );
  const getSortedMeasures = measures => {
    var namedMeasures = measures.filter(measure => isNaN(measure.name));
    namedMeasures = namedMeasures.sort();

    measures = measures.filter(measure => !isNaN(measure.name));
    measures = measures.sort();
    measures = namedMeasures.concat(measures);

    return measures.map(x => x);
  };
  return getSortedMeasures(filteredMeasures).slice();
};

export default {
  allSpareParts,
  allUnitOfMeasures,
  getUnitOfMeasureByDefault,
  allMeasures,
  getMeasuresByUnitOfMeasureId
};
