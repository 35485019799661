<template>
    <div></div>
  </template>
  
  <script>
  import { CLIENT_API_URL } from '../../../constants/Environment';
  import html2pdf from 'html2pdf.js';
  import printJS from 'print-js';
  import axios from 'axios';
  
  export default {
    name: 'PrintQualityAssuranceForm',
  
    created() {
      this.$root.$on('print-quality-assurance-pdf', this.printPDF);
    },
  
    beforeDestroy() {
      this.$root.$off('print-quality-assurance-pdf');
    },
  
    methods: {
      async printPDF(orderId) {
        let objectToPrint = await this._fetchObjectToPrint(
          orderId,
          'quality-assurance-print'
        );
        var opt = {
          html2canvas: { scrollY: 0 },
          filename: objectToPrint.fileName + '.pdf',
          jsPDF: {
            unit: 'in',
            orientation: 'portrait'
          }
        };
  
        html2pdf()
          .set(opt)
          .from(objectToPrint.htmlData)
          .outputPdf()
          .then(function(pdf) {
            printJS({
              printable: btoa(pdf),
              type: 'pdf',
              base64: true
            });
          });
      },
  
      async _fetchObjectToPrint(orderId, url) {
        let objectToPrintData;
        try {
          objectToPrintData = await axios.get(
            `${CLIENT_API_URL}/orders/${orderId}/${url}`
          );
        } catch (error) {
          console.error(
            'There was an error while retrieving the object to print' + error
          );
        }
        return objectToPrintData.data;
      }
    }
  };
  </script>
  