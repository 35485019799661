<i18n src="./resources/locales.json"></i18n>

<template>
  <OrderStatusesList
    :businessOrderStatusTitleList="businessOrderStatusTitleList"
    :businessId="businessId"
  />
</template>

<script>
import OrderStatusesList from '@/components/reusable-components/order-statuses-list/OrderStatusesList.vue';
import { BUSINESS_ID_RECTIFICATION } from '@/constants/ConfigurationConstants';

export default {
  name: 'RectifierOrderStatusesList',

  components: { OrderStatusesList },

  data: () => ({
    businessId: BUSINESS_ID_RECTIFICATION
  }),

  computed: {
    businessOrderStatusTitleList() {
      return this.$t('engineRebuildingOrderStatuses');
    }
  }
};
</script>
