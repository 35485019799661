'use strict';

const allParts = state => {
  return state.parts.slice();
};

const getSubPartsByPartId = state => partId => {
  let subParts = state.parts.find(part => part.id === partId)?.subParts;
  return subParts !== undefined && subParts !== null
    ? subParts.slice()
    : [].slice();
};

export default {
  allParts,
  getSubPartsByPartId
};
