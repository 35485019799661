<i18n src="./resources/locales.json"></i18n>

<template>
  <v-container>
    <h1 class="ml-3 primary--text">{{ $t('workers') }}</h1>
    <v-row
      class="mt-10 ml-16 mb-n12 mr-16"
      no-gutters
      style="flex-wrap: nowrap;"
    >
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="addWorker">
        {{ $t('newWorker') }}
      </v-btn>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="allWorkers"
      sort-by="name"
      class="elevation-1 ma-16"
      hide-default-footer
      disable-pagination
      :loading="getIsWorkerLoading"
      :loading-text="$t('loadingPleaseWait')"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small color="primary" class="mr-2" @click="configAccount(item)">
          mdi-account-cog-outline
        </v-icon>
        <v-icon small color="primary" class="mr-2" @click="assignPart(item)">
          mdi-engine-outline
        </v-icon>
        <v-icon small color="primary" class="mr-2" @click="editWorker(item)">
          mdi-pencil-outline
        </v-icon>
        <!--v-icon small color="primary" @click="deleteWorker(item)">
          mdi-delete
        </v-icon-->
      </template>
      <template v-slot:no-data>
        <p>
          {{ $t('thereAreNoWorkers') }}
        </p>
      </template>
    </v-data-table>

    <DeleteConfirmModal
      ref="deleteWorkerModal"
      @confirm-action="deleteWorkerConfirm"
    />
    <PartsAssigmentListModal
      ref="partsAssigmentListModal"
      @confirm-action="assignPartsConfirm"
    />
    <WorkerAccountConfigModal ref="workerAccountConfigModal" />
    <WorkerDetailModal ref="workerDetail" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DeleteConfirmModal from '@/components/reusable-components/delete-confirm-modal/DeleteConfirmModal.vue';
import PartsAssigmentListModal from '@/components/spare-part/parts-assignment-modal/PartsAssigmentListModal.vue';
import WorkerDetailModal from '@/components/worker/worker-detail-modal/WorkerDetailModal.vue';
import WorkerAccountConfigModal from '@/components/worker/worker-account-config-modal/WorkerAccountConfigModal.vue';

export default {
  name: 'WorkersList',
  components: {
    DeleteConfirmModal,
    PartsAssigmentListModal,
    WorkerDetailModal,
    WorkerAccountConfigModal
  },

  computed: {
    headers() {
      return [
        { text: this.$t('name'), value: 'name', align: 'center' },
        {
          text: this.$t('actions'),
          value: 'actions',
          align: 'center',
          sortable: false
        }
      ];
    },

    ...mapGetters('workers', ['allWorkers']),
    ...mapGetters('loading', ['getIsWorkerLoading'])
  },

  methods: {
    ...mapActions('workers', ['removeWorker', 'updateWorker']),

    addWorker() {
      this.$refs.workerDetail.open();
    },

    editWorker(workerToUpdate) {
      this.$refs.workerDetail.open(Object.assign({}, workerToUpdate));
    },

    deleteWorker(workerToDelete) {
      this.$refs.deleteWorkerModal.open(
        Object.assign(
          { message: this.$t('sureToDeleteThisWorker') },
          workerToDelete
        )
      );
    },

    deleteWorkerConfirm(workerToDeleteId) {
      this.removeWorker(workerToDeleteId);
    },

    assignPart(worker) {
      let workerToAssignPart = Object.assign({}, worker);
      this.$refs.partsAssigmentListModal.open(
        this._convertStringPartsWorkerToArray(workerToAssignPart)
      );
    },

    configAccount(worker) {
      this.$refs.workerAccountConfigModal.open(Object.assign({}, worker));
    },

    assignPartsConfirm(workerToAssignPart) {
      this.updateWorker(
        this._convertArrayPartsWorkerToString(workerToAssignPart)
      );
    },

    _convertStringPartsWorkerToArray(workerToAssignPart) {
      workerToAssignPart.parts = JSON.parse(workerToAssignPart.relatedParts);
      delete workerToAssignPart.relatedParts;
      if (workerToAssignPart.parts !== null) {
        workerToAssignPart.parts = workerToAssignPart.parts.map(p => {
          return { id: p };
        });
      } else workerToAssignPart.parts = [];
      return workerToAssignPart;
    },

    _convertArrayPartsWorkerToString(workerToAssignPart) {
      workerToAssignPart.parts = workerToAssignPart.parts.map(p => {
        return p.id;
      });
      workerToAssignPart.relatedParts = JSON.stringify(
        workerToAssignPart.parts
      );
      delete workerToAssignPart.parts;
      return workerToAssignPart;
    }
  }
};
</script>
