'use strict';

const getLoading = state => {
  return state.loading;
};

const getIsClientLoading = state => {
  return state.isClientLoading;
};

const getIsPartLoading = state => {
  return state.isPartLoading;
};

const getIsCylinderLoading = state => {
  return state.isCylinderLoading;
};

const getIsCylinderArrangementLoading = state => {
  return state.isCylinderArrangementLoading;
};

const getIsUnitsOfMeasureLoading = state => {
  return state.isUnitsOfMeasureLoading;
};

const getIsMeasureLoading = state => {
  return state.isMeasureLoading;
};

const getIsSparePartLoading = state => {
  return state.isSparePartLoading;
};

const getIsServiceLoading = state => {
  return state.isServiceLoading;
};

const getIsOrderStatusLoading = state => {
  return state.isOrderStatusLoading;
};

const getIsOrderLoading = state => {
  return state.isOrderLoading;
};

const getIsWorkerLoading = state => {
  return state.isWorkerLoading;
};

const getIsBrandReportLoading = state => {
  return state.isBrandReportLoading;
};

const getIsClientReportLoading = state => {
  return state.isClientReportLoading;
};

const getIsEngineSeriesReportLoading = state => {
  return state.isEngineSeriesReportLoading;
};

const getIsFinancialReportLoading = state => {
  return state.isFinancialReportLoading;
};

const getIsServicesReportLoading = state => {
  return state.isServicesReportLoading;
};

const getIsSparePartReportLoading = state => {
  return state.isSparePartReportLoading;
};

const getIsOrdersReportByEngineBrandNumberCylinders = state => {
  return state.isOrdersReportByEngineBrandNumberCylinders;
};

const getIsWorkersReportLoading = state => {
  return state.isWorkersReportLoading;
};

const getIsCalendarOrderLoading = state => {
  return state.isCalendarOrderLoading;
};

const getIsOrdersReportBySparePartsLoading = state => {
  return state.isOrdersReportBySparePartsLoading;
};

const getIsDebtorsReportLoading = state => {
  return state.isDebtorsReportLoading;
};

const getIsUnpickedOrdersReportLoading = state => {
  return state.isUnpickedOrdersReportLoading;
};

export default {
  getLoading,
  getIsClientLoading,
  getIsPartLoading,
  getIsCylinderLoading,
  getIsCylinderArrangementLoading,
  getIsUnitsOfMeasureLoading,
  getIsMeasureLoading,
  getIsSparePartLoading,
  getIsServiceLoading,
  getIsOrderStatusLoading,
  getIsOrderLoading,
  getIsWorkerLoading,
  getIsBrandReportLoading,
  getIsClientReportLoading,
  getIsEngineSeriesReportLoading,
  getIsFinancialReportLoading,
  getIsServicesReportLoading,
  getIsSparePartReportLoading,
  getIsOrdersReportByEngineBrandNumberCylinders,
  getIsWorkersReportLoading,
  getIsCalendarOrderLoading,
  getIsOrdersReportBySparePartsLoading,
  getIsDebtorsReportLoading,
  getIsUnpickedOrdersReportLoading
};
