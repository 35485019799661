'use strict';

import axios from 'axios';
import { ITEM_API_URL } from '../../../constants/Environment';

const retrieveAllCylinders = async ({ dispatch, commit }) => {
  dispatch('loading/setIsCylinderLoading', true, { root: true });
  try {
    await axios.get(`${ITEM_API_URL}/cylinders`).then(response => {
      commit('setAllCylinders', response.data);
    });
    dispatch('loading/setIsCylinderLoading', false, { root: true });
  } catch (error) {
    console.error('There was an error while retrieving cylinders' + error);
  }
};

const retrieveAllCylinderArrangements = async ({ dispatch, commit }) => {
  dispatch('loading/setIsCylinderArrangementLoading', true, { root: true });
  try {
    await axios.get(`${ITEM_API_URL}/cylinder-arrangements`).then(response => {
      commit('setAllCylinderArrangements', response.data);
    });
    dispatch('loading/setIsCylinderArrangementLoading', false, { root: true });
  } catch (error) {
    console.error(
      'There was an error while retrieving the cylinder arrangements' + error
    );
  }
};

const addCylinder = async ({ commit }, cylinderToAdd) => {
  delete cylinderToAdd.id;
  return new Promise((resolve, reject) => {
    axios
      .post(`${ITEM_API_URL}/cylinders`, cylinderToAdd)
      .then(response => {
        commit('addCylinder', response.data);
        resolve(response);
      })
      .catch(error => {
        reject(error);
        console.error('There was an error while posting the cylinder' + error);
      });
  });
};

const addCylinderArrangement = async ({ commit }, cylinderArrangementToAdd) => {
  delete cylinderArrangementToAdd.id;
  return new Promise((resolve, reject) => {
    axios
      .post(`${ITEM_API_URL}/cylinder-arrangements`, cylinderArrangementToAdd)
      .then(response => {
        commit('addCylinderArrangement', response.data);
        resolve(response);
      })
      .catch(error => {
        reject(error);
        console.error(
          'There was an error while posting the cylinder arrangement' + error
        );
      });
  });
};

const updateCylinder = async ({ commit }, cylinderToUpdate) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${ITEM_API_URL}/cylinders?id=${cylinderToUpdate.id}`,
        cylinderToUpdate
      )
      .then(response => {
        commit('updateCylinder', response.data);
        resolve(resolve);
      })
      .catch(error => {
        reject(error);
        console.error('There was an error while updating the cylinder' + error);
      });
  });
};

const updateCylinderArrangement = async (
  { commit },
  cylinderArrangementToUpdate
) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${ITEM_API_URL}/cylinder-arrangements?id=${cylinderArrangementToUpdate.id}`,
        cylinderArrangementToUpdate
      )
      .then(response => {
        commit('updateCylinderArrangement', response.data);
        resolve(resolve);
      })
      .catch(error => {
        reject(error);
        console.error('There was an error while updating the cylinder' + error);
      });
  });
};

const removeCylinder = ({ commit }, cylinderId) => {
  axios.delete(`${ITEM_API_URL}/cylinders?id=${cylinderId}`).then(response => {
    commit('removeCylinder', response.data.id);
  });
};

const removeCylinderArrangement = ({ commit }, cylinderArrangementId) => {
  axios
    .delete(`${ITEM_API_URL}/cylinder-arrangements?id=${cylinderArrangementId}`)
    .then(response => {
      commit('removeCylinderArrangement', response.data.id);
    });
};

export default {
  retrieveAllCylinders,
  retrieveAllCylinderArrangements,
  addCylinder,
  addCylinderArrangement,
  updateCylinder,
  updateCylinderArrangement,
  removeCylinder,
  removeCylinderArrangement
};
