'use strict';

const setAllWorkDones = (state, workDones) => {
  state.workDones = workDones;
};

const addWorkDone = (state, workDoneToAdd) => {
  state.workDones.push(workDoneToAdd);
};

const updateWorkDone = (state, workDoneToUpdate) => {
  const foundWorkDoneIndex = state.workDones.findIndex(
    wd => wd.id === workDoneToUpdate.id
  );
  if (foundWorkDoneIndex >= 0) {
    state.workDones.splice(foundWorkDoneIndex, 1, workDoneToUpdate);
  }
};

const removeWorkDone = (state, workDoneToRemove) => {
  state.workDones.filter(wd => wd.id !== workDoneToRemove.id);
};

export default {
  setAllWorkDones,
  addWorkDone,
  updateWorkDone,
  removeWorkDone
};
