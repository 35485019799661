<i18n src="./resources/locales.json"></i18n>

<template>
  <v-container>
    <h1 class="ml-3 primary--text">{{ $t('serviceReport') }}</h1>
    <v-row
      class="tool-bar mt-10 ml-16 mb-n12 mr-16"
      no-gutters
      style="flex-wrap: nowrap;"
    >
      <v-col cols="2">
        <div class="ml-5 mb-7">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="dates"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                background-color="primaryVariant2"
                :value="dateRangeText"
                :label="$t('dates')"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                clearable
                @click:clear="clearPickedDate"
                hide-details
                class="search-bar"
                outlined
                dense
                flat
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dates"
              range
              scrollable
              show-adjacent-months
              no-title
              :max="currentDate"
              :locale="$i18n.locale"
              color="primary"
              @change="verifyRangeChangesDate"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">
                {{ $t('cancel') }}
              </v-btn>
              <v-btn color="primary" @click="savePickedDate">
                {{ $t('ok') }}
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>
        <v-select
          background-color="primaryVariant2"
          :items="servicesToSelect"
          item-text="name"
          return-object
          :label="$t('service')"
          v-model="selectedService"
          class="ml-5"
          @change="getServiceReport"
          outlined
          dense
          flat
        ></v-select>
      </v-col>
      <v-col cols="10" v-if="isChartDataLengthMoreThanCero()">
        <PieChart :chartData="chartData" />
      </v-col>
      <v-col cols="10" v-else>
        {{ $t('noDataMessage') }}
      </v-col>
    </v-row>
    <p class="mt-10 ml-16 mb-n12 mr-16" v-if="isReportsMoreThatTen()">
      {{ $t('remainingService') }}
    </p>
    <v-data-table
      :headers="headers"
      :items="getTableData()"
      class="elevation-1 ma-16"
      :items-per-page="5"
      v-if="isReportsMoreThatTen()"
    >
    </v-data-table>
    <LoadingMask :isLoading="getIsServicesReportLoading" />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PieChart from '../../../components/reusable-components/chart/pie-chart/PieChart.vue';
import LoadingMask from '@/components/reusable-components/loading-mask/LoadingMask.vue';
import { ALL_OPTIONS } from '@/constants/ConfigurationConstants';
import moment from 'moment';

export default {
  name: 'ServicesReport',

  data() {
    return {
      selectedService: null,
      dates: [],
      menu: false,
      currentDate: moment().format('YYYY-MM-DD'),
      availableDates: []
    };
  },

  components: {
    PieChart,
    LoadingMask
  },

  computed: {
    ...mapGetters('reports', ['allServiceReport']),
    ...mapGetters('services', ['allServices']),
    ...mapGetters('loading', ['getIsServicesReportLoading']),

    headers() {
      return this.isAllOptionsSelected()
        ? [
            {
              text: this.$t('subService'),
              value: 'service.name',
              align: 'center',
              width: '50%'
            },
            {
              text: this.$t('frequencyThatIsPerformed'),
              value: 'numberOfOrders',
              align: 'center',
              width: '50%'
            }
          ]
        : [
            {
              text: this.$t('subService'),
              value: 'subService.name',
              align: 'center',
              width: '50%'
            },
            {
              text: this.$t('frequencyThatIsPerformed'),
              value: 'numberOfOrders',
              align: 'center',
              width: '50%'
            }
          ];
    },

    chartData() {
      let chartColors = [
        '#00CC00',
        '#CC0000',
        '#1F618D',
        '#F1C40F',
        '#27AE60',
        '#884EA0',
        '#D35400',
        '#66FFCC',
        '#641E16',
        '#6600FF',
        '#AEB6BF'
      ];
      let chartReports = this.getChartData();
      let labels = [];
      if (this.isAllOptionsSelected()) {
        labels = chartReports.map(({ service }) => {
          if (service) return service.name;
        });
      } else {
        labels = chartReports.map(({ subService }) => {
          if (subService) return subService.name;
        });
      }
      let isInvalidLabels = labels.some(l => l === undefined);
      if (isInvalidLabels) {
        labels = [];
      }

      let data = chartReports.map(({ numberOfOrders }) => numberOfOrders);

      return {
        labels: labels,
        datasets: [
          {
            label: this.$t('serviceReport'),
            data: data,
            backgroundColor: chartColors,
            hoverBackgroundColor: chartColors
          }
        ]
      };
    },

    servicesToSelect() {
      return [this.allServicesOption].concat(
        this.getAlphabeticallySortedAllServicesByName()
      );
    },

    allServicesOption() {
      return { id: ALL_OPTIONS, name: this.$t('allOptions') };
    },

    dateRangeText() {
      return this.dates
        .slice()
        .map(d => this.convertDate(d))
        .join(' - ');
    }
  },

  created() {
    this.retrieveAllServices();
    this.selectedService = {
      id: ALL_OPTIONS,
      name: this.$t('allOptions')
    };
    this.getServiceReport();
  },

  methods: {
    ...mapActions('reports', [
      'retrieveAllServiceReports',
      'retrieveAllSubServiceReports'
    ]),
    ...mapActions('services', ['retrieveAllServices']),

    getChartData() {
      let chartData = this.allServiceReport;
      let sumOthers = 0;

      if (this.isReportsMoreThatTen()) {
        chartData = this.allServiceReport.slice(0, 10);
        sumOthers = this.getSumOthers();

        if (this.isAllOptionsSelected())
          chartData.push({
            service: { name: this.$t('others') },
            numberOfOrders: sumOthers
          });
        else
          chartData.push({
            subService: { name: this.$t('others') },
            numberOfOrders: sumOthers
          });
      }
      return chartData;
    },

    getTableData() {
      let tableData = [];
      if (this.isReportsMoreThatTen())
        tableData = this.allServiceReport.slice(
          10,
          this.allServiceReport.length
        );
      return tableData;
    },

    isReportsMoreThatTen() {
      return this.allServiceReport.length > 10;
    },

    getSumOthers() {
      return this.getTableData().reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue.numberOfOrders,
        0
      );
    },

    isChartDataLengthMoreThanCero() {
      return this.getChartData().length > 0;
    },

    isAllOptionsSelected() {
      const { id } = this.selectedService;
      return id === ALL_OPTIONS;
    },

    savePickedDate() {
      this.$refs.menu.save(this.dates);
      this.getServiceReport();
    },

    clearPickedDate() {
      this.dates = [];
      this.savePickedDate();
    },

    convertDate(date) {
      let convertedDate = moment(date).format('YYYY-MM-DD');
      const [year, month, day] = convertedDate.split('-');
      return `${day}/${month}/${year}`;
    },

    verifyRangeChangesDate() {
      const [first, second] = this.dates;
      if (first !== undefined && second !== undefined) {
        if (moment(first).isSame(second)) this.dates = [first];
        if (moment(first).isAfter(second)) this.dates = [second, first];
      }
    },

    getServiceReport() {
      const [first, second] = this.dates;

      let parameters = {};
      if (first) {
        parameters.fromDate = moment(first).format();
        parameters.toDate = this.setDateUntilLastMinute(first);
      }
      if (second) parameters.toDate = this.setDateUntilLastMinute(second);

      if (this.isAllOptionsSelected())
        this.retrieveAllServiceReports(parameters);
      else {
        const { name } = this.selectedService;
        parameters.serviceName = name;
        this.retrieveAllSubServiceReports(parameters);
      }
    },

    getAlphabeticallySortedAllServicesByName() {
      return this.allServices.sort((s1, s2) => (s1.name > s2.name ? 1 : -1));
    },

    setDateUntilLastMinute(date) {
      return moment(date)
        .set({ hour: 23, minute: 59, second: 59 })
        .format();
    }
  }
};
</script>
<style scoped>
@import './ScopedStyles.css';
</style>
