<i18n src="./resources/locales.json"></i18n>

<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" scrollable max-width="300px" persistent>
      <v-card>
        <v-card-title>{{ $t('orderStatuses') }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 280px">
          <v-list>
            <v-list-item>
              <v-radio-group
                v-model="selectedOrderStatusId"
                @change="enableSaveOption()"
              >
                <v-radio
                  v-for="orderStatus in allOrderStatuses"
                  :key="orderStatus.id"
                  :label="orderStatus.name"
                  :value="orderStatus.id"
                ></v-radio>
              </v-radio-group>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="primary" v-if="showSaveOption" @click="save">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <OrderDeliverOptionModal ref="deliverOptionModal" @confirm-action="close" />
    <OrderStatusChangeConfirmationModal
      ref="confirmationChangeModal"
      @confirm-action="close"
    />
  </v-row>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import { BUSINESS_ID_RECTIFICATION } from '@/constants/ConfigurationConstants';
import OrderDeliverOptionModal from '@/components/order/order-deliver-option-modal/OrderDeliverOptionModal.vue';
import OrderStatusChangeConfirmationModal from '@/components/order/order-status-change-confirmation-modal/OrderStatusChangeConfirmationModal.vue';

export default {
  name: 'OrderStatusSelectionModal',

  components: { OrderDeliverOptionModal, OrderStatusChangeConfirmationModal },

  data() {
    return {
      dialog: false,
      showSaveOption: false,
      selectedOrderStatusId: '',
      originalSelectedOrderStatusId: '',
      order: {},
      defaultOrder: {},
      businessId: BUSINESS_ID_RECTIFICATION
    };
  },

  computed: {
    ...mapGetters('orderStatuses', [
      'allOrderStatuses',
      'getFinalOrderStatus',
      'getInitialOrderStatus'
    ])
  },

  created() {
    this.$root.$on('open-order-status-selection-modal', this.open);
    this.retrieveAllBusinessAndSpecialOrderStatuses(this.businessId);
    this.retrieveFinalOrderStatus();
    this.retrieveInitialOrderStatus();
  },

  beforeDestroy() {
    this.$root.$off('open-order-status-selection-modal');
  },

  methods: {
    ...mapActions('orderStatuses', [
      'retrieveAllBusinessAndSpecialOrderStatuses',
      'retrieveFinalOrderStatus',
      'retrieveInitialOrderStatus'
    ]),
    ...mapActions('orders', ['updateOrderStatusOfOrder']),

    open(orderToUpsert) {
      this.order = Object.assign({}, orderToUpsert);
      this.selectedOrderStatusId = this.order.orderStatusId;
      this.originalSelectedOrderStatusId = this.selectedOrderStatusId;
      this.dialog = true;
    },

    close() {
      this.selectedOrderStatusId = '';
      this.originalSelectedOrderStatusId = '';
      this.showSaveOption = false;
      this.order = Object.assign({}, this.defaultOrder);
      this.dialog = false;
    },

    async save() {
      if (
        this.order.orderStatusId === this.getInitialOrderStatus.id &&
        (!this.verifyAllAssignedServices() || !this.isAllSparePartsRecieved())
      ) {
        this.order.orderStatusId = this.selectedOrderStatusId;
        let confirmationOptionTitle = this.$t(
          'youAreAboutToChangeTheStatusOfTheOrderWithoutAssigningAllTheJobsAndWithoutRecievingAllSpareParts'
        );
        let isFromBoard = false;
        this.$refs.confirmationChangeModal.open(
          confirmationOptionTitle,
          isFromBoard,
          Object.assign({}, this.order)
        );
      } else {
        this.order.orderStatusId = this.selectedOrderStatusId;
        if (this.order.orderStatusId === this.getFinalOrderStatus.id) {
          if (this.isAllServicesFinished()) {
            let isFromBoard = false;
            this.$refs.deliverOptionModal.open(
              isFromBoard,
              false,
              Object.assign({}, this.order)
            );
          } else {
            let confirmationOptionTitle = this.$t(
              'youAreAboutToChangeToFinalStatusOfTheOrderWithoutFinishingAllServices'
            );
            let isFromBoard = false;
            this.$refs.confirmationChangeModal.open(
              confirmationOptionTitle,
              isFromBoard,
              Object.assign({}, this.order)
            );
          }
        } else {
          try {
            await this.updateOrderStatusOfOrder({
              orderToUpdate: Object.assign({}, this.order),
              date: moment().format('YYYY-MM-DD[T]HH:mm:ss.SSSZ')
            });
            this.$root.$emit(
              'open-status-message-snackbar',
              Object.assign(
                {},
                {
                  message: this.$t('orderStatusUpdatedSuccessfully'),
                  messageType: 'success'
                }
              )
            );
          } catch (error) {
            this.$root.$emit(
              'open-status-message-snackbar',
              Object.assign(
                {},
                {
                  message: this.$t('errorWhileUpdatingOrderStatus'),
                  messageType: 'error'
                }
              )
            );
          }
          this.close();
        }
      }
    },

    enableSaveOption() {
      if (this.selectedOrderStatusId !== this.originalSelectedOrderStatusId) {
        this.showSaveOption = true;
      } else {
        this.showSaveOption = false;
      }
    },

    verifyAllAssignedServices() {
      let totalServices = 0;
      this.order.detail.services.forEach(service => {
        if (service.subServices.length === 0) {
          totalServices += 1;
        } else {
          totalServices += service.subServices.length;
        }
      });
      return totalServices === this.order.assignedServices.length;
    },

    isAllSparePartsRecieved() {
      return this.order.detail.item.spareParts.every(
        sp => sp.received === true
      );
    },

    isAllServicesFinished() {
      const { detail } = this.order;
      let totalServicesCounter = 0;
      let totalFinishedServicesCounter = 0;
      if (detail && detail.services) {
        detail.services.forEach(service => {
          if (service.subServices.length === 0) {
            if (service.isFinished !== undefined) {
              if (service.isFinished === true) {
                totalFinishedServicesCounter++;
              }
            }
            totalServicesCounter++;
          } else {
            service.subServices.forEach(subService => {
              if (subService.isFinished !== undefined) {
                if (subService.isFinished === true) {
                  totalFinishedServicesCounter++;
                }
              }
              totalServicesCounter++;
            });
          }
        });

        return totalServicesCounter === totalFinishedServicesCounter;
      }
    }
  }
};
</script>
