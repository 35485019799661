import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VueAxios from 'vue-axios';

import clients from './modules/clients';
import orders from './modules/orders';
import orderStatuses from './modules/orderStatuses';
import services from './modules/services';
import parts from './modules/parts';
import spareParts from './modules/spareParts';
import workers from './modules/workers';
import cylinders from './modules/cylinders';
import brands from './modules/brands';
import userStorage from './modules/userStorage';
import roles from './modules/roles';
import reports from './modules/reports';
import languageStorage from './modules/languageStorage';
import loading from './modules/loading';
import calendar from './modules/calendar';
import workDone from './modules/workDone';
import workDoneDetail from './modules/workDoneDetail';
import orderSpareParts from './modules/orderSpareParts';
import orderWorkDonesAndDetails from './modules/orderWorkDonesAndDetails';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

const store = {
  modules: {
    clients,
    orders,
    orderStatuses,
    services,
    parts,
    spareParts,
    workers,
    cylinders,
    brands,
    userStorage,
    roles,
    reports,
    languageStorage,
    loading,
    calendar,
    workDone,
    workDoneDetail,
    orderSpareParts,
    orderWorkDonesAndDetails
  }
};

export default new Vuex.Store(store);
