<i18n src="./resources/locales.json"></i18n>

<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <template v-slot:default="{ open }">
        <span v-if="open" class="opened-section-name-color">
          {{ sectionName }}
        </span>
        <span v-else>
          {{ sectionName }}
        </span>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content
      :style="getSectionStyle()"
      class="align-self-start overflow-y-auto section-height-width list-content-background-color content-padding"
      v-scroll.self="() => {}"
      eager
    >
      <v-container>
        <v-row>
          <v-sheet class="width-section list-content-background-color">
            <v-sheet
              v-for="part in partsToBeSelected"
              :key="part.id"
              class="list-content-background-color"
            >
              <v-checkbox
                v-model="part.isChecked"
                :label="part.name"
                hide-details
              />
              <v-container v-if="part.isChecked" class="pl-8">
                <v-sheet class="list-content-background-color">
                  <v-row>
                    <v-col
                      cols="6"
                      class="py-0"
                      v-for="subPart in part.subParts"
                      :key="subPart.id"
                    >
                      <v-checkbox
                        v-model="subPart.isChecked"
                        :label="subPart.name"
                        v-if="subPart.id !== ''"
                        hide-details
                      >
                      </v-checkbox>
                    </v-col>
                  </v-row>
                  <br />
                  <v-row>
                    <v-col cols="4">
                      <v-text-field
                        v-model="part.subParts.find(s => s.id === '').name"
                        :label="$t('other')"
                        v-if="part.subParts.find(s => s.id === '').id === ''"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-container>
            </v-sheet>
          </v-sheet>
        </v-row>
      </v-container>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'PartsSection',

  props: ['sectionName'],

  data() {
    return {
      partsToBeSelected: []
    };
  },

  created() {
    this.retrieveAllParts();
  },

  watch: {
    allParts: {
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.setAllPartsToPartsToBeSelected();
        }
      },
      deep: true
    }
  },

  computed: {
    ...mapGetters('parts', ['allParts'])
  },

  methods: {
    ...mapActions('parts', ['retrieveAllParts']),

    setAllPartsToPartsToBeSelected() {
      this.partsToBeSelected = this.allParts.map(p => ({
        ...p,
        subParts: p.subParts.map(sp => ({ ...sp, isChecked: false })),
        isChecked: false
      }));
      this.partsToBeSelected.forEach(p => ({
        ...p,
        subParts: p.subParts.push({ id: '', name: '', isChecked: false })
      }));
    },

    sendPartsData() {
      return this.partsToBeSelected.slice();
    },

    cleanSection() {
      this.setAllPartsToPartsToBeSelected();
    },

    setPartsDataToEdit(parts) {
      this.partsToBeSelected.forEach(ps => {
        if (parts.some(p => p.id === ps.id)) {
          ps.isChecked = true;
          if (ps.subParts !== null) {
            var otherSubPart;
            ps.subParts.forEach(pssp => {
              parts.forEach(p => {
                if (
                  p.subParts.some(sp => sp.id === pssp.id && pssp.id !== '')
                ) {
                  pssp.isChecked = true;
                }
              });
            });

            parts.forEach(p => {
              otherSubPart = p.subParts.find(sp => sp.id === '');
              if (otherSubPart && p.name === ps.name) {
                ps.subParts.splice(ps.subParts.length - 1, 1, {
                  ...otherSubPart,
                  isChecked: false
                });
              }
            });
          }
        }
      }, this.partsToBeSelected);
    },
    getSectionStyle() {
      return {
        '--window-height': window.innerHeight + 'px',
        '--window-width': window.innerWidth + 'px',
        '--sections-height': 300 + 'px'
      };
    }
  }
};
</script>
