'use strict';

const setAllBrandsReport = (state, brandsReport) => {
  state.brandsReport = brandsReport;
};

const setAllClientsReport = (state, clientsReport) => {
  state.clientsReport = clientsReport;
};

const setAllFinantialReport = (state, finantialReport) => {
  state.finantialReport = finantialReport;
};

const setPendingBillsReport = (state, pendingBillsReport) => {
  state.pendingBillsReport = pendingBillsReport;
};

const setAllServiceReport = (state, serviceReport) => {
  state.serviceReport = serviceReport;
};

const setAllSubServiceReport = (state, subServiceReport) => {
  state.subServiceReport = subServiceReport;
};

const setAllSparePartsReport = (state, sparePartsReport) => {
  state.sparePartsReport = sparePartsReport;
};

const setAllOrdersReportByEngineBrandNumberCylinders = (
  state,
  ordersReport
) => {
  state.ordersReport = ordersReport;
};

const setAllSparePartsTrendReport = (state, sparePartTrendReport) => {
  state.sparePartTrendReport = sparePartTrendReport;
};

const setAllWorkersReport = (state, workersReport) => {
  state.workersReport = workersReport.map(w => ({
    worker: w.worker.name,
    servicesFinishedOnTimeRatio: w.servicesFinishedOnTimeRatio * 100,
    moneyGenerated: w.moneyGenerated,
    position: w.position
  }));
};

const setAllDebtorsReport = (state, debtorsReport) => {
  state.debtorsReport = debtorsReport;
};

const setAllUnpickedOrdersReport = (state, unpickedOrdersReport) => {
  state.unpickedOrdersReport = unpickedOrdersReport;
};

export default {
  setAllBrandsReport,
  setAllClientsReport,
  setAllFinantialReport,
  setPendingBillsReport,
  setAllSparePartsReport,
  setAllSparePartsTrendReport,
  setAllServiceReport,
  setAllOrdersReportByEngineBrandNumberCylinders,
  setAllWorkersReport,
  setAllSubServiceReport,
  setAllDebtorsReport,
  setAllUnpickedOrdersReport
};
