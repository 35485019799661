'use strict';

import axios from 'axios';
import { CLIENT_API_URL } from '../../../constants/Environment';

const retrieveAllWorkDoneDetails = async ({ commit }) => {
  try {
    await axios.get(`${CLIENT_API_URL}/work-done-detail`).then(response => {
      commit('setAllWorkDoneDetails', response.data);
    });
  } catch (error) {
    console.error(
      'There was an error while retrieving the work done details' + error
    );
  }
};

const addWorkDoneDetail = async ({ commit }, WorkDoneDetail) => {
  delete WorkDoneDetail.id;
  return new Promise((resolve, reject) => {
    axios
      .post(`${CLIENT_API_URL}/work-done-detail`, WorkDoneDetail)
      .then(response => {
        commit('addWorkDoneDetail', response.data);
        resolve(response);
      })
      .catch(error => {
        reject(error);
        console.error(
          'There was an error while posting the work done detail' + error
        );
      });
  });
};

const updateWorkDoneDetail = async ({ commit }, WorkDoneDetailToUpdate) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${CLIENT_API_URL}/work-done-detail/${WorkDoneDetailToUpdate.id}`,
        WorkDoneDetailToUpdate
      )
      .then(response => {
        commit('updateWorkDoneDetail', response.data);
        resolve(response);
      })
      .catch(error => {
        reject(error);
        console.error(
          'There was an error while updating the work done detail' + error
        );
      });
  });
};

const removeWorkDoneDetail = ({ commit }, WorkDoneDetailToDeleteId) => {
  axios
    .delete(`${CLIENT_API_URL}/work-done-detail/${WorkDoneDetailToDeleteId}`)
    .then(response => {
      commit('removeWorkDoneDetail', response.data);
    });
};

export default {
  retrieveAllWorkDoneDetails,
  addWorkDoneDetail,
  updateWorkDoneDetail,
  removeWorkDoneDetail
};
