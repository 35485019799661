<i18n src="./resources/locales.json"></i18n>

<template>
  <v-dialog persistent v-model="dialog" max-width="500px">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{ $t('confirmIdentity') }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form>
          <v-text-field
            v-model="code"
            :label="$t('code')"
            :type="getTypeCode()"
            :append-icon="getAppendIconForCode()"
            @click:append="showCode = !showCode"
            prepend-icon="mdi-lock"
            :error="error"
            @keyup.enter="consult"
          />
          <v-text-field
            v-model="pin"
            :label="$t('pin')"
            :type="getTypePin()"
            :append-icon="getAppendIconForPin()"
            @click:append="showPin = !showPin"
            prepend-icon="mdi-lock"
            :error="error"
            @keyup.enter="consult"
          />
          <p v-if="this.error === true">
            <span class="default-alert">
              {{ $t('verificationError') }}
            </span>
          </p>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="primary--text" variant="text" @click="close">{{
          $t('cancel')
        }}</v-btn>
        <v-btn
          color="primary"
          @keyup.enter="consult"
          @click="consult"
          :loading="isLoading()"
          :disabled="isLoading() || isNotValid()"
          >{{ $t('verify') }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'VerificationModal',

  data() {
    return {
      code: '',
      pin: '',
      dialog: false,
      showCode: false,
      showPin: false,
      error: false
    };
  },

  computed: {
    ...mapGetters('userStorage', ['getQuickValidationResponse'])
  },

  methods: {
    ...mapActions('loading', ['setLoadingOnFalse', 'setLoadingOnTrue']),
    ...mapActions('userStorage', ['quickValidation']),

    open() {
      this.dialog = true;
    },

    moveOrder() {
      this.close();
      this.$emit('confirm-move-order');
    },

    maintainOrder() {
      this.close();
      this.$emit('maintain-order');
    },

    close() {
      (this.code = ''), (this.pin = ''), (this.error = false);
      this.dialog = false;
    },

    validate() {
      this.$refs.form.validate();
    },

    async consult() {
      this.setLoadingOnTrue();
      let code = this.code;
      let pin = this.pin;
      await this.quickValidation({ code, pin });
      if (this.getQuickValidationResponse.response) {
        this.setLoadingOnFalse();
        this.$emit('confirm-action');
        this.close();
      } else {
        this.error = true;
        this.setLoadingOnFalse();
      }
    },

    getTypeCode() {
      return this.showCode ? 'text' : 'password';
    },

    getTypePin() {
      return this.showPin ? 'text' : 'password';
    },

    getAppendIconForCode() {
      return this.showCode ? 'mdi-eye' : 'mdi-eye-off';
    },

    getAppendIconForPin() {
      return this.showPin ? 'mdi-eye' : 'mdi-eye-off';
    },

    isThereNoOrdersForUser() {
      let answer = false;
      if (this.getOrders.length === 0) {
        answer = true;
      }
      return answer;
    },

    isUserStorageRoleIdCorrect(userStorageRoleId) {
      return userStorageRoleId !== '' && userStorageRoleId !== null;
    },

    isUserStorageRoleIdIncorrect() {
      return this.userStorageRoleId === null;
    },

    isLoading() {
      return this.getLoading;
    },

    isNotValid() {}
  }
};
</script>
