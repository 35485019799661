'use strict';

import axios from 'axios';
import { USER_API_URL } from '../../../constants/Environment';

const retrieveAllRoles = ({ commit }) => {
  axios.get(`${USER_API_URL}/roles`).then(response => {
    window.localStorage.setItem('roles', JSON.stringify(response.data));
    commit('setAllRoles', response.data);
  });
};

export default {
  retrieveAllRoles
};
