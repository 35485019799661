<i18n src="./resources/locales.json"></i18n>

<template>
  <Board :boardTitle="boardTitle" :businessId="businessId" />
</template>

<script>
import Board from '@/components/reusable-components/board/Board.vue';
import { BUSINESS_ID_RECTIFICATION } from '@/constants/ConfigurationConstants';

export default {
  name: 'RectifierOrdersBoard',

  components: { Board },

  data: () => ({
    businessId: BUSINESS_ID_RECTIFICATION
  }),

  computed: {
    boardTitle() {
      return this.$t('engineRebuildingOrdersBoard');
    }
  }
};
</script>
