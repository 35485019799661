'use strict';

import axios from 'axios';
import {
  CLIENT_API_URL,
  ITEM_API_URL,
  SERVICE_API_URL
} from '../../../constants/Environment';

const retrieveAllClientReports = async ({ dispatch, commit }) => {
  dispatch('loading/setIsClientReportLoading', true, { root: true });
  try {
    await axios.get(`${CLIENT_API_URL}/clients/report`).then(response => {
      commit('setAllClientsReport', response.data);
    });
    dispatch('loading/setIsClientReportLoading', false, { root: true });
  } catch (error) {
    console.error(
      'There was an error while retrieving clients reports' + error
    );
  }
};

const retrieveAllBrandReports = async ({ dispatch, commit }) => {
  dispatch('loading/setIsBrandReportLoading', true, { root: true });
  try {
    await axios.get(`${ITEM_API_URL}/brands/report`).then(response => {
      commit('setAllBrandsReport', response.data);
    });
    dispatch('loading/setIsBrandReportLoading', false, { root: true });
  } catch (error) {
    console.error('There was an error while retrieving brands reports' + error);
  }
};

const retrieveAllBrandsTrendReport = async (
  { dispatch, commit },
  { fromDate, toDate }
) => {
  let urlBrandTrendReport = `${ITEM_API_URL}/brands/report-trend`;
  if (fromDate) urlBrandTrendReport += `?fromDate=${fromDate}`;
  if (toDate) urlBrandTrendReport += `&toDate=${toDate}`;

  dispatch(
    'loading/setIsOrdersReportByEngineBrandNumberCylindersLoading',
    true,
    { root: true }
  );
  try {
    await axios.get(urlBrandTrendReport).then(response => {
      commit('setAllOrdersReportByEngineBrandNumberCylinders', response.data);
    });
    dispatch(
      'loading/setIsOrdersReportByEngineBrandNumberCylindersLoading',
      false,
      { root: true }
    );
  } catch (error) {
    console.error(
      'There was an error while retrieving brands by trend report' + error
    );
  }
};

const retrieveBrandTrendReport = async (
  { dispatch, commit },
  { fromDate, toDate, brandName, seriesOrCylinders }
) => {
  let urlBrandTrendReport = `${ITEM_API_URL}/brands/report-trend-of-brand?brand=${brandName}&cylinderOrSeries=${seriesOrCylinders}`;
  if (fromDate) urlBrandTrendReport += `&fromDate=${fromDate}`;
  if (toDate) urlBrandTrendReport += `&toDate=${toDate}`;

  dispatch(
    'loading/setIsOrdersReportByEngineBrandNumberCylindersLoading',
    true,
    { root: true }
  );
  try {
    await axios.get(urlBrandTrendReport).then(response => {
      commit('setAllOrdersReportByEngineBrandNumberCylinders', response.data);
    });
    dispatch(
      'loading/setIsOrdersReportByEngineBrandNumberCylindersLoading',
      false,
      { root: true }
    );
  } catch (error) {
    console.error(
      'There was an error while retrieving brands by trend report' + error
    );
  }
};

const retrieveAllFinancialReports = async (
  { dispatch, commit },
  { fromDate, toDate }
) => {
  let urlFinantialReport = `${CLIENT_API_URL}/orders/report-in-date-range/financial`;

  if (fromDate) urlFinantialReport += `?fromDate=${fromDate}`;
  if (toDate) urlFinantialReport += `&toDate=${toDate}`;

  dispatch('loading/setIsFinancialReportLoading', true, { root: true });
  try {
    await axios.get(urlFinantialReport).then(response => {
      commit('setAllFinantialReport', response.data);
    });
    dispatch('loading/setIsFinancialReportLoading', false, { root: true });
  } catch (error) {
    console.error(
      'There was an error while retrieving financial reports' + error
    );
  }
};

const retrieveAllPendingBillsReports = async ({ dispatch, commit }) => {
  dispatch('loading/setIsFinancialReportLoading', true, { root: true });
  try {
    await axios
      .get(`${CLIENT_API_URL}/orders/report/financial-debtors`)
      .then(response => {
        commit('setPendingBillsReport', response.data);
      });
    dispatch('loading/setIsFinancialReportLoading', false, { root: true });
  } catch (error) {
    console.error(
      'There was an error while retrieving the pending bills reports' + error
    );
  }
};

const retrieveAllDebtorsReport = async ({ dispatch, commit }) => {
  dispatch('loading/setIsDebtorsReportLoading', true, { root: true });
  try {
    await axios
      .get(`${CLIENT_API_URL}/orders/report/debtors`)
      .then(response => {
        commit('setAllDebtorsReport', response.data);
      });
    dispatch('loading/setIsDebtorsReportLoading', false, { root: true });
  } catch (error) {
    console.error(
      'There was an error while retrieving the debtors report' + error
    );
  }
};

const retrieveAllUnpickedOrdersReport = async ({ dispatch, commit }) => {
  dispatch('loading/setIsUnpickedOrdersReportLoading', true, { root: true });
  try {
    await axios
      .get(`${CLIENT_API_URL}/orders/report/unpicked`)
      .then(response => {
        commit('setAllUnpickedOrdersReport', response.data);
      });
    dispatch('loading/setIsUnpickedOrdersReportLoading', false, { root: true });
  } catch (error) {
    console.error(
      'There was an error while retrieving the unpicked orders report' + error
    );
  }
};

const retrieveAllServiceReports = async (
  { dispatch, commit },
  { fromDate, toDate }
) => {
  dispatch('loading/setIsServicesReportLoading', true, { root: true });
  let url = `${SERVICE_API_URL}/services/report?`;
  if (fromDate) url += `&fromDate=${fromDate}`;
  if (toDate) url += `&toDate=${toDate}`;

  try {
    await axios.get(url).then(response => {
      commit('setAllServiceReport', response.data);
    });
    dispatch('loading/setIsServicesReportLoading', false, { root: true });
  } catch (error) {
    console.error(
      'There was an error while retrieving services reports' + error
    );
  }
};

const retrieveAllServiceMoneyReport = async (
  { dispatch, commit },
  { fromDate, toDate }
) => {
  dispatch('loading/setIsServicesReportLoading', true, { root: true });
  let url = `${SERVICE_API_URL}/services/report-money-generated?`;
  if (fromDate) url += `&fromDate=${fromDate}`;
  if (toDate) url += `&toDate=${toDate}`;

  try {
    await axios.get(url).then(response => {
      commit('setAllServiceReport', response.data);
    });
    dispatch('loading/setIsServicesReportLoading', false, { root: true });
  } catch (error) {
    console.error(
      'There was an error while retrieving services reports' + error
    );
  }
};

const retrieveAllSubServiceReports = async (
  { dispatch, commit },
  { serviceName, fromDate, toDate }
) => {
  dispatch('loading/setIsServicesReportLoading', true, { root: true });
  let url = `${SERVICE_API_URL}/sub-services/report?serviceName=${serviceName}`;
  if (fromDate) url += `&fromDate=${fromDate}`;
  if (toDate) url += `&toDate=${toDate}`;
  try {
    await axios.get(url).then(response => {
      commit('setAllServiceReport', response.data);
    });
    dispatch('loading/setIsServicesReportLoading', false, { root: true });
  } catch (error) {
    console.error(
      'There was an error while retrieving sub services reports' + error
    );
  }
};

const retrieveSubServiceMoneyReport = async (
  { dispatch, commit },
  { serviceName, fromDate, toDate }
) => {
  dispatch('loading/setIsServicesReportLoading', true, { root: true });
  let url = `${SERVICE_API_URL}/sub-services/report-money-generated?serviceName=${serviceName}`;
  if (fromDate) url += `&fromDate=${fromDate}`;
  if (toDate) url += `&toDate=${toDate}`;
  try {
    await axios.get(url).then(response => {
      commit('setAllSubServiceReport', response.data);
    });
    dispatch('loading/setIsServicesReportLoading', false, { root: true });
  } catch (error) {
    console.error(
      'There was an error while retrieving sub services reports' + error
    );
  }
};

const retrieveAllSubServiceMoneyReport = async (
  { dispatch, commit },
  { fromDate, toDate }
) => {
  dispatch('loading/setIsServicesReportLoading', true, { root: true });
  let url = `${SERVICE_API_URL}/sub-services/report-money-generated-all`;
  if (fromDate) url += `?fromDate=${fromDate}`;
  if (toDate) url += `&toDate=${toDate}`;
  try {
    await axios.get(url).then(response => {
      commit('setAllSubServiceReport', response.data);
    });
    dispatch('loading/setIsServicesReportLoading', false, { root: true });
  } catch (error) {
    console.error(
      'There was an error while retrieving sub services reports' + error
    );
  }
};

const retrieveAllSparePartsReports = async (
  { dispatch, commit },
  { fromDate, toDate }
) => {
  dispatch('loading/setIsSparePartReportLoading', true, { root: true });
  try {
    let url = `${ITEM_API_URL}/spare-parts/report`;
    if (fromDate) url += `?fromDate=${fromDate}`;
    if (toDate) url += `&toDate=${toDate}`;

    await axios.get(url).then(response => {
      commit('setAllSparePartsReport', response.data);
    });
    dispatch('loading/setIsSparePartReportLoading', false, { root: true });
  } catch (error) {
    console.error(
      'There was an error while retrieving spare parts reports' + error
    );
  }
};

const retrieveAllSparePartsReportsByBrands = async (
  { dispatch, commit },
  { brandName, numberOfCylinders, fromDate, toDate, series }
) => {
  dispatch('loading/setIsSparePartReportLoading', true, { root: true });
  try {
    let url = `${ITEM_API_URL}/spare-parts/report-by-brand?`;
    if (brandName) url += `brandName=${brandName}`;
    if (numberOfCylinders) url += `&numberOfCylinders=${numberOfCylinders}`;
    if (series) url += `&series=${series}`;
    if (fromDate) url += `&fromDate=${fromDate}`;
    if (toDate) url += `&toDate=${toDate}`;

    await axios.get(url).then(response => {
      commit('setAllSparePartsReport', response.data);
    });
    dispatch('loading/setIsSparePartReportLoading', false, { root: true });
  } catch (error) {
    console.error(
      'There was an error while retrieving spare parts reports' + error
    );
  }
};

const retrieveAllSparePartTrendReport = async (
  { dispatch, commit },
  { fromDate, toDate }
) => {
  let urlSparePartTrendReport = `${ITEM_API_URL}/spare-parts/report-by-trend`;
  if (fromDate) urlSparePartTrendReport += `?fromDate=${fromDate}`;
  if (toDate) urlSparePartTrendReport += `&toDate=${toDate}`;

  dispatch('loading/setIsOrdersReportBySparePartsLoading', true, {
    root: true
  });
  try {
    await axios.get(urlSparePartTrendReport).then(response => {
      commit('setAllSparePartsTrendReport', response.data);
    });
    dispatch('loading/setIsOrdersReportBySparePartsLoading', false, {
      root: true
    });
  } catch (error) {
    console.error(
      'There was an error while retrieving all spare parts by trend report' +
        error
    );
  }
};

const retrieveSparePartTrendReport = async (
  { dispatch, commit },
  { fromDate, toDate, brandName }
) => {
  let urlSparePartTrendReport = `${ITEM_API_URL}/spare-parts/report-by-trend`;
  if (fromDate) urlSparePartTrendReport += `?fromDate=${fromDate}`;
  if (toDate) urlSparePartTrendReport += `&toDate=${toDate}`;
  if (brandName) urlSparePartTrendReport += `&brandName=${brandName}`;

  dispatch('loading/setIsOrdersReportBySparePartsLoading', true, {
    root: true
  });
  try {
    await axios.get(urlSparePartTrendReport).then(response => {
      commit('setAllSparePartsTrendReport', response.data);
    });
    dispatch('loading/setIsOrdersReportBySparePartsLoading', false, {
      root: true
    });
  } catch (error) {
    console.error(
      'There was an error while retrieving a spare part by trend report' + error
    );
  }
};

const retrieveAllOrdersReportByEngineBrandNumberCylinders = async (
  { dispatch, commit },
  { brand, numberOfCylinders, fromDate, toDate, series }
) => {
  dispatch(
    'loading/setIsOrdersReportByEngineBrandNumberCylindersLoading',
    true,
    { root: true }
  );
  let url = `${CLIENT_API_URL}/orders/report-in-date-range/engine?`;
  if (brand) url += `brand=${brand}`;
  if (numberOfCylinders) url += `&numberOfCylinders=${numberOfCylinders}`;
  if (series) url += `&series=${series}`;
  if (fromDate) url += `&fromDate=${fromDate}`;
  if (toDate) url += `&toDate=${toDate}`;

  try {
    await axios.get(url).then(response => {
      commit('setAllOrdersReportByEngineBrandNumberCylinders', response.data);
    });
    dispatch(
      'loading/setIsOrdersReportByEngineBrandNumberCylindersLoading',
      false,
      { root: true }
    );
  } catch (error) {
    console.error(
      'There was an error while retrieving orders report by engine brand number of cylinders' +
        error
    );
  }
};

const retrieveAllWorkersReportMostPunctual = async (
  { dispatch, commit },
  { fromDate, toDate }
) => {
  let url = `${CLIENT_API_URL}/workers/report-in-date-range/most-punctual`;

  if (fromDate) url += `?fromDate=${fromDate}`;
  if (toDate) url += `&toDate=${toDate}`;

  dispatch('loading/setIsWorkersReportLoading', true, { root: true });
  try {
    await axios.get(url).then(response => {
      commit('setAllWorkersReport', response.data);
    });
    dispatch('loading/setIsWorkersReportLoading', false, { root: true });
  } catch (error) {
    console.error('There was an error while retrieving workers report' + error);
  }
};

const retrieveAllWorkersReportMostMoneyGenerated = async (
  { dispatch, commit },
  { fromDate, toDate }
) => {
  let url = `${CLIENT_API_URL}/workers/report-in-date-range/most-money-generated`;

  if (fromDate) url += `?fromDate=${fromDate}`;
  if (toDate) url += `&toDate=${toDate}`;

  dispatch('loading/setIsWorkersReportLoading', true, { root: true });
  try {
    await axios.get(url).then(response => {
      commit('setAllWorkersReport', response.data);
    });
    dispatch('loading/setIsWorkersReportLoading', false, { root: true });
  } catch (error) {
    console.error('There was an error while retrieving workers report' + error);
  }
};

const retrieveAllWorkersReportMostMoneyGeneratedInFinishedOrders = async (
  { dispatch, commit },
  { fromDate, toDate }
) => {
  let url = `${CLIENT_API_URL}/workers/report-in-date-range/most-money-generated-in-finished-orders`;

  if (fromDate) url += `?fromDate=${fromDate}`;
  if (toDate) url += `&toDate=${toDate}`;

  dispatch('loading/setIsWorkersReportLoading', true, { root: true });
  try {
    await axios.get(url).then(response => {
      commit('setAllWorkersReport', response.data);
    });
    dispatch('loading/setIsWorkersReportLoading', false, { root: true });
  } catch (error) {
    console.error('There was an error while retrieving workers report' + error);
  }
};

const retrieveAllWorkersReportMoneyGeneratedOfPunctualServices = async (
  { dispatch, commit },
  { fromDate, toDate }
) => {
  let url = `${CLIENT_API_URL}/workers/report-in-date-range/most-money-generated-of-punctual-services`;

  if (fromDate) url += `?fromDate=${fromDate}`;
  if (toDate) url += `&toDate=${toDate}`;

  dispatch('loading/setIsWorkersReportLoading', true, { root: true });
  try {
    await axios.get(url).then(response => {
      commit('setAllWorkersReport', response.data);
    });
    dispatch('loading/setIsWorkersReportLoading', false, { root: true });
  } catch (error) {
    console.error('There was an error while retrieving workers report' + error);
  }
};

export default {
  retrieveAllClientReports,
  retrieveAllBrandReports,
  retrieveAllBrandsTrendReport,
  retrieveBrandTrendReport,
  retrieveAllFinancialReports,
  retrieveAllPendingBillsReports,
  retrieveAllSparePartsReports,
  retrieveAllSparePartsReportsByBrands,
  retrieveAllServiceReports,
  retrieveAllSubServiceReports,
  retrieveAllOrdersReportByEngineBrandNumberCylinders,
  retrieveAllWorkersReportMostPunctual,
  retrieveAllWorkersReportMostMoneyGenerated,
  retrieveAllWorkersReportMostMoneyGeneratedInFinishedOrders,
  retrieveAllWorkersReportMoneyGeneratedOfPunctualServices,
  retrieveAllSubServiceMoneyReport,
  retrieveAllServiceMoneyReport,
  retrieveSubServiceMoneyReport,
  retrieveAllSparePartTrendReport,
  retrieveSparePartTrendReport,
  retrieveAllDebtorsReport,
  retrieveAllUnpickedOrdersReport
};
