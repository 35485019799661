'use strict';

const setAllOrders = (state, orders) => {
  state.orders = orders;
};

const updateOrder = (state, order) => {
  const foundOrderIndex = state.orders.findIndex(o => o.id === order.id);
  if (foundOrderIndex >= 0) {
    state.orders.splice(foundOrderIndex, 1, order);
  }
};

const addOrder = (state, orderToAdd) => {
  state.orders.push(orderToAdd);
};

const removeOrder = (state, orderId) => {
  state.orders = state.orders.filter(cond => cond.id !== orderId);
};

const setCount = (state, count) => {
  state.count = count;
};

const setTotalCount = (state, totalCount) => {
  state.totalCount = totalCount;
};

const setHighestGroup = (state, highestGroup) => {
  state.highestGroup = highestGroup;
};

const setNextOrderNumber = (state, nextOrderNumber) => {
  state.nextOrderNumber = nextOrderNumber;
};

const updateFilteredOrdersByOrderStatusId = (
  state,
  { orders, orderStatusId }
) => {
  state.orders = state.orders.filter(
    order => order.orderStatusId !== orderStatusId
  );
  state.orders = state.orders.concat(orders);
};

const setSingleOrderById = (state, order) => {
  state.singleOrder = order;
};

const setAllUnassignedServices = (state, unassignedServices) => {
  var newUnassignedServices = [];
  for (const element of unassignedServices) {
    element.order.detail = JSON.parse(element.order.detail);
    element.order.assignedServices = JSON.parse(element.order.assignedServices);
    let order = JSON.parse(JSON.stringify(element.order));
    for (const unassignment of element.assignments) {
      let assignmentOrder = {
        unassignment,
        order
      };
      newUnassignedServices.push(assignmentOrder);
    }
  }
  state.unassignedServices = newUnassignedServices;
};

export default {
  setAllOrders,
  updateOrder,
  addOrder,
  removeOrder,
  setCount,
  setTotalCount,
  setHighestGroup,
  setNextOrderNumber,
  updateFilteredOrdersByOrderStatusId,
  setSingleOrderById,
  setAllUnassignedServices
};
