<i18n src="./resources/locales.json"></i18n>

<template>
  <v-container>
    <h1 class="ml-3 primary--text">{{ $t('workersReport') }}</h1>
    <h2 class="ml-3 primary--text">
      {{ $t('workersReportThatGeneratedMostMoneyAccordingJobs') }}
    </h2>
    <v-row
      class="tool-bar mt-10 ml-16 mb-n12 mr-16"
      no-gutters
      style="flex-wrap: nowrap"
    >
      <v-col cols="2">
        <div class="ml-5 mb-7 date-bar">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="dates"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                background-color="primaryVariant2"
                :value="dateRangeText"
                :label="$t('dates')"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                hide-details
                class="search-bar"
                outlined
                dense
                flat
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dates"
              range
              scrollable
              show-adjacent-months
              no-title
              :max="currentDate"
              :locale="$i18n.locale"
              color="primary"
              @change="verifyRangeChangesDate"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">
                {{ $t('cancel') }}
              </v-btn>
              <v-btn color="primary" @click="savePickedDate">
                {{ $t('ok') }}
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="ml-13">
        <v-data-table
          :headers="headers"
          :items="allWorkersReport"
          :footer-props="{
            itemsPerPageOptions: [10, 20],
            itemsPerPageText: $t('rowsPerPage')
          }"
          :items-per-page="10"
          class="elevation-1 ma-8"
        >
          <template v-slot:[`footer.page-text`]="items">
            {{ items.pageStart }} - {{ items.pageStop }} {{ $t('of') }}
            {{ items.itemsLength }}
          </template>
          <template v-slot:no-data>
            <p>{{ $t('NoOrdersInDateRange') }}</p>
          </template>
        </v-data-table>
        <LoadingMask :isLoading="getIsWorkersReportLoading" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import LoadingMask from '@/components/reusable-components/loading-mask/LoadingMask.vue';
import moment from 'moment';

export default {
  name: 'WorkersReport',

  data() {
    return {
      dates: [],
      menu: false,
      currentDate: moment().format('YYYY-MM-DD'),
      availableDates: []
    };
  },

  components: {
    LoadingMask
  },

  computed: {
    ...mapGetters('reports', ['allWorkersReport']),
    ...mapGetters('loading', ['getIsWorkersReportLoading']),

    headers() {
      return [
        {
          text: this.$t('position'),
          value: 'position',
          align: 'center',
          sortable: false,
          class: 'primary--text'
        },
        {
          text: this.$t('worker'),
          value: 'worker',
          align: 'center',
          sortable: false,
          class: 'primary--text'
        },
        {
          text: this.$t('generatedMoney'),
          value: 'moneyGenerated',
          align: 'center',
          sortable: false,
          class: 'primary--text'
        }
      ];
    },

    dateRangeText() {
      return this.dates
        .slice()
        .map(d => this.convertDate(d))
        .join(' - ');
    }
  },

  created() {
    this.dates = [
      moment()
        .startOf('month')
        .format('YYYY-MM-DD'),
      this.currentDate
    ];
    this.retrieveWorkersReport();
  },

  methods: {
    ...mapActions('reports', [
      'retrieveAllWorkersReportMostMoneyGeneratedInFinishedOrders'
    ]),

    getTableData() {
      let tableData = [];
      if (this.isReportsMoreThatTen())
        tableData = this.allWorkersReport.slice(
          10,
          this.allWorkersReport.length
        );
      return tableData;
    },

    isReportsMoreThatTen() {
      return this.allWorkersReport.length > 10;
    },

    getSumOthers() {
      return this.getTableData().reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue.moneyGenerated,
        0
      );
    },

    _isValueCero(val) {
      return val === 0;
    },

    savePickedDate() {
      this.$refs.menu.save(this.dates);
      this.retrieveWorkersReport();
    },

    convertDate(date) {
      let convertedDate = moment(date).format('YYYY-MM-DD');
      const [year, month, day] = convertedDate.split('-');
      return `${day}/${month}/${year}`;
    },

    verifyRangeChangesDate() {
      const [first, second] = this.dates;
      if (first !== undefined && second !== undefined) {
        if (moment(first).isSame(second)) this.dates = [first];
        if (moment(first).isAfter(second)) this.dates = [second, first];
      }
    },

    retrieveWorkersReport() {
      this.retrieveAllWorkersReportMostMoneyGeneratedInFinishedOrders(
        this.getBuiltParameters()
      );
    },

    getBuiltParameters() {
      const [first, second] = this.dates;
      let parameters = {};
      if (first) parameters.fromDate = moment(first).format();
      if (second) parameters.toDate = this.setDateUntilLastMinute(second);
      else parameters.toDate = this.setDateUntilLastMinute(first);
      return parameters;
    },

    setDateUntilLastMinute(date) {
      return moment(date)
        .set({ hour: 23, minute: 59, second: 59 })
        .format();
    }
  }
};
</script>
<style scoped>
@import './ScopedStyles.css';
</style>
