<script>
import { Pie, mixins } from 'vue-chartjs';

const { reactiveProp } = mixins;

export default {
  extends: Pie,

  name: 'PieChart',

  data: () => ({
    options: {
      responsive: true,
      maintainAspectRatio: false
    }
  }),

  mixins: [reactiveProp],

  props: ['chartData', 'aditionalOptions'],

  mounted() {
    if (this.aditionalOptions)
      this.options = Object.assign(this.options, this.aditionalOptions);
    this.renderChart(this.chartData, this.options);
  }
};
</script>
