<i18n src="./resources/locales.json"></i18n>

<template>
  <v-dialog persistent v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title class="headline">
        {{ $t('alert') }}
      </v-card-title>
      <v-card-text>
        {{ message }}
        <ul>
          <li v-for="(service, index) in blockedServices" :key="index">
            {{ service }}
          </li>
        </ul>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="accept">{{ $t('accept') }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AlertAssignedServices',

  data() {
    return {
      message: '',
      blockedServices: [],
      dialog: false
    };
  },

  methods: {
    open(modalInfo) {
      this.message = modalInfo.message;
      this.blockedServices = modalInfo.blockedServices;
      this.dialog = true;
    },

    accept() {
      this.dialog = false;
      this.message = '';
      this.blockedServices = [];
      this.$emit('confirm-action');
    }
  }
};
</script>
