<i18n src="./resources/locales.json"></i18n>

<template>
  <v-dialog v-model="dialog" fullscreen scrollable persistent>
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="addToCalendar" :disabled="!radioGroup">
              {{ $t('addToCalendar') }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </v-card-title>

      <v-card-title class="pa-0 justify-center">
        <v-col cols="3" class="d-flex justify-space-around">
          <DatePicker
            :labelText="$t('serviceDeliverDate')"
            :givenDate.sync="serviceDeliverDate"
            :maxDate="undefined"
            :minDate="getMinDate()"
            :icon="undefined"
          />
        </v-col>
        <v-col cols="3" class="d-flex justify-space-around">
          <HourPicker
            :labelText="$t('serviceDeliverHour')"
            :givenHour.sync="serviceDeliverHour"
            :icon="undefined"
          />
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="selectedWorker"
            :items="allWorkers"
            item-text="name"
            return-object
            :label="$t('worker')"
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-textarea
            :label="$t('note')"
            v-model="note"
            no-resize
            rows="2"
            height="60"
            outlined
          ></v-textarea>
        </v-col>
      </v-card-title>

      <v-card-text>
        <v-radio-group v-model="radioGroup">
          <template v-for="(unassignedService, index) in allUnassignedServices">
            <v-row :key="index" align="center">
              <v-col cols="11" class="py-0">
                <Order
                  :key="unassignedService.order.id"
                  :order="unassignedService.order"
                  :isShowDotsContextMenu="false"
                  :isShowIcon="false"
                  :showContextMenuOptions="true"
                  :currentOrHistorical="CURRENT"
                  :isShowForUnassignedServiceCalendar="true"
                  :unassignedServiceForCalendar="unassignedService.unassignment"
                />
              </v-col>
              <v-col cols="1" class="pl-4">
                <v-radio :value="unassignedService"></v-radio>
              </v-col>
              <v-divider :inset="true"> </v-divider>
            </v-row>
          </template>
        </v-radio-group>

        <p v-if="allUnassignedServices.length === 0" class="pl-4">
          {{ $t('thereAreNoTUnassignedServices') }}
        </p>
      </v-card-text>
    </v-card>
    <LoadingMask :isLoading="getIsOrderLoading" />
    <InfoMessageModal
      ref="infoMessageModal"
      @confirm-action="setOverflowHiden()"
    />
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import DatePicker from '@/components/reusable-components/date-picker/DatePicker.vue';
import HourPicker from '@/components/reusable-components/hour-picker/HourPicker.vue';
import LoadingMask from '@/components/reusable-components/loading-mask/LoadingMask.vue';
import InfoMessageModal from '@/components/reusable-components/info-message-modal/InfoMessageModal.vue';
import OrderMixin from '../../mixins/OrderMixin.js';
import Order from '@/components/reusable-components/order/Order.vue';
import { CURRENT } from '@/constants/ConfigurationConstants';

export default {
  name: 'UnassignedServicesList',

  components: {
    LoadingMask,
    DatePicker,
    HourPicker,
    InfoMessageModal,
    Order
  },

  data: () => ({
    dialog: false,
    radioGroup: null,
    serviceDeliverDate: null,
    serviceDeliverHour: null,
    selectedWorker: null,
    note: '',
    CURRENT
  }),

  mixins: [OrderMixin],

  computed: {
    ...mapGetters('orders', ['allUnassignedServices']),
    ...mapGetters('loading', ['getIsOrderLoading']),
    ...mapGetters('workers', ['allWorkers'])
  },

  methods: {
    ...mapActions('orders', [
      'retrieveAllUnassignedServices',
      'setAllUnassignedServicesAsEmpty'
    ]),
    ...mapActions('calendar', ['addAssignServiceOnCalendar']),

    async open(serviceDeliverDateTime, isDisableHourNotNull) {
      this.serviceDeliverDate = moment(serviceDeliverDateTime).format(
        'YYYY-MM-DD'
      );
      if (isDisableHourNotNull)
        this.serviceDeliverHour = moment(serviceDeliverDateTime).format(
          'HH:mm'
        );
      this.dialog = true;
      this.retrieveAllUnassignedServices();
    },

    close() {
      this.dialog = false;
      this.serviceDeliverDate = null;
      this.serviceDeliverHour = null;
      this.selectedWorker = null;
      this.radioGroup = null;
      this.note = '';
      this.setAllUnassignedServicesAsEmpty();
    },

    async addToCalendar() {
      let unassignedService = JSON.parse(
        JSON.stringify(this.radioGroup.unassignment)
      );
      let order = JSON.parse(JSON.stringify(this.radioGroup.order));

      let concatedDeadline = moment(
        this.serviceDeliverDate + this.serviceDeliverHour,
        'YYYY-MM-DDLT'
      );
      if (this.serviceDeliverHour === null) {
        this.$refs.infoMessageModal.open({
          message: `${this.$t('selectEstimatedDeliverHour')}`,
          title: this.$t('alert')
        });
      } else if (this.selectedWorker === null) {
        this.$refs.infoMessageModal.open({
          message: `${this.$t('selectWorker')}`,
          title: this.$t('alert')
        });
      } else if (order.estimatedDeliverDate === null) {
        this.$refs.infoMessageModal.open({
          message: `${this.$t(
            'haveToAssignEstimatedDeliverDateBeforeAssigningWorkDate'
          )}`,
          title: this.$t('alert')
        });
      } else if (moment(concatedDeadline).isAfter(order.estimatedDeliverDate)) {
        this.$refs.infoMessageModal.open({
          message: `${this.$t(
            'newAssignedDeliverDateIsHiggerThanOrderEstimatedDeliverDate'
          )}`,
          title: this.$t('alert')
        });
      } else {
        let newAssignedService = {
          serviceId: unassignedService.serviceId,
          subServiceId: unassignedService.subServiceId,
          workerId: this.selectedWorker.id,
          assignedDateTime: moment().format('YYYY-MM-DD[T]HH:mm:ss.SSSZ'),
          deliverDateTime: moment(concatedDeadline).format(
            'YYYY-MM-DD[T]HH:mm:ss.SSSZ'
          ),
          finishedDateTime: null,
          note: this.note,
          orderId: unassignedService.orderId
        };
        this.addAssignServiceOnCalendar(newAssignedService);
        this.close();
      }
    },

    setOverflowHiden() {
      this.$nextTick(function() {
        var root = document.getElementsByTagName('html')[0];
        root.setAttribute('class', 'overflow-y-hidden');
      });
    },

    getMinDate() {
      return moment().format('YYYY-MM-DD');
    },

    renderUnassignedService(order) {
      let assignedService = ` ${order.serviceName}`;
      if (order.subServiceName !== null)
        assignedService += ` (${order.subServiceName})`;
      return assignedService;
    }
  }
};
</script>
