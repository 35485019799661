<i18n src="./resources/locales.json"></i18n>

<template>
  <v-container>
    <h1 class="ml-3 primary--text">{{ $t('spareParts') }}</h1>
    <v-row
      class="mt-10 ml-16 mb-n12 mr-16"
      no-gutters
      style="flex-wrap: nowrap"
    >
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="addSparePart">
        {{ $t('newSparePart') }}
      </v-btn>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="allSpareParts"
      sort-by="name"
      class="elevation-1 ma-16"
      hide-default-footer
      disable-pagination
      :loading="getIsSparePartLoading"
      :loading-text="$t('loadingPleaseWait')"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          color="primary"
          class="mr-2"
          @click="selectMeasures(item)"
        >
          mdi-tape-measure
        </v-icon>
        <v-icon small color="primary" class="mr-2" @click="assignPart(item)">
          mdi-engine-outline
        </v-icon>
        <v-icon small color="primary" class="mr-2" @click="editSparePart(item)">
          mdi-pencil-outline
        </v-icon>
        <!--v-icon small color="primary" @click="deleteSparePart(item)">
          mdi-delete
        </v-icon-->
      </template>
      <template v-slot:no-data>
        <p>{{ $t('thereAreNoSpareParts') }}</p>
      </template>
    </v-data-table>

    <MeasuresSelectionModal ref="measuresSelectionModal" />
    <PartsAssigmentListModal
      ref="partsAssigmentListModal"
      @confirm-action="assignPartsConfirm"
    />
    <SparePartDetailModal ref="sparePartDetailModal" />
    <DeleteConfirmModal
      ref="deleteSparePartModal"
      @confirm-action="deleteSparePartConfirm"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SparePartDetailModal from '@/components/spare-part/spare-part-detail-modal/SparePartDetailModal.vue';
import DeleteConfirmModal from '@/components/reusable-components/delete-confirm-modal/DeleteConfirmModal.vue';
import MeasuresSelectionModal from '@/components/spare-part/measures-selection-modal/MeasuresSelectionModal.vue';
import PartsAssigmentListModal from '@/components/spare-part/parts-assignment-modal/PartsAssigmentListModal.vue';

export default {
  name: 'SparePartsList',

  components: {
    SparePartDetailModal,
    DeleteConfirmModal,
    MeasuresSelectionModal,
    PartsAssigmentListModal
  },

  computed: {
    headers() {
      return [
        { text: this.$t('name'), value: 'name', align: 'center' },
        {
          text: this.$t('description'),
          value: 'description',
          align: 'center'
        },
        { text: this.$t('tags'), value: 'tags', align: 'center' },
        {
          text: this.$t('actions'),
          value: 'actions',
          align: 'center',
          sortable: false
        }
      ];
    },

    ...mapGetters('spareParts', ['allSpareParts']),
    ...mapGetters('loading', ['getIsSparePartLoading'])
  },

  created() {
    this.retrieveAllSpareParts();
  },

  methods: {
    ...mapActions('spareParts', [
      'removeSparePart',
      'retrieveAllSpareParts',
      'updateSparePart'
    ]),

    addSparePart() {
      this.$refs.sparePartDetailModal.open();
    },

    editSparePart(sparePartToUpdate) {
      this.$refs.sparePartDetailModal.open(
        Object.assign({}, sparePartToUpdate)
      );
    },

    deleteSparePart(sparePartToDelete) {
      this.$refs.deleteSparePartModal.open(
        Object.assign(
          { message: this.$t('sureToDeleteThisSparePart') },
          sparePartToDelete
        )
      );
    },

    deleteSparePartConfirm(sparePartToDeleteId) {
      this.removeSparePart(sparePartToDeleteId);
    },

    selectMeasures(sparePart) {
      this.$refs.measuresSelectionModal.open(sparePart);
    },

    assignPart(sparePart) {
      this.$refs.partsAssigmentListModal.open(sparePart);
    },

    async assignPartsConfirm(sparePart) {
      try {
        await this.updateSparePart(sparePart);
        this.$root.$emit(
          'open-status-message-snackbar',
          Object.assign(
            {},
            {
              message: this.$t('thePartsHaveBeenSuccessfullyAssigned'),
              messageType: 'success'
            }
          )
        );
      } catch (error) {
        this.$root.$emit(
          'open-status-message-snackbar',
          Object.assign(
            {},
            {
              message: this.$t('thereWasAnErrorWhileAssigningTheParts'),
              messageType: 'error'
            }
          )
        );
      }
    }
  }
};
</script>
