<i18n src="./resources/locales.json"></i18n>

<template>
  <div class="text-center">
    <v-container>
      <v-row justify="center" align="center" class="mt-5">
        <v-col cols="12" md="4" class="d-flex align-center">
          <v-select
            v-model="selectedDateRange"
            :items="dropdownOptions"
            item-text="label"
            item-value="value"
            :label="$t('selectDateRange')"
            outlined
            dense
            style="height: 40px;"
            @change="updateDateRange()"
          >
          </v-select>
        </v-col>
        <v-col cols="12" md="2" class="d-flex align-center">
          <v-btn color="primary" @click="printAssignedJobs()">
            {{ $t('generate') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'AssignedJobs',

  data: () => ({
    startDate: '',
    endDate: '',
    selectedDateRange: '',
    formattedStartDate: null,
    formattedEndDate: null,
    dropdownOptions: []
  }),
  created() {
    this.setDropdownOptions();
  },
  methods: {
    setDropdownOptions() {
      const translations = {
        today: this.$t('today'),
        thisWeek: this.$t('thisWeek'),
        nextWeek: this.$t('nextWeek')
      };

      this.dropdownOptions = [
        { label: translations.today, value: 'today' },
        { label: translations.thisWeek, value: 'thisWeek' },
        { label: translations.nextWeek, value: 'nextWeek' }
      ];
    },

    formatDate(date) {
      const isoString = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSSSSSZ');
      return isoString.replace(/:/g, '%3A');
    },

    updateDateRange() {
      const now = moment();
      let start, end;
      switch (this.selectedDateRange) {
        case 'today':
          start = now.clone().startOf('day');
          end = now.clone().endOf('day');
          break;

        case 'thisWeek':
          start = now.clone().startOf('isoWeek');
          end = now
            .clone()
            .endOf('isoWeek')
            .subtract(1, 'day');
          break;

        case 'nextWeek':
          start = now
            .clone()
            .add(1, 'week')
            .startOf('isoWeek');
          end = now
            .clone()
            .add(1, 'week')
            .endOf('isoWeek')
            .subtract(1, 'day');
          break;

        default:
          start = null;
          end = null;
      }

      this.startDate = start;
      this.endDate = end;

      this.formattedStartDate = this.startDate
        ? this.formatDate(this.startDate)
        : null;
      this.formattedEndDate = this.endDate
        ? this.formatDate(this.endDate)
        : null;
    },

    printAssignedJobs() {
      if (this.formattedStartDate && this.formattedEndDate) {
        this.$root.$emit(
          'print-assigned-jobs-pdf',
          this.formattedStartDate,
          this.formattedEndDate
        );
      } else {
        console.warn('Please select a valid date range.');
      }
    }
  }
};
</script>

<style scoped>
@import './ScopedStyles.css';
</style>
