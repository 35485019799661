'use strict';

const setAllOrderSpareParts = (state, orderSpareParts) => {
  state.orderSpareParts = orderSpareParts;
};

const addOrderSparePart = (state, orderSparePartToAdd) => {
  state.orderSpareParts.push(orderSparePartToAdd);
};

export default {
  setAllOrderSpareParts,
  addOrderSparePart
};
