import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = () => ({
  orders: [],
  count: 0,
  totalCount: 0,
  nextOrderNumber: 0,
  highestGroup: 1,
  singleOrder: {},
  unassignedServices: []
});

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
