<i18n src="./resources/locales.json"></i18n>

<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t('workerAccountConfig') }}</span>
      </v-card-title>
      <v-form ref="accountConfigForm" v-model="isValid">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  class="required-field"
                  v-model="workerUser.email"
                  placeholder="johndoe@worker.com"
                  :label="$t('user')"
                  :rules="[emailRules.required, emailRules.validEmail]"
                  required
                ></v-text-field>
                <v-text-field
                  disabled
                  v-model="workerUser.code"
                  :label="$t('code')"
                ></v-text-field>
                <v-text-field
                  class="required-field"
                  v-model="workerUser.pin"
                  :label="'PIN'"
                  :type="getTypePin()"
                  :rules="[pinRules.required, pinRules.fixedLength]"
                  required
                  :append-icon="getAppendIconForPin()"
                  @click:append="showPin = !showPin"
                  @input="resetConfirmPassword()"
                  @keypress="isNumber($event)"
                ></v-text-field>
                <v-text-field
                  class="required-field"
                  v-model="workerUser.password"
                  :label="$t('password')"
                  :type="getTypePassword()"
                  :rules="[passwordRules.required]"
                  required
                  :append-icon="getAppendIconForPassword()"
                  @click:append="showPassword = !showPassword"
                  @input="resetConfirmPassword()"
                ></v-text-field>
                <v-text-field
                  class="required-field"
                  v-model="confirmPassword"
                  :label="$t('confirmPassword')"
                  :type="getTypeConfirmPassword()"
                  :rules="[
                    confirmPasswordRules.required,
                    confirmPasswordRules.samePasswords
                  ]"
                  required
                  :append-icon="getAppendIconForConfirmPassword()"
                  @click:append="showConfirmPassword = !showConfirmPassword"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="primary" :disabled="!isValid" @click="save">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { EMPTY_GUID, WORKER_ROLE_ID } from '@/constants/ConfigurationConstants';

export default {
  name: 'WorkerAccountConfigModal',

  data() {
    return {
      isValid: false,
      confirmPassword: '',
      defaultWorkerUser: {
        id: EMPTY_GUID,
        fullName: '',
        email: '',
        password: '',
        roleId: WORKER_ROLE_ID,
        code: '',
        pin: ''
      },
      workerUser: {
        id: EMPTY_GUID,
        fullName: '',
        email: '',
        password: '',
        roleId: WORKER_ROLE_ID,
        code: '',
        pin: ''
      },
      worker: {},
      originalWorkerUser: '',
      dialog: false,
      showPassword: false,
      showPin: false,
      showConfirmPassword: false
    };
  },

  created() {
    this.retrieveAllUsersWithPassword();
  },

  computed: {
    ...mapGetters('userStorage', [
      'getUserWithPasswordById',
      'getUsersWithPassword'
    ]),

    emailRules() {
      return {
        required: value => !!value || this.$t('userIsRequired'),
        validEmail: value =>
          !value ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
          this.$t('userMustBeValid')
      };
    },

    getPassword() {
      return this.workerUser.password;
    },

    passwordRules() {
      return {
        required: value => !!value || this.$t('passwordIsRequired'),
        samePasswords: value =>
          value === this.confirmPassword || this.$t('passwordsMustBeTheSame')
      };
    },

    pinRules() {
      return {
        required: value => !!value || this.$t('pinIsRequired'),
        fixedLength: value =>
          value.length === 4 || this.$t('pinMustBeFourCharacters')
      };
    },

    confirmPasswordRules() {
      return {
        required: value => !!value || this.$t('passwordsMustBeTheSame'),
        samePasswords: value =>
          value === this.workerUser.password ||
          this.$t('passwordsMustBeTheSame')
      };
    }
  },

  methods: {
    ...mapActions('userStorage', [
      'retrieveAllUsersWithPassword',
      'updateUser'
    ]),
    ...mapActions('workers', ['configAccount']),

    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    getTypePassword() {
      return this.showPassword ? 'text' : 'password';
    },

    getTypePin() {
      return this.showPin ? 'text' : 'password';
    },

    getTypeConfirmPassword() {
      return this.showConfirmPassword ? 'text' : 'password';
    },

    getAppendIconForPassword() {
      return this.showPassword ? 'mdi-eye' : 'mdi-eye-off';
    },

    getAppendIconForPin() {
      return this.showPin ? 'mdi-eye' : 'mdi-eye-off';
    },

    getAppendIconForConfirmPassword() {
      return this.showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off';
    },

    open(workerToUpsert) {
      this.worker = Object.assign({}, workerToUpsert);
      if (this.worker.userId !== EMPTY_GUID) {
        this.workerUser = Object.assign(
          {},
          this.getUserWithPasswordById(this.worker.userId)
        );
        this.originalWorkerUser = this.workerUser.email;
      }
      this.dialog = true;
    },

    close() {
      this.resetValidations();
      this.isValid = false;
      this.worker = Object.assign({}, {});
      this.workerUser = Object.assign({}, this.defaultWorkerUser);
      this.originalWorkerUser = '';
      this.dialog = false;
      this.confirmPassword = '';
      this.showPassword = false;
      this.showPin = false;
      this.showConfirmPassword = false;
    },

    async save() {
      if (
        this.getUsersWithPassword.find(
          user => user.email === this.workerUser.email
        ) &&
        this.workerUser.email !== this.originalWorkerUser
      ) {
        this.$root.$emit(
          'open-status-message-snackbar',
          Object.assign(
            {},
            {
              message: this.$t('userAlreadyExists'),
              messageType: 'error'
            }
          )
        );
      } else {
        if (this.worker.userId !== EMPTY_GUID) {
          try {
            await this.updateUser(this.workerUser);
            this.$root.$emit(
              'open-status-message-snackbar',
              Object.assign(
                {},
                {
                  message: this.$t('theAccountWasUpdatedSuccessfully'),
                  messageType: 'success'
                }
              )
            );
          } catch (error) {
            this.$root.$emit(
              'open-status-message-snackbar',
              Object.assign(
                {},
                {
                  message: this.$t(
                    'errorWhileUpdatingTheAccountPleaseTryAgain'
                  ),
                  messageType: 'error'
                }
              )
            );
          }
        } else {
          try {
            this.workerUser.fullName = this.worker.name;
            await this.configAccount({
              workerToUpdate: this.worker,
              userToUpdate: this.workerUser
            });
            this.$root.$emit(
              'open-status-message-snackbar',
              Object.assign(
                {},
                {
                  message: this.$t('theAccountWasConfiguredSuccessfully'),
                  messageType: 'success'
                }
              )
            );
          } catch (error) {
            this.$root.$emit(
              'open-status-message-snackbar',
              Object.assign(
                {},
                {
                  message: this.$t('errorWhileConfiguringTheAccount'),
                  messageType: 'error'
                }
              )
            );
          }
        }
        this.close();
      }
      this.confirmPassword = '';
      this.showPassword = false;
      this.showConfirmPassword = false;
    },

    resetValidations() {
      this.$refs.accountConfigForm.resetValidation();
    },

    resetConfirmPassword() {
      this.confirmPassword = '';
    }
  }
};
</script>
