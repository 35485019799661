'use strict';

import axios from 'axios';
import { CLIENT_API_URL } from '../../../constants/Environment';

const retrieveAllOrderStatuses = ({ commit }) => {
  axios.get(`${CLIENT_API_URL}/order-statuses`).then(response => {
    commit('setAllOrderStatuses', response.data);
  });
};

const retrieveAllBusinessOrderStatuses = async ({ commit }, businessId) => {
  try {
    axios
      .get(`${CLIENT_API_URL}/order-statuses/business/${businessId}`)
      .then(response => {
        commit('setAllOrderStatuses', response.data);
      });
  } catch (error) {
    console.error('There was an error while retrieving orders' + error);
  }
};

const retrieveAllBusinessAndSpecialOrderStatuses = async (
  { dispatch, commit },
  businessId
) => {
  dispatch('loading/setIsOrderStatusLoading', true, { root: true });
  try {
    await axios
      .get(
        `${CLIENT_API_URL}/order-statuses/business-with-specials/${businessId}`
      )
      .then(response => {
        commit('setAllOrderStatuses', response.data);
      });
    dispatch('loading/setIsOrderStatusLoading', false, { root: true });
  } catch (error) {
    console.error('There was an error while retrieving orders' + error);
  }
};

const retrieveInitialOrderStatus = async ({ commit }) => {
  axios.get(`${CLIENT_API_URL}/order-statuses/initial`).then(response => {
    commit('setInitialOrderStatus', response.data);
  });
};

const retrieveFinalOrderStatus = async ({ commit }) => {
  axios.get(`${CLIENT_API_URL}/order-statuses/final`).then(response => {
    commit('setFinalOrderStatus', response.data);
  });
};

const addOrderStatus = async ({ commit }, orderStatusToAdd) => {
  delete orderStatusToAdd.id;
  return new Promise((resolve, reject) => {
    axios
      .post(`${CLIENT_API_URL}/order-statuses`, orderStatusToAdd)
      .then(response => {
        commit('addOrderStatus', response.data);
        resolve(response);
      })
      .catch(error => {
        reject(error);
        console.error(
          'There was an error while posting the order status' + error
        );
      });
  });
};

const updateOrderStatus = async ({ commit }, orderStatusToUpdate) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${CLIENT_API_URL}/order-statuses/${orderStatusToUpdate.id}`,
        orderStatusToUpdate
      )
      .then(response => {
        commit('updateOrderStatus', response.data);
        resolve(response);
      })
      .catch(error => {
        reject(error);
        console.error('There was an error while updating the order status');
      });
  });
};

const updateOrderStatuses = async (
  { commit, state },
  orderStatusesToUpdate
) => {
  try {
    let originalOrderStatusesState = state.orderStatuses;
    originalOrderStatusesState = originalOrderStatusesState.slice(0);
    commit('setAllOrderStatuses', orderStatusesToUpdate);
    await axios
      .put(`${CLIENT_API_URL}/order-statuses/`, orderStatusesToUpdate)
      .catch(error => {
        console.error(
          'There was an error while updating order statuses' + error
        );
        commit('setAllOrderStatuses', originalOrderStatusesState);
      });
  } catch (error) {
    console.error('There was an error while updating order statuses' + error);
  }
};

const removeOrderStatus = ({ commit }, orderStatusId) => {
  axios
    .delete(`${CLIENT_API_URL}/order-statuses/${orderStatusId}`)
    .then(response => {
      commit('removeOrderStatus', response.data.id);
    });
};

export default {
  retrieveAllOrderStatuses,
  retrieveAllBusinessOrderStatuses,
  retrieveAllBusinessAndSpecialOrderStatuses,
  retrieveInitialOrderStatus,
  retrieveFinalOrderStatus,
  addOrderStatus,
  updateOrderStatus,
  updateOrderStatuses,
  removeOrderStatus
};
