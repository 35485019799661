<i18n src="./resources/locales.json"></i18n>

<template>
  <v-dialog persistent v-model="dialogConfirmation" max-width="500px">
    <v-card>
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-card-text>
        {{ message }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="rejectAction">{{ $t('no') }}</v-btn>
        <v-btn color="primary" @click="confirmAction">{{ $t('yes') }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmationModal',

  data() {
    return {
      message: '',
      dialogConfirmation: false,
      isOverOtherModal: false,
      title: ''
    };
  },

  methods: {
    open(modalInfo) {
      const { message, isOverOtherModal, title } = modalInfo;
      this.message = message;
      this.isOverOtherModal = isOverOtherModal;
      this.title = title;
      this.dialogConfirmation = true;
    },

    confirmAction() {
      this.$emit('confirm-action');
      this.dialogConfirmation = false;
      if (this.isOverOtherModal) {
        this.$nextTick(function() {
          var root = document.getElementsByTagName('html')[0];
          root.setAttribute('class', 'overflow-y-hidden');
        });
      }
    },

    rejectAction() {
      this.dialogConfirmation = false;
      this.$emit('reject-action');
      if (this.isOverOtherModal) {
        this.$nextTick(function() {
          var root = document.getElementsByTagName('html')[0];
          root.setAttribute('class', 'overflow-y-hidden');
        });
      }
    }
  }
};
</script>
