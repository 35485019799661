<i18n src="./resources/locales.json"></i18n>

<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <template v-slot:default="{ open }">
        <span v-if="open" class="opened-section-name-color">
          {{ sectionName }}
        </span>
        <span v-else>
          {{ sectionName }}
        </span>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content
      :style="getSectionStyle()"
      class="align-self-start overflow-y-auto section-height-width list-content-background-color content-padding"
      v-scroll.self="() => {}"
      eager
    >
      <v-form ref="clientSectionForm" v-model="isValid">
        <v-container class="mt-3">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              right
              color="primary"
              class="mb-2"
              @click="clearSearchClient"
              v-if="isOldClient() && isEditOrder()"
            >
              {{ $t('createNewClient') }}
            </v-btn>
          </v-row>
          <br />
          <v-combobox
            class="required-field"
            v-model.number="client.ci"
            :items="allParsedClients"
            item-text="ci"
            item-value="ci"
            :label="$t('id')"
            :rules="[idRules.required]"
            required
            return-object
            @change="clientToSearchByCi"
            v-if="isNewOrder() || (isNewClient() && isEditOrder())"
            @keydown="setAsNewClientByCi"
            dense
          >
            <template v-slot:no-data>
              <span>{{ $t('thereIsNotAvailableData') }}</span>
            </template></v-combobox
          >

          <v-autocomplete
            class="required-field"
            v-model.number="client.ci"
            :items="allParsedClients"
            item-text="ci"
            item-value="ci"
            :label="$t('id')"
            :rules="[idRules.required]"
            required
            return-object
            @change="clientToSearchByCi"
            v-if="isEditOrder() && isOldClient()"
            dense
            :no-data-text="$t('thereIsNotAvailableData')"
          >
          </v-autocomplete>

          <v-combobox
            class="required-field"
            v-model="client.name"
            :items="allParsedClients"
            item-text="name"
            item-value="name"
            :label="$t('nameAndLastName')"
            :rules="[
              nameRules.required,
              value =>
                isValidClientName(value) || $t('nameAndLastNameAreRequired')
            ]"
            required
            return-object
            @change="clientToSearchByName"
            v-if="isNewOrder() || (isNewClient() && isEditOrder())"
            @keydown="setAsNewClientByName"
            dense
            :no-data-text="$t('thereIsNotAvailableData')"
          >
            <template v-slot:no-data>
              <span>{{ $t('thereIsNotAvailableData') }}</span>
            </template>
          </v-combobox>

          <v-autocomplete
            class="required-field"
            v-model="client.name"
            :items="allParsedClients"
            item-text="name"
            item-value="name"
            :label="$t('nameAndLastName')"
            :rules="[nameRules.required]"
            required
            return-object
            @change="clientToSearchByName"
            v-if="isEditOrder() && isOldClient()"
            dense
            :no-data-text="$t('thereIsNotAvailableData')"
          ></v-autocomplete>

          <v-text-field
            class="required-field"
            v-model.number="client.phone"
            :label="$t('phone')"
            :rules="[phoneRules.required]"
            required
            type="number"
            :readonly="isOldClient()"
            dense
          ></v-text-field>

          <v-checkbox
            v-model="hasPriority"
            :label="$t('priorityClient')"
            dense
          ></v-checkbox>

          <v-divider></v-divider>
          <p class="font-weight-bold primary--text" v-if="isNotQuickService()">
            <br />
            {{ $t('engine') }}
          </p>

          <v-text-field
            class="required-field"
            v-model="specification.engine"
            :label="$t('engine')"
            :rules="[engineRules.required]"
            required
            v-if="isNotQuickService()"
            dense
          ></v-text-field>
          <v-row v-if="isNotQuickService()">
            <v-col cols="6">
              <v-combobox
                class="required-field"
                v-model="specification.brand"
                :items="allBrands"
                item-text="name"
                item-value="name"
                :label="$t('brand')"
                :rules="[brandRules.required]"
                required
                :return-object="false"
                dense
              ></v-combobox
            ></v-col>
            <v-col cols="3">
              <v-select
                class="required-field"
                :items="nOfCylindersToSelect"
                v-model="number"
                :label="$t('nOfCylinders')"
                :rules="[nOfCylindersRules.required]"
                required
                item-text="number"
                item-value="number"
                dense
              ></v-select>
            </v-col>
            <v-col cols="3">
              <v-select
                class="required-field"
                :items="cylinderArrangementsToSelect"
                v-model="arrangement"
                :label="$t('arrangement')"
                :rules="[arrangementRules.required]"
                required
                item-text="arrangement"
                item-value="arrangement"
                dense
              ></v-select>
            </v-col>
          </v-row>

          <v-checkbox
            v-model="isQuickService"
            :label="$t('quickService')"
            @change="sendQuickService"
            dense
          ></v-checkbox>

          <v-text-field
            class="required-field"
            v-model="quickService.name"
            :label="$t('otherServiceName')"
            :rules="[quickServiceNameRules.required]"
            required
            dense
            v-if="isQuickService"
          ></v-text-field>

          <v-text-field
            class="required-field"
            v-model.number="quickService.price"
            :label="$t('otherServicePrice')"
            :rules="[
              quickServicePriceRules.required,
              quickServicePriceRules.positive
            ]"
            required
            type="number"
            dense
            v-if="isQuickService"
          ></v-text-field>

          <v-divider></v-divider>
          <p class="font-weight-bold primary--text">
            <br />
            {{ $t('note') }}
          </p>

          <v-text-field
            v-model="localNote"
            @input="updateNote"
            :label="$t('note')"
            dense
          >
          </v-text-field>

          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              right
              color="primary"
              class="mb-2 ml-2"
              @click="clearClientSection"
              v-if="isNewOrder() || (isNewClient() && isEditOrder())"
            >
              {{ $t('clearForm') }}
            </v-btn>
          </v-row>
        </v-container>
      </v-form>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { EMPTY_GUID } from '@/constants/ConfigurationConstants';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ClientSection',

  props: ['orderId', 'sectionName', 'note'],

  data: () => ({
    isValid: false,
    number: '',
    arrangement: '',
    localNote: '',
    client: {
      id: EMPTY_GUID,
      name: '',
      phone: null,
      ci: null
    },
    specification: {
      engine: '',
      brand: '',
      numberOfCylinders: ''
    },
    quickService: {
      id: 'quickService',
      isOther: true,
      name: '',
      price: null,
      subServices: [],
      currencyCode: 'BOB',
      quantity: 1,
      isFinished: false
    },
    isQuickService: false,
    hasPriority: false,
    defaultClient: {
      id: EMPTY_GUID,
      name: '',
      phone: null,
      ci: null
    },
    defaultSpecification: {
      engine: '',
      brand: '',
      numberOfCylinders: ''
    },
    defaultQuickService: {
      id: 'quickService',
      isOther: true,
      name: '',
      price: null,
      subServices: [],
      currencyCode: 'BOB',
      quantity: 1,
      isFinished: false
    },
    defaultCylinder: {
      id: EMPTY_GUID,
      number: -1,
      name: '',
      position: ''
    },
    defaultArrangement: {
      id: EMPTY_GUID,
      arrangement: ''
    }
  }),

  created() {
    this.retrieveAllDataClients();
  },

  computed: {
    ...mapGetters('clients', ['allDataClients']),
    ...mapGetters('cylinders', ['allCylinders', 'allCylinderArrangements']),
    ...mapGetters('brands', ['allBrands']),

    allParsedClients() {
      let parsedClients = [];
      this.allDataClients.forEach(client => {
        parsedClients.push({
          address: client.address,
          ci: client.ci,
          id: client.id,
          name: client.name + ' ' + client.lastName,
          phone: client.phone
        });
      });
      return parsedClients;
    },

    nameRules() {
      return {
        required: value => !!value || this.$t('nameAndLastNameAreRequired')
      };
    },

    idRules() {
      return { required: value => !!value || this.$t('idIsRequired') };
    },

    phoneRules() {
      return { required: value => !!value || this.$t('phoneIsRequired') };
    },

    engineRules() {
      return { required: value => !!value || this.$t('engineIsRequired') };
    },

    brandRules() {
      return { required: value => !!value || this.$t('brandIsRequired') };
    },

    nOfCylindersRules() {
      return {
        required: value => !!value || this.$t('nOfCylindersIsRequired')
      };
    },

    arrangementRules() {
      return {
        required: value => !!value || this.$t('arrangementIsRequired')
      };
    },

    quickServiceNameRules() {
      return { required: value => !!value || this.$t('serviceIsRequired') };
    },

    quickServicePriceRules() {
      return {
        required: value => !!value || this.$t('priceIsRequired'),
        positive: value => value >= 0 || this.$t('priceMustBePositive')
      };
    },

    nOfCylindersToSelect() {
      var cylind = this.allCylinders;
      if (this.number && !cylind.some(el => el.number === this.number)) {
        let customCylinderOption = this.defaultCylinder;
        customCylinderOption.number = this.number;
        return [customCylinderOption].concat(cylind);
      } else {
        return cylind;
      }
    },

    cylinderArrangementsToSelect() {
      var arr = this.allCylinderArrangements;
      if (
        this.arrangement &&
        !arr.some(el => el.arrangement === this.arrangement)
      ) {
        let customCylinderArrangementOption = this.defaultArrangement;
        customCylinderArrangementOption.arrangement = this.arrangement;

        return [customCylinderArrangementOption].concat(arr);
      } else {
        return arr;
      }
    },

    nOfCylinderParts() {
      return `${this.number}|${this.arrangement}`;
    }
  },

  watch: {
    nOfCylinderParts(newVal, oldVal) {
      if (newVal !== oldVal) {
        const [newNumber, newArrangement] = newVal.split('|');

        if (
          (newNumber || newNumber !== '') &&
          (newArrangement || newArrangement !== '')
        ) {
          this.specification.numberOfCylinders = newNumber + newArrangement;
        }
      }
    },

    isValid: {
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$emit('validated-client-data', this.isValid);
        }
      }
    },

    isQuickService: {
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal) {
          if (newVal) {
            this.specification = Object.assign({}, this.defaultSpecification);
          } else {
            this.quickService = Object.assign({}, this.defaultQuickService);
          }
        }
      }
    },

    note(newVal) {
      this.localNote = newVal;
    }
  },

  methods: {
    ...mapActions('clients', ['retrieveAllDataClients']),

    clearClientSection() {
      this.resetValidations();
      this.clearSearchClient();
      this.clearEngine();
      this.localNote = '';
    },

    clearSearchClient() {
      this.retrieveAllDataClients();
      this.resetValidations();
      this.client = Object.assign({}, this.defaultClient);
      this.hasPriority = false;
    },

    setNameClientAsNew() {
      this.client.name = '';
    },

    setCiClientAsNew() {
      this.client.ci = null;
    },

    setPhoneClientAsNew() {
      this.client.phone = null;
    },

    clearEngine() {
      this.specification = Object.assign({}, this.defaultSpecification);
    },

    clientToSearchByName(e) {
      if (e !== null) {
        if (!this.isString(e)) this.client = Object.assign({}, e);
      }
    },

    clientToSearchByCi(e) {
      if (e !== null) {
        if (this.isString(e)) {
          if (e !== '') {
            this.client.ci = parseInt(e);
          } else {
            this.client.ci = null;
          }
        } else {
          this.client = Object.assign({}, e);
        }
      }
    },

    isNotQuickService() {
      return !this.isQuickService;
    },

    isString(value) {
      return typeof value === 'string' || value instanceof String;
    },

    sendQuickService(e) {
      this.resetValidations();
      this.$emit('send-quick-service', e);
    },

    sendClientAndEngineData() {
      return {
        client: Object.assign({}, this.client),
        specification: Object.assign({}, this.specification),
        hasPriority: this.hasPriority,
        quickService: Object.assign({}, this.quickService),
        isQuickService: this.isQuickService
      };
    },

    cleanSection() {
      this.resetValidations();
      this.clearClientSection();
      this.quickService = Object.assign({}, this.defaultQuickService);
      this.isQuickService = false;
      this.hasPriority = false;
      this.number = '';
      this.arrangement = '';
    },

    setClientDataToEdit(client) {
      client.name = client.name + ' ' + client.lastName;
      delete client.lastName;
      this.client = Object.assign({}, client);
    },

    setSpecificationDataToEdit(engine) {
      this.specification = Object.assign({}, engine);
      var num = this.specification.numberOfCylinders.match(/\d+/g);
      if (num) {
        this.number = Number(num[0]);
      } else {
        this.number = '';
      }
      this.allCylinders.push();
      var arr = this.specification.numberOfCylinders.match(/[a-zA-Z ]+/g);
      if (arr) {
        this.arrangement = arr[0];
      } else {
        this.arrangement = '';
      }
    },

    setIsQuickServiceDataToEdit(isQuickService) {
      this.isQuickService = isQuickService;
    },

    setQuickServiceDataToEdit(quickService) {
      this.quickService = Object.assign({}, quickService);
    },

    setHasPriorityDataToEdit(hasPriority) {
      this.hasPriority = hasPriority;
    },

    isNewOrder() {
      return this.orderId === '-1';
    },

    isEditOrder() {
      return this.orderId !== '-1';
    },

    isNewClient() {
      return this.client.id === EMPTY_GUID;
    },

    isOldClient() {
      return this.client.id !== EMPTY_GUID;
    },

    isNewClientOnEditOrder() {
      return this.isEditOrder() && this.isNewClient();
    },

    isValidClientName(value) {
      let splitedName = value.split(' ');
      if (value === '' || splitedName.length < 2 || splitedName[1] === '') {
        return false;
      } else {
        return true;
      }
    },

    setAsNewClientByCi() {
      if (this.isOldClient()) {
        this.client = Object.assign(
          {},
          {
            id: EMPTY_GUID,
            name: '',
            phone: null,
            ci: this.client.ci
          }
        );
      }
    },

    setAsNewClientByName() {
      if (this.isOldClient()) {
        this.client = Object.assign(
          {},
          {
            id: EMPTY_GUID,
            name: this.client.name,
            phone: null,
            ci: null
          }
        );
      }
    },

    getSectionStyle() {
      let sectionsHeight = this.isQuickService ? 140 : 300;
      return {
        '--window-height': window.innerHeight + 'px',
        '--window-width': window.innerWidth + 'px',
        '--sections-height': sectionsHeight + 'px'
      };
    },

    resetValidations() {
      this.$refs.clientSectionForm.resetValidation();
    },

    updateNote() {
      this.$emit('update:note', this.localNote);
    }
  }
};
</script>
