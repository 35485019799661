import Vue from 'vue';
import App from './App.vue';
import router from './router';
import i18n from './config/i18n';
import store from './store/Store';
import vuetify from './plugins/vuetify';
import './filters/currency.js';
import './filters/letter.js';
import './GlobalStyle.css';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app');
