<template>
  <v-menu
    v-model="contextMenu.show"
    absolute
    close-on-click
    close-on-content-click
    class="context-menu"
    :position-x="contextMenu.position.x"
    :position-y="contextMenu.position.y"
  >
    <v-list>
      <template v-for="(action, actionIndex) in actionsToShow">
        <v-list-item
          :key="actionIndex"
          @click.stop="
            () => {
              action.action(data);
              contextMenu.show = false;
            }
          "
          :disabled="!action.action"
          v-if="action.isAction"
        >
          <v-list-item-icon class="mr-2">
            <v-icon
              :disabled="!action.action"
              v-if="action.icon"
              small
              color="primary"
              class="mr-2"
              v-text="action.icon"
            ></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="action.label"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider :key="actionIndex" v-else></v-divider>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'ContextMenu',

  data() {
    return {
      actions: [],
      contextMenu: {
        show: false,
        position: {
          x: 0,
          y: 0
        }
      }
    };
  },

  computed: {
    actionsToShow() {
      return this.actions.filter(a => a.show);
    }
  },

  created() {
    this.$root.$on('open-context-menu', this.openContextMenu);
  },

  beforeDestroy() {
    this.$root.$off('open-context-menu');
  },

  methods: {
    openContextMenu(menuOptions) {
      this.actions = menuOptions.actions;
      this.data = menuOptions.data;
      this.contextMenu.position.x = menuOptions.positionX;
      this.contextMenu.position.y = menuOptions.positionY;
      this.contextMenu.show = true;
    }
  }
};
</script>

<style scoped>
@import './ScopedStyles.css';
</style>
