'use strict';

const allBrandsReport = state => {
  return state.brandsReport.slice();
};

const allClientsReport = state => {
  return state.clientsReport.slice();
};

const finantialReport = state => {
  return Object.assign({}, state.finantialReport);
};

const pendingBillsReport = state => {
  return Object.assign({}, state.pendingBillsReport);
};

const allServiceReport = state => {
  return state.serviceReport.slice();
};

const allSubServiceReport = state => {
  return state.subServiceReport.slice();
};

const allSparePartsReport = state => {
  return state.sparePartsReport.slice();
};

const allSparePartsTrendReport = state => {
  return Object.assign({}, state.sparePartTrendReport);
};

const allOrdersReportByEngineBrandNumberCylinders = state => {
  return Object.assign({}, state.ordersReport);
};

const allWorkersReport = state => {
  return state.workersReport.slice();
};

const allDebtorsReport = state => {
  return state.debtorsReport.slice();
};

const allUnpickedOrdersReport = state => {
  return state.unpickedOrdersReport.slice();
};

export default {
  allBrandsReport,
  allClientsReport,
  finantialReport,
  pendingBillsReport,
  allSparePartsReport,
  allSparePartsTrendReport,
  allServiceReport,
  allOrdersReportByEngineBrandNumberCylinders,
  allWorkersReport,
  allSubServiceReport,
  allDebtorsReport,
  allUnpickedOrdersReport
};
