'use strict';

const allRoles = state => {
  return state.roles.slice();
};

const getAdminId = state => {
  let role = state.roles.find(r => r.roleName === 'Administrator');
  if (role) return role.id;
  else return role;
};

const getWorkerId = state => {
  let role = state.roles.find(r => r.roleName === 'Worker');
  if (role) return role.id;
  else return role;
};

const getSupervisorId = state => {
  let role = state.roles.find(r => r.roleName === 'Supervisor');
  if (role) return role.id;
  else return role;
};

const getCashierId = state => {
  let role = state.roles.find(r => r.roleName === 'Cashier');
  if (role) return role.id;
  else return role;
};

export default {
  allRoles,
  getAdminId,
  getWorkerId,
  getSupervisorId,
  getCashierId
};
