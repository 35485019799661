import { render, staticRenderFns } from "./WorkersReport.vue?vue&type=template&id=2f2d107e&scoped=true"
import script from "./WorkersReport.vue?vue&type=script&lang=js"
export * from "./WorkersReport.vue?vue&type=script&lang=js"
import style0 from "./WorkersReport.vue?vue&type=style&index=0&id=2f2d107e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f2d107e",
  null
  
)

/* custom blocks */
import block0 from "./resources/locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fgithub%2Fworkspace%2Fsrc%2Fviews%2Freports%2Fworkers-report%2FWorkersReport.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports