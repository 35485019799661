import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = () => ({
  orderWorkDoneList: [],
  orderWorkDoneDetailList: [],
  orderId: ''
});

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
