'use strict';

const allClients = state => {
  return state.clients.slice();
};

const allDataClients = state => {
  return state.allClients.slice();
};

const getClientListPaginationProps = state => {
  return Object.assign({}, state.clientsPaginationProps);
};

export default {
  allClients,
  allDataClients,
  getClientListPaginationProps
};
