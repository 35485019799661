<i18n src="./resources/locales.json"></i18n>

<template>
  <v-container>
    <h1 class="ml-3 primary--text">{{ $t('clientsReport') }}</h1>
    <h2 class="ml-3 primary--text">
      {{ $t('customersThatFrequentTheRectifierTheMost') }}
    </h2>
    <div class="mt-10 ml-16 mb-n12 mr-16">
      <BarChart :chartData="chartData" />
      <p v-if="isReportsMoreThatTen()">
        {{ $t('remainingFrequentClients') }}
      </p>
    </div>
    <v-data-table
      :headers="headers"
      :items="getTableData()"
      class="elevation-1 ma-16"
      :items-per-page="5"
      v-if="isReportsMoreThatTen()"
    >
    </v-data-table>
    <LoadingMask :isLoading="getIsClientReportLoading" />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BarChart from '../../../components/reusable-components/chart/bar-chart/BarChart.vue';
import LoadingMask from '@/components/reusable-components/loading-mask/LoadingMask.vue';

export default {
  name: 'ClientsReport',

  components: {
    BarChart,
    LoadingMask
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t('client'),
          value: 'client.name',
          align: 'center'
        },
        {
          text: this.$t('ordersQuantity'),
          value: 'numberOfOrders',
          align: 'center'
        }
      ];
    },

    ...mapGetters('reports', ['allClientsReport']),
    ...mapGetters('loading', ['getIsClientReportLoading']),

    chartData() {
      let chartColors = [
        '#00CC00',
        '#CC0000',
        '#1F618D',
        '#F1C40F',
        '#27AE60',
        '#884EA0',
        '#D35400',
        '#66FFCC',
        '#641E16',
        '#6600FF',
        '#AEB6BF'
      ];
      let chartReports = this.getChartData();
      let labels = chartReports.map(({ client }) => client.name);
      let data = chartReports.map(({ numberOfOrders }) => numberOfOrders);
      return {
        labels: labels,
        datasets: [
          {
            axis: 'y',
            label: this.$t('ordersQuantity'),
            data: data,
            fill: false,
            backgroundColor: chartColors,
            hoverBackgroundColor: chartColors
          }
        ]
      };
    },

    allClients() {
      return this.allClientsReport.map(c => {
        return {
          ...c,
          client: {
            id: c.client.id,
            name: c.client.name + ' ' + c.client.lastName,
            phone: c.client.phone,
            address: c.client.address,
            ci: c.client.ci
          }
        };
      });
    }
  },

  created() {
    this.retrieveAllClientReports();
  },

  methods: {
    ...mapActions('reports', ['retrieveAllClientReports']),

    getChartData() {
      let chartData = this.allClients;
      let sumOthers = 0;

      if (this.isReportsMoreThatTen()) {
        chartData = this.allClients.slice(0, 10);
        sumOthers = this.getSumOthers();
        chartData.push({
          client: { name: this.$t('others') },
          numberOfOrders: sumOthers
        });
      }
      return chartData;
    },

    getTableData() {
      let tableData = [];
      if (this.isReportsMoreThatTen())
        tableData = this.allClients.slice(10, this.allClients.length);
      return tableData;
    },

    isReportsMoreThatTen() {
      return this.allClients.length > 10;
    },

    getSumOthers() {
      return this.getTableData().reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue.numberOfOrders,
        0
      );
    }
  }
};
</script>
