<i18n src="./resources/locales.json"></i18n>

<template>
  <v-container>
    <h1 class="ml-3 primary--text">{{ $t('serviceReport') }}</h1>
    <v-row
      class="tool-bar mt-12 ml-8 mb-n1"
      no-gutters
      style="flex-wrap: nowrap;"
    >
      <v-col cols="3" class="mt-n6 ">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="dates"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              background-color="primaryVariant2"
              :value="dateRangeText"
              :label="$t('dates')"
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              clearable
              @click:clear="clearPickedDate"
              hide-details
              outlined
              dense
              flat
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dates"
            range
            scrollable
            show-adjacent-months
            no-title
            :max="currentDate"
            :locale="$i18n.locale"
            color="primary"
            @change="verifyRangeChangesDate"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false">
              {{ $t('cancel') }}
            </v-btn>
            <v-btn color="primary" @click="savePickedDate">
              {{ $t('ok') }}
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="3">
        <v-select
          background-color="primaryVariant2"
          :items="servicesToSelect"
          item-text="name"
          return-object
          :label="$t('service')"
          v-model="selectedService"
          class="ml-5"
          @change="getServiceReport"
          outlined
          dense
          flat
        ></v-select>
      </v-col>
    </v-row>
    <v-data-table
      v-if="isAllServicesSelected"
      :headers="headersServiceReport"
      :items="allServiceReport"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 20],
        itemsPerPageText: 'Filas por pagina'
      }"
      :items-per-page="10"
      class="elevation-1 ma-8"
      :loading-text="'Cargando... Por favor espere'"
    >
      <template v-slot:[`footer.page-text`]="items">
        {{ items.pageStart }} - {{ items.pageStop }} de
        {{ items.itemsLength }}
      </template>
      <template v-slot:no-data>
        <p>No hay ordenes</p>
      </template>
    </v-data-table>

    <v-data-table
      v-if="!isAllServicesSelected"
      :headers="headersSubServiceReport"
      :items="allSubServiceReport"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 20],
        itemsPerPageText: 'Filas por pagina'
      }"
      :items-per-page="10"
      class="elevation-1 ma-8"
    >
      <template v-slot:[`footer.page-text`]="items">
        {{ items.pageStart }} - {{ items.pageStop }} de
        {{ items.itemsLength }}
      </template>
      <template v-slot:no-data>
        <p>No hay ordenes</p>
      </template>
    </v-data-table>
    <LoadingMask :isLoading="getIsServicesReportLoading" />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import LoadingMask from '@/components/reusable-components/loading-mask/LoadingMask.vue';
import {
  ALL_OPTIONS,
  ALL_SUBSERVICES
} from '@/constants/ConfigurationConstants';
import moment from 'moment';

export default {
  name: 'ServicesMoneyReport',

  data() {
    return {
      selectedService: null,
      dates: [],
      menu: false,
      currentDate: moment().format('YYYY-MM-DD'),
      availableDates: []
    };
  },

  components: {
    LoadingMask
  },

  computed: {
    ...mapGetters('reports', ['allServiceReport', 'allSubServiceReport']),
    ...mapGetters('services', ['allServices']),
    ...mapGetters('loading', ['getIsServicesReportLoading']),

    headersSubServiceReport() {
      return [
        {
          text: this.$t('position'),
          value: 'position',
          align: 'center',
          width: '25%'
        },
        {
          text: this.$t('subService'),
          value: 'subService.name',
          align: 'center',
          width: '25%'
        },
        {
          text: this.$t('service'),
          value: 'serviceName',
          align: 'center',
          width: '25%'
        },
        {
          text: this.$t('moneyGenerated'),
          value: 'moneyGenerated',
          align: 'center',
          width: '25%'
        }
      ];
    },

    headersServiceReport() {
      return [
        {
          text: this.$t('position'),
          value: 'position',
          align: 'center',
          width: '25%'
        },
        {
          text: this.$t('service'),
          value: 'service.name',
          align: 'center',
          width: '25%'
        },
        {
          text: this.$t('moneyGenerated'),
          value: 'moneyGenerated',
          align: 'center',
          width: '25%'
        }
      ];
    },

    allOptions() {
      return ALL_OPTIONS;
    },

    isAllServicesSelected() {
      const { id } = this.selectedService;
      return id === ALL_OPTIONS;
    },

    servicesToSelect() {
      return [this.allServicesOption, this.allSubServicesOption].concat(
        this.getAlphabeticallySortedAllServicesByName()
      );
    },

    allServicesOption() {
      return { id: ALL_OPTIONS, name: this.$t('allOptions') };
    },

    allSubServicesOption() {
      return { id: ALL_SUBSERVICES, name: this.$t('allSubServices') };
    },

    dateRangeText() {
      return this.dates
        .slice()
        .map(d => this.convertDate(d))
        .join(' - ');
    }
  },

  created() {
    this.retrieveAllServices();
    this.selectedService = {
      id: ALL_OPTIONS,
      name: this.$t('allOptions')
    };
    this.dates = [
      moment()
        .startOf('month')
        .format('YYYY-MM-DD'),
      this.currentDate
    ];
    this.getServiceReport();
  },

  methods: {
    ...mapActions('reports', [
      'retrieveAllServiceMoneyReport',
      'retrieveSubServiceMoneyReport',
      'retrieveAllSubServiceMoneyReport'
    ]),
    ...mapActions('services', ['retrieveAllServices']),

    getTableData() {
      let tableData = [];
      if (this.isReportsMoreThatTen())
        tableData = this.allServiceReport.slice(
          10,
          this.allServiceReport.length
        );
      return tableData;
    },

    isReportsMoreThatTen() {
      return this.allServiceReport.length > 10;
    },

    getSumOthers() {
      return this.getTableData().reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue.numberOfOrders,
        0
      );
    },

    isAllOptionsSelected() {
      const { id } = this.selectedService;
      return id === ALL_OPTIONS;
    },

    isAllSubServicesOptionsSelected() {
      const { id } = this.selectedService;
      return id === ALL_SUBSERVICES;
    },

    savePickedDate() {
      this.$refs.menu.save(this.dates);
      this.getServiceReport();
    },

    clearPickedDate() {
      this.dates = [];
      this.savePickedDate();
    },

    convertDate(date) {
      let convertedDate = moment(date).format('YYYY-MM-DD');
      const [year, month, day] = convertedDate.split('-');
      return `${day}/${month}/${year}`;
    },

    verifyRangeChangesDate() {
      const [first, second] = this.dates;
      if (first !== undefined && second !== undefined) {
        if (moment(first).isSame(second)) this.dates = [first];
        if (moment(first).isAfter(second)) this.dates = [second, first];
      }
    },

    getServiceReport() {
      const [first, second] = this.dates;

      let parameters = {};
      if (first) {
        parameters.fromDate = moment(first).format();
        parameters.toDate = this.setDateUntilLastMinute(first);
      }
      if (second) parameters.toDate = this.setDateUntilLastMinute(second);

      if (this.isAllOptionsSelected()) {
        this.retrieveAllServiceMoneyReport(parameters);
      } else if (this.isAllSubServicesOptionsSelected()) {
        this.retrieveAllSubServiceMoneyReport(parameters);
      } else {
        const { name } = this.selectedService;
        parameters.serviceName = name;
        this.retrieveSubServiceMoneyReport(parameters);
      }
    },

    getAlphabeticallySortedAllServicesByName() {
      return this.allServices.sort((s1, s2) => (s1.name > s2.name ? 1 : -1));
    },

    setDateUntilLastMinute(date) {
      return moment(date)
        .set({ hour: 23, minute: 59, second: 59 })
        .format();
    }
  }
};
</script>
<style scoped>
@import './ScopedStyles.css';
</style>
