<i18n src="./resources/locales.json"></i18n>

<template>
  <v-container>
    <h1 class="ml-3 primary--text">{{ $t('brandsReport') }}</h1>
    <v-row
      class="tool-bar mt-10 ml-8 mb-10 mr-16"
      no-gutters
      style="flex-wrap: nowrap;"
    >
      <v-col cols="4">
        <v-select
          background-color="primaryVariant2"
          :items="dateRangeOptions"
          item-text="name"
          return-object
          :label="$t('dateRanges')"
          v-model="selectedDateRange"
          @change="getOrdersReport"
          outlined
          dense
          flat
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-select
          background-color="primaryVariant2"
          :items="brandsToSelect"
          item-text="name"
          return-object
          :label="$t('brand')"
          v-model="selectedBrand"
          class="ml-5"
          @change="getOrdersReport"
          outlined
          dense
          flat
        ></v-select>
      </v-col>

      <v-col cols="3" class="ml-5" style="width: 50px;">
        <template v-if="this.selectedBrand.id !== allOptions">
          <v-radio-group
            inline
            :label="$t('reportBy')"
            v-model="seriesOrCylinders"
            @change="getOrdersReport"
          >
            <v-radio :label="$t('series')" value="series"></v-radio>
            <v-radio :label="$t('cylinder')" value="cylinder"></v-radio>
          </v-radio-group>
        </template>
      </v-col>
    </v-row>
    <v-row
      class="tool-bar mt-n10 ml-8 mb-2 mr-16"
      no-gutters
      style="flex-wrap: nowrap;"
    >
      <v-col cols="4">
        <template v-if="!selectedDateRange.dateRange">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="dates"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                background-color="primaryVariant2"
                :value="dateRangeText"
                :label="$t('dates')"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                clearable
                @click:clear="clearPickedDate"
                hide-details
                outlined
                dense
                flat
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dates"
              range
              scrollable
              show-adjacent-months
              no-title
              :max="currentDateFormatted"
              :locale="$i18n.locale"
              color="primary"
              @change="verifyRangeChangesDate"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">
                {{ $t('cancel') }}
              </v-btn>
              <v-btn color="primary" @click="savePickedDate">
                {{ $t('ok') }}
              </v-btn>
            </v-date-picker>
          </v-menu>
        </template>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <LineChart
          :chartData="chartData()"
          :aditionalOptions="aditionalChartOptions()"
        />
      </v-col>
    </v-row>
    <LoadingMask :isLoading="getIsOrdersReportByEngineBrandNumberCylinders" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import LineChart from '../../../components/reusable-components/chart/line-chart/LineChart.vue';
import LoadingMask from '@/components/reusable-components/loading-mask/LoadingMask.vue';
import { ALL_OPTIONS } from '@/constants/ConfigurationConstants';
import moment from 'moment';

export default {
  name: 'BrandsReport',

  data() {
    return {
      selectedBrand: {
        id: ALL_OPTIONS,
        name: this.$t('allBrandsOption')
      },
      selectedDateRange: null,
      selectedCylinder: null,
      selectedNumber: 0,
      selectedArrangement: '',
      seriesOrCylinders: 'series',
      dates: [],
      menu: false,
      chartTitleInfo: this.$t('brands'),
      currentDate: moment().startOf('day'),
      currentDateFormatted: moment()
        .startOf('day')
        .format('YYYY-MM-DD'),
      availableDates: [],
      chartColors: [
        '#00CC00',
        '#CC0000',
        '#1F618D',
        '#F1C40F',
        '#27AE60',
        '#884EA0',
        '#D35400',
        '#66FFCC',
        '#641E16',
        '#6600FF',
        '#AEB6BF'
      ],
      monthNames: [
        this.$t('january'),
        this.$t('february'),
        this.$t('march'),
        this.$t('april'),
        this.$t('may'),
        this.$t('june'),
        this.$t('july'),
        this.$t('august'),
        this.$t('september'),
        this.$t('october'),
        this.$t('november'),
        this.$t('december')
      ]
    };
  },

  components: {
    LineChart,
    LoadingMask
  },

  computed: {
    ...mapGetters('reports', ['allOrdersReportByEngineBrandNumberCylinders']),
    ...mapGetters('brands', ['allBrands']),
    ...mapGetters('loading', ['getIsOrdersReportByEngineBrandNumberCylinders']),

    allOptions() {
      return ALL_OPTIONS;
    },

    brandsToSelect() {
      return [this.allBrandsOption].concat(this.allBrands);
    },

    allBrandsOption() {
      return { id: ALL_OPTIONS, name: this.$t('allBrandsOption') };
    },

    dateRangeText() {
      return this.dates
        .slice()
        .map(d => this.convertDate(d))
        .join(' - ');
    },

    dateRangeOptions() {
      let options = [
        {
          name: this.$t('last7Days'),
          dateRange: [
            this.currentDate
              .clone()
              .subtract(7, 'days')
              .format('YYYY-MM-DD'),
            this.currentDateFormatted
          ]
        },
        {
          name: this.$t('last30Days'),
          dateRange: [
            this.currentDate
              .clone()
              .subtract(30, 'days')
              .startOf('day')
              .format('YYYY-MM-DD'),
            this.currentDateFormatted
          ]
        },
        {
          name: this.$t('last3Months'),
          dateRange: [
            this.currentDate
              .clone()
              .subtract(3, 'months')
              .startOf('day')
              .format('YYYY-MM-DD'),
            this.currentDateFormatted
          ]
        },
        {
          name: this.$t('last6Months'),
          dateRange: [
            this.currentDate
              .clone()
              .subtract(6, 'months')
              .startOf('day')
              .format('YYYY-MM-DD'),
            this.currentDateFormatted
          ]
        },
        {
          name: this.$t('lastYear'),
          dateRange: [
            this.currentDate
              .clone()
              .subtract(1, 'year')
              .startOf('day')
              .format('YYYY-MM-DD'),
            this.currentDateFormatted
          ]
        },
        {
          name: this.$t('last2Years'),
          dateRange: [
            this.currentDate
              .clone()
              .subtract(2, 'year')
              .startOf('day')
              .format('YYYY-MM-DD'),
            this.currentDateFormatted
          ]
        },
        {
          name: this.$t('currentMonth'),
          dateRange: [
            moment()
              .startOf('month')
              .format('YYYY-MM-DD'),
            this.currentDateFormatted
          ]
        },
        {
          name: this.$t('currentYear'),
          dateRange: [
            moment()
              .startOf('year')
              .format('YYYY-MM-DD'),
            this.currentDateFormatted
          ]
        },
        {
          name: this.$t('customDateRange')
        }
      ];
      return options;
    }
  },

  created() {
    this.selectedDateRange = {
      name: this.$t('currentMonth'),
      dateRange: [
        moment()
          .startOf('month')
          .format('YYYY-MM-DD'),
        this.currentDate
      ]
    };
    this.retrieveAllBrands();

    this.getOrdersReport();
  },

  methods: {
    ...mapActions('reports', [
      'retrieveAllBrandsTrendReport',
      'retrieveBrandTrendReport'
    ]),
    ...mapActions('brands', ['retrieveAllBrands']),

    chartData() {
      let labels = this.allOrdersReportByEngineBrandNumberCylinders
        .listOfDateTimes;

      if (labels !== undefined) {
        if (
          labels[0] !== undefined &&
          /^\d+$/.test(labels[0]) &&
          Number(labels[0]) < 1500
        ) {
          //test if element in labels[0] can be converted to int
          labels = labels.map(
            label => (label = this.monthNames[+label - 1]) //unarPlus conversion to int from string already formated as int
          );
        }

        return {
          labels: labels,
          datasets: this.getDataSets(),
          options: {
            scales: {
              y: {
                title: {
                  display: true,
                  text: 'Y axis title'
                }
              }
            }
          }
        };
      }
    },

    getDataSets() {
      let datasets = [];
      let brandReportsList = this.allOrdersReportByEngineBrandNumberCylinders
        .brandReports;

      brandReportsList.forEach((element, index) => {
        let dataset = {
          data: element.numberOfOrdersInTimePeriod,
          borderColor: this.chartColors[index],
          fill: false,
          tension: 0
        };

        if (element.series === null && element.cylinder === null) {
          dataset.label = element.brand.name;
        } else if (element.series !== null) {
          dataset.label = element.series;
        } else if (element.cylinder !== null) {
          dataset.label = element.cylinder;
        }

        datasets.push(dataset);
      });
      return datasets;
    },

    aditionalChartOptions() {
      return {
        title: {
          display: true,
          text: 'Top 10 '
        },
        scales: {
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: this.$t('nOfAppearances')
              },
              ticks: {
                precision: 0
              }
            }
          ]
        }
      };
    },

    isAllOptionsSelected(selected) {
      const { id } = selected;
      return id === ALL_OPTIONS;
    },

    savePickedDate() {
      this.$refs.menu.save(this.dates);
      this.getOrdersReport();
    },

    clearPickedDate() {
      this.dates = [];
      this.savePickedDate();
    },

    convertDate(date) {
      let convertedDate = moment(date).format('YYYY-MM-DD');
      const [year, month, day] = convertedDate.split('-');
      return `${day}/${month}/${year}`;
    },

    verifyRangeChangesDate() {
      const [first, second] = this.dates;
      if (first !== undefined && second !== undefined) {
        if (moment(first).isSame(second)) this.dates = [first];
        if (moment(first).isAfter(second)) this.dates = [second, first];
      }
    },

    getOrdersReport() {
      let parameters = {};
      if (this.selectedDateRange.dateRange) {
        const [first, second] = this.selectedDateRange.dateRange;
        parameters.fromDate = moment(first).format();
        parameters.toDate = moment(second).format();
      } else {
        const [first, second] = this.dates;
        if (first) {
          parameters.fromDate = moment(first).format();
          parameters.toDate = this.setDateUntilLastMinute(first);
        }
        if (second) parameters.toDate = this.setDateUntilLastMinute(second);
      }

      if (this.selectedDateRange.dateRange || this.dates.some(x => x)) {
        if (this.selectedBrand.id === ALL_OPTIONS) {
          this.retrieveAllBrandsTrendReport(parameters);
        } else {
          parameters.brandName = this.selectedBrand.name;
          parameters.seriesOrCylinders = this.seriesOrCylinders;
          this.retrieveBrandTrendReport(parameters);
        }
      }
    },

    setDateUntilLastMinute(date) {
      return moment(date)
        .set({ hour: 23, minute: 59, second: 59 })
        .format();
    }
  }
};
</script>
<style scoped>
@import './ScopedStyles.css';
</style>
